export const SIGNIN_VALIDATION_RULES = {
  email: {
    required: true,
    validations: [{ key: "required" }, { key: "email" }]
  },
  password: { required: true, validations: [{ key: "required" }] },
  country: {
    required: true, validations: [{ key: 'objectKeyRequired', value: 'countryCode' }]
  }
};

export const EAAS_SET_PASSWORD_VALIDATION_RULES = {
  email: {
    required: true,
    validations: [{ key: "required" }, { key: "email" }]
  },
  password: { required: true, validations: [{ key: "required" }, { key: "passwordCheck" }] },
}

export const EAAS_CHANGE_PASSWORD_VALIDATION_RULES = {
  password: {
    required: true, validations: [{ key: "required" }, { key: "passwordCheck" }]
  },
  confirmPassword: {
    required: true, validations: [{ key: "required" }, { key: "confirmPwd", value: "password" }]
  }
}

export const FORGOT_PWD_VALIDATIONS = {
  email: {
    required: true,
    validations: [{ key: "required" }, { key: "email" }]
  }
};

export const RAZORPAY_UPI_VALIDATIONS = {
  phone: {
    required: true,
    validations: [{ key: "required" }]
  },
  upiId: {
    required: true,
    validations: [{ key: "required" }]
  }
};

export const APP_SIGNIN_VALIDATION_RULES = {
  email: { required: true, validations: [{ key: "required" }, { key: "email" }] },
  password: { required: true, validations: [{ key: "required" }] },
};

export const FORGOT_PASSWORD_VALIDATIONS = {
  email: {
    required: true,
    validations: [{ key: "required" }, { key: "email" }]
  }
};

export const SIGNUP_VALIDATION_RULES = {
  firstName: {
    required: true,
    validations: [{ key: "required" }]
  },
  lastName: {
    required: true,
    validations: [{ key: "required" }]
  },
  email: {
    required: true,
    validations: [{ key: "required" }, { key: "email" }]
  },
  password: { required: true, validations: [{ key: "required" }] }
};

export const APP_SIGNUP_VALIDATION_RULES = {
  firstName: {
    required: true,
    validations: [{ key: "required" },{ key: "char-and-apostrophe-only"}]
  },
  lastName: {
    required: true,
    validations: [{ key: "required" },{ key: "char-and-apostrophe-only"}]
  },
  email: {
    required: true,
    validations: [{ key: "required" }, { key: "email" }]
  },
  password: { required: true, validations: [{ key: "required" }, { key: "passwordCheck" }] },
  businessName: {
    required: true,
    validations: [{ key: "required" }]
  },
};

export const ACCOUNT_TYPE_VALIDATIONS = {
  entityType: {
    required: true,
    validations: [{ key: "required" }]
  },
  businessName: {
    required: true,
    validations: [{ key: "required" }]
  },
  firstName: {
    required: true,
    validations: [{ key: "required" }]
  },
  lastName: {
    required: true,
    validations: [{ key: "required" }]
  },
  country: {
    required: true,
    validations: [{ key: 'objectKeyRequired', value: 'name' }]
  },
};

export const TRANSACTION_SUMMARY_VALIDATIONS = {
  paymentFor: { required: true, validations: [{ key: "required" }] },
  country: { required: true, validations: [{ key: "required" }] },
  // invoiceAmount: { required: true, validations: [{ key: "required" }, { key: "numberOnly" }, { key: "minmax" }] }
  invoiceAmount: { required: true, validations: [{ key: "required" }, { key: "numberOnly" }] }

};

export const TRANSACTION_DETAIL_VALIDATIONS = {
  contactEmail: { required: true, validations: [{ key: "required" }, { key: "email" }] },
  contactName: { required: true, validations: [{ key: "required" }, { key: "charlimit" }] },
  companyName: { required: true, validations: [{ key: "charlimit" }] },
  transactionDescription: { required: true, validations: [{ key: "required" }, { key: "charlimit" }] },
  // files: { required: true, validations: [{ key: "arrayLength" }] },

};

export const PAYMENT_RELEASE_CONDITIONS = {
  additionalDocuments: { required: true, validations: [{ key: "charlimit" }] },
};

export const BANK_DETAIL_VALIDATIONS = {
  nameOnBank: { required: true, validations: [{ key: "required" }, { key: "charlimit" }, { key: "nospecialchar" }] },
  country: { required: true, validations: [{ key: "required" }] },
  currency: { required: true, validations: [{ key: "required" }] },
  bankName: { required: true, validations: [{ key: "required" }, { key: "charlimit" }, { key: "nospecialchar" }] },
  accountNo: { required: true, validations: [{ key: "required" }, { key: "charlimit" }, { key: "nospace" }, { key: "nospecialchar" }, { key: "alphanumericonly" }] },
  bankCode: { required: true, validations: [{ key: "required" }, { key: "nospecialchar" }, { key: "nospace" }, { key: "charlimit" }] },
  address: { required: true, validations: [{ key: "required" }] },
  city: { required: true, validations: [{ key: "required" }] },
  state: { required: true, validations: [{ key: "required" }] },
  zipcode: { required: true, validations: [{ key: "required" },{key:"numberOnly"}] },


};

export const CONTACT_CODE_VALIDATIONS = {
  contactCode: { required: true, validations: [{ key: "required" }] },
  contactNo: { required: true, validations: [{ key: "required" }, { key: "phoneNoCheck", value: "contactCode" }] },
};

export const DEBIT_CARD_VALIDATIONS = {
  firstName: { required: true, validations: [{ key: "required" }] },
  lastName: { required: true, validations: [{ key: "required" }] },
  emailId: { required: true, validations: [{ key: "required" }, { key: "email" }] },
  address1: { required: true, validations: [{ key: "required" }] },
  city: { required: true, validations: [{ key: "required" }] },
  zipcode: { required: true, validations: [{ key: "required" }, { key: "numberOnly" }] },
  country: { required: true, validations: [{ key: "required" }] },
  state: { required: true, validations: [{ key: "required" }] },
};

export const CREATE_ESCROW_USER = {
  accountType: { required: true, validations: [{ key: "required" }] },
  companyName: { required: true, validations: [{ key: "required" }] },
}


export const SELLER_DETAILS = {
  sellerName: { required: true, validations: [{ key: "required" }] },
}

export const BUYER_DETAILS = {
  buyerName: { required: true, validations: [{ key: "required" }] },
}

export const TRANSACTION_INVOICE_DETAILS = {
  invoiceAmount: { required: true, validations: [{ key: "required" }, { key: "numberOnly" }] },
  transactionDescription: { required: true, validations: [{ key: "required" }, { key: "charlimit" }] },
}

export const STRIPE_VALIDATIONS = {
  cardHolderName: { required: true, validations: [{ key: "required" }] },
}

export const EDIT_API_CONFIG = {
  platformName: { required: true, validations: [{ key: "required" }, { key: "charlimit" }] },
  paymentFor: { required: true, validations: [{ key: "required" }] },
  buyerPercentage: { required: true, validations: [{ key: "required" }] }
}

export const RESET_PWD_VALIDATIONS = {
  password: {
    required: true, validations: [{ key: "required" }, { key: "passwordCheck" }]
  },
  confirmPassword: {
    required: true, validations: [{ key: "required" }, { key: "confirmPwd", value: "password" }]
  }
}

export const EDIT_ACCOUNT_DETAILS_VALIDATIONS = {
  firstName: {
    required: true, validations: [{ key: "required" }, { key: "charonly" }]
  },
  lastName: {
    required: true, validations: [{ key: "required" }, { key: "charonly" }]
  },
  
}

export default {
  SIGNIN_VALIDATION_RULES, SIGNUP_VALIDATION_RULES, FORGOT_PASSWORD_VALIDATIONS,
  ACCOUNT_TYPE_VALIDATIONS, FORGOT_PWD_VALIDATIONS, TRANSACTION_SUMMARY_VALIDATIONS,
  TRANSACTION_DETAIL_VALIDATIONS, PAYMENT_RELEASE_CONDITIONS, DEBIT_CARD_VALIDATIONS,
  SELLER_DETAILS, BUYER_DETAILS, TRANSACTION_INVOICE_DETAILS, EDIT_API_CONFIG, 
  APP_SIGNUP_VALIDATION_RULES, RESET_PWD_VALIDATIONS,EDIT_ACCOUNT_DETAILS_VALIDATIONS,
  RAZORPAY_UPI_VALIDATIONS
};

import { getDateRange, formatToISOString } from "@/helpers/dateHelper.js";

function formDatesFromPeroid(datePeriod) {
  let dateRange = getDateRange(datePeriod);
  dateRange.to = dateRange.to.setDate(dateRange.to.getDate() + 1);
  let from_date = formatToISOString(dateRange.from);
  let to_date = formatToISOString(dateRange.to);
  return {
    from_date,
    to_date,
    date_period: datePeriod,
  };
}

function getCustomSearchDate() {
  let last7Days = getDateRange("last7days");
  let toDate = last7Days.to.setDate(last7Days.to.getDate() + 1);
  let custom_to_date = formatToISOString(toDate);
  let custom_7days_from = formatToISOString(last7Days.from);
  let last30Days = getDateRange("last30days");
  let custom_30days_from = formatToISOString(last30Days.from);
  let last90Days = getDateRange("last90days");
  let custom_90days_from = formatToISOString(last90Days.from);
  return {
    custom_to_date,
    custom_7days_from,
    custom_30days_from,
    custom_90days_from,
  };
}
export { formDatesFromPeroid, getCustomSearchDate };

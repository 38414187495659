const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const setStringifiedLocalStorage = (key, values) => {
  const jsonData = JSON.stringify(values);
  localStorage.setItem(key, jsonData);
};

const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

const removeLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

const setSessionStorage = (key, values) => {
  const jsonData = JSON.stringify(values);
  sessionStorage.setItem(key, jsonData);
};

const getSessionStorage = (key) => {
  return sessionStorage.getItem(key);
};

const removeSessionStorage = (key) => {
  return sessionStorage.removeItem(key);
};

const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};

function getCookie(name) {
  const cookieDecoded = decodeURIComponent(document.cookie);
  const cookieArray = cookieDecoded.split("; ");
  let result = null;

  cookieArray.forEach((element) => {
    if (element.indexOf(name) == 0) {
      result = element.substring(name.length + 1);
    }
  });
  return result;
}

function setCookie(name, value, years) {
  const date = new Date();
  date.setTime(date.getTime() + years * 365 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

function deleteCookie(name){
  setCookie(name, null, null);
}

export {
  setLocalStorage,
  setStringifiedLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
  clearStorage,
  getCookie,
  setCookie,
  deleteCookie
};

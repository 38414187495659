<template>
  <div class="tw-fixed custom-index tw-z-10 tw-inset-0 tw-overflow-y-auto">
    <div class="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-p-0">
      <!--
Background overlay, show/hide based on modal state.
Entering: “ease-out duration-300”
From: “opacity-0"
To: “opacity-100”
Leaving: “ease-in duration-200"
From: “opacity-100”
To: “opacity-0"
-->
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"></span>&#8203;
      <!--
      Modal panel, show/hide based on modal state.
      Entering: “ease-out duration-300"
      From: “opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95”
      To: “opacity-100 translate-y-0 sm:scale-100"
      Leaving: “ease-in duration-200”
      From: “opacity-100 translate-y-0 sm:scale-100"
      To: “opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95”-->
      <!-- Specifying the opacity on parent div-->
      <div class="popup-padding tw-fixed tw-left-0 tw-top-0 tw-overflow-auto tw-w-full tw-h-screen" style="background: rgba(68, 66, 72, 0.6); z-index: 79000; width: 100%">
        <transition enter-active-class="tw-transition tw-ease-out tw-duration-300 tw-transform"
          enter-class="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          enter-to-class="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave-active-class="tw-transition tw-ease-in tw-duration-200 tw-transform"
          leave-class="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave-to-class="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95">
          <div style="" @click="outside" class="tw-flex tw-justify-center tw-opacity-">
            <!-- Responsiveness : xl: extra large desktop, lg: Desktop, md: medium desktop, sm: tablet , default: for mobiles & all extra small devices -->
            <!-- adjust margin left, right and width according to device -->
            <div ref="content" class="tw-w-full tw-mb-24 tw-ml-4 tw-mr-4 sm:tw-ml-0 md:tw-ml-0 lg:tw-ml-0 xl:tw-ml-0 sm:tw-mr-0 md:tw-mr-0 lg:tw-mr-0 xl:tw-mr-0 sm:tw-w-11/12 md:tw-w-9/12 lg:tw-w-4/5
 tw-align-bottom tw-bg-white tw-rounded-lg tw-p-6 sm:tw-p-8 md:tw-p-8 lg:tw-p-8 xl:tw-p-8 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-align-middle tw-mt-3 dialog-mobile" :class="[dialogSizeSmall ? 'xl:tw-w-2/5': 'xl:tw-w-3/5']">
              <div role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div>
                  <div class="tw-flex tw-items-baseline sm:tw-items-center md:tw-items-center lg:tw-items-center xl:tw-items-center tw-justify-between tw-w-full">
                    <h6 class="tw-text-primary-700 tw-text-base sm:tw-text-xl md:tw-text-xl lg:tw-text-xl xl:tw-text-xl tw-leading-6 tw-font-semibold tw-mr-8 sm:tw-mr-0 md:tw-mr-0 lg:tw-mr-0 xl:tw-mr-0">{{heading}}</h6>                    
                    <span @click="hideModal">
                      <tc-icon icon="close" size="2xl" color="primary-700" class="tw-cursor-pointer"/>
                    </span>
                  </div>
                  <div class="tw-pt-4" v-if="heading !=''" style="border-bottom: 1px solid #EEEEEE;"></div>
                  <div class="tw-mt-8">
                    <slot></slot>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "XDialog",
  props: {
    heading: String,
    dialogSizeSmall: Boolean
  },
  data: function() {
    return {};
  },
  methods: {
    hideModal() {
      this.setDefaultScroll();
      this.$emit("hide", true);
    },
    // Close modal with 'esc' key
    closeOnKeyPress() {
      document.addEventListener("keydown", e => {
        if (e.keyCode == 27) {
          this.setDefaultScroll();
          this.$emit("close", true);
        }
      });
    },
    // Close Popup When we click Outside of the Popup
    outside(e) {
      if (this.$refs.content !== null) {
        return this.$refs.content.contains(e.target);
      }
      this.setDefaultScroll();
      this.$emit("outside", true);
    },

    // Set Normal Behaviour of scollbar when we close the popup
    setDefaultScroll() {
      let element = document.getElementsByTagName("html")[0];
      if (element) {
        element.style.overflow = "auto";
      }
    }
  },
  mounted() {
    this.closeOnKeyPress();
  }
};
</script>

<style scoped>

@media (min-width: 300px) {
  .popup-padding{
  padding-top: 100px !important;
}
}
@media (min-width: 900px) {
  .popup-padding{
  padding-top: 200px !important;
}
}
.custom-index{
  z-index: 999;
}


</style>

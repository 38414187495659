<template>
  <div class="tw-m-auto tw-flex">
    <div class="tw-flex tw-flex-col tw-items-center tw-px-4">
      <img src="@/assets/images/thankyou-sm.svg" />
      <div class="tw-mt-6 tw-text-center tw-text-xl tw-font-semibold tw-text-neutral-800 lg:tw-text-2xl xl:tw-text-2xl">
        Thank you for submitting your business information
      </div>
      <div
        class="tw-mt-6 tw-text-center tw-text-base tw-font-normal tw-text-neutral-600 lg:tw-text-xl lg:tw-font-semibold xl:tw-text-xl xl:tw-font-semibold"
      >
        We will review your details within 5 to 7 working days and will contact you if more information is required.
      </div>
      <div class="tw-w-full lg:tw-flex lg:tw-justify-center xl:tw-flex xl:tw-justify-center">
        <InputButton buttonClass="btn-primary-white" buttonLabel="View KYB form" @inputOnCLick="goBack" />
      </div>
    </div>
  </div>
</template>
<script>
// import XDialog from "@/components/shared/XDialog";
import { InputButton } from "tazapay-ui";
//import InputButton from "@/ui-components/formControls/InputButton";
export default {
  name: "kybThankyou",
  components: {
    InputButton,
  },
  data: () => ({
    showThankyou: true,
  }),
  methods: {
    toAddBank() {
      this.$emit("hideKybDialog");
      this.$router.push("/bank-details");
    },
    goBack() {
      // triggers an event to go back to KYB screens
      this.$emit("showKybDialog");
      // this.$router.push({ name: "kyb" });
    },
  },
  mounted() {
    this.showThankyou = true;
  },
};
</script>
<style scoped></style>

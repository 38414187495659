import parsePhoneNumber from "libphonenumber-js";
import { regexUrlValidation } from "@/helpers/regex.js";
export default class FormValidation {
  // TODO: Move this to seprate file for language(i18n) support
  requiredErrorCopy = "Please fill this required field";
  requiredLengthCopy = "Contain at least :length characters";
  minLengthCopy = "Should be at least :length characters";
  maxLengthCopy = "Should be at max :length characters";
  exactLengthCopy = "Should be :length characters";
  emailErrorCopy = "Please enter valid e-mail Id";
  confirmPasswordErrorCopy = `Passwords don't match`;
  validateDateErrorCopy = "Please select valid date";
  endDateValidationErrorCopy = "End date should be greater than start date";
  startDateShouldBeGreaterThanCurrentDateCopy = "Start date should be greater than current date & time";
  charLimitErrorCopy = "value should be less than :limit charcters";
  numberCheckErrorCopy = "Value Should be Number and greater than 0";
  arrayLengthErrorCopy = "Select at least one value";
  passwordValidationCopy =
    "Password must be at least 8 characters, must contain at least 1 number, 1 uppercase letter and 1 special character shouldn't contain spaces.";
  noSpecialCharErrorCopy = "No Special Characters allowed";
  charOnlyErrorCopy = "Please enter only alphabetical letters";
  charAndApostropheOnlyErrorCopy = "Please enter only alphabetical letter or apostrophe";
  noSpaceErrorCopy = "No Space allowed";
  alphaNumericOnlyErrorCopy = "Value Should be alphanumeric";
  alphaNumericErrorCopy = "Value Should be alphanumeric";
  phoneNoErrorCopy = "Please enter a valid phone number";
  urlValidationErrorCopy = "Please enter valid URL";
  panErrorCopy = "Please enter valid PAN";
  // TODO: check the error code
  numberAndDecimalErrorCopy = "Value should be a whole number or a decimal with 2 decimal points";

  model = [];
  validations = [];
  modelHasValidData = true;
  constructor() { }

  validateForm (model, validations, scollToError = false) {
    // Step1 - Model and Validations internal assignment
    this.model = model;
    this.validations = validations;
    this.iterateModels();
    if (scollToError) {
      this.setScrollToError();
    }
    return this.modelHasValidData;
  }

  setScrollToError () {
    setTimeout(() => {
      const errorHolder = document.querySelector('.input-error-border');
      const formContentContainer = document.querySelector('.form-content-container');
      if (errorHolder && formContentContainer) {
          const formContainerTop = formContentContainer.getBoundingClientRect().top;
          const errorHolderTop = errorHolder.getBoundingClientRect().top;
          const scrollTo = Math.abs(formContainerTop - errorHolderTop);
          window.scrollTo(0, scrollTo)
      }
    });
  }

  validateField (model, validations, formModel = null) {
    if (formModel) {
      this.model = formModel;
    }
    model.validations = [];
    const modelKey = model['modelKey']
    const modelValidationRules = validations[modelKey];
    model.validations = this.validateModel(model, modelValidationRules);
  }

  iterateModels () {
    // Step2 - Extracting MODEL and MODEL-VALIDATION from internal models and validate with input value
    this.modelHasValidData = true;
    this.model.forEach((model) => {
      const clientErrors = model['validation'] || [];
      const serverErrros = model['serverErrors'] || [];
      if (clientErrors.length || serverErrros.length) {
        // If model has client errors (or) server errors
        // we're breaking the loop and returning false
        this.modelHasValidData = false;
        return false;
      }
      model['validations'] = [];
      model['serverErrors'] = [];
      const modelKey = model['modelKey']
      const modelValidationRules = this.validations[modelKey];
      model.validations = this.validateModel(model, modelValidationRules);
    });
  }

  validateModel (value, modelValidation = {}) {
    const errors = [];
    const skipValidation = value['skip'] || false;
    const validationRequired = (modelValidation['required'] && !skipValidation) ? true : false;
    if (validationRequired) {
      const validations = modelValidation['validations'];
      validations.forEach((validation) => {
        const errorDetails = this.validateWithType(value, validation);
        if (!errorDetails['hasValidData']) {
          this.modelHasValidData = false;
          errors.push(errorDetails['error']);
        }
      });
    }
    return errors;
  }

  validateWithType (value, validation) {
    const model = value['model'];
    let error = {};
    switch (validation.key) {
      case 'required':
        error = this._requiredCheck(model);
        break;
      case 'objectKeyRequired':
        error = this._objectKeyRequiredCheck(model, validation['value']);
        break;
      case 'email':
        error = this._emailCheck(model);
        break;
      case 'length':
        error = this._lengthCheck(model, validation['value']);
        break;
      case 'minlength':
        error = this._minlengthCheck(model, validation['value']);
        break;
      case 'maxlength':
        error = this._maxlengthCheck(model, validation['value']);
        break;
      case 'exactlength':
        error = this._exactlengthCheck(model, validation['value']);
        break;
      case 'confirmPwd':
        error = this._confirmPwdCheck(model, validation['value']);
        break;
      case 'date':
        error = this._isValidDate(model);
        break;
      case 'gte':
        error = this._greaterThanCurrentDateCheck(model);
        break;
      case 'endDate':
        error = this._startDateEndDateValidations(validation['value'], model);
        break;
      case 'minmax':
        error = this._minMaxValidation(value, model);
        break;
      case 'charlimit':
        error = this._characterLengthValidation(value, model);
        break;
      case 'numberOnly':
        error = this._numberCheck(model);
        break;
      case 'arrayLength':
        error = this._arrayLength(model);
        break;
      case 'passwordCheck':
        error = this._passwordCheck(model)
        break;
      case 'nospecialchar':
        error = this._noSpecialCharacter(model)
        break;
      case 'nospace':
        error = this._noSpace(model)
        break;
      case 'charonly':
        error = this._charOnly(model)
        break;
      case 'char-and-apostrophe-only' :
        error = this._charAndApostropheOnly(model)
        break;
      case "alphanumericonly":
        error = this._alphaNumericOnly(model)
        break;
      case "alphanumeric":
          error = this._alphaNumeric(model)
          break;
      case "phoneNoCheck":
        error = this._phoneNoCheck(model, validation['value']);
        break;
      case "urlValidation":
        error = this._urlValidation(model)        
        break;
      case "panValidation":
        error = this._panValidation(model)        
        break;
      case "numberAndDecimalOnly":
        error = this._numberAndDecimal(model);
        break;
      case "alphaNumericSentence":
        error = this._alphaNumericSentence(model);
        break;
    }
    return error;
  }

  prepareSave (models) {
    const newModel = {};
    models.forEach((modelWrapper) => {
      let modelValue = modelWrapper['model'];
      if (modelWrapper['enableLowerCase']) {
        modelValue = modelValue.toString().toLowerCase();
      }
      // TODO: Getting value should be based on valueType not on generic model
      const valueObjectHasKeys = Boolean(modelValue && Object.keys(modelValue));
      if ((modelValue && modelValue.length)
        || this._isValidDate(modelValue)['hasValidData']
        || (modelValue === true || modelValue === false)
        || valueObjectHasKeys) {
        const filterBy = modelWrapper['filterBy'];
        const modelKey = modelWrapper['modelKey']
        newModel[modelKey] = (filterBy) ? this.getFilteredValue(modelValue, filterBy) : modelValue;
      }
    });
    return newModel;
  }

  getFilteredValue (value, filterBy) {
    let newValue;
    switch (filterBy) {
      case 'id':
        newValue = Array.isArray(value) ?
          value.map((item) => item[filterBy]) : value['id'];
        break;
      case 'date':
        newValue = value.toString();
        break;
    }
    return newValue;
  }

  fetchValueFromModel (model, key) {
    return model.map((obj) => {
      return obj[key];
    });
  }

  setModelValues (input, output) {
    const outputKeys = Object.keys(input);
    outputKeys.forEach((key) => {
      if (output[key] || (output[key] === true || output[key] === false)) {
        output[key]['model'] = input[key];
      }
    });
    return output;
  }

  // TODO: We've to move into separate validation file so that anyone can make use of it.
  _requiredCheck (model) {
    const modelContentLength = model ? model.trim().length : 0;
    const hasValidData = Boolean(model && modelContentLength);
    return {
      hasValidData,
      error: this.requiredErrorCopy
    };
  }

  _objectKeyRequiredCheck (model, key) {
    const hasValidData = Boolean(model && model[key] && model[key].length);
    return {
      hasValidData,
      error: this.requiredErrorCopy
    };
  }

  _emailCheck (model) {
    //eslint-disable-next-line
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const hasValidData = Boolean(model && model.length && model.match(mailformat));
    return {
      hasValidData,
      error: this.emailErrorCopy
    };
  }

  _lengthCheck (model, modelLength) {
    const hasValidData = Boolean(model && model.length > modelLength);
    const error = this.requiredLengthCopy.replace(':length', modelLength);
    return {
      hasValidData,
      error
    };
  }
  _minlengthCheck (model, modelLength) {
    const hasValidData = Boolean(model && model.length >= modelLength);
    const error = this.minLengthCopy.replace(':length', modelLength);
    return {
      hasValidData,
      error
    };
  }
  _maxlengthCheck (model, modelLength) {
    const hasValidData = Boolean(model && model.length <= modelLength);
    const error = this.maxLengthCopy.replace(':length', modelLength);
    return {
      hasValidData,
      error
    };
  }
  _exactlengthCheck (model, modelLength) {
    const hasValidData = Boolean(model && model.length === modelLength);
    const error = this.exactLengthCopy.replace(':length', modelLength);
    return {
      hasValidData,
      error
    };
  }
  _arrayLength (model) {
    model = model ? model : [];
    const hasValidData = Boolean(model.length);
    const error = this.arrayLengthErrorCopy;
    return {
      hasValidData,
      error
    };
  }


  _confirmPwdCheck (confirmPassword, passwordPointer) {
    const sourcePwd = this.model.filter((itr) => itr.modelKey == passwordPointer)[0]['model'];
    const hasValidData = (confirmPassword === sourcePwd);
    return {
      hasValidData,
      error: this.confirmPasswordErrorCopy
    };
  }

  _isValidDate (model) {
    let hasValidData = false;
    if (Object.prototype.toString.call(model) === '[object Date]') {
      hasValidData = !isNaN(model.getTime());
    }
    return {
      hasValidData,
      error: this.validateDateErrorCopy
    };
  }

  _greaterThanCurrentDateCheck (startDate) {
    const startDateTime = Date.parse(startDate);
    const currentDateTime = new Date().getTime();
    const hasValidData = startDateTime >= currentDateTime;
    return {
      hasValidData,
      error: this.startDateShouldBeGreaterThanCurrentDateCopy
    };
  }

  _startDateEndDateValidations (startDate, endDate) {
    startDate = this.model[startDate]['model'];
    const hasValidData = !(Date.parse(startDate) >= Date.parse(endDate));
    return {
      hasValidData,
      error: this.endDateValidationErrorCopy
    };
  }

  _minMaxValidation (modelWrapper, model) {
    const minValue = modelWrapper['min'];
    const maxValue = modelWrapper['max'];
    const actualValue = Number(model);
    const hasValidData = (actualValue >= minValue && actualValue <= maxValue)
    return {
      hasValidData,
      error: `Value should >= ${minValue} & <= ${maxValue}`
    };
  }

  _characterLengthValidation (modelWrapper, model) {
    const modelText = model ? model : '';
    const charLimit = modelWrapper['charLimit'];
    const hasValidData = modelText.length <= charLimit;
    const errorCopy = this.charLimitErrorCopy.replace(':limit', charLimit)
    return {
      hasValidData,
      error: errorCopy
    };
  }

  _numberCheck (model) {
    //eslint-disable-next-line
    const isNumberFormat = Number(model);
    const hasValidData = (isNumberFormat && model > 0)
    return {
      hasValidData,
      error: this.numberCheckErrorCopy
    };
  }


  _passwordCheck (model) {
    // eslint-disable-next-line
    let hasValidData = /[A-Z]/.test(model) && /[a-z]/.test(model) && /[0-9]/.test(model) && /[\^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+-]/.test(model) && !/\s/.test(model) && model.length >= 8;
    //  /[A-Z]/.test(model) && /[a-z]/.test(model) && /[0-9]/.test(model) && model.length >= 8
    return {
      hasValidData,
      error: this.passwordValidationCopy
    };
  }

  _noSpecialCharacter (model) {
    const hasValidData = !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/i.test(model);
    return {
      hasValidData,
      error: this.noSpecialCharErrorCopy
    };
  }

  _noSpace (model) {
    const hasValidData = !/\s/i.test(model);
    return {
      hasValidData,
      error: this.noSpaceErrorCopy
    };
  }

  _charOnly (model) {
    const hasValidData = /^[a-zA-Z ]*$/i.test(model);
    return {
      hasValidData,
      error: this.charOnlyErrorCopy
    };
  }

  _charAndApostropheOnly (model) {
    const hasValidData = /^[a-zA-Z' ]*$/i.test(model);
    return {
      hasValidData,
      error: this.charAndApostropheOnlyErrorCopy
    };
  }

  _alphaNumericOnly (mode) {
    //NOTE: digits mandatory
    const hasValidData = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/i.test(mode) ||
      /^[\d+]*$/i.test(mode)
    return {
      hasValidData,
      error: this.alphaNumericOnlyErrorCopy
    };
  }

  _alphaNumeric (mode) {
    //NOTE: any conmbination of alphabets and/or digits
    const hasValidData = /^[a-zA-Z0-9]*$/i.test(mode)
    return {
      hasValidData,
      error: this.alphaNumericErrorCopy
    };
  }

  _phoneNoCheck (model, contactCodeKey) {
    const contactCode = this.model.filter((itr) => itr.modelKey == contactCodeKey)[0]['model'];
    const phoneNumber = contactCode + model
    const checkNumber = parsePhoneNumber(phoneNumber);
    const hasValidData = checkNumber && checkNumber.isValid();
    return {
      hasValidData,
      error: this.phoneNoErrorCopy
    };
  }

  _urlValidation (model) {
    const hasValidData = regexUrlValidation(model);
    return {
      hasValidData,
      error: this.urlValidationErrorCopy
    };
  }

  _panValidation (model) {
     //NOTE: any combination of alphabets and/or digits
     const hasValidData = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(model)
     return {
       hasValidData,
       error: this.panErrorCopy
     };
  }

  _numberAndDecimal(model) {
    //NOTE: any combination of numbers and decimals with only one decimal point
    //eg: 641.56 is valid but 89198.842.489 and 6.8466 are invalid
    const hasValidData = /^\d+(\.\d{1,2})?$/i.test(model);
    return {
      hasValidData,
      error: this.numberAndDecimalErrorCopy,
    };
  }

  _alphaNumericSentence(model){
    //NOTE: any conmbination of alphabets and/or digits and spaces
    const hasValidData = /^([A-Za-z0-9])+([A-Za-z0-9 ]+)*$/i.test(model)
    return {
      hasValidData,
      error: this.alphaNumericErrorCopy
    };
  }
}

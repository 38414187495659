export const setPlatformUser = () => {  
  return localStorage.setItem("platformUser", true);
}

export const getPlatformUser = () => {  
  return localStorage.getItem("platformUser");
}

export const removePlatformUser = () => {  
  return localStorage.removeItem("platformUser");
}
import ModuleGuard from "./guards/module-guard";
import { ROUTE_TRANSACTION } from "@/constants/route.js";

export const EscrowRoutes = [
  {
    path: "/agreement-listing",
    name: "agreementListing",
    beforeEnter: ModuleGuard,
    redirect: `${ROUTE_TRANSACTION.TRANSACTION_LISTING}`,
    component: () => import("@/views/escrow/AgreementListing.vue"),
    meta: {
      title: "Transactions",
      toolbar: {
        title: "Transactions",
        primaryRight: 'AgreementToolbarAction',
        secondaryLeft: 'AgreementToolbarTab',
      },
      layout: "AppLayout"
    },           
  },


  {
    path: "/trade-details/:id/", 
    name: "tradedetails",
    beforeEnter: ModuleGuard,
    component: () => import("@/views/tradeDetails/tradeDetailsContainer.vue"),
    meta: { 
      title: "Trade Details",
      breadCrumb() {        
        return [
          {
            text: 'Transactions',
            to: { name: 'transactionsListing' }
          },
          {
            text: 'Transaction Summary',
            to: { name: 'transactionsDetails', params: { escrowId : 'id' }},           
          }
        ]
      },      
      layout: "AppLayout"
    },
  },

  {
    path: "/trade-details/:id/",
    name: "tradedetails",
    beforeEnter: ModuleGuard,
    // TODO: replace this with native-xp iframe
    component: () => import("@/views/tradeDetails/tradeDetailsContainer.vue"),
    meta: {
      title: "Payment",
      breadCrumb() {
        return [
          {
            text: "Transactions",
            to: { name: "transactionsListing" },
          },
          {
            text: 'Transaction Summary',
            to: { name: 'transactionsDetails', params: { escrowId : 'id' }},           
          },
          {
            text: 'Payment',            
          }
        ];
      },
      layout: "AppLayout",
    },
  },

  {
    path: "/simplified-escrowdetails/:escrowId/",
    name: "escrowdetailscontainer",
    beforeEnter: ModuleGuard,
    component: () => import("@/views/escrow/simplifiedAgreementDetails/containers/agreementDetailsContainer"),
    meta: {
      title: "Trade Details",
      breadCrumb(route) {
        console.log("route", route);
        return [
          {
            text: "Transactions",
            to: { name: "transactionsListing" },
          },
          // {
          //   text: 'Escrow Summary',
          //   to: { path: `/trade-details/${route.params.escrowId}` }
          // },
          {
            text: route.meta?.toolbar?.title,
          },
        ];
      },
      toolbar: {
        title: "Transaction Request",
      },
      layout: "AppLayout",
    },
  },
  {
    path: "/simplified-acknowledgementFlow",
    name: "acknowledgementFlow",
    meta: { title: "Escrow details", layout: "AppLayout" },
    beforeEnter: ModuleGuard,
    component: () => import("@/views/escrow/simplifiedAgreementDetails/containers/acknowledgementFlowContainer"),
  },

  {
    path: "/getting-started",
    name: "updatedEscrowLanding",
    redirect: `/home`,
    meta: { title: "Getting Started", layout: "AppLayout" },
    beforeEnter: ModuleGuard,
    component: () => import("@/views/escrow/newEscrowLanding/containers/updatedEscrowLanding"),
  },

  {
    path: "/simplified-creation",
    name: "formSummaryContainer",
    beforeEnter: ModuleGuard,
    component: () => import("@/views/escrow/simplifiedCreationFlow/containers/formSummaryContainer"),
    meta: {
      title: "New Escrow Transaction",
      breadCrumb(route) {
        return [
          {
            text: "Transactions",
            to: { name: "transactionsListing" },
          },
          {
            text: route.meta?.toolbar?.title,
          },
        ];
      },
      toolbar: {
        title: "New Escrow Transaction",
      },
      layout: "AppLayout",
    },
  },

  {
    path: "/simplified-creation/:id",
    name: "formSummaryContainerSpecific",
    meta: { title: "New Creation Flow", layout: "AppLayout" },
    beforeEnter: ModuleGuard,
    component: () => import("@/views/escrow/simplifiedCreationFlow/containers/formSummaryContainer"),
  },

  {
    path: "/sharable-link",
    name: "sharableCounterParty",
    meta: { title: "Sharable Link", layout: "AppLayout" },
    beforeEnter: ModuleGuard,
    component: () => import("@/views/escrow/simplifiedCreationFlow/components/sharableCounterParty"),
  },

  {
    path: "/storage-error",
    name: "LocalStorageError",
    meta: { title: "Storage Error", layout: "BlankLayout" },
    component: () => import("@/views/tradeDetails/marketplace/localStorageError"),
  },
];

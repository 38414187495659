import { setRedirectionReferrer } from "@/helpers/emailRedirection";
import { logOut } from "@/helpers/authOps";


const AuthGuard = (to, from, next) => {
  setRedirectionReferrer();
  const isLoggedIn = (localStorage.getItem('accessToken') && localStorage.getItem('accessToken').length);
  if (isLoggedIn) {
    // if user clicking login button from docs.tazapay.com 
    // if the user already logged then logout.
    // only in sandbox.
    if(to.path === '/signin' && import.meta.env.VITE_ENV_NAME === "sandbox" && to.query.ref === "https://docs.tazapay.com/"){
      logOut(true,to.query);
      return;
    }
    next('/home');
  } else {
    next();
  }
}

export default AuthGuard;
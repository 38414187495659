<template>
  <div class="input-holder" :class="{ 'input-box-icon': leftIcon }">
    <div class="form-label txt-left" v-if="label">{{ label }}</div>
    <div class="tw-relative">
      <div
        v-if="leftIcon"
        class="input-left-icon tw-border-0 tw-border-r tw-border-solid tw-border-neutral-200 tw-pr-2"
      >
        <img :src="getIconUrl" />
      </div>
      <input 
        class="input-text input-box" 
        :data-cy="cypressID" 
        :id="inputID" 
        :placeholder="placeholder"
        :type="inputType" 
        :value="modelValue" 
        :disabled="disabled" 
        :autocomplete="autocomplete"
        :class="errors?.length ? 'input-error-border' : 'default-input-text'" 
        @input="inputOnChange($event.target.value)"
        @blur="inputOnBlur" 
        @focus="inputOnFocus" 
        @change="inputValueChange($event.target.value)"
        @keyup.enter="onEnter($event)" 
        @keydown="onKeyDown($event)" 
        />

      <div v-if="enablePwdToggle" data-cy="btnTogglePwd" class="show-hide-pwd pointer">
        <img src="@/assets/images/closed-eye.svg" v-if="inputType == 'password'" @click="inputType = 'text'" />
        <img src="@/assets/images/open-eye.svg" v-if="inputType == 'text'" @click="inputType = 'password'" />
      </div>
    </div>
    <template v-if="errors">
      <div class="error-holder" v-if="showMultipleErrors">
        <div data-cy="errorMsg" class="error-msg" v-for="error in errors" :key="error">
          {{ error }}
        </div>
      </div>
      <div class="error-holder" v-else>
        <div data-cy="errorMsg" class="error-msg">
          {{ errors[0] }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { useAbsoluteUrl } from "@/composables/viteSupport.js";
export default {
  name: "InputText",
  props: {
    errors: Array,
    placeholder: String,
    label: String,
    type: String,
    modelValue: String,
    disabled: { default: false },
    showMultipleErrors: { default: false },
    enablePwdToggle: { default: false },
    enableOnChangeValidation: { default: false },
    inputID: { default: "" },
    cypressID: { default: "" },
    autocomplete: String,
    leftIcon: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    inputType: "text",
  }),
  methods: {
    inputOnChange(updatedModel) {
      this.$emit("update:modelValue", updatedModel);
      this.$emit("textInputOnChange", updatedModel);
      if (this.enableOnChangeValidation) {
        this.$emit("inputOnChangeValidation", updatedModel);
      }
    },
    inputOnBlur() {
      // make is optional
      this.$emit("inputOnBlur");
    },
    inputOnFocus() {
      // make is optional
      this.$emit("inputOnFocus");
    },
    onEnter(event) {
      this.$emit("onEnter", event);
    },
    inputValueChange(value) {
      this.$emit("inputValueChange", value);
    },
    onKeyDown(value) {
      this.$emit("onKeyDown", value);
    },
    getImageUrl(img){
      return useAbsoluteUrl(`${img}.svg`);
    },
  },
  mounted() {
    this.inputType = this.type;
  },
  computed: {
    getIconUrl() {
      return this.leftIcon ? this.getImageUrl(this.leftIcon) : "";
    },
  },
};
</script>

<style scoped>
.input-box-icon input {
  padding-left: 46px;
}

.input-box-icon .input-left-icon {
  position: absolute;
  left: 10px;
  top: 12px;
  line-height: 22px;
  z-index: 99;
}
</style>

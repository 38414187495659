<template>
  <div class="kyb-address-input tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-baseline tw-pt-2 tw-pb-4 sm:tw-pb-2">
    <label
      class="tw-text-sm tw-leading-sm tw-font-bold tw-text-neutral-700 tw-w-auto tw-mb-2 tw-mr-8 sm:tw-w-48 sm:tw-mb-0"
      >{{ label }}</label
    >
    <div class="tw-w-full sm:tw-w-80" v-if="!viewMode">
      <input-text
        class="tw-w-full tw-mb-2"
        :title="addressLine1 || 'Address line 1'"
        :modelValue="addressLine1"
        placeholder="Address line 1"
        :disabled="disabled"
        autocomplete="address-line1"
        @textInputOnChange="handleChange($event, 'addressLine1')"
        :errors="errorMsg.addressLine1"
      ></input-text>
      <input-text
        class="tw-w-full tw-mb-2"
        :title="addressLine2 || 'Address line 2 (Optional)'"
        :modelValue="addressLine2"
        placeholder="Address line 2"
        autocomplete="address-line2"
        :disabled="disabled"
        @textInputOnChange="handleChange($event, 'addressLine2')"
        :errors="[]"
      ></input-text>
      <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-x-2 clear-margin">
        <input-text
          class="tw-w-full tw-mb-2"
          :title="country || 'Country'"
          :disabled="true"
          :errors="[]"
         autocomplete="country"
          placeholder="Country"
          :modelValue="country"
          @textInputOnChange="handleChange($event, 'country')"
        ></input-text>
        <input-text
          class="tw-w-full"
          :title="state || 'State / Province (Optional)'"
          :modelValue="state"
          :errors="[]"
          autocomplete="address-level1"
          placeholder="State / Province"
          :disabled="disabled"
          @textInputOnChange="handleChange($event, 'state')"
        ></input-text>
      </div>
      <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-x-2 clear-margin">
        <input-text
          class="tw-w-full"
          :title="city || 'City (Optional)'"
          :modelValue="city"
          :errors="[]"
          autocomplete="address-level2"
          placeholder="City"
          :disabled="disabled"
          @textInputOnChange="handleChange($event, 'city')"
        ></input-text>
        <input-text
          class="tw-w-full"
          :title="postal || 'postal'"
          :modelValue="postal"
          :errors="errorMsg.postal"
          placeholder="Enter postal code"
          autocomplete="postal-code"
          :disabled="disabled"
          @textInputOnChange="handleChange($event, 'postal')"
        ></input-text>
      </div>
    </div>
    <div class="tw-w-full sm:tw-w-80" v-if="viewMode">
      <label class="tw-w-full sm:tw-w-80 tw-text-base tw-leading-sm tw-text-neutral-700 value-label">
        <span class="tw-leading-none">{{ addressLine1 + "," + addressLine2 }}</span>
        <span class="tw-block tw-leading-none">{{ (city ? city + "," : "") + state }}</span>
        <span class="tw-leading-none">{{ country + "," + postal }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import {InputText} from "tazapay-ui"

//import InputText from "@/ui-components/formControls/InputText.vue";
import FormValidation from "@/ui-components/formBuilder/FormValidation";

export default {
  components: { InputText },
  props: {
    label: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    addressLine1: {
      type: String,
    },
    addressLine2: {
      type: String,
    },
    country: {
      type: String,
    },
    state: {
      type: String,
    },
    city: {
      type: String,
    },
    postal: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: ()=>({})
    },
  },
  data() {
    return {
      errorMsg: {
        addressLine1: [],
        addressLine2: [],
        city: [],
        postal: [],
        state: [],
        country: []
      },
      formValidation: new FormValidation(),
    };
  },
  watch: {
    errors() {
      this.errorMsg = {...this.errors};
    }
  },
  methods: {
    handleChange(e, key) {
      this.validateAddress(e, key);
      let eventData = {
        fieldName: this.fieldName,
        subFieldName: key,
        value: e,
      };
      this.$emit("onChange", eventData);
    },
    validateAddress(e, key) {
      let validations = [];
      validations.push({ key: "required" });
      this.errorMsg[key] = [...this.formValidation.validateModel({ model: e }, { validations, required: true })];
    },
  },
};
</script>

<style scoped>
.kyb-address-input:deep .input-holder {
  margin-bottom: 8px;
}
/* .kyb-address-input .error-holder {
  margin-top: 0px;
} */
.kyb-address-input:deep .input-holder .input-box:disabled {
  @apply tw-bg-neutral-200;
}

.kyb-address-input .clear-margin .input-holder {
  margin-bottom: 3px;
}
</style>

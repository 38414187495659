<template>
    <div class="tw-py-16">
        <div class="tw-text-center ">
          <img src="@/assets/images/noreports.svg" />
        </div>
        <div class="tw-mt-6 tw-font-semibold tw-text-base tw-text-neutral-800">
          No reports found!
        </div>
        <div class="tw-mt-2 tw-font-normal tw-text-base tw-text-neutral-800">
          Generate your first report by going to your <span class="tw-text-info-600">transactions</span> and clicking on “Export”
        </div>
        <div @click="showHelperPopup" class="tw-font-normal tw-mt-2 tw-text-sm tw-text-info-600 tw-cursor-pointer">
          Learn more about generating reports.
        </div>
        <XDialog
        v-if="showPopup"
        @close="hideHelperPopup"
        @hide="hideHelperPopup">
        <div class="tw-px-4 tw-py-4 tw-text-center">
          <div class="tw-text-lg tw-font-semibold tw-text-left tw-text-neutral-800"> 
            How do I export CSVs for payment reconciliation (Seller's Advice etc.)?
          </div>
          <div class="tw-mt-4 tw-text-lg tw-font-normal tw-text-left tw-text-neutral-800">
            Navigate to the dashboard, filter out the date range you want, and export as CSV.
          </div>
          <div class="tw-mt-8">
            <iframe width="500" height="283" src="https://www.youtube.com/embed/buI2Q3d-zNQ" title="How do I export CSVs for payment reconciliation?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="tw-text-left tw-mt-10 tw-text-sm tw-font-normal tw-text-neutral-800">
            Read the full article on how to generate a report on our <span class="tw-text-info-600 tw-cursor-pointer" @click="navigateToSupport">support page.</span>
          </div>
        </div>
    </XDialog>
    </div>
</template>
<script>
import XDialog from "@/components/shared/XDialog";
export default {
  name: 'NoReport',
  components: {
    XDialog
  },
  data: function() {
    return {
      showPopup: false
    }
  },
  methods: {
    showHelperPopup() {
      this.showPopup = true
    },
    hideHelperPopup() {
      this.showPopup = false
    },
    navigateToSupport() {
      window.open('https://support.tazapay.com/how-do-i-export-csv-for-payment-reconciliation', '_blank')
    }
  }
}
</script>
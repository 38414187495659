export const bankDetails = {
  breadCrumb: {
    account: "Account",
    bank_details: "Bank Details",
    add_details: "Add Details",
    view_details: "View Details",
    arrow: ">",
  },
  default_heading: "Your Bank Details",
  add_bank_heading: "Add Bank Details",
  view_bank_heading: "View Bank Details",
  heading_note: "We provide payouts in both local currency and USD.",
  only_lcy_heading_note: "We provide payout in your local currency.",
  only_fcy_heading_note: "We provide payout in USD.",
  sub_heading: "Please provide us with your bank details for your preferred payout currency.",
  only_lcy_sub_heading_note: "Please provide us with your bank details for your local currency.",
  only_fcy_sub_heading_note: "Please provide us with your bank details for USD.",
  no_ccy_message: "Payouts are not enabled for your country",
  instruction:
    "Your legal business name should be same as the bank account name in order for us to release the money to your account.",
  bank_account: "Bank Account",
  add_account_note: "Add :currency Account",
  btn_add_account: "+ Add Bank Account",
  lcy_account_note: "Your local currency bank account which accepts payment in",
  fcy_account_note: "Your foreign currency bank account which accepts payment in",
  optional: "( Optional )",
  btn_cancel: "Cancel",
  btn_save: "Save",
  bank_details_heading: "Bank account which accepts payment in",
  error_note: "Sorry, your country is not enabled for escrow transactions.",
  error_info: "We are adding more countries and we will send you an update soon!",
  country_error_note: "Payouts are not enabled for Tazapay accounts in your country.",
  btn_back: "Back to Home",
  view_bank_details: "View bank details",
  primary_account: "Primary Account",
  tooltip_content: "You will receive money to this account. Can be changed after saving.",
  set_primary_account: "Set this as your primary account",
  change_primary_account: "Set as primary bank account",
  new_primary_account_success: "Your primary account has been added and the money will be released to that account",
  changed_primary_account_success: "Your primary account has been changed!",
  changed_primary_account_note:
    "You have set your :currency bank account as your primary account and will receive money to that account.",
};

<script setup>
/* eslint-disable */
import { ref, reactive, onMounted, computed, defineProps } from "vue";
import { useRouter } from "vue-router";
import { Line, PageSpinner, InputButton, InputRadioGroup, TipBanner } from "tazapay-ui";

import { bankDetails as text_bankDetails } from "@/copies/account/bankDetails";
import BankDetailsForm from "./components/BankDetailsForm.vue";

import { getBankDetails, addBankDetails, updateBankDetails } from "@/api/account/bankDetails";
import { ERROR_TYPE } from "@/constants/errorCodes.js";
import clearStorageAndRedirect from "@/helpers/clearStorageAndRedirect";

const localBankForm = ref(null);
const foreignBankForm = ref(null);
const emitter = defineEmits(["onChange"]);
const router = useRouter();
const props = defineProps({
  readOnlyMode: {
    type: Boolean,
    default: false,
  },
});

const bankDetails = reactive({});

const isLoading = ref(false);
const accounts = reactive({
  hasLocalAccount: false,
  hasForeignAccount: false,
});

/* Using Computed props to make life easier */
const contactCode = computed(() => bankDetails?.country_contact_code);
const isContactNumRequired = computed(() => bankDetails?.is_contact_number_required);
const hasLocalAccount = computed(() => bankDetails?.local_currency_bank_details?.length > 0);
const hasForeignAccount = computed(() => bankDetails?.foreign_currency_bank_details?.length > 0);

const isLocalAccountPrimary = computed(() => bankDetails?.local_currency_bank_details?.[0]?.is_primary_account);
const isForeignAccountPrimary = computed(() => bankDetails?.foreign_currency_bank_details?.[0]?.is_primary_account);

const localCurrency = computed(() => bankDetails?.available_bank_currencies?.local_currency);
const foreignCurrency = computed(() => bankDetails?.available_bank_currencies?.foreign_currency);

const primaryAccount = computed(() => {
  if (isLocalAccountPrimary.value) {
    return "local";
  } else if (isForeignAccountPrimary.value) {
    return "foreign";
  } else {
    return "";
  }
});
const accountOptions = computed(() => {
  let options = [];

  if (localCurrency.value) {
    options.push({
      label: localCurrency.value + " Bank Account",
      value: "local",
    });
  }
  if (foreignCurrency.value) {
    options.push({
      label: foreignCurrency.value + " Bank Account",
      value: "foreign",
    });
  }

  return options;
});
const hasTwoAccounts = computed(() => {
  return hasLocalAccount.value && hasForeignAccount.value;
});

const headingNoteText = computed(() => {
  if (localCurrency.value && foreignCurrency.value) return text_bankDetails.heading_note;
  else if (localCurrency.value) return text_bankDetails.only_lcy_heading_note;
  else if (foreignCurrency.value) return text_bankDetails.only_fcy_heading_note;
  else return text_bankDetails.no_ccy_message;
});
const subHeadingNoteText = computed(() => {
  if (localCurrency.value && foreignCurrency.value) return text_bankDetails.sub_heading;
  else if (localCurrency.value) return text_bankDetails.only_lcy_sub_heading_note;
  else if (foreignCurrency.value) return text_bankDetails.only_fcy_sub_heading_note;
  else return "";
});

const loadAccountDetails = (callback) => {
  isLoading.value = true;
  accounts.hasLocalAccount = false;
  accounts.hasForeignAccount = false;

  getBankDetails()
    .then((res) => {
      if (res?.status === 200 && res?.data) {
        if (res.data?.local_currency_bank_details) {
          accounts.hasLocalAccount = true;
        }

        if (res.data?.foreign_currency_bank_details) {
          accounts.hasForeignAccount = true;
        }

        Object.assign(bankDetails, res.data);
      }
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      if (error?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
        clearStorageAndRedirect(error);
      }
      errorMsg = error.message;
    })
    .finally(() => (isLoading.value = false));
};

const addNewAccount = (currency) => {
  let account = {
    currency,
    bank_codes: {},
    country: bankDetails?.country_name,
  };

  if (currency === localCurrency.value) {
    bankDetails.local_currency_bank_details = [account];
    if (!hasForeignAccount.value) {
      setPrimaryAccount(true);
    } else {
      dispatchChange();
    }
  } else if (currency === foreignCurrency.value) {
    bankDetails.foreign_currency_bank_details = [account];
    if (!hasLocalAccount.value) {
      setPrimaryAccount(false);
    } else dispatchChange();
  } else {
    console.log("Invalid currency");
    return;
  }
};
const deleteAccount = (accountType) => {
  switch (accountType) {
    case "local":
      accounts.hasLocalAccount = false;
      bankDetails.local_currency_bank_details = null;
      setPrimaryAccount(false);
      break;
    case "foreign":
      accounts.hasForeignAccount = false;
      bankDetails.foreign_currency_bank_details = null;
      setPrimaryAccount(true);
      break;
  }
};
const setPrimaryAccount = (isLocal) => {
  if (isLocal) {
    if (hasLocalAccount.value) {
      bankDetails.local_currency_bank_details[0].is_primary_account = true;
    }
    if (hasForeignAccount.value) {
      bankDetails.foreign_currency_bank_details[0].is_primary_account = false;
    }
  } else {
    if (hasLocalAccount.value) {
      bankDetails.local_currency_bank_details[0].is_primary_account = false;
    }
    if (hasForeignAccount.value) {
      bankDetails.foreign_currency_bank_details[0].is_primary_account = true;
    }
  }

  dispatchChange();
};

const handleChange = (isLocal, record) => {
  if (isLocal) {
    bankDetails.local_currency_bank_details = [record];
  } else {
    bankDetails.foreign_currency_bank_details = [record];
  }
  dispatchChange();
};

const dispatchChange = (markDirty = true) => {
  emitter(
    "onChange",
    JSON.parse(
      JSON.stringify({
        local: bankDetails.local_currency_bank_details?.[0],
        foreign: bankDetails.foreign_currency_bank_details?.[0],
        markDirty,
      })
    )
  );
};

const validateData = () => {
  let record = {
    ...(hasLocalAccount.value && { local: localBankForm.value?.validateData() }),
    ...(hasForeignAccount.value && { foreign: foreignBankForm.value?.validateData() }),
  };

  if (!record.local && !record.foreign) {
    record.errorMsg = "Please add a bank account";
  } else if (record.local && !record.local.isValid) {
    record.errorMsg = "Please provide valid bank details";
  } else if (record.foreign && !record.foreign.isValid) {
    record.errorMsg = "Please provide valid bank details";
  } else {
    record.isValid = true;
  }
  return record;
};

const makeAccountAPIRequest = (record) => {
  let request = JSON.parse(JSON.stringify(record));
  request.country_name = request.country_name;
  request.contact_number = request.contact_number || "";
  request.country_code = request.country_code || "";
  // delete request.country;
  // if (request.id?.length > 0) {
  //   return updateBankDetails(request);
  // } else {
  //   return addBankDetails(request);
  // }
    addBankDetails(request)
};

const saveToServer = () =>
  new Promise((resolve, reject) => {
    //NOTE: Assuming data has been successfully validated
    // 1. Check for accounts flag to identify newly added account
    // 2. Call addAccount Api for that new account
    // 3. Call update account Api for primary account

    let apiCalls = [];

    if (hasLocalAccount.value) {
      apiCalls.push(makeAccountAPIRequest(bankDetails.local_currency_bank_details[0]));
    }
    if (hasForeignAccount.value) {
      apiCalls.push(makeAccountAPIRequest(bankDetails.foreign_currency_bank_details[0]));
    }

    isLoading.value = true;
    Promise.all(apiCalls)
      .then((responses) => {
        const callback = () => {
          resolve("Bank details updated" && "");
        };
        //NOTE: Ignoring API responses success check
        if (responses.length) {
          loadAccountDetails(callback);
        } else {
          callback();
        }
      })
      .catch((error) => {
        if (error?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
          clearStorageAndRedirect(error);
        }
        console.log(error);
        reject("Error adding new account" && "");
      })
      .finally(() => {
        if (apiCalls.length === 0) isLoading.value = false;
      });
  });

defineExpose({
  validateData,
  saveToServer,
});

onMounted(() => loadAccountDetails(() => dispatchChange(false)));
</script>
<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-mb-4 tw-w-full tw-border-0 tw-text-base tw-font-bold tw-leading-lg tw-text-neutral-700">
      {{ headingNoteText }}<br />
      {{ subHeadingNoteText }}
    </div>
    <TipBanner
      v-if="localCurrency || foreignCurrency"
      message="A $15 fee may be applicable per payout in USD. Additional bank charges may also apply."
    />
    <Line />
    <div class="tw-flex tw-flex-col">
      <PageSpinner v-if="isLoading" style="height: 240px" />
      <div v-else class="tw-flex tw-flex-col tw-gap-4 tw-py-4">
        <InputButton
          v-if="localCurrency && !hasLocalAccount"
          data-gtm-key="add_bank"
          buttonLabel=""
          styleClass="tw-self-start tp-basic-btn"
          @inputOnCLick="addNewAccount(localCurrency)"
        >
          <tc-icon icon="plus" size="2xl" />
          Add {{ localCurrency }} Bank Account
        </InputButton>
        <InputButton
          v-if="foreignCurrency && !hasForeignAccount"
          data-gtm-key="add_bank"
          buttonLabel=""
          styleClass="tw-self-start tp-basic-btn"
          @inputOnCLick="addNewAccount(foreignCurrency)"
        >
          <tc-icon icon="plus" size="2xl" />
          Add {{ foreignCurrency[0] }} Bank Account
        </InputButton>
        <BankDetailsForm
          v-if="hasLocalAccount"
          ref="localBankForm"
          v-model="bankDetails.local_currency_bank_details[0]"
          :bankCodes="bankDetails?.currency_bank_codes"
          :isAddressRequired="bankDetails?.is_address_required"
          :isEditable="!readOnlyMode || !accounts.hasLocalAccount"
          :isDeletable="!accounts.hasLocalAccount"
          :contactCode="contactCode"
          :bankDetails="bankDetails"
          :isContactNumRequired="isContactNumRequired"
          @onChange="handleChange(true, $event)"
          @onDelete="deleteAccount('local')"
          hasDefaultEditMode
        />
        <BankDetailsForm
          v-if="hasForeignAccount"
          ref="foreignBankForm"
          v-model="bankDetails.foreign_currency_bank_details[0]"
          :bankCodes="bankDetails?.currency_bank_codes"
          :isAddressRequired="false"
          :isEditable="!readOnlyMode || !accounts.hasForeignAccount"
          :isDeletable="!accounts.hasForeignAccount"
          :contactCode="contactCode"
          :bankDetails="bankDetails"
          :isContactNumRequired="isContactNumRequired"
          @onChange="handleChange(false, $event)"
          @onDelete="deleteAccount('foreign')"
          hasDefaultEditMode
        />
        <template v-if="hasTwoAccounts">
          <Line />
          <p>
            Since you have added two bank accounts, please specify which will be your primary bank account (Account to
            which payouts will be made)
          </p>
          <div
            class="tw-flex tw-flex-col tw-pt-2 tw-pb-4 sm:tw-flex-row sm:tw-items-start sm:tw-pb-2"
            :class="{ 'tw-pt-4 sm:tw-pb-4': readOnlyMode }"
          >
            <label
              class="tw-mb-2 tw-mr-8 tw-w-auto tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700 sm:tw-mb-0 sm:tw-w-48"
              >Primary Bank Account</label
            >
            <div class="tw-flex tw-w-full tw-flex-col sm:tw-w-80">
              <input-radio-group
                :groupData="accountOptions"
                name="primaryAccount"
                @inputOnChange="setPrimaryAccount($event === 'local')"
                :model="primaryAccount"
                class="tw-flex-col tw-gap-4"
                style="display: flex"
              >
                <template #default="option">
                  <span class="tw-font-normal">
                    {{ option.label }}
                  </span>
                </template>
              </input-radio-group>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

import ModuleGuard from "./guards/module-guard";
import { ROUTE_ACTIONS } from "@/constants/route.js";

export const ActionsRequiredRoutes = [  
  {
    path: `${ROUTE_ACTIONS.DELIVERY_PROOF}`,
    name: "actionRequired", 
    beforeEnter: ModuleGuard,
    component: () => import("@/views/actionsRequired/ActionRequired.vue"),
    meta: { 
      title: "Action Required",      
      toolbar: {
        title: "Action Required",
      },
      layout: "AppLayout",
      classes: {
        app: "tl-app--bg-white tl-app--content-toolbar",
      }
    },
  },
];

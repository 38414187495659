<template>
  <div>
    <div v-for="file in listData" :key="file.file_name">
      <div class="tw-flex tw-w-full tw-items-center tw-py-4">
        <tc-icon icon="paperclip" size="lg" color="info-600"></tc-icon>
        <label
          @click="downLoadFile(file.document_url)"
          class="file-name tw-ml-2 tw-w-full tw-max-w-xs tw-cursor-pointer tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-sm tw-font-bold tw-leading-none tw-text-neutral-500 tw-underline"
        >
          {{ file.file_name }}
        </label>
        <div class="tw-ml-6 tw-flex tw-items-center">
          <tc-icon icon="check-circle" size="lg" color="success-800"></tc-icon>
          <img
            data-cy="removeDoc"
            @click="removeDocument(file)"
            class="tw-ml-4 tw-cursor-pointer"
            src="@/assets/images/delete-doc.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    //fileName: String,
    listData: Array,
  },
  methods: {
    removeDocument(file) {
      this.$emit("removeFile", file);
    },
    downLoadFile(url) {
      let fileUrlOpen = window.open(url, "_blank");
      fileUrlOpen.focus();
    },
  },
};
</script>

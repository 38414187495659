<template>
  <div class="tp-agreement-tab">
    <div @click="emitAgreement('all')" v-if="isPlatformUser && isHavingPermission(allPermissions.ESCROW_VIEW)" class="tp-agreement-tab__item" :class="{'tp-agreement-tab__item--active': activeTab === 'all'}">All</div>
    <div v-if="isHavingPermission(allPermissions.ESCROW_VIEW)" @click="emitAgreement('escrow')"  class="tp-agreement-tab__item" :class="{'tp-agreement-tab__item--active': activeTab === 'escrow'}">Escrow</div>
    <div v-if="isPlatformUser && isHavingPermission(allPermissions.PAYMENT_LINK_VIEW)" @click="emitAgreement('platform')" class="tp-agreement-tab__item" :class="{'tp-agreement-tab__item--active': activeTab === 'platform'}">Payment Links</div>
  </div>
</template>

<script>
import { eventBus } from "@/helpers/eventBus.js";
import { getPlatformUser } from "@/helpers/platformUser";
import { hasPermission, getCurrentUserRole } from "@/helpers/permissionHelper";
import { allPermissions } from "@/constants/permissions";

export default {
  name: 'AgreementAction',
  data(){
    return{
      activeTab: 'all',
      allPermissions: allPermissions
    }
  },
  computed: {
    isPlatformUser(){
      return Boolean(getPlatformUser());      
    },
  },
  methods: {
    emitAgreement(val) {
      this.activeTab = val;
      window.location.hash = `#tab=${val}`
      eventBus.$emit('emitAgreement', val);
    },
    isHavingPermission(val) {
      return hasPermission(val);
    },

    setActiveTab() {
      let role = getCurrentUserRole();
      switch (role) {
        case 'owner': {
          !this.isPlatformUser ? this.activeTab = 'escrow' : this.activeTab = 'all';
          break;
        }
        case 'manager': {
          this.isHavingPermission(allPermissions.PAYMENT_LINK_VIEW) ? this.activeTab = 'platform' : this.activeTab = '';
          break;
        }
      }
      this.emitAgreement(this.activeTab)
    }
  },
  mounted() {
    this.setActiveTab();
    // if (!this.isPlatformUser) this.activeTab = 'escrow';
  }
}
</script>

<style lang="scss" scoped>
  .tp-agreement-tab{
    @apply tw-flex tw-items-center tw-gap-6 tw-text-sm tw-mt-2 tw-text-neutral-600;

    @include bp(xl){
      @apply tw-h-full tw-ml-10 tw-mt-0;
    }
    &__{
      &item{
        @apply tw-h-full tw-flex tw-items-center tw-cursor-pointer;

        &--active{
          box-shadow: 0px 5px 0 $white, 0 8px 0 $primary-700;
          @apply tw-text-primary-700 tw-font-semibold;
        }
      }      
    }
  }
</style>
<template>
  <div>
    <div ref="popperSrc">
      <slot name="trigger"></slot>
    </div>
    <Teleport :to="teleportTarget" :disabled="!(useTeleport && showContent)">
      <div ref="popperContent" v-show="showContent" id="tooltip" role="tooltip" :class="contentClasses">
        <slot name="content"></slot>
        <div v-if="showArrow" id="arrow" data-popper-arrow></div>
      </div>
    </Teleport>
  </div>
</template>
<script setup>
// eslint-disable-next-line
import { createPopper } from "@popperjs/core";
import { ref, onMounted, watch, defineProps, defineEmits, onUnmounted } from "vue";

// eslint-disable-next-line
const props = defineProps({
  showContent: {
    type: Boolean,
  },
  offset: {
    type: Array,
    default: [0, 0],
  },
  placement: {
    type: String,
    default: "bottom-start",
  },
  contentClasses: {
    type: String,
    default: "",
  },
  showArrow: {
    type: Boolean,
    default: false,
  },
  useTeleport: {
    type: Boolean,
    default: false,
  },
  teleportTarget: {
    type: String,
    default: "#teleportal",
  },
});

const emit = defineEmits(["onDocClick", "onContentClick"]);

let popperIns;
let popperContent = ref(null);
let popperSrc = ref(null);

function handleDocClick(e) {
  if (popperContent.value?.contains(e.target)) {
    emit("onContentClick");
  } else if (popperSrc.value?.contains(e.target)) {
    emit("onDocClick");
    e.stopPropagation();
  } else {
    emit("onDocClick");
  }
}

onMounted(() => {
  popperIns = createPopper(popperSrc.value, popperContent.value, {
    placement: props.placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: props.offset,
        },
      },
    ],
  });

  watch(
    () => props.showContent,
    () => {
      if (props.showContent) {
        document.addEventListener("click", handleDocClick, true);
        popperContent.value.setAttribute("data-show", "");

        // add event listener only when popper is visible
        popperIns.setOptions((options) => ({
          ...options,
          modifiers: [...options.modifiers, { name: "eventListeners", enabled: true }],
        }));
        popperIns.update();
      } else {
        popperContent.value.removeAttribute("data-show");

        // remove event listener only when popper is not visible
        popperIns.setOptions((options) => ({
          ...options,
          modifiers: [...options.modifiers, { name: "eventListeners", enabled: false }],
        }));
        document.removeEventListener("click", handleDocClick, true);
      }
    },
    { immediate: true }
  );

  // document.addEventListener("click", handleDocClick, true);
});
onUnmounted(() => {
  // handled in watch
  // document.removeEventListener("click", handleDocClick, true);
});
</script>
<style scoped>
#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}
#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
}
#tooltip[data-popper-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
}
.tp-popper-tooltip{  
  @apply tw-z-99000 tw-bg-white tw-rounded-lg;
  @apply tw-shadow-3 tw-border tw-border-solid tw-border-neutral-120 !important;
}
.tp-popper-tooltip-primary{  
  @apply tw-z-99000 tw-bg-primary-800 tw-rounded-lg;
  @apply tw-shadow-3 tw-border-0 !important;
}
.tp-popper-dropdown{
  @apply tw-z-99000 tw-bg-white tw-rounded;
  @apply tw-shadow-lg tw-border tw-border-solid tw-border-neutral-200 !important;
}
</style>

export const account = {
  accountType: "/api/account/type",
  accountDetails: "/api/account",
  bankDetails: "/bank/add",
  updateBank: "/bank/primary/",
  updateBankDetails: "/bank",
};
export const signup = {
  signupAutoFill: "/api/signup-autofill/",
};

export const creationFlow = {
  countryConfig: "/v2/metadata/countryconfig",
  currencyMapping: "/v2/metadata/invoicecurrency",
  tradeDetails: "/v2/agreement/trade",
  paymentDetails: "/v2/escrow/{escrow_id}/summary",
  submitAgreement: "/v2/agreement/{escrow_id}",
  counterPartyInfo: "/v2/agreement/counterparty",
};

export const agreementList = {
  agreementList: "/agreements",
  agreementSearch: "/agreements/search",
  agreementSettlementList: "/dashboard/settlement",
};

export const uploadDocument = {
  fileUpload: "/api/upload-file",
};

export const tradeSummary = {
  downloadProofOfShipment: "/agreement/{escrow_id}/shipping",
  submitDocuments: "/agreement/{escrow_id}/shipping/upload/document",
};

export const actionsRequired = {
  deliveryProof: "/dashboard/action/required",
};

export const exportSettlement = {
  exportSettlement: "/reports/trigger_sqs",
};

export const common = {
  emailAction: "/dashboard/trigger/email",
}
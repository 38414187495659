import ModuleGuard from './guards/module-guard';

export const AccountRoutes = [
  {
    path: "/account-details", 
    name: "AccountDetails", 
    beforeEnter: ModuleGuard,
    component: () => import("@/views/account/AccountDetails.vue"),
    meta: { 
      title: "Account Details",
      toolbar: {
        title: "Account Details"
      },
      layout: "AppLayout"
    },
  },

];
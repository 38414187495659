<template>
  <div
    class="kyb-representative-files tw-border tw-border-solid tw-border-neutral-100 tw-shadow-2"
    :class="{ 'tw-mb-4': viewMode }"
  >
    <div
      class="tw-flex tw-items-center tw-justify-between tw-bg-neutral-150 tw-p-4 tw-text-sm tw-font-bold tw-leading-sm tw-text-primary-700"
    >
      <label class="tw-uppercase">{{ heading }}</label>
      <label
        v-if="showDelete && !viewMode"
        class="tw-borer-solid tw-rounded tw-border tw-border-neutral-300 tw-bg-white tw-p-2 tw-shadow-2"
        @click="handleDelete"
      >
        <tc-icon icon="trash-can"></tc-icon>
        <span class="tw-ml-2 tw-hidden sm:tw-inline">Delete</span>
      </label>
    </div>
    <div :class="{ 'show-border': viewMode }" class="tw-px-4 tw-py-4">
      <kyb-select-input
        label="Identity proof"
        placeholder="Select Proof"
        fieldName="identityProofType"
        :value="identityTypeData"
        :required="true"
        :errors="docErrors.identityProofType"
        @onChange="handleIdentityProofChange"
        :items="['Passport', 'Driving License', 'National ID', 'Other']"
        :viewMode="viewMode"
        :showTooltip="true"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Identity Proof</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            Any Government issued ID proof that has your name, date of birth, photo, signature and has not expired
          </div>
        </template>
      </kyb-select-input>
      <div v-show="identityTypeData">
        <kyb-file-upload
          label="ID proof - Front"
          :viewMode="viewMode"
          :fileInfo="identityFrontFileInfoData"
          fieldName="identityProofTypeFrontDoc"
          :required="true"
          :errors="docErrors.identityProofTypeFrontDoc"
          @onChange="handleIdentityFrontDocChange"
          @removeFile="removeDocument('identityProofTypeFrontDoc')"
        ></kyb-file-upload>
        <kyb-file-upload
          label="ID proof - Back"
          :viewMode="viewMode"
          fieldName="identityProofTypeBackDoc"
          :fileInfo="identityBackFileInfoData"
          :required="true"
          :errors="docErrors.identityProofTypeBackDoc"
          @onChange="handleIdentityBackDocChange"
          @removeFile="removeDocument('identityProofTypeBackDoc')"
        ></kyb-file-upload>
      </div>
      <kyb-checkbox
        label="Address proof"
        checkboxDescription="Same as in identity proof"
        :viewMode="viewMode"
        :value="sameAddress"
        fieldName="addressProof"
        :showTooltip="true"
        @onChange="handleAddressProofChange"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Address Proof</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            Please provide any of the following documents showing current residential address :
          </div>
          <div class="tw-mt-4 tw-ml-5 tw-text-sm tw-font-normal tw-text-white">
            <ul>
              <li>A Recent Utility Bill</li>
              <li>Bank Statement or</li>
              <li>Lease Agreement</li>
            </ul>
          </div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            Document must be recent (not more than 6 months old) and should be in the name of the business.
          </div>
        </template>
      </kyb-checkbox>
      <div class="tw-flex" v-if="!sameAddress">
        <div class="tw-mr-8 tw-hidden tw-w-48 sm:tw-block"></div>
        <kyb-file-upload
          class="tw-overflow-hidden"
          :viewMode="viewMode"
          fieldName="AddressProofDoc"
          :fileInfo="addressProofFileInfoData"
          :required="!sameAddressProof"
          :errors="docErrors.respsAddressProofDoc"
          @onChange="handleIdentityAddressDocChange"
          @removeFile="removeDocument('AddressProofDoc')"
        ></kyb-file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import KybSelectInput from "./kybSelectInput.vue";
import KybFileUpload from "./kybFileUpload.vue";
import KybCheckbox from "./kybCheckbox.vue";
export default {
  components: {
    KybSelectInput,
    KybFileUpload,
    KybCheckbox,
  },
  props: {
    heading: {
      type: String,
    },
    fieldName: {
      type: [String, Number],
    },
    identityType: {
      type: String,
    },
    sameAddressProof: {
      type: Boolean,
      default: true,
    },
    documents: {
      type: Array,
    },
    showDelete: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sameAddress: false,
      identityTypeData: "",
      identityFrontFileInfoData: null,
      identityBackFileInfoData: null,
      addressProofFileInfoData: null,
      repDocs: [],
      docErrors: {},
    };
  },
  watch: {
    sameAddressProof() {
      this.sameAddress = this.sameAddressProof;
    },
    documents() {
      this.updateDocuments();
    },
    errors: {
      handler(newValue) {
        this.docErrors = { ...newValue };
      },
      deep: true,
    },
  },
  methods: {
    updateDocuments() {
      this.identityFrontFileInfoData = null;
      this.identityBackFileInfoData = null;
      this.addressProofFileInfoData = null;
      this.repDocs = [];
      const documents = this.documents || [];
      documents.forEach((doc) => {
        if (doc.tag === "identityProofTypeFrontDoc") {
          this.identityTypeData = doc.type;
          this.identityFrontFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        } else if (doc.tag === "identityProofTypeBackDoc") {
          this.identityTypeData = doc.type;
          this.identityBackFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        } else if (doc.tag === "AddressProofDoc") {
          this.addressProofFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        }
      });
    },
    handleDelete() {
      this.$emit("onDelete", this.fieldName);
    },
    handleAddressProofChange(e) {
      this.sameAddress = e.value;
      this.repDocs = this.repDocs.filter((item) => item.tag !== "AddressProofDoc");
      this.dispatchChange("addressProof");
    },
    handleIdentityProofChange(e) {
      this.identityTypeData = e.value;
      this.identityFrontFileInfoData = null;
      this.identityBackFileInfoData = null;
      this.repDocs = this.repDocs.filter((item) => item.tag === "AddressProofDoc");
      this.dispatchChange("identityProofType");
    },
    handleIdentityFrontDocChange(e) {
      let docData = {
        proof_type: "Identity",
        type: this.identityTypeData,
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Identity Proof front side",
        tag: "identityProofTypeFrontDoc",
      };
      this.repDocs.push(docData);
      this.dispatchChange("identityProofTypeFrontDoc");
    },
    handleIdentityBackDocChange(e) {
      let docData = {
        proof_type: "Identity",
        type: this.identityTypeData,
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Identity Proof back side",
        tag: "identityProofTypeBackDoc",
      };
      this.repDocs.push(docData);
      this.dispatchChange("identityProofTypeBackDoc");
    },
    handleIdentityAddressDocChange(e) {
      let docData = {
        proof_type: "Address",
        type: this.identityTypeData,
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Address proof doc",
        tag: "AddressProofDoc",
      };
      this.repDocs.push(docData);
      this.dispatchChange("AddressProofDoc");
    },
    removeDocument(fieldName) {
      this.repDocs = this.repDocs.filter((item) => item.tag !== fieldName);
      this.dispatchChange(fieldName);
    },
    dispatchChange(fieldName) {
      let result = {
        same_operating_address: this.sameAddress,
        documents: this.repDocs,
        fieldName,
        value: fieldName === 'identityProofType' ? this.identityTypeData : ''
      };
      this.$emit("onChange", result);
    },
  },
  mounted() {
    this.sameAddress = this.sameAddressProof;
    this.updateDocuments();
  },
};
</script>

<style scoped>
/* .show-border > div {
  border: 1px solid #f1f5f9;
} */
</style>

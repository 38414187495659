import axios from "axios";
import router from '../../../router';
// import store from '@/store';

const api = axios.create({
  baseURL: import.meta.env.VITE_MARKET_PLACE_DASHBOARD_URL
});

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('accessToken');
    const userIP = localStorage.getItem('user-ip') || '';
    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'TZ-User-IP': userIP
      }
      if (!config.url.includes('internal')) {
        config.headers['Cache-Control'] = 'no-cache';
        config.headers['Pragma'] = 'no-cache';
        config.headers['Expires'] = '0';
      }
    }
    return config;
  },


  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log('before setting error from api', error);
    let errorData = error?.response?.data;
    // store.dispatch('notification/add', {
    //   message: errorData?.errors?.[0]?.message || errorData?.error?.message || errorData?.message || 'Something went wrong!!',
    //   type: 'error',
    // })
    if (error?.response) {
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear()
        sessionStorage.clear()
        router.push("/signin");
      }
    }
    return Promise.reject(errorData);
  }
);






export default api;

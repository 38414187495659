<!-- Header-Section-Starts -->
<template>
  <!-- Actual height mentioned in the figma is 64 -->
  <!-- header is display for only device and hidden for other devices  -->
  <div class="tl-header tw-gap-2">
    <div class="tw-hidden xl:tw-flex">
      <BreadCrumb />
    </div>
      <!-- Get Help -->
    <div class="tw-ml-auto">
      <GetHelp />
    </div>
    <div class="tw-text-base tw-relative" id="dropdown-user-account">
      <!--Avatar-Starts-here-->
      <button data-user-initial-btn-id="user-initial-button" class="tw-inline-flex tw-items-center tw-gap-3" @click="showDropdown = !showDropdown" data-cy="userInitialBtn">
        <span data-span="user-initial-text" class="tw-bg-info-100 tw-text-primary-700 tw-font-bold tw-rounded tw-h-28P tw-w-28P tw-inline-flex tw-items-center tw-justify-center">
          <tc-text size="xs" weight="semibold">{{getUserNameInitials(user)}}</tc-text>
        </span>            
        <tc-text weight="semibold" size="xs" class="tw-hidden sm:tw-inline">{{ user }}</tc-text>
        <!-- <i class="mdi mdi-chevron-down tw-ml-1 tw-text-2xl"></i> -->
      </button>
      <!-- Avatar-Ends-here-->      
      
      <!--Signout button section -->
      <div v-if="showDropdown" class="tw-absolute tw-z-59000 tw-right-0 tw-top-full tw-mt-1 tw-bg-white tw-text-neutral-800 tw-rounded tw-py-1 tw-min-w-180P tw-border tw-border-solid tw-border-neutral-200 tw-shadow-lg">
        <div data-gtm-key="signout" data-cy="signoutBtn" data-signout-btn-id="signout-button" @click="submit" class="tw-px-4 tw-py-3 hover:tw-bg-neutral-150 tw-text-sm tw-flex tw-items-center tw-cursor-pointer tw-font-semibold">
          <i class="mdi mdi-logout-variant tw-mr-2 tw-text-xl"></i>
          <span>Sign Out</span>
        </div>
      </div>
      <!-- Signout-button-section-ends-->  
    </div>
  </div>  
</template>

<script>
import { Signout } from "@/api/legacyapi/user";
import { Auth } from 'aws-amplify';
import { logOut } from "@/helpers/authOps";
import { BreadCrumb } from "tazapay-ui";
import GetHelp from "@/views/contactSupport/getHelp.vue";

export default {
  name: "Header",
  components: {
    BreadCrumb,
    GetHelp
  },
  data: () => ({
    user: localStorage.userEmail,
    showDropdown: false
  }),  
  methods: {    
    submit() {
      if(localStorage.getItem('amplify-signin-with-hostedUI') === 'true') {
        this.googleSignOut();
      } else {
        this.$emit("submit");
        Signout().then(response => {
          if (response) {
            const respMessage = response?.output?.message;
            // TODO- replace localstorage with more secure option
            window.localStorage.clear();
            window.sessionStorage.clear();
            if (respMessage) {
              logOut();
            }
          }
        }).catch(err => {console.error(err)})
      }
    },

    async googleSignOut() {
      try {
        await Auth.signOut({ global: true });
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },
    getUserNameInitials(value) {
      var name = value;
      var initial;
      // TODO - to check the If block
      if (name) {
        initial = name[0].substring(0, 1).toUpperCase();        
        return initial;
      }
    },   
    toggleDropdown(event){        
      if(document.getElementById("dropdown-user-account").isSameNode(event.target) ||
        document.getElementById("dropdown-user-account").contains(event.target)){
        // Event triggered from within the #dropdown-user-account div element
        // Do nothing and return
        return; 
      }
      if(this.showDropdown) return this.showDropdown = false
    }   
  },
  mounted(){
    //on click on body, hide the dropdown if it is shown    
    document.documentElement.addEventListener('click', this.toggleDropdown, true);       
  },
  unmounted(){
    //remove the event listeners related to dropdown
    document.documentElement.removeEventListener('click', this.toggleDropdown, true)
  }
};
</script>

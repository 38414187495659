//ROUTES PATH
export const ROUTE_SETTINGS = {
  ACCOUNT_TYPE: "/account-type",
  ACCOUNT_CREATION: "/account-creation",
};
export const ROUTE_BANK = {
  BANK_DETAILS: "/bank-details/:action?",
  DRAGON_CHECKOUT: "/dragonpaycheckout",
};
export const ROUTE_AUTHENTICATION = {
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  TERMS_AND_CONDITIONS: "/termsandconditions/v1",
};
export const ROUTE_TRANSACTION = {
  LISTING: "/agreement-listing",
  TRANSACTION_LISTING: `/transactions/view`,
  TRANSACTION_DETAILS: `/transaction/details/:id`,
  TRADE_DETAILS: "/trade-details/",
  SHARABLE_LINK: "/sharable-link",
  SETTLEMENT_LISTING: `/settlements/view`,
};
export const ROUTE_ACTIONS = {
  DELIVERY_PROOF: "/actions/deliveryproof",
};
export const ROUTE_SIMPLIFIED = {
  CREATE_ESCROW: "/simplified-creation",
  ESCROW_DETAILS: "/simplified-escrowdetails/",
  ACKNOWLEDGEMENT: "/simplified-acknowledgementFlow",
};
export const ROUTE_MARKETPLACE = {
  CREATE_PAYMENT_LINK: "/marketplace/create-payment-link",
};

export const ROUTE_PARAMS = {
  REF_SOCIAL: "reference=social",
};

export const ROUTE_SHOPIFY = {
  VERIFY_API: "/shopify",
};

export const SOCIAL_SIGNUP = {
  SOCIAL_SIGNUP_ERROR: "/sso-error",
};

<template>
  <div class="tl-toolbar" v-if="showToolbar">
    <div class="tl-toolbar__item">
      <div class="tl-toolbar__title" v-if="pageTitle">
        <div>
          {{ pageTitle }}
        </div>
      </div>
      <div class="tl-toolbar__actions" v-if="showPrimary">
        <div class="tl-toolbar__action">
          <component :is="primaryLeft" />
        </div>
        <div class="tl-toolbar__action">
          <component :is="primaryRight" />
        </div>
      </div>
    </div>
    <div class="tl-toolbar__item" v-if="showSecondary">
      <div class="tl-toolbar__actions">
        <div class="tl-toolbar__action">
          <component :is="secondaryLeft" />
        </div>
        <div class="tl-toolbar__action">
          <component :is="secondaryRight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgreementToolbarAction from "@/views/escrow/agreement/partials/toolbarAction.vue";
import AgreementToolbarTab from "@/views/escrow/agreement/partials/toolbarTab.vue";
import TransactionToolbarTab from "@/views/transaction/listing/components/common/Tab.vue";
import BankDetailsBanner from "@/views/settlement/listing/components/BankDetailsBanner.vue";
import HeadingLabel from "@/views/transaction/listing/components/common/HeadingLabel.vue"

export default {
  name: "Toolbar",
  components: {
    AgreementToolbarAction,
    AgreementToolbarTab,
    TransactionToolbarTab,
    BankDetailsBanner,
    HeadingLabel
  },
  computed: {
    pageTitle() {
      return this.$route?.meta?.toolbar?.title;
    },
    primaryLeft() {
      return this.$route?.meta?.toolbar?.primaryLeft;
    },
    primaryRight() {
      return this.$route?.meta?.toolbar?.primaryRight;
    },
    secondaryLeft() {
      return this.$route?.meta?.toolbar?.secondaryLeft;
    },
    secondaryRight() {
      return this.$route?.meta?.toolbar?.secondaryRight;
    },
    showToolbar() {
      return this.pageTitle || this.primaryLeft || this.primaryRight || this.secondaryLeft || this.secondaryRight;
    },
    showPrimary() {
      return this.primaryLeft || this.primaryRight;
    },
    showSecondary() {
      return this.secondaryLeft || this.secondaryRight;
    },
  },
};
</script>

<style lang="scss" scoped>
.tl-toolbar {
  min-height: 60px;
  box-shadow: 0px 2px 4px rgba(226, 232, 240, 0.24), 0px 4px 6px rgba(226, 232, 240, 0.5);
  @apply tw-flex tw-flex-col tw-justify-center tw-gap-3 tw-bg-white tw-py-2 tw-px-4;
  @include cplr();

  // @include bp(xl){
  //   @apply tw-items-stretch;
  // }
  &__ {
    &item {
      @apply tw-flex tw-items-center tw-gap-4;
    }
    &title {
      @apply tw-flex tw-items-baseline tw-justify-center tw-gap-4 tw-font-sans-lead tw-text-xl tw-font-bold tw-text-neutral-800 sm:tw-text-2xl sm:tw-leading-sm lg:tw-text-3xl lg:tw-leading-none;
    }
    &caption {
      @apply tw-hidden tw-text-xs tw-font-semibold tw-leading-xs tw-text-primary-700 lg:tw-inline-flex;
    }
    &action {
      &s {
        @apply tw-flex tw-flex-1 tw-items-center tw-justify-between tw-gap-3;
        // @include bp(xl){
        // }
      }
    }
  }
}
</style>

//Add bank details
export const BANK_CURRENCIES = {
  LOCAL_CURRENCY: "LCY",
  FOREIGN_CURRENCY: "FCY",
};

export function getAccountLabel(currency) {
  let AccountNumber = "Account Number";
  switch (currency) {
    case "MXN":
      AccountNumber = "Account Number / Clabe Number";
      break;
    case "PEN":
      AccountNumber = "Account Number (CCI)";
      break;
    case "ARS":
      AccountNumber = "Account Number (CBU)";
      break;
  }
  return AccountNumber;
}

function getToday() {
  let today = new Date();
  return { from: today, to: today };
}

function getLast7Days() {
  let toDate = new Date();
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 6);
  return { from: fromDate, to: toDate };
}

function getLast15Days() {
  let toDate = new Date();
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 14);
  return { from: fromDate, to: toDate };
}

function getLast30Days() {
  let toDate = new Date();
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 29);
  return { from: fromDate, to: toDate };
}

function getLast90Days() {
  let toDate = new Date();
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 89);
  return { from: fromDate, to: toDate };
}

function getThisMonth() {
  let toDate = new Date();
  let fromDate = new Date();
  fromDate.setDate(1);
  return { from: fromDate, to: toDate };
}

function formatDate(dateStr) {
  if (!dateStr) {
    return "";
  }
  var options = { day: "numeric", month: "short", year: "numeric" };
  let today = new Date(dateStr);
  return today.toLocaleDateString("en-GB", options);
}

function formatDateAndTime(date) {
  var options = { day: "numeric", month: "short", hour: "2-digit", minute: "2-digit", hour12: true };
  let dateIns = new Date(date);
  return dateIns.toLocaleDateString("en-IN", options);
}

function formatDateLongWithTime(date) {
  var options = { day: "numeric", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit", hour12: true };
  let dateIns = new Date(date);
  return dateIns.toLocaleDateString("en-IN", options);
}

function formatDateAndLongTime(date) {
  var options = { day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", hour12: false };
  let dateIns = new Date(date);
  return dateIns.toLocaleDateString("en-IN", options);
}

function formatDateLongWithLongTime(date) {
  var options = { day: "numeric", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit", hour12: false };
  let dateIns = new Date(date);
  return dateIns.toLocaleDateString("en-IN", options);
}

function formatToCurrency(amount) {
  return new Intl.NumberFormat("en-GB", { style: "decimal", maximumFractionDigits: 2 }).format(amount);
}

// call this method to get UTC date.
function formatToISOString(date) {
  if (!date) {
    return "";
  }
  date = new Date(date).setHours(0, 0, 0, 0);
  return new Date(date).toISOString();
}

function getDateRange(value) {
  let result;
  switch (value) {
    case "today":
      result = getToday();
      break;
    case "last7days":
      result = getLast7Days();
      break;
    case "last15days":
      result = getLast15Days();
      break;
    case "last30days":
      result = getLast30Days();
      break;
    case "last90days":
      result = getLast90Days();
      break;
    case "thismonth":
      result = getThisMonth();
      break;
    default:
      result = { from: "", to: "" };
      break;
  }
  return result;
}

function getUTCDateTime(dateStr) {
  return new Date(dateStr).toISOString();
}

function getMonth(num){
  switch(num){
    case 0:
      return 'Jan'
    case 1:
      return 'Feb' 
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'Aug' 
    case 8:
      return 'Sept'
    case 9:
      return 'Oct'
    case 10:
      return 'Nov'
    case 11:
      return 'Dec'
  }
}

function getFullMonth(num){
  switch(num){
    case 0:
      return 'January'
    case 1:
      return 'February' 
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'August' 
    case 8:
      return 'September'
    case 9:
      return 'October'
    case 10:
      return 'November'
    case 11:
      return 'December'
  }
}

function convertToTwoDigit(num){
  if (num<10){
    return "0" + num;
  }
  return num;
}

export {
  getDateRange,
  getToday,
  getLast7Days,
  getLast15Days,
  getLast30Days,
  getLast90Days,
  getThisMonth,
  formatDate,
  formatDateAndTime,
  formatToCurrency,
  formatToISOString,
  formatDateLongWithTime,
  getUTCDateTime,
  formatDateAndLongTime,
  formatDateLongWithLongTime,
  getMonth,
  getFullMonth,
  convertToTwoDigit  
};

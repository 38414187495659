<template>
  <div class="tl-kyb-banner">    
    <div class="xl:tw-inline tw-mr-3">{{ bannerText }} </div>
    <div class="tw-inline-flex tw-cursor-pointer tw-text-xs tw-px-3 tw-py-1 tw-bg-white tw-rounded-3xl hover:tw-text-white hover:tw-bg-primary-900 tw-gap-1" @click="loadKybModal">
      <span>Complete Now</span>
      <tc-icon icon="arrow-right-thick"/>
    </div>
  </div>  
</template>
<script>
import { getPlatformUser } from "@/helpers/platformUser";
export default {
  name: "KybBanner",
  methods: {
    loadKybModal() {
      if (document.getElementById("dialog-comp") != null) {
        document.getElementById("dialog-comp").classList.remove("tw-hidden");
      }
    },
  },
  computed: {
    bannerText() {
      if (this.isPlatformUser) {
        return "You can receive payouts only after completion of KYB.";
      }
      return "You can start collecting payments (via payment links or APIs) and receive payouts only after completion of KYB.";
    },
    isPlatformUser() {
      return getPlatformUser() === true || getPlatformUser() === "true";
    },
  },
};
</script>
<style scoped>
.tl-kyb-banner{
  @apply tw-bg-success-300 tw-text-primary-900 tw-px-4 tw-text-sm tw-font-semibold tw-py-2 tw-flex tw-items-center lg:tw-justify-center tw-gap-2 tw-flex-wrap;
  min-height: 48px;
}
</style>

import api from '@/api/Api';
import { actionsRequired } from '@/constants/apiEndPoints'
let BASE_URL = import.meta.env.VITE_BASE_URL;

function getDeliveryProofList(queryparams) {
  const list = `${BASE_URL}${actionsRequired.deliveryProof}`;
  let url = new URL(list);
  for(const key in queryparams){
    url.searchParams.append(key,queryparams[key]);
  }
  return api.get(url.href)
}
export {
  getDeliveryProofList,
}
import ModuleGuard from "./guards/module-guard";
import { ROUTE_SHOPIFY } from "@/constants/route.js";

export const ShopifyRoutes = [
  {
    path: `${ROUTE_SHOPIFY.VERIFY_API}`,
    name: "ShopifyVerification",
    beforeEnter: ModuleGuard,
    component: () => import("@/views/shopify/index.vue"),
    meta: {
      title: "Shopify",
      toolbar: {
        title: "Shopify",
        primaryLeft: "",
      },
      layout: "AppLayout",
      classes: {
        app: "tl-app--bg-white tl-app--content-toolbar",
      },
    },
  },
];

import { getLocalStorage } from "@/helpers/storageService";
import { sendFingerPrint } from "@/api/fingerPrint.js";
import * as Sentry from "@sentry/vue";

export const getFPParams = () => {
  const FP_INFO = JSON.parse(getLocalStorage("deviceFingerPrint"));
  if(FP_INFO ){
    try{
      const fpInfoIovation =  FP_INFO.iovation;
      return {
        fp: fpInfoIovation,
        provider: "IO",
      };
    }catch(error){
      console.log("FingerPrint Error: ", error);
      Sentry.captureException(error);
    }
  }
  return {
    fp : "",
    provider: "",
  }
};

export const getFingerPrint = () => {
  let apiReq = {
    dfp: getFPParams(),
  };
  sendFingerPrint(apiReq)
    .then((response) => {
      if (response.status === "success") {
        return apiReq;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

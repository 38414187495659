export const ERROR_TYPE = Object.freeze({
  TOKEN_EXPIRED: {
    CODE: 401,
    MESSAGE: "The incoming token has expired"
  },
  SESSION_EXPIRED: {
    CODE: 1201,
    MESSAGE: `session expired`,
    MESSAGE_SHORT: `session expired`
  },
  INVALID_AUTH_TOKEN: {
    CODE: 1202,
    MESSAGE: `Invalid authorization token`,
    MESSAGE_SHORT: `Invalid authorization token`
  },
  INVOICE_AMOUNT_MAX_LIMIT: {
    CODE: 1416,
    MESSAGE: `We accept invoice amount up to`,
    MESSAGE_SHORT: `We accept invoice amount`
  },
  RESOURCE_NOT_FOUND: {
    CODE: 404,
    MESSAGE: `The resource you are trying to retrieve is not present. This error code happens when trying to Get or Read a data`,
    MESSAGE_SHORT: `resource not found`
  },
  INVALID_COUNTRY_CODE: {
    CODE: 2411,
    MESSAGE: `Invalid country code. Please pass the country code of the country to which this user is registered`,
    MESSAGE_SHORT: `Invalid country code`
  },
  PAYMENT_METHODS_RESTRICTED: {
    CODE: 1518,
    MESSAGE: `All available payment methods are restricted for the participants in this transaction`,
    MESSAGE_SHORT: `payment methods are restricted`
  },
  FORBIDDEN: {
    CODE: 403,
    MESSAGE: `Forbidden : You are not authorised to access the requested resource`,
    MESSAGE_SHORT: `Forbidden`
  },
  INVOICE_AMOUNT_LESS_THAN: {
    CODE: 1416,
    MESSAGE: `Invoice amount should be less than`,
    MESSAGE_SHORT: `Invoice amount should be less than`
  },
  COUNTRY_NOT_SUPPORTED: {
    CODE: 1312,
    MESSAGE: `country is not supported for trading at this moment`,
    MESSAGE_SHORT: `country is not supported for trading`
  },
  PARTIES_SHOULD_NOT_IDENTICAL: {
    CODE: 1423,
    MESSAGE: `parties should not be identical`,
    MESSAGE_SHORT: `parties should not be identical`
  },
  ESCROW_DISABLED: {
    CODE: ``,
    MESSAGE: ``,
    MESSAGE_SHORT: `escrow_disabled`
  },
  BUYER_DISABLED: {
    CODE: ``,
    MESSAGE: ``,
    MESSAGE_SHORT: `buyer_disabled`
  },
  USER_ALREADY_EXISTS: {
    CODE: ``,
    MESSAGE: `user already exists`,
    MESSAGE_SHORT: `user already exists`
  },
  INVITATION_ALREADY_SENT: {
    CODE: ``,
    MESSAGE: `invitation already sent for the current user`,
    MESSAGE_SHORT: `invitation already sent for the current user`
  },
  PARTNER_USER_EXISTS: {
    CODE: ``,
    MESSAGE: `Partner user exists - cannot signup again.`,
    MESSAGE_SHORT: `Partner user exists`
  },
  INVALID_COUNTRY: {
    CODE: ``,
    MESSAGE: ``,
    MESSAGE_SHORT: `invalid country`
  },
  DUPLICATE_KEY: {
    CODE: ``,
    MESSAGE: ``,
    MESSAGE_SHORT: `duplicate key value violates`
  },
  DUPLICATE_PARTNER: {
    CODE: ``,
    MESSAGE: ``,
    MESSAGE_SHORT: `duplicate partner_code`
  },
  VALUE_MUST_BE: {
    CODE: ``,
    MESSAGE: ``,
    MESSAGE_SHORT: `value must be`
  },
  UNAUTHORISED: {
    CODE: 401,
    MESSAGE: `Unauthorised : The API key entered is invalid. Please check if you are using the sandbox key on the production end point or vice versa`,
    MESSAGE_SHORT: `Unauthorised`
  },
  UNAUTHORIZED: {
    CODE: 401,
    MESSAGE: ``,
    MESSAGE_SHORT: `unauthorized`
  },
  PASSWORD_RESET_EXPIRED: {
    CODE: ``,
    MESSAGE: `This password reset link has expired.`,
    MESSAGE_SHORT: `reset link has expired`
  },
})
<template>
  <div>
    <div v-if="isKybFetched && isTxnFetched" class="tw-grid tw-gap-10">
      <!-- setup guide -->
      <div class="tp-home" v-if="showSetupGuide()">
        <div class="tp-home__title">Let’s Get Started</div>
        <div class="tp-home__content">
          <div class="tw-col-span-2">
            <!-- setup guide steps component -->
            <Setup class="tw-h-full" />
          </div>
          <div
            class="tw-col-span-2 tw-hidden tw-gap-4 md:tw-grid lg:tw-col-span-1 lg:tw-grid-flow-row lg:tw-gap-6"
            style="grid-template-columns: repeat(auto-fit, minmax(280px, 1fr))"
          >
            <TilePricing class="tw-h-full" />
            <TileSupport class="tw-h-full" />
          </div>
        </div>
      </div>

      <div v-else class="tp-home">
        <div class="tp-home__title">Your Transactions</div>
        <KeyPerformanceIndicator />
        <!-- <KybBanner v-if="state == preKybWithTxn && isKybFetched" /> -->
        <ActionRequiredBanner :actionReqTxns="actionReqTxns" v-if="actionReqTxns > 0" />
      </div>

      <div class="tp-home tw-hidden sm:tw-block" v-if="!showSetupGuide()">
        <div class="tp-home__title">Start Collecting with Tazapay</div>
        <!-- <AnnouncementBanner /> -->
        <div class="tp-home__content">
          <TileTransactionCreate />
          <TileTransactionView />
          <TileSettlementView />
        </div>
      </div>

      <div class="tp-home tw-hidden sm:tw-block">
        <div class="tp-home__title">Integrations & Plugins</div>
        <div class="tp-home__content">
          <TileAPI />
          <TileSandbox />
          <TilePlugin />
        </div>
      </div>

      <div class="tp-home tw-hidden sm:tw-block" v-if="!isTxnAvailable">
        <div class="tp-home__title">Payment Methods & FX Rates</div>
        <div class="tp-home__content">
          <TilePayment />
          <TileWidget />
        </div>
      </div>

      <div class="tp-home tw-hidden sm:tw-block" v-if="!showSetupGuide()">
        <div class="tp-home__title">Manage Account</div>
        <div class="tp-home__content" style="grid-template-columns: repeat(auto-fit, minmax(160px, 1fr))">
          <TileSettingsAccount />
          <TileSettingsPlatform />
          <TileSettingsAPI />
          <TileSettingsBank />
          <TileSettingsReports />
        </div>
      </div>

      <div class="tp-home tw-hidden sm:tw-block" v-if="showSetupGuide()">
        <div class="tp-home__title">Get to Know Our Product</div>
        <div class="tp-home__content">
          <TileTransactionCreate />
          <TileTransactionView />
          <TileSettlementView />
        </div>
      </div>
    </div>

    <HomePageSkeleton v-else />

  </div>
</template>

<script setup>
/* eslint-disable */
import Setup from "@/views/home/components/Setup.vue";
import TilePricing from "@/views/home/components/tile/TilePricing.vue";
import TileSupport from "@/views/home/components/tile/TileSupport.vue";
import TileAPI from "@/views/home/components/tile/TileAPI.vue";
import TileSandbox from "@/views/home/components/tile/TileSandbox.vue";
import TilePlugin from "@/views/home/components/tile/TilePlugin.vue";
import TilePayment from "@/views/home/components/tile/TilePayment.vue";
import TileWidget from "@/views/home/components/tile/TileWidget.vue";
import TileTransactionCreate from "@/views/home/components/tile/TileTransactionCreate.vue";
import TileTransactionView from "@/views/home/components/tile/TileTransactionView.vue";
import TileSettlementView from "@/views/home/components/tile/TileSettlementView.vue";
import TileSettingsAccount from "@/views/home/components/tile/TileSettingsAccount.vue";
import TileSettingsPlatform from "@/views/home/components/tile/TileSettingsPlatform.vue";
import TileSettingsAPI from "@/views/home/components/tile/TileSettingsAPI.vue";
import TileSettingsBank from "@/views/home/components/tile/TileSettingsBank.vue";
import TileSettingsReports from "@/views/home/components/tile/TileSettingsReports.vue";
import ActionRequiredBanner from "@/views/home/components/ActionRequiredBanner.vue";
// import KybBanner from "@/views/home/components/KybBanner.vue";
import KeyPerformanceIndicator from "@/views/escrow/KPI/KeyPerformanceIndicator.vue";
// import { getKPIdetails } from "@/api/mp-dashboard.js";
import { computed, onMounted, ref, nextTick } from "vue";
import { useGlobalStore } from "@/stores/GlobalStore";
import { useActionRequiredStore } from "@/stores/ActionRequiredStore.js";
import { getAgreementSearch } from "@/api/agreementListing";
import { getCustomSearchDate } from "@/views/transaction/listing/consumables/TxnDateHelper.js";
// import { formatToISOString } from "@/helpers/dateHelper.js";
import HomePageSkeleton from "@/views/home/components/HomePageSkeleton.vue";
// import { isKeyObject } from "util/types";
// import AnnouncementBanner from "@/views/home/components/AnnouncementBanner.vue"

// const postKybNoTxn = "postKybNoTxn";
// const postKybWithTxn = "postKybWithTxn";
// const preKybNoTxn = "preKybNoTxn";
// const preKybWithTxn = "preKybWithTxn";


// let successfulTxnCount = ref();

let sandboxEnv = ref(import.meta.env.VITE_ENV_NAME === "sandbox");
let isTxnFetched = ref(false);
let isTxnAvailable = ref(false);
let actionReqTxns = computed(() => {
  return useActionRequiredStore().actionRequiredCount;
});

function showSetupGuide() {
  if (isKybFetched.value && isTxnFetched.value) {
    return sandboxEnv.value ? (!isKybCompleted.value && !isTxnAvailable.value) : (!isKybCompleted.value || (isKybCompleted.value && !isTxnAvailable.value))
  }
};

let isKybFetched = computed(() => {
  return useGlobalStore().isKybFetched;
});

let kybStatus = computed(() => {
  return useGlobalStore().getKybStatus || "not_started";
});

let qualityFlag = computed(() => {
  return useGlobalStore().qualityFlag;
});

let isKybCompleted = computed(() => {
  // let isKybCompleted;
  let preKybStage = ["not_started", "initiated", "pending", "rejected"];
  if (preKybStage.includes(kybStatus.value)) {
    return false;
  } else {
    return qualityFlag.value != "FDD Not Completed";
  }
  // if (isKybCompleted) {
  //   return successfulTxnCount.value == 0 ? postKybNoTxn : postKybWithTxn;
  // } else {
  //   return successfulTxnCount.value == 0 ? preKybNoTxn : preKybWithTxn;
  // }
});

// function getDetails() {
//   let toDate = new Date().setDate(new Date().getDate() + 1);
//   let params = {
//     date_from: "",
//     date_to: formatToISOString(toDate),
//     yesterday_from_date: "",
//     yesterday_to_date: "",
//   };
//   getKPIdetails(params)
//     .then((res) => {
//       let data = res.data.data;
//       successfulTxnCount.value = data.successful_transactions;
//       showSkeleton.value = false;
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }

function checkForTxns() {
  let params = {};
  params["sort_by"] = "createdAt";
  params["page_size"] = 1;
  params = { ...params, ...getCustomSearchDate() };
  params["useCustomSearch"] = true;
  params["current_page"] = 1;
  getAgreementSearch(params)
    .then((res) => {
      isTxnFetched.value = true;
        isTxnAvailable.value = !!res.data.data.metadata.total_records;

    })
    .catch((err) => {
      console.log(err);
    });
}

onMounted(() => {
  checkForTxns();
});
</script>

<style lang="scss" scoped>
.tp-home {
  &__ {
    &title {
      @apply tw-mb-4 tw-text-lg tw-font-bold tw-text-primary-900 lg:tw-text-2xl;
    }
    &content {
      @apply tw-grid tw-w-full tw-gap-3 lg:tw-gap-6;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }
}
</style>

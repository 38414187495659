<template>
  <div v-if="showCreateNew">
    <div v-if="isPlatformUser" class="tp-toolbar-action-select" id="dropdown-create-txn" >
      <div class="tp-toolbar-action-select__trigger" @click="showDropdown = !showDropdown">
        <span>Create New</span>      
        <tc-icon icon="chevron-down" size="xl"/> 
      </div>      
      <div v-show="showDropdown" class="tp-toolbar-action-select__list">
        <div 
          v-for="item in items" :key="item.value" 
          class="tp-toolbar-action-select__item" 
          @click="redirectTo(item.value)"
          >
          {{ item.label }}
        </div>          
      </div>      
    </div>
    <template v-else>
      <button data-gtm-key="create_new_escrow" type="button" data-cy="btnCreateEscrow" @click.prevent="redirectTo('escrow')" class="tp-toolbar-action-button">
        <span>Create New</span>                
      </button>
    </template>
  </div>
</template>

<script>
import { getPlatformUser } from "@/helpers/platformUser";
import { hasPermission } from "@/helpers/permissionHelper";
import { allPermissions } from "@/constants/permissions";

export default {
  name: 'AgreementAction',  
  data(){
    return {
      item: 'Create New',
      items: [
        { label: 'Escrow', value: 'escrow'},        
        { label: 'Payment Link', value: 'platform'},
      ],
      showCreateNew : true,
      showDropdown: false
    }
  },
  computed: {
    isPlatformUser(){
      return Boolean(getPlatformUser());
    }
  },
  methods: {    
    redirectTo(e){      
      if(e ===  'platform') return this.$router.push("/marketplace/create-payment-link");
      if(e ===  'escrow') return this.$router.push("/simplified-creation");
    },
    toggleDropdown(event){        
      if(document.getElementById("dropdown-create-txn")?.isSameNode(event.target) ||
        document.getElementById("dropdown-create-txn")?.contains(event.target)){
        // Event triggered from within the #dropdown-create-txn div element
        // Do nothing and return
        return; 
      }
      if(this.showDropdown) return this.showDropdown = false
    }    
  },
  mounted() {
    if(hasPermission(allPermissions.ESCROW_CREATE) && hasPermission(allPermissions.PAYMENT_LINK_CREATE)){
      this.items = [
        { label: 'Escrow', value: 'escrow'},        
        { label: 'Payment Link', value: 'platform'},
      ]
    } else if(hasPermission(allPermissions.ESCROW_CREATE)) {
      this.items = [{
        label: 'Escrow', value: 'escrow'
      }]
    } else if(hasPermission(allPermissions.PAYMENT_LINK_CREATE)) {
      this.items = [{
        label: 'Payment Link', value: 'platform'
      }]
    } else {
      this.items = []
      this.showCreateNew = false
    }
    
    //on click on body, hide the dropdown if it is shown    
    document.documentElement.addEventListener('click', this.toggleDropdown, true);       
  },
  unmounted(){
    //remove the event listeners related to dropdown
    document.documentElement.removeEventListener('click', this.toggleDropdown, true)
  }
}
</script>

<style scoped lang="scss">    
.tp-toolbar-action-select{ 
  @apply tw-relative tw-text-sm;

  &__{
    &list{
      min-width: 160px;
      @apply tw-absolute tw-z-88000 tw-right-0 tw-top-full tw-mt-1 tw-bg-white tw-text-neutral-800 tw-rounded tw-shadow-lg tw-py-1 tw-border tw-border-solid tw-border-neutral-200;    
    }
    &item{
      @apply tw-px-4 tw-py-3 hover:tw-bg-neutral-100 tw-flex tw-items-center tw-cursor-pointer;
    }
  }
}
.tp-toolbar-action-select__trigger,
.tp-toolbar-action-button{
  @apply tw-inline-flex tw-items-center tw-rounded tw-font-semibold tw-px-4 tw-h-48P tw-text-sm tw-whitespace-nowrap tw-bg-primary-800 tw-text-white tw-cursor-pointer;
}

</style>
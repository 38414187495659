<template>
  <div>
    <div v-if="isLoading" style="width: 360px"><PageSpinner /></div>
    <div v-else>
      <div>
        <MultiCheckbox
          :label="contactDetails.topic"
          :subLabel="subLabel"
          :showSubLabel="showSubLabel"
          :items="contactDetails.sub_topics"
          :errors="errMsgSelectItem"
          @change="setSelectedItems"
        />
      </div>
      <!-- Transaction Number -->
      <div v-show="activeItem == 'Transaction related' || activeItem == 'Payments'" class="tw-flex tw-flex-col tw-pt-4">
        <div class="tw-text-sm tw-font-semibold tw-leading-sm tw-text-neutral-700">Transaction number :</div>
        <InputText
          class="tw-pt-2 tw-font-normal tw-leading-base"
          type="text"
          v-model="transactionNo"
          :errors="errMsgTransNo"
          @inputOnBlur="setTransNo"
        />
      </div>
      <!-- Text area -->
      <div class="tw-flex tw-flex-col tw-pt-4">
        <div class="tw-text-sm tw-font-semibold tw-leading-sm tw-text-neutral-700">Can you give us more details?</div>
        <div class="tw-pt-2">
          <textarea
            class="tw-h-32 tw-w-full tw-rounded tw-border tw-border-solid tw-bg-info-50 tw-px-3 tw-pt-3 tw-text-base tw-font-normal tw-leading-sm tw-shadow-sm"
            :class="errMsgUsercomments.length != 0 ? 'error-border' : 'tw-border-neutral-300'"
            id="textArea"
            v-model="userComments"
            @blur="setUserComments"
          />
        </div>
      </div>
      <div v-show="errMsgUsercomments != ''">
        <div class="error-msg tw-pt-0.5">
          {{ errMsgUsercomments[0] }}
        </div>
      </div>

      <!-- Document Section -->

      <div class="tw-flex tw-gap-6 tw-pt-4" :class="docUploaded ? 'tw-flex-col tw-gap-px tw-pb-4' : ' tw-pb-3'">
        <div class="tw-pb-3 tw-text-sm tw-font-normal tw-leading-base tw-text-neutral-500">
          <span class="tw-font-semibold tw-text-neutral-600">Attachments</span>
          <span class="tw-text-neutral-600">(Optional)</span>
          Upload a screenshot of the issue you’re having, or the site content you'd like help with.
        </div>
        <div v-if="docUploaded">
          <UploadHeader @removeFile="removeDocument" :listData="uploadedList" />
        </div>
        <div>
          <kybFileUpload @onChange="handleDocUploaded" :onlyUpload="onlyUpload" />
        </div>
      </div>

      <!-- FAQ -->
      <div v-show="activeItem != 'Others'">
        <div class="tw-pb-1 tw-text-sm tw-font-semibold tw-leading-base tw-text-neutral-700">
          Frequently asked questions
        </div>
        <div
          v-for="faq in contactDetails.faq_links"
          class="tw-flex tw-flex-col tw-py-1 tw-text-left tw-text-sm tw-font-normal tw-leading-base"
        >
          <a :href="faq.url" target="_blank">
            <div class="tw-text-neutral-500">{{ faq.text }}</div>
          </a>
        </div>
        <div class="tw-flex tw-pb-3 tw-pt-2">
          <div class="tw-ml-52"></div>
          <a :href="contactDetails.faq_page_url" target="_blank">
            <div class="tw-flex tw-justify-end tw-gap-2">
              <div class="tw-text-sm tw-font-semibold tw-leading-base tw-text-info-600">View all FAQ</div>
              <img src="@/assets/images/faqArrow.svg" alt="Right arrow" />
            </div>
          </a>
        </div>
      </div>

      <!-- Sumbit Section -->
      <div class="tw-flex tw-justify-between tw-gap-8 tw-pt-4 tw-pb-8">
        <button
          class="tw-rounded tw-border tw-border-solid tw-border-neutral-300 tw-bg-white tw-px-6 tw-py-3 tw-text-base tw-font-semibold tw-leading-sm tw-text-primary-700 tw-shadow-2"
          @click="cancelGetHelp(false)"
        >
          Cancel
        </button>
        <button
          class="tw-rounded tw-border tw-border-solid tw-border-neutral-300 tw-bg-primary-700 tw-px-6 tw-py-3 tw-text-base tw-font-semibold tw-leading-sm tw-text-white tw-shadow-2"
          @click="submit()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import MultiCheckbox from "@/ui-components/MultiCheckbox.vue";
import { InputText, PageSpinner } from "tazapay-ui";
import kybFileUpload from "@/components/shared/kybDialog/kybFileUpload.vue";
import UploadHeader from "@/components/shared/FileDownloader.vue";
import { submitSupportForm } from "@/views/m-dashboard/api/dashboardApi";
import { h } from "vue";
import { useNotificationStore } from "@/stores/NotificationStore";
export default {
  name: "SectionSupport",
  components: {
    MultiCheckbox,
    InputText,
    kybFileUpload,
    PageSpinner,
    UploadHeader,
  },
  data() {
    return {
      userComments: "",
      transactionNo: "",
      errMsgTransNo: [],
      errMsgSelectItem: [],
      errMsgUsercomments: [],
      docUploaded: false,
      selectedItem: [],
      fileUrl: [],
      allowSubmit: false,
      isLoading: false,
      subLabel: "What do you need help with?",
      showSubLabel: this.activeItem != "Others",
      onlyUpload: true,
      uploadedList: [],
    };
  },
  props: {
    activeItem: String,
    contactDetails: Object,
  },
  methods: {
    cancelGetHelp(value) {
      this.$emit("cancelGetHelp", value);
    },
    submit() {
      let fileUrls = this.uploadedList.map((element) => ({ file_name: element.file_name, url: element.document_url }));
      let userRequest = {
        topic: this.activeItem,
        sub_topics: this.selectedItem,
        description: this.userComments,
        txn_no: this.transactionNo,
        document_urls: fileUrls,
      };
      if (userRequest.sub_topics.length == 0 && this.contactDetails.sub_topics != 0) {
        this.errMsgSelectItem.push("Select one item");
      }
      if (userRequest.txn_no.trim().length === 0) {
        this.errMsgTransNo.push("Transaction Number is Required");
      }
      if (userRequest.description.trim().length === 0) {
        this.errMsgUsercomments.push("Please provide the details");
      }
      if (
        (userRequest.sub_topics.length != 0 && userRequest.description.trim().length != 0) ||
        (userRequest.topic == "Others" && userRequest.description.trim().length != 0)
      ) {
        this.allowSubmit = true;
        if (
          (userRequest.topic == "Transaction related" || userRequest.topic == "Payments") &&
          userRequest.txn_no.trim().length === 0
        ) {
          this.allowSubmit = false;
        }
      }
      if (this.allowSubmit) {
        this.isLoading = true;
        this.$emit("showTopicLabel", false);
        submitSupportForm(userRequest)
          .then((resp) => {
            if (resp.data.status === "success") {
              this.isLoading = false;
              this.$emit("showThanksSection", true);
            } else {
              this.showErrorToaster();
              this.isLoading = false;
            }
          })
          .catch((err) => {
            console.error(err);
            this.showErrorToaster();
            this.isLoading = false;
          });
      }
    },
    handleDocUploaded(e) {
      let response = e.value;
      this.uploadedList.push({
        file_name: response.file_name,
        document_url: response.document_url,
      });
      this.docUploaded = true;
    },
    removeDocument(file) {
      this.uploadedList = this.uploadedList.filter((list) => {
        return list !== file;
      });
      if (this.uploadedList.length == 0) {
        this.docUploaded = false;
      }
    },
    setTransNo() {
      if (this.transactionNo.trim().length === 0) {
        this.errMsgTransNo.push("Transaction Number is Required");
      } else {
        this.errMsgTransNo = [];
      }
    },
    setSelectedItems(e) {
      this.selectedItem = e;
      if (this.selectedItem.length == 0) {
        this.errMsgSelectItem.push("Select one item");
      } else {
        this.errMsgSelectItem = [];
      }
    },
    setUserComments() {
      if (this.userComments.trim().length === 0) {
        this.errMsgUsercomments.push("Please provide the details");
      } else {
        this.errMsgUsercomments = [];
      }
    },
    showErrorToaster() {
      const notification = {
        type: "error",
        icon: "refresh",
        message: h(
          "span",
          {
            class: "tw-font-bold tw-text-base tw-leading-sm",
          },
          "Something went wrong. Please try again"
        ),
      };
      useNotificationStore().add(notification);
    },
  },
};
</script>
<style scoped>
.error-msg {
  color: var(--color-danger-600);
  font-size: 12px;
  text-align: left;
  font-weight: 500;
}
.error-border {
  border: 1px solid transparent;
  box-shadow: 0 0 0 2px var(--color-danger-600);
  outline: 0;
}
</style>

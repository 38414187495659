import { defineStore } from "pinia";
import { getDeliveryProofList } from "@/api/actionsRequired.js"

export const useActionRequiredStore = defineStore("ActionRequired", {
  state: () => ({
    actionRequiredCount: null,    
  }), 
  actions: {
    getActionRequiredCount(){
      return getDeliveryProofList({page_size:1})
        .then((res)=>{        
          this.actionRequiredCount = res.data.data.Count;   
          return this.actionRequiredCount;
        })
    },
  }
});

import api from './apiWrapper'
import keyGenerationEndPoints from './keyGenerationEndPoints';
let BASE_URL = import.meta.env.VITE_EAAS_BASE_URL;


function generateApiKeys(data) {
  let generateApiKey = `${BASE_URL}${keyGenerationEndPoints.generateApiKeys}`;
  return api.post(generateApiKey, data)
}

function getApiDetails() {
  let generateApiKey = `${BASE_URL}${keyGenerationEndPoints.generateApiKeys}`;
  return api.get(generateApiKey)
}

function updateConfig(config) {
  let generateApiKey = `${BASE_URL}${keyGenerationEndPoints.generateApiKeys}`;
  return api.put(generateApiKey, config);
}

function updatePublicKey(config) {
  let generatePublicKey = `${BASE_URL}${keyGenerationEndPoints.generatePublicKey}`;
  return api.put(generatePublicKey, config);
}

export {
  generateApiKeys,
  getApiDetails,
  updateConfig,
  updatePublicKey
}
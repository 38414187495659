<template>
  <div
    class="kyb-file-upload tw-flex tw-flex-col tw-pt-2 tw-pb-4 sm:tw-flex-row sm:tw-items-center sm:tw-pb-2"
    :class="{ 'tw-pt-4 sm:tw-pb-4': viewMode }"
  >
    <label
      v-if="label"
      class="leading-10 tw-mb-2 tw-mr-6 tw-flex tw-w-auto tw-items-center tw-text-xs tw-font-bold tw-text-neutral-700 sm:tw-mb-0 sm:tw-w-48"
    >
      <tc-icon icon="file-document" color="neutral-300" size="lg" class="tw-mr-2"></tc-icon>
      <span class="tw-overflow-hidden tw-overflow-ellipsis" :title="label">{{ label }}</span>
      <span v-if="optional && !viewMode" class="tw-ml-1 tw-text-xs tw-text-neutral-400">(optional)</span>
      <tc-tips class="tw-ml-2" v-if="showTooltip" :position="tooltipPosition" trigger="click" size="lg">
        <template v-slot:trigger>
          <tc-icon icon="information-outline" color="primary-700" size="xl"></tc-icon>
        </template>
        <slot name="tooltipContent"></slot>
      </tc-tips>
    </label>
    <div class="tw-flex tw-flex-col tw-items-start">
      <FileBrowser
        v-if="!file"
        leftIcon="tray-arrow-up"
        label="Upload"
        labelClass="tw-pr-4"
        @onFileSelect="fileSelected"
        :disabled="disabled"
        :allowedTypes="supportedFormats.length ? ' ' : 'image,csv,pdf,word,excel'"
        :mimeTypes="supportedFormats.join()"
      />
      <div v-if="!viewMode && errorMsg && !emitError" class="error-msg tw-text-xs tw-text-danger-800 sm:tw-ml-2">
        {{ errorMsg }}<br />{{ hint }}
      </div>
    </div>

    <div v-if="file">
      <div class="tw-flex tw-w-full tw-items-center sm:tw-w-auto">
        <!-- <img class="tw-mt-1 tw-mr-1" src="@/assets/images/paper-clip.svg" alt="paper-clip" /> -->
        <tc-icon icon="paperclip" size="lg" color="primary-800"></tc-icon>
        <label
          v-show="file.file_name"
          :title="file.file_name"
          @click="downLoadFile(file.document_url)"
          :class="{ 'tw-cursor-pointer tw-underline': viewMode }"
          class="file-name tw-ml-2 tw-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-xs tw-font-bold tw-leading-none tw-text-neutral-500 sm:tw-w-32"
        >
          {{ file.file_name }}
        </label>

        <div v-if="showProgressBar" class="progress-container tw-ml-3 tw-flex tw-items-center sm:tw-max-w-xs">
          <div class="progress tw-mr-4" :style="progressWidth"></div>
          <p class="progress-percentage tw-text-sm">{{ progressPercentage }}%</p>
        </div>

        <div v-if="!showProgressBar || file" class="tw-ml-5 tw-flex tw-items-center">
          <!-- <img class="tw-mr-6" src="@/assets/images/set-logo.svg" alt="" /> -->
          <!-- <tc-icon v-if="!viewMode" icon="check-circle" size="lg" color="success-800"></tc-icon> -->
          <img
            v-if="!viewMode"
            data-cy="removeDoc"
            @click="removeDocument"
            class="tw-cursor-pointer"
            src="@/assets/images/bin.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getUploadUrl } from "@/views/m-dashboard/api/dashboardApi";
import { clearStorage } from "@/helpers/storageService.js";

import { Tips, FileBrowser } from "tazapay-ui";

export default {
  components: { "tc-tips": Tips, FileBrowser },
  props: {
    label: {
      type: String,
    },
    fileInfo: {
      type: Object,
    },
    fieldName: {
      type: String,
    },
    onlyUpload: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    uploadUrl: {
      type: String,
      default: "",
    },
    tooltipPosition: {
      type: String,
      default: "top-right",
    },
    supportedFormats: {
      type: Array,
      default: () => {
        return [];
      },
    },
    errors: {
      type: String,
      default: "",
    },
    emitError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: "",
      uploadPercentage: "",
      progressPercentage: "",
      progressWidth: "",
      showProgressBar: false,
      errorMsg: "",
      hint: "",
    };
  },
  computed: {
    supportedTypes() {
      if (this.supportedFormats.length) {
        return this.supportedFormats.join();
      }
      return this.validTypes.join();
    },
  },
  watch: {
    fileInfo() {
      this.file = this.fileInfo ? { ...this.fileInfo } : "";
    },
    viewMode() {
      this.errorMsg = "";
    },
    errors() {
      if (this.required) {
        this.errorMsg = this.errors;
      } else {
        this.errorMsg = "";
      }
    },
    optional() {
      if (this.optional) {
        this.errorMsg = "";
      }
    },
    errorMsg() {
      if (this.emitError) {
        this.emitFileUploadError();
      }
    },
  },
  methods: {
    async fileSelected(files) {
      this.errorMsg = "";
      this.hint = "";

      if (!files || !files.length) {
        this.errorMsg = "No file selected";
        this.hint = "Please select a file";
        return;
      } else if (!files[0].isValid) {
        if (files[0].errorCode !== "IE6" || this.supportedFormats.length > 0) {
          this.errorMsg = files[0].msg;
          this.hint = files[0].hint;
          return;
        }
      }

      let apiEndPoint = "";
      if (!this.uploadUrl) {
        try {
          // have to wait until upload endpoint is resolved.
          apiEndPoint = await this.getUploadEndPoint();
        } catch {
          this.errorMsg = "Failed to upload. Try Again.";
          return;
        }
      } else {
        apiEndPoint = this.uploadUrl;
      }

      this.errorMsg = "";
      let file = files[0].file;
      this.file = this.onlyUpload ? this.file : file;

      let newFileName = file.name;
      // File name is checked for ascii character and if not, it is changed to a generic name
      let asciiRegex = /^[\x00-\x7F]*$/;
      if (!asciiRegex.test(file.name)) {
        newFileName = this.label;
      }

      if (file) {
        let formData = file;
        let axiosFn = axios.put;
        if (this.uploadUrl) {
          formData = new FormData();
          formData.append("files", file, newFileName);
          axiosFn = axios.post;
        }

        this.showProgressBar = true;
        axiosFn(apiEndPoint, formData, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            this.progressPercentage = this.uploadPercentage;
            this.progressWidth = `width: ${this.uploadPercentage}%;`;
          },
        }).then(
          (res) => {
            if (res?.status === 200) {
              let fileResponce = {
                file_name: file.name,
                document_url: apiEndPoint,
              };
              this.file = this.onlyUpload ? this.file : fileResponce;
              this.$emit("onChange", {
                fieldName: this.fieldName,
                value: {
                  file_name: fileResponce.file_name,
                  document_url: fileResponce.document_url,
                  responce: res,
                },
              });
              this.showProgressBar = false;
              console.log(res);
            }
          },
          () => {
            this.file = null;
            this.errorMsg = "Failed to upload. Try Again.";
          }
        );
      } else {
        this.errorMsg = file?.msg || "";
      }
    },

    downLoadFile(url) {
      this.$emit("onDownload");
      if (!url) {
        return;
      }
      let fileUrlOpen = window.open(url, "_blank");
      fileUrlOpen.focus();
    },
    emitFileUploadError() {
      this.$emit("onError", {
        errorMsg: this.errorMsg,
        hint: this.hint,
      });
    },
    removeDocument() {
      let eventData = {
        fieldName: this.fieldName,
        value: this.file,
      };
      this.$emit("removeFile", eventData);
      this.file = "";
    },
    async getUploadEndPoint() {
      let url = await getUploadUrl().then(
        (res) => {
          if (res?.status === 200) {
            return res.data.data.upload_url;
          }
        },
        (error) => {
          if (error?.message === "Unauthorized") {
            clearStorage();
            this.$router.push("/signin");
            return;
          }
          return new Error("error in fetching api url for file upload ", error);
        }
      );
      return url;
    },
  },
  mounted() {
    this.file = this.fileInfo ? { ...this.fileInfo } : "";
  },
};
</script>

<style scoped>
.progress-container {
  width: 100px;
}

.file-name {
  text-overflow: ellipsis;
}

.progress-container .progress {
  width: 0%;
  height: 5px;
  background: #58c5ed;
  overflow: hidden;
  color: white;
}

@media only screen and (min-width: 315px) and (max-width: 599px) {
  .file-name {
    max-width: 14rem;
  }
}
</style>

<template>
  <div class="tw-mb-8 tw-rounded tw-border tw-border-solid tw-border-neutral-150 tw-shadow-1 tw-bg-white">
    <div
      class="tw-flex tw-flex-col tw-justify-between tw-gap-2 sm:tw-items-center tw-border-b tw-border-0 tw-border-solid tw-border-neutral-150 tw-py-3 tw-px-3 tw-text-base sm:tw-flex-row md:tw-items-center md:tw-px-6"
    >
      <div class="tw-flex tw-items-center">
        <label class="tw-mr-3 tw-font-bold tw-text-primary-900 md:tw-mb-0">Overview</label>
        <tc-icon v-show="loading" icon="loading" color="primary-900" size="xl" class="tw-animate-spin"></tc-icon>
      </div>
      <div class="tw-flex tw-items-center tw-flex-wrap tw-gap-2">
        <PopperComponent @onDocClick="showDropdown = false" :showContent="showDropdown" placement="bottom-end" :offset="[0, 10]" :useTeleport="true" contentClasses="tp-popper-dropdown">
          <template v-slot:trigger>
            <button class="tw-flex tw-items-center" @click="showDropdown = !showDropdown">
              <div
                class="tw-whitespace-nowrap tw-pr-1 tw-text-xs tw-font-bold tw-capitalize tw-text-neutral-600"
              >
                {{ presetDates[selectedCustomDate] }}
              </div>
              <tc-icon
                icon="chevron-down tw-pr-1"
                color="primary-900"
                size="lg"
                class="tw-border-0 tw-border-r tw-border-solid tw-border-neutral-300"
              ></tc-icon>
            </button>
          </template>
          <template v-slot:content>
            <div class="tw-grid tw-grid-cols-1">
              <template v-for="(label, value) in presetDates">
                <button
                  class="tw-py-2 tw-px-4 tw-text-left tw-text-sm tw-text-neutral-700 hover:tw-bg-info-50"
                  @click="handleCustomDateChange(value)"
                >
                  {{ label }}
                </button>
              </template>
            </div>
          </template>
        </PopperComponent>
        <div class="tw-flex tw-items-center tw-flex-wrap tw-gap-1">
          <InputDatePicker :errors="[]" @inputOnChange="handleDatePickerChange($event, 'fromDate')">
            <template v-slot:icon-calendar>
              <img src="@/assets/images/Calendar.svg" />
            </template>
            <template v-slot:input>
              <button
                ref="$fromEle"
                class="date-label tw-whitespace-nowrap tw-rounded tw-p-1 tw-text-xs tw-font-bold tw-leading-lg tw-text-neutral-600"
                :class="selectedFromDate ? '' : 'tw-text-neutral-400'"
              >
                {{ formatDate(selectedFromDate) || "From Date" }}
              </button>
            </template>
          </InputDatePicker>
          <span class="tw-text-xs tw-text-neutral-500">to</span>
          <InputDatePicker :errors="[]" @inputOnChange="handleDatePickerChange($event, 'toDate')">
            <template v-slot:icon-calendar>
              <img src="@/assets/images/Calendar.svg" />
            </template>
            <template v-slot:input>
              <button
                ref="$toEle"
                class="date-label tw-whitespace-nowrap tw-rounded tw-p-1 tw-text-xs tw-font-bold tw-leading-lg tw-text-neutral-600"
                :class="selectedTodate ? '' : 'tw-text-neutral-400'"
              >
                {{ formatDate(selectedTodate) || "To Date" }}
              </button>
            </template>
          </InputDatePicker>
        </div>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-1 tw-p-4 md:tw-grid-cols-3">
      <div class="tw-flex tw-flex-col tw-justify-between tw-py-4 md:tw-px-4 md:tw-py-0">
        <div class="tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex tw-items-center">
            <label class="tw-mr-2 tw-text-sm tw-font-bold tw-leading-sm tw-text-primary-900">Gross Revenue</label>
            <tips position="top" variant="white" size="lg">
              <template v-slot:trigger>
                <tc-icon icon="information-outline" color="primary-900"></tc-icon>
              </template>
              <div class="tw-text-sm tw-leading-lg tw-text-neutral-700">
                Total value of all successful transactions during the period.
              </div>
            </tips>
          </div>
          <label class="tw-text-xs tw-font-medium tw-text-neutral-400" title="Last updated time">{{
            formatDateAndTime(currentTime)
          }}</label>
        </div>
        <div class="tw-self-end">
          <label class="tw-mr-2 tw-text-xl tw-font-bold tw-text-neutral-700">{{
            formatToCurrency(KPIMetric.gross_revenue)
          }}</label>
          <label class="tw-text-xl tw-text-neutral-400">{{ KPIMetric.gross_revenue_currency }}</label>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between">
          <label class="tw-text-xs tw-font-medium tw-text-neutral-400">Yesterday</label>
          <div class="tw-flex tw-items-center">
            <label class="tw-mr-2 tw-text-sm tw-text-neutral-700">{{
              formatToCurrency(KPIMetric.yesterday_gross_revenue)
            }}</label>
            <label class="tw-text-xl tw-text-neutral-400">{{ KPIMetric.gross_revenue_currency }}</label>
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-justify-between tw-border-0 tw-border-t-2 tw-border-b-2 tw-border-solid tw-py-4 tw-text-neutral-150 md:tw-border-t-0 md:tw-border-b-0 md:tw-border-r-2 md:tw-border-l-2 md:tw-px-4 md:tw-py-0"
      >
        <div class="tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex tw-items-center">
            <label class="tw-mr-2 tw-text-sm tw-font-bold tw-leading-sm tw-text-primary-900">Net Revenue</label>
            <tips position="top" variant="white" size="lg">
              <template v-slot:trigger>
                <tc-icon icon="information-outline" color="primary-900"></tc-icon>
              </template>
              <div class="tw-text-sm tw-leading-lg tw-text-neutral-700">
                Total value of all successful transactions during the period net of fees, refunds and disputes.
              </div>
            </tips>
          </div>
          <label class="tw-text-xs tw-font-medium tw-text-neutral-400" title="Last updated time">{{
            formatDateAndTime(currentTime)
          }}</label>
        </div>
        <div class="tw-self-end">
          <label class="tw-mr-2 tw-text-xl tw-font-bold tw-text-neutral-700">{{
            formatToCurrency(KPIMetric.net_revenue)
          }}</label>
          <label class="tw-text-xl tw-text-neutral-400">{{ KPIMetric.net_revenue_currency }}</label>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between">
          <label class="tw-text-xs tw-font-medium tw-text-neutral-400">Yesterday</label>
          <div class="tw-flex tw-items-center">
            <label class="tw-mr-2 tw-text-sm tw-text-neutral-700">{{
              formatToCurrency(KPIMetric.yesterday_net_revenue)
            }}</label>
            <label class="tw-text-xl tw-text-neutral-400">{{ KPIMetric.net_revenue_currency }}</label>
          </div>
        </div>
      </div>
      <div class="tw-py-4 md:tw-px-4 md:tw-py-0">
        <label class="tw-font-bold tw-text-primary-900">Transaction Count</label>
        <div class="tw-mt-2 tw-flex tw-items-center tw-justify-between">
          <label class="tw-text-xs tw-leading-sm tw-text-neutral-600">Successful Transactions</label>
          <label class="tw-text-sm tw-font-bold">{{ KPIMetric.successful_transactions }}</label>
        </div>
        <div class="tw-mt-2 tw-flex tw-items-center tw-justify-between">
          <label class="tw-text-xs tw-leading-sm tw-text-neutral-600">Number of Unique Buyers</label>
          <label class="tw-text-sm tw-font-bold">{{ KPIMetric.unique_buyers }}</label>
        </div>
        <div class="tw-mt-2 tw-flex tw-items-center tw-justify-between">
          <label class="tw-text-xs tw-leading-sm tw-text-neutral-600">Refund Count</label>
          <label class="tw-text-sm tw-font-bold">{{ KPIMetric.refund_count }}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import { Tips, InputDatePicker } from "tazapay-ui";
import { ref, nextTick, watch } from "vue";
import PopperComponent from "@/components/shared/PopperComponent.vue";
import { getKPIdetails } from "@/api/mp-dashboard.js";
import clearStorageAndRedirect from "@/helpers/clearStorageAndRedirect";
import { ERROR_TYPE } from "@/constants/errorCodes";

import {
  getDateRange,
  formatDate,
  formatDateAndTime,
  formatToCurrency,
  formatToISOString,
} from "@/helpers/dateHelper.js";

let presetDates = {
  today: "Today",
  last7days: "Last 7 Days",
  last15days: "Last 15 Days",
  thismonth: "This Month",
  custom: "Custom",
};

let showDropdown = ref(false);
/* eslint-enable */

let $fromEle = ref(null);
let $toEle = ref(null);
let loading = ref(false);

let selectedCustomDate = ref("today");

let selectedFromDate = ref();
let selectedTodate = ref();
let currentTime = ref(new Date());

let KPIMetric = ref({
  gross_revenue: 0,
  gross_revenue_currency: "",
  net_revenue: 0,
  net_revenue_currency: "",
  refund_count: 0,
  successful_transactions: 0,
  unique_buyers: 0,
  yesterday_gross_revenue: 0,
  yesterday_net_revenue: 0,
});

// eslint-disable-next-line
function handleCustomDateChange(value) {
  selectedCustomDate.value = value;
  let filterDate = getDateRange(selectedCustomDate.value);
  selectedFromDate.value = filterDate.from;
  selectedTodate.value = filterDate.to;
  if (selectedCustomDate.value === "custom") {
    nextTick(() => {
      // opens the from date picker.
      $fromEle.value.click();
      $fromEle.value.focus();
    });
  }
}

// eslint-disable-next-line
function handleDatePickerChange(event, dateStr) {
  selectedCustomDate.value = "custom";
  if (dateStr === "fromDate") {
    selectedFromDate.value = event;
    // reset to date when date is invalid
    if (selectedTodate.value && selectedFromDate.value > selectedTodate.value) {
      selectedTodate.value = "";
    }
    nextTick(() => {
      // opens the to date picker.
      $toEle.value.click();
      $toEle.value.focus();
    });
  } else if (dateStr === "toDate") {
    selectedTodate.value = event;
    // reset from date when date is invalid
    if (selectedFromDate.value && selectedFromDate.value > selectedTodate.value) {
      selectedFromDate.value = "";
      nextTick(() => {
        // opens the to date picker.
        $fromEle.value.click();
        $fromEle.value.focus();
      });
    }
  }
}

function fetchDetails(from, to) {
  loading.value = true;
  let toDate = new Date(to).setDate(to.getDate() + 1);
  let yesterdayToDate = new Date();
  let yesterdayFromDate = new Date().setDate(yesterdayToDate.getDate() - 1);

  let params = {
    date_from: formatToISOString(from),
    date_to: formatToISOString(toDate),
    yesterday_from_date: formatToISOString(yesterdayFromDate),
    yesterday_to_date: formatToISOString(yesterdayToDate),
  };
  getKPIdetails(params)
    .then((res) => {
      KPIMetric.value = res.data?.data || {};
    })
    .catch((err) => {
      let errMessage = err.message || "";
      if (
        errMessage === ERROR_TYPE.TOKEN_EXPIRED.MESSAGE ||
        errMessage.toLowerCase() === ERROR_TYPE.UNAUTHORIZED.MESSAGE_SHORT
      ) {
        clearStorageAndRedirect(ERROR_TYPE.TOKEN_EXPIRED.CODE);
      }
    })
    .finally(() => {
      loading.value = false;
      // update the last updated time of the results.
      currentTime.value = new Date();
    });
}

// trigger
let defaultDate = getDateRange("today");
selectedFromDate.value = defaultDate.from;
selectedTodate.value = defaultDate.to;
fetchDetails(defaultDate.from, defaultDate.to);

watch(
  () => {
    return [selectedTodate.value, selectedFromDate.value];
  },
  () => {
    if (selectedFromDate.value && selectedTodate.value) {
      fetchDetails(selectedFromDate.value, selectedTodate.value);
    } else if (selectedCustomDate.value === "all") {
      fetchDetails();
    }
  }
);
</script>
<style scoped>
.date-label:focus {
  background-color: #e9f6fb;
}
:deep .mx-datepicker {
  width: auto;
  min-width: 100px;
}

:deep .input-holder .error-holder {
  margin-top: 0px;
}

:deep .mx-input-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

:deep .mx-icon-calendar {
  position: static;
  transform: none;
  height: 18px;
}
</style>

import MarketPlaceDashBoardGuard from './guards/mp-guard';
import ModuleGuard from './guards/module-guard';

export const MarketPlaceDashBoardRoutes = [
  {
    path: "/marketplace/create-payment-link", 
    name: "mpGeneratePaymentLinkContainer", 
    beforeEnter: ModuleGuard,
    component: () => import("@/views/m-dashboard/containers/generatePaymentLinkContainer.vue"),
    meta: { 
      title: "Create Payment Link",
      breadCrumb(route) {        
        return [
          {
            text: 'Transactions',
            to: { name: 'transactionsListing' }
          },
          {
            text: route.meta?.toolbar?.title,
          }
        ]
      },
      toolbar:{
        title: "Create Payment Link"
      },
      layout: "AppLayout"
    },
  },
  
  {
    path: "/marketplace/share-payment-link", name: "mpSharePaymentLink", meta: { title: "Share Payment Link", layout: "AppLayout" },
    beforeEnter: MarketPlaceDashBoardGuard,
    component: () => import("@/views/m-dashboard/containers/sharePaymentLinkContainer.vue")
  },

  {
    path: "/marketplace/share-payment-link/:id", name: "mpSharePaymentLinkId", meta: { title: "Share Payment Link", layout: "AppLayout"},
    beforeEnter: MarketPlaceDashBoardGuard,
    component: () => import("@/views/m-dashboard/containers/sharePaymentLinkContainer.vue")
  },
  // {
  //   path: "/marketplace/create-api-key", 
  //   name: "mpCreateApiKey",    
  //   component: () => import("@/views/dashboard/PlatformConfigurations.vue"),
  //   meta: { 
  //     title: "Create API Key",      
  //     toolbar: {
  //       title: "API Keys & Payment Link Set-up",
  //     },
  //     layout: "AppLayout"
  //   },
  // },
  {
    path: "/marketplace/api-key-requested", name: "mpApiKeyRequested", meta: { title: "API key requested", layout: "AppLayout" },    
    component: () => import("@/views/m-dashboard/containers/apiRequestedContainer.vue")
  },
  // below route is no longer needed just using to side menu navigation
  // page title and title
  {
    path: "/marketplace/api-config", 
    name: "mpCreateApiConfig", 
    beforeEnter: ModuleGuard,    
    component: () => import("@/views/dashboard/GenerateApiKey.vue"),
    meta: { 
      title: "API Configuration",      
      toolbar: {
        title: "API Keys",
      },
      layout: "AppLayout"
    },
  },
  {
    path: "/plugins", 
    beforeEnter: ModuleGuard,    
    component: () => import("@/views/dashboard/Plugins.vue"),
    meta: { 
      title: 'Plugins',  
      breadCrumb() {        
        return [
          {
            text: 'API Keys',
            to: { name: 'mpCreateApiConfig' }
          },
          {
            text: 'Plugins',
          },
        ]
      },
      layout: "AppLayout"
    },
  },
  {
    path: "/marketplace/platform-config", 
    name: "mpCreatePlatformConfig", 
    beforeEnter: ModuleGuard,
    component: () => import("@/views/dashboard/PlatformConfigurations.vue"),
    meta: { 
      title: "Platform Configurations",      
      toolbar: {
        title: "Platform Configurations",
      },
      layout: "AppLayout"
    },
  },
];

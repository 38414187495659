import ModuleGuard from "./guards/module-guard";
import { ROUTE_TRANSACTION } from "@/constants/route.js";

export const TransactionRoutes = [
  {
    path: `${ROUTE_TRANSACTION.TRANSACTION_LISTING}`,
    name: "transactionsListing",
    beforeEnter: ModuleGuard,
    component: () => import("@/views/transaction/listing/TransactionListing.vue"),
    meta: {
      title: "Transactions",
      toolbar: {
        title: "Transactions",
        primaryRight: "AgreementToolbarAction",
        primaryLeft: "HeadingLabel",
        // secondaryLeft: "TransactionToolbarTab",
      },
      layout: "AppLayout",
      classes: {
        app: "tl-app--bg-white tl-app--content-toolbar",
      }
    },
  },
  {
    path: `${ROUTE_TRANSACTION.TRANSACTION_DETAILS}`,
    name: "transactionsDetails",
    beforeEnter: ModuleGuard,
    component: () => import("@/views/transaction/details/TransactionDetails.vue"),
    meta: {
      title: "Transactions",
      breadCrumb() {
        return [
          {
            text: "Transactions",
            to: { name: 'transactionsListing' } //this.$router.options.history.state.back,
          },
          {
            text: "Transactions Summary",
          },
        ];
      },
      layout: "AppLayout",
    },
  },
];

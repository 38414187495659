<template>
  <div class="tp-banner" v-if="showBankDetailBanner">
    <div class="tp-banner__seperator"></div>
    <button class="tp-banner__content" @click="routeToBankDetails">
      <tc-icon icon="alert-circle" size="lg" class="tw-hidden md:tw-block"></tc-icon>
      <span>Bank details required</span>
      <tc-icon icon="arrow-right-thick" size="lg" class="tw-hidden md:tw-block"></tc-icon>
    </button>
  </div>
</template>

<script setup>
/* eslint-disable */
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getBankDetails } from "@/api/account/bankDetails";

const route = useRoute();
const router = useRouter();

let pageTitle = ref(route?.meta?.toolbar?.title);
let isBankDetailSaved = ref(false);

let showBankDetailBanner = computed(() => {
  return pageTitle.value === "Settlements" && isBankDetailSaved.value;
});

function checkBankDetails() {
  getBankDetails()
    .then((res) => {
      if (res?.status === 200 && res?.data) {
        let bankDetails = res?.data;
        isBankDetailSaved.value = !bankDetails.is_bank_details_saved;
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

function routeToBankDetails() {
  router.push("/bank-details");
}

onMounted(() => {
  checkBankDetails();
});
</script>

<style lang="scss" scoped>
.tp-banner {
  @apply tw-flex tw-gap-4;
  &__ {
    &seperator {
      @apply tw-h-6 tw-w-px tw-self-center tw-bg-neutral-200;
    }
    &content {
      @apply tw-flex tw-gap-2 tw-rounded-3xl tw-bg-danger-200 tw-py-2 tw-px-3 tw-text-sm tw-font-semibold tw-leading-sm tw-text-danger-600;
    }
  }
}
</style>

<template>
  <Banner bannerIcon="email" :text="bannerText">
    <template v-slot:action>
      <button
        @click="handleAction"
        class="tw-w-full tw-rounded tw-bg-primary-700 tw-p-3 tw-text-xs tw-font-bold tw-leading-sm tw-text-info-100 md:tw-w-auto"
      >
        {{ actionLabel }}
      </button>
    </template>
  </Banner>
</template>
<script setup>
// eslint-disable-next-line
import {Banner} from "tazapay-ui";

import { VerifyEmail } from "@/api/legacyapi/user";
import { getLocalStorage, clearStorage } from "@/helpers/storageService";
import { ref, onMounted } from "vue";
import { ERROR_TYPE } from "@/constants/errorCodes.js";

// eslint-disable-next-line
const props = defineProps({
  text: {
    type: String,
    default: "Verify your email to complete your registration",
  },
});

const ACTION_LABEL = {
  SEND: "Send Verification",
  SENDING: "Processing...",
  SENT: "Sent",
  RESEND: "Re-send Verification",
};

const BANNER_TEXT = {
  VERIFY_EMAIL: "Verify your email to complete your registration",
  RESEND: "We’ve sent a verification email to ",
};

let actionLabel = ref(ACTION_LABEL.SEND);
// eslint-disable-next-line
let bannerText = ref(props.text);
// eslint-disable-next-line
function handleAction() {
  sendVerficationEmail();
}

// eslint-disable-next-line
function sendVerficationEmail() {
  actionLabel.value = ACTION_LABEL.SENDING;
  let mailId = getLocalStorage("userEmail");
  if (!mailId) {
    clearStorage();
    this.$router.push("/signin");
  }
  VerifyEmail(mailId).then(
    () => {
      changeToPendingState();
    },
    (rej) => {
      if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
        clearStorage();
        this.$router.push("/signin");
      }
    }
  );
}

// eslint-disable-next-line
function changeToPendingState() {
  let mailId = getLocalStorage("userEmail");
  actionLabel.value = ACTION_LABEL.RESEND;
  bannerText.value = BANNER_TEXT.RESEND + mailId;
  localStorage.VerifyInProgress = "true";
  localStorage.setItem("isEmailNotVerified", false);
}

onMounted(() => {
  let isVerifyInProgress = getLocalStorage("VerifyInProgress");
  if (isVerifyInProgress === "true") {
    changeToPendingState();
  }
});
</script>

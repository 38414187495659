<template>
  <div v-if="showDrawer">
    <Teleport to="#teleportal">
    <Drawer :open="showDrawer" @emitCloseDrawer="supportClose">
      <!-- header -->
      <template v-slot:header>
        <div class="tw-flex tw-justify-between tw-bg-primary-800 tw-px-5 tw-py-4">
          <div class="tw-text-base tw-font-semibold tw-leading-base tw-text-white">Contact support</div>
          <button @click="supportClose(false)">
            <img src="@/assets/images/supportClose.svg" alt="close icon" />
          </button>
        </div>
      </template>
      <!-- body starts -->
      <div v-if="isLoad" style="width: 360px">
        <PageSpinner />
      </div>
      <div
        v-if="!thanksSection && !isLoad"
        class="tw-px-4 tw-text-sm tw-font-semibold tw-leading-base tw-text-neutral-700"
        style="max-width: 360px"
      >
        <div v-show="showTopic" class="tw-pt-3">
          <div v-if="activeItem == 'default'">Select a topic:</div>
          <div v-else class="tw-flex tw-gap-2 tw-pb-2">
            <div>Topic</div>
            <button @click="activeItem = 'default'" class="tw-font-normal tw-text-info-600">Change</button>
          </div>
        </div>
        <!-- topic div -->
        <div v-show="activeItem == 'default'">
          <div v-for="(value, id) in supportDetails" :key="value" class="tw-my-3 tw-flex tw-flex-col">
            <button
              class="tw-rounded tw-border tw-border-solid tw-border-neutral-150 tw-bg-white tw-py-2 tw-pl-3 tw-shadow-2  hover:tw-border-neutral-300 hover:tw-bg-info-50 hover:tw-shadow-1"
              @click="changeState(value.topic, id)"
            >
              <div class="tw-flex tw-items-center tw-justify-between tw-pr-3">
                <div class="">{{ value.topic }}</div>
                <img src="@/assets/images/supportTopicArrow.svg" alt="support topic arrow" />
              </div>
              <div class="tw-pr-14 tw-text-left tw-font-normal tw-text-neutral-500">{{ value.description }}</div>
            </button>
          </div>
        </div>
        <!-- faq   -->
        <div v-show="activeItem == 'default'">
          <div class="tw-pb-1 tw-pt-3">Frequently asked questions</div>
          <div v-for="(link, question) in questions" class="tw-flex tw-flex-col tw-py-1 tw-text-left tw-font-normal">
            <a :href="link" target="_blank"
              ><div class="tw-text-neutral-500">{{ question }}</div></a
            >
          </div>
          <div class="tw-flex tw-pt-2 tw-pb-12">
            <div class="tw-ml-52"></div>
            <a href="https://support.tazapay.com" target="_blank">
              <div class="tw-flex tw-justify-end tw-gap-2">
                <div class="tw-text-sm tw-font-semibold tw-leading-base tw-text-info-600">View all FAQ</div>
                <img src="@/assets/images/faqArrow.svg" alt="FAQ arrow" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- Individual topics Section -->
      <div class="tw-px-4" style="max-width: 360px">
        <div v-if="activeItem != 'default' && !thanksSection">
          <SectionSupport
            :activeItem="activeItem"
            :contactDetails="supportDetails[sectionId]"
            @cancelGetHelp="supportClose"
            @showThanksSection="showThanksSection"
            @showTopicLabel="showTopicLabel"
          />
        </div>
      </div>
      <!-- Submit Page -->
      <div v-show="thanksSection" style="max-width: 360px">
        <ThanksSection />
      </div>
    </Drawer>
    </Teleport>
  </div>
</template>
<script>
import { Drawer } from "tazapay-ui";
import { PageSpinner } from "tazapay-ui";
import SectionSupport from "@/views/contactSupport/sectionSupport.vue";
import ThanksSection from "@/views/contactSupport/thanksSection.vue";
import { h } from "vue";
import { useNotificationStore } from "@/stores/NotificationStore";
import { getSupportTopics } from "@/views/m-dashboard/api/dashboardApi";
export default {
  name: "ContactSupport",
  components: {
    Drawer,
    SectionSupport,
    ThanksSection,
    PageSpinner,
  },
  props: {
    showDrawer: {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      activeItem: "default",
      questions: {
        "What payment methods do you support?": "https://support.tazapay.com/what-payment-methods-do-you-support",
        "What is the fee for Tazapay's service and who pays the fee?":
          "https://support.tazapay.com/what-is-the-fee-for-tazapays-service-and-who-pays-the-fee",
        "What can I do if a transaction is declined?":
          "https://support.tazapay.com/what-can-i-do-if-transaction-declined",
      },
      thanksSection: false,
      sectionId: 0,
      showTopic: true,
      supportDetails: [],
      isLoad: false,
    };
  },
  methods: {
    supportClose(payload) {
      this.$emit("supportClose", payload);
      this.activeItem = "default";
      this.thanksSection = false;
      this.showTopic = true;
    },
    showThanksSection(value) {
      this.thanksSection = value;
    },
    changeState(topic, value) {
      this.sectionId = value;
      this.activeItem = topic;
    },
    showTopicLabel(value) {
      this.showTopic = value;
    },
    async showSupportDrawer() {
      this.isLoad = true;
      this.supportDetails = await getSupportTopics()
        .then((resp) => {
          if (resp.data.status === "success") {
            this.isLoad = false;
            return resp.data.data;
          } else {
            this.isLoad = false;
            this.showErrorToaster();
          }
        })
        .catch((err) => {
          console.error(err);
          this.showErrorToaster();
          this.isLoad = false;
        });
    },
    showErrorToaster() {
      const notification = {
        type: "error",
        icon: "refresh",
        message: h(
          "span",
          {
            class: "tw-font-bold tw-text-base tw-leading-sm",
          },
          "Something went wrong. Please try again"
        ),
      };
      useNotificationStore().add(notification);
    },
  },
  mounted(){
    this.showSupportDrawer()
  }
};
</script>
<style scoped>
:deep.tp-drawer{
  left: 0 !important;
}
</style>
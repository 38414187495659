<template>
  <div class="tp-tile" :class="bg">
    <div class="tp-tile__header" v-if="slots.header"><slot name="header" /></div>
    <div class="tp-tile__content"><slot /></div>
    <div class="tp-tile__footer" v-if="slots.footer"><slot name="footer" /></div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { useSlots, defineProps } from 'vue'
const slots = useSlots()
const props = defineProps({
  bg: {
    type: String,
    default: 'tw-bg-white'
  }
})
</script>

<style lang="scss" scoped>
.tp-tile{  
  @apply tw-p-4 lg:tw-p-6 tw-rounded-lg tw-shadow-1 tw-text-sm tw-flex tw-flex-col;
  
  &__{
    &header{
      @apply tw-mb-2 tw-font-semibold tw-text-base tw-text-primary-900;
    }
    &content{
      @apply tw-text-sm;
    }
    &footer{
      @apply tw-mt-auto tw-pt-4;
    }    
  }
}
</style>
<style lang="scss">
.tp-tile{
  $root: &;

  [alt="arrow"]{
    @apply tw-relative tw-left-0;
    transition: all .2s;
  }
  &:hover [alt="arrow"]{
      @apply tw-left-1;    
  }       
}
</style>
import { getPermissions } from '@/helpers/permissionHelper';

const bootstrap = () => {
  
  const user = localStorage.getItem('accessToken')

  const href = window.location.href;
  // urls which dont need permissions API for to avoid redirecting to login page
  const whiteListUrl = [
    'marketplace/paymentdetails', 
    'signin', 
    'signup', 
    'forgotpassword', 
    'escrowtermsandconditions',
    'confirm-forgot-password', 
    'changePasswordSuccesful', 
    'resetpasswordprogress', 
    'verificationlinkexpired', 
    'verificationcompleted', 
    'verify', 
    '/termsAndConditions/v1'
    ]

  const isRoleApiReq = whiteListUrl.some(url => {
    if(href.includes(url)){
      return true
    }
    return false
  })

  // get permissions before proceeding with the app similar to app initializer in angular  
  return new Promise((resolve, reject) => {
    // We're getting the current user from localStorage
    if (user && !isRoleApiReq) {
      // We're simulating the request
      getPermissions().then((p) => {
        // checking if permissions were loaded
        if(p.length > 0){
          const hasCountry = Boolean(p[0]?.dashboard_partner_user_role_info?.user_data[0]?.country);            
          resolve(hasCountry);
        } else{
          console.log('permissions not Loaded')
          reject()
        }
      }).catch(err => {
        console.log('err', err)
        reject(err)
      })
    } else {
      resolve()
    }
  })  
}

export default bootstrap;
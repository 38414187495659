<template>
  <div
    class="kyb-radio-group tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center tw-pt-2 tw-pb-4 sm:tw-pb-2"
    :class="{ 'tw-pt-4 sm:tw-pb-4': viewMode }"
  >
    <label
      class="tw-text-sm tw-leading-sm tw-font-bold tw-text-neutral-700 tw-w-auto tw-mb-2 tw-mr-8 sm:tw-w-48 sm:tw-mb-0"
      >{{ label }}</label
    >
    <div class="tw-flex tw-flex-col tw-w-full sm:tw-w-80">
      <div class="tw-text-sm tw-leading-sm tw-font-normal tw-mb-2 tw-text-neutral-700">{{ radioDescription }}</div>
      <input-radio-group
        v-if="!viewMode"
        :groupData="groupData"
        :name="fieldName"
        @inputOnChange="handleChange"
        v-model:model="modelValue"
        :disabled="disabled"
      ></input-radio-group>
      <div v-if="viewMode" class="tw-h-auto">
        <label class="tw-w-full sm:tw-w-80 tw-text-base tw-leading-sm tw-text-neutral-700 tw-h-12 value-label">{{
          valueLabel
        }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import {InputRadioGroup} from "tazapay-ui"
export default {
  components: {
    InputRadioGroup,
  },
  props: {
    label: {
      type: String,
    },
    groupData: {
      type: Array,
    },
    radioDescription: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelValue: "",
    };
  },
  computed: {
    valueLabel() {
      let result = this.groupData.filter((item) => {
        return item.value === this.value;
      });
      return result[0].label;
    },
  },
  methods: {
    handleChange() {
      let eventData = {
        fieldName: this.fieldName,
        value: this.modelValue,
      };
      this.$emit("onChange", eventData);
    },
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
};
</script>

<style scoped>
.kyb-radio-group:deep .input-holder {
  display: flex;
  margin-bottom: 0px;
}
.kyb-radio-group:deep .radiobox-container {
  @apply tw-mr-8;
}
.value-label {
  line-height: auto;
}
</style>

<template>
  <div class="tl-app" :class="classApp">
    <!-- APP HEAD -->
    <div class="tl-app__head">
      <!-- COMPONENT: GLOBAL NOTIFIACTION -->
      <!-- <Notify>
        <div class="tw-text-sm tw-font-semibold">
          As a result of maintenance, reports will not be available for a short period of time. Sorry for the
          inconvenience!!
        </div>
      </Notify> -->
    </div>

    <!-- APP BODY -->
    <div class="tl-app__body">
      <!-- APP ASIDE -->
      <div class="tl-app__aside">
        <!-- COMPONENT: SIDEBAR -->
        <Sidebar />
      </div>

      <!-- APP MAIN -->
      <div class="tl-app__main">
        <!-- APP HEADER -->
        <div class="tl-app__header">
          <!-- COMPONENT: HEADER -->
          <Header />

          <!-- COMPONENT: BREADCRUMB ON MOBILE -->
          <div class="xl:tw-hidden">
            <BreadCrumb class="tw-bg-white tw-px-4 tw-py-2 lg:tw-px-8" />
          </div>

          <!-- COMPONENT: KYB BANNER -->
          <KybBanner v-if="showKybBanner && !isSandboxEnv && route.name != 'Home'" />

          <!-- COMPONENT: TOOLBAR -->
          <Toolbar />
        </div>

        <!-- APP CONTENT -->
        <div class="tl-app__content" id="taza-app-content">
          <div class="tl-content">
            <!-- COMPONENT: MESSAGE -->
            <MessageContainer :messages="messages" @remove="removeMessage" />

            <slot />
          </div>
        </div>
      </div>
    </div>
    <Teleport to="#teleportal">
      <kybDialog @kybStatusChange="handleKybStatus"></kybDialog>
      <NotificationContainer :notifications="notifications" @remove="removeNotification" />
    </Teleport>
  </div>
</template>
<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { mapState, mapActions } from "pinia";
import Sidebar from "./app/Sidebar.vue";
import Header from "./app/Header.vue";
import Notify from "./app/Notify.vue";
import Toolbar from "./app/Toolbar.vue";
import kybDialog from "@/components/shared/kybDialog/kybDialog.vue";
import KybBanner from "@/components/shared/kybDialog/KybBanner.vue";
import { hasPermission } from "@/helpers/permissionHelper";
// import { getAccountDetails } from "@/api/settings"; // temporary banner; will be removed after JAN/02/2023
import { BreadCrumb, NotificationContainer, MessageContainer } from "tazapay-ui";
import { useNotificationStore } from "@/stores/NotificationStore";
import { useMessageStore } from "@/stores/MessageStore";
import { getApiDetails } from "@/views/m-dashboard/api/keyGeneration.js";
import { useGlobalStore } from "@/stores/GlobalStore.js";

const route = useRoute();

const showKybBanner = ref(false);
// const showGlobalBanner = ref(true);

const classApp = computed(() => {
  return route.meta.classes?.app;
});

let isSandboxEnv = import.meta.env.VITE_ENV_NAME === "sandbox";

function handleKybStatus(val) {
  let restrictedPage = ["mpCreateApiKey", "mpGeneratePaymentLinkContainer"].includes(route.name);
  showKybBanner.value = val === "not_started" && hasPermission("KYB_CREATE") && !restrictedPage ? true : false;
}

function setPlatformInfo() {
  getApiDetails()
    .then((res) => {
      useGlobalStore().platformInfo = res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

onMounted(() => {
  setPlatformInfo();
});

// function handleGlobalBanner(){ // temporary banner; will be removed after JAN/02/2023
//   getAccountDetails().then(
//     res => {
//       const isIndiaUser = res?.data?.country?.toLowerCase() === "india";
// const isAgreementListingPage = this.$route.name == "agreementListing";
// showGlobalBanner.value = isAgreementListingPage; //isIndiaUser && isAgreementListingPage;
//     }
//   ).catch(err => {console.error(err)})
// }
</script>
<script>
export default {
  //temporary banner; will be removed after JAN/02/2023
  computed: {
    ...mapState(useNotificationStore, ["notifications"]),
    ...mapState(useMessageStore, ["messages"]),
  },
  methods: {
    ...mapActions(useNotificationStore, { removeNotification: "remove" }),
    ...mapActions(useNotificationStore, { removeMessage: "remove" }),
  },
};
</script>
<style lang="scss" scoped>
@import "./app/layout.scss";
</style>

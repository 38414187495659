const ModuleGuard = (to, from, next) => {
  const isLoggedIn = (localStorage.getItem('accessToken') && localStorage.getItem('accessToken').length);
  if(isLoggedIn) {
    next();
  } else {
    localStorage.setItem('navigationFrom', JSON.stringify(to));
    next('/signin'); 
  }
}

export default ModuleGuard;
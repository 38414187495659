export const MarketPlaceRoutes = [
  {
    path: "/marketplace/paymentdetails/:token", name: "marketplaceLanding", meta: { title: "Market Place", layout: "BlankLayout" },
    component: () => import("@/views/payment-link/Redirection.vue")
  },
  {
    path: "/marketplace/payment/:id/", name: "marketplacePayment", meta: { title: "Payment Details", layout: "BlankLayout" },
    component: () => import("@/views/payment-link/Redirection.vue")
  },
  {
    path: "/notFound", name: "marketplaceError", meta: { title: "404", layout: "BlankLayout" },
    component: () => import("@/views/marketPlace/marketplaceError.vue")
  },

  {
    path: "/linkExpired", name: "linkExpired", meta: { title: "404", layout: "BlankLayout" },
    component: () => import("@/views/marketPlace/linkExpired.vue")
  },

  // {
  //   path: "/rapyd/fallback", name: "redirectToApp", meta: { title: "Redirecting to App", layout: "BlankLayout" },
  //   component: () => import("@/views/marketPlace/middleWareRoute.vue")
  // },
  {
    path: "/shopify/:token", name: "shopifyPayment", meta: { title: "Shopify Payment", layout: "BlankLayout" },
    component: () => import("@/views/shopify/middleware.vue")
  },
  {
    path: "/payment/awaiting-confirmation", name: "awaitingConfirmation", meta: { title: "Awaiting Confirmation", layout: "BlankLayout" },
    component: () => import("@/views/tradeDetails/payment/paymentAwaiting.vue")
  },
  // {
  //   path: "/dynamic-routing", name: "dynamicRouting", meta: { title: "DP Routing", layout: "BlankLayout" },
  //   component: () => import("@/views/tradeDetails/payment/dynamicRouting.vue")
  // }
];
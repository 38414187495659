import api from "@/api/Api";
import { updateKYBApprovedStatus } from "@/helpers/kybStatusUtils.js";
import { useGlobalStore } from "@/stores/GlobalStore";

function updateQualityFlag() {
  let accountInfo = useGlobalStore().getAccountInfo;
  let mailId = accountInfo.has_team_associated ? accountInfo.owner_email : accountInfo.email;
  let BASE_URL = import.meta.env.VITE_SELLER_FLAG_URL;
  let FLAG_URL = `${BASE_URL}/risk/flag/${mailId}`;
  api
    .get(FLAG_URL)
    .then((responce) => {
      useGlobalStore().setQualityFlag(responce.data.data.quality_flag);
      updateKYBApprovedStatus();
    })
    .catch((err) => {
      console.error("Network Error occured on fetching flag", err);
    });
}

export { updateQualityFlag };

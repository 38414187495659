<template>  
  <div class="tw-bg-primary-800 tp-bg-lines tw-min-h-screen tw-pt-10 tw-pb-20">   
    <div class="tw-text-center tw-mb-10">
        <img src="@/assets/images/updatedlogo.svg" alt="tazapay" />
    </div> 
    <div class=" tw-px-4">  
      <div class="tw-max-w-xl tw-mx-auto tw-rounded-md tw-bg-white">
        <slot></slot>
      </div>
    </div>
    <NotificationContainer :notifications="notifications" @remove="removeNotification"/>  
  </div>
</template>
<script>
import { NotificationContainer } from "tazapay-ui";
import { mapState, mapActions } from 'pinia';
import { useNotificationStore } from "@/stores/NotificationStore";
export default {
  name: "AccountLayout",
  components: {
    NotificationContainer
  },
  computed:{
    ...mapState(useNotificationStore, ['notifications']),
  },
  methods: {  
    ...mapActions(useNotificationStore, {removeNotification:'remove'})
  }
}
</script>
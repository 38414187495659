// redirect user to the signin page if we get the 401 statuscode from api response
const clearStorageAndRedirect = (error) => {
  const errorStatus = error?.status ?? error?.statusCode ?? error;
  if (errorStatus === 401) {
    sessionStorage.clear()
    localStorage.clear()
    location.href = '/signin'
  }
}

export default clearStorageAndRedirect;
import api from '@/api/Api';
import { account } from '@/constants/apiEndPoints'
let BASE_URL = import.meta.env.VITE_BASE_URL;

export function getAccountType(data) {
  const accountTypeUrl = `${BASE_URL}${account.accountType}`;
  return api.put(accountTypeUrl, data)
}

export function getAccountDetails() {
  const accountDetailsUrl = `${BASE_URL}${account.accountDetails}`;
  return api.get(accountDetailsUrl)

}









import { useGlobalStore } from "@/stores/GlobalStore";

function updateKYBApprovedStatus() {
  let kybStatus = useGlobalStore().getKybStatus;
  let qualityFlag = useGlobalStore().getQualityFlag;
  if (kybStatus === "completed" && ["Platinum", "Gold", "Silver", "Needs Monitoring","Non Card Processing"].includes(qualityFlag)) {
    useGlobalStore().setKybApprovedStatus(true);
  } else {
    useGlobalStore().setKybApprovedStatus(false);
  }
}

export { updateKYBApprovedStatus };

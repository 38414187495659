<template>
  <div
    class="kyb-select-input tw-flex tw-flex-col tw-pt-2 tw-pb-4 sm:tw-flex-row sm:tw-items-center sm:tw-pb-2"
    :class="{ 'tw-pt-4 sm:tw-pb-4': viewMode }"
  >
    <label
      class="tw-mb-2 tw-mr-8 tw-flex tw-w-auto tw-items-center tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700 sm:tw-mb-0 sm:tw-w-48"
      >{{ label }}
      <tc-tips class="tw-ml-2" v-if="showTooltip" position="bottom" trigger="click" size="lg">
        <template v-slot:trigger>
          <tc-icon icon="information-outline" color="primary-700" size="xl"></tc-icon>
        </template>
        <slot name="tooltipContent"></slot>
      </tc-tips>
    </label>
    <div class="tw-flex tw-w-full tw-flex-col sm:tw-w-80">
      <label
        v-if="description && !viewMode"
        class="tw-mb-2 tw-text-sm tw-font-normal tw-leading-sm tw-text-neutral-700"
        >{{ description }}</label
      >
      <input-select
        :class="{ 'allow-multiple': multiple, 'contains-error': errors.length > 0, 'beneficial-error': beneficialError }"
        v-if="!viewMode"
        :errors="errors"
        :options="items"
        :appendToBody="false"
        :placeholder="placeholder"
        :model="value"
        :disabled="disabled"
        :multiple="multiple"
        :closeOnSelect="!multiple"
        :selectableOptions="selectableOptions"
        @inputOnChange="handleChange"
      >
      </input-select>
      <label
        v-if="viewMode"
        class="value-label tw-h-auto tw-w-full tw-text-base tw-leading-sm tw-text-neutral-700 sm:tw-w-80"
        >{{ parseValue(value) }}</label
      >

      <!-- <select class="tw-border select-input tw-rounded tw-w-full" @change="handleChange">
        <option :selected="selectDefault" hidden>{{ defaultItemText }}</option>
        <option v-for="item in items" :key="item" :selected="value === item">
          {{ item }}
        </option>
      </select> -->
    </div>
  </div>
</template>

<script>
import { InputSelect, Tips as TcTips } from "tazapay-ui";
// import InputSelect from "@/ui-components/formControls/InputSelect.vue";
//import TcTips from "@/ui-components/tips.vue";

export default {
  components: {
    InputSelect,
    TcTips,
  },
  props: {
    label: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    value: {
      type: [String, Array],
    },
    items: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    selectableOptions: {
      type: Function
    },
    beneficialError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectDefault: false,
    };
  },
  watch: {
    value(newVal) {
      this.selectDefault = !this.items.includes(newVal);
    },
  },
  methods: {
    handleChange(e) {
      let eventData = {
        fieldName: this.fieldName,
        value: e,
      };
      this.$emit("onChange", eventData);
    },
    parseValue(value) {
      if (Array.isArray(value)) {
        return value.join(", ");
      }
      return value;
    },
  },
};
</script>

<style scoped>
.kyb-select-input:deep .input-holder {
  margin-bottom: 0px;
}
.kyb-select-input:deep .error-holder {
  margin-top: 0px;
}
.kyb-select-input:deep .contains-error .error-holder {
  margin-top: 5px;
}
/* .value-label {
  line-height: 1;
  padding-bottom: 16px;
}
@media screen and (min-width: 600px) {
  .value-label {
    line-height: 48px;
    padding-bottom: 0px;
  }
} */
/* .select-input {
  background: var(--color-info-50);
  border-radius: 4px;
  border: 1px solid var(--color-neutral-200);
  display: block;
  position: relative;
  padding: 12px 10px 12px 10px;
  font-size: 16px;
  transition: 0.2s ease all;
  height: 48px;
}

.select-input:focus {
  border: 1px solid transparent;
  box-shadow: 0 0 0 2px var(--color-info-600);
  outline: 0;
} */
:deep .allow-multiple .vs__selected {
  background-color: #e4f8ff;
  border-radius: 12px;
  font-weight: 600;
  border: none;
  padding: 4px 8px;
}
:deep .allow-multiple .vs__dropdown-toggle {
  height: auto;
}
:deep .beneficial-error button[title='Deselect Beneficial Owner']{
  display: none;
}
</style>

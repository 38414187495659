import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import '@/assets/css/imports.scss'
import text from './ui-components/text.vue'
import { TZModal, Icon, Alert } from "tazapay-ui"

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import Amplify from 'aws-amplify';
import config from './aws-exports';
import bootstrap from './bootstrap';

Amplify.configure(config);

import { createGtm } from '@gtm-support/vue-gtm';
import vSelect from 'vue-select' 
import { ROUTE_SETTINGS, ROUTE_PARAMS } from "@/constants/route.js";
import clearStorageAndRedirect from "@/helpers/clearStorageAndRedirect";

import "tazapay-ui/dist/style.css"

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
 // create a function to load the app pass app as a parameter

function initializeApp(app) {
  app.component('vue-select', vSelect);
  app.component('tc-text', text);
  app.component('tc-modal', TZModal);
  app.component('tc-icon', Icon);
  app.component('tc-alert', Alert);
  
  app.use(createGtm({
    id: import.meta.env.VITE_GTM_ID || 'GTM-WC5JN27', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: true // Whether or not call trackView in app.nextTick
  }));
  
  if(import.meta.env.PROD){  
    Sentry.init({
      app,
      dsn: "https://4a5abacdf6f04cf6ad5dddf13f58be83@o869141.ingest.sentry.io/6320801",      
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["app.tazapay.com", /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,
      environment: import.meta.env.PROD,
      ignoreErrors:[
        "Non-Error promise rejection captured",
        "Non-Error exception captured with keys"
      ]
    });
    Sentry.attachErrorHandler(app, { logErrors: true });
    // Sentry.captureMessage("componentName:functionName:errorMessage");
    // https://docs.sentry.io/platforms/javascript/guides/vue/usage/
    // https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/context/ 
  }

  app.use(router);  
  app.use(pinia);
  app.mount('#app')
 }
 
bootstrap().then((country=null) => {  
  initializeApp(app)  
  if(country !=null && !country){
    router.push(`${ROUTE_SETTINGS.ACCOUNT_TYPE}?${ROUTE_PARAMS.REF_SOCIAL}`);  
  }
}).catch(err => {
  initializeApp(app)
  clearStorageAndRedirect(err);
  console.log('err', err);
})

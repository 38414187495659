import api from "@/api/Api";
let BASE_URL = import.meta.env.VITE_KYB_BASE_URL_V2;

export function getKYBStatus(queryparams) {
  const statusPath = `${BASE_URL}/kyb/verified`;

  let url = new URL(statusPath);
  for (const key in queryparams) {
    url.searchParams.append(key, queryparams[key]);
  }

  return api.get(url.href);
}

export function createKYB(data) {
  const statusPath = `${BASE_URL}/kyb`;
  return api.post(statusPath, data);
}

export function getKYBData(applicationId) {
  const statusPath = `${BASE_URL}/kyb/${applicationId}`;
  return api.get(statusPath);
}

export function updateKYB(applicationId, data) {
  const statusPath = `${BASE_URL}/kyb/${applicationId}`;
  return api.put(statusPath, data);
}

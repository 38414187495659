import api from './apiWrapper'
import mpEndpoints from './mpEndPoints';

let BASE_URL = import.meta.env.VITE_MARKET_PLACE_DASHBOARD_URL;

const createUser = (params) => {
  let createUserUrl = `${BASE_URL}${mpEndpoints.createUserEndpoint}`;
  return api.post(createUserUrl, params)
}

function submitEscrow(createEscrowRequest) {
  let createEscrowUrl = `${BASE_URL}${mpEndpoints.createEscrowEndpoint}`;
  return api.post(createEscrowUrl, createEscrowRequest)
}

function createPaymentLinkPayment(data) {
  let createPaymentLink = `${BASE_URL}${mpEndpoints.createPaymentLinkEndpoint}`;
  return api.post(createPaymentLink, data)
}

const getCountryConfig = (cntry) => {
  let countryConfigUrl = `${BASE_URL}${mpEndpoints.countryConfigEndpoint}${cntry}`
  return api.get(countryConfigUrl)
}

function getInvoiceCurrencyConfig(buyerCountry, sellerCountry) {
  let invoiceConfig = `${BASE_URL}${mpEndpoints.invoiceCurrencyEndPoint}?buyer_country=${buyerCountry}&seller_country=${sellerCountry}`;
  return api.get(invoiceConfig)
}

function getDashBoardMetaData() {
  let dashboardMetaData = `${BASE_URL}${mpEndpoints.dashboardMetaDataEndPoint}`;
  return api.get(dashboardMetaData);

}

function getUserDetails(email) {
  let userDetailUrl = `${BASE_URL}${mpEndpoints.getUserDetailsEndPoint}`;
  let formattedUrl = userDetailUrl.replace(':email_id', email);
  return api.get(formattedUrl)
}
const getUploadUrl = () => {
  let uploadurl = `${BASE_URL}${mpEndpoints.getUploadUrlEndPoint}`
  return api.get(uploadurl);
}
function getSupportTopics() {
  let supportTopics = `${BASE_URL}${mpEndpoints.getSupportTopicEndPoint}`;
  return api.get(supportTopics);

}
function submitSupportForm(createSupportRequest) {
  let createSupportUrl = `${BASE_URL}${mpEndpoints.submitSupportEndpoint}`;
  return api.post(createSupportUrl, createSupportRequest)
}
function acuantRefundFeedback(request){
  let feedbackUrl = `${BASE_URL}${mpEndpoints.acuantRefundFeedbackEndpoint}`;
  return api.post(feedbackUrl,request);
}

export {
  createUser,
  submitEscrow,
  createPaymentLinkPayment,
  getCountryConfig,
  getInvoiceCurrencyConfig,
  getDashBoardMetaData,
  getUserDetails,
  getUploadUrl,
  getSupportTopics,
  submitSupportForm,
  acuantRefundFeedback
}


//  move to a common helper file
function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

async function getIP() {
  return new Promise((resolve, reject) => {
    fetch("https://api64.ipify.org/?format=json")
    .then(x => x.json())
    .then(({ ip }) => {
    resolve(ip)
  }).catch(err => {
    reject(err)
  })
  })
  
}

//hubspot integration for signin screen using forms api

export async function SigninHubspot(email) {
  getIP().then(ip => {
    // get cookie value
    let data = {
    fields: [
      {
        name: 'email',
        value: email,
      },
    ],
    context: {
      hutk: getCookie('hubspotutk'),
      pageUri: 'app.tazapay.com/signin',
      pageName: 'Tazapay Sign in page',
      ipAddress: ip
    },

    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text:
          'I agree to allow Tazapay to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text:
              'I agree to receive marketing communications from Tazapay.',
          },
        ],
      },
    },
  }
  try {
    const response = fetch(`https://api.hsforms.com/submissions/v3/integration/submit/7971209/b8f38564-1d90-439e-949f-a60eb3913077`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const out = response.json()
    return {
      output: out,
      status: response.status
    }
  }
  catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
  })
  .catch(err => {console.error(err)})
}

//hubspot integration with forms api for signup screen
export async function SignupHubspot(name, email, country, code, contact) {
  getIP().then(ip => {
    let data = {
    fields: [
      {
        name: 'email',
        value: email,
      },
      {
        name: 'firstname',
        value: name
      },
      {
        name: 'country_sign_up',
        value: country
      }
    ],
    context: {
      hutk: getCookie('hubspotutk'),
      pageUri: 'app.tazapay.com/signup',
      pageName: 'Tazapay Sign up page',
      ipAddress: ip
    },

    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text:
          'I agree to allow Tazapay to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text:
              'I agree to receive marketing communications from Tazapay.',
          },
        ],
      },
    },
  }
  if(!(contact === undefined || contact === '' || contact === null)) {
    data.fields.push(
      {
        name: 'phone',
        value: `${code}${' '}${contact}`
      }
    )
   }
   try {
    const response = fetch(`https://api.hsforms.com/submissions/v3/integration/submit/7971209/672befd9-7d96-4342-8885-8bb446c1d8fb`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const out = response.json()
    return {
      output: out,
      status: response.status
    }
  }
  catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
  })
  .catch(err => {console.error(err)})
}
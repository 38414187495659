import api from "@/api/Api";
import { getCurrentUserData } from "@/api/rbac"
import { allPermissionsBeforeSerialized } from "@/constants/permissions";
import clearStorageAndRedirect from "@/helpers/clearStorageAndRedirect";

const BASE_URL = import.meta.env.VITE_RBAC_BASE_URL;
let PERMISSIONS = [];
let CURRENT_USER_PERMISSIONS = [];


export const getPermissions = async () => { 
  PERMISSIONS = []
  await getCurrentUserData().then((resp) => {
    if(resp?.status === 200) {
      PERMISSIONS.push(resp.data.data)
      permissionsSerialiser()
    }
  }).catch(err => {
    console.error('error in getPermissions api--->', err)
    /* Clearing the local storage and redirecting to the signin page. */
    clearStorageAndRedirect(err);
  })
  permissionsSerialiser();
  return PERMISSIONS
}

// used cache to optimise the flow
export const loadPermissions = async () => {
  PERMISSIONS = [];
  await api
    .get(`${BASE_URL}/role`)
    .then((resp) => {
      if (resp?.status === 200) {
        PERMISSIONS.push(resp.data.data);
      }
    })
    .catch((err) => {
      // in case role API fails for any reason we log user out and let him log in again to get correct permissions
      // TO DO: Discuss with tiffany on how this can be handled gracfully
      // From BE we are getting 2 error scenarios 
      console.error('error in roles api--->', err)
      clearStorageAndRedirect(err);      
    });
  permissionsSerialiser();
  return PERMISSIONS;
};

// Serialises Permissions for current User

// Current Permissions according to our system

// 0: "ESCROW_CREATE"
// 1: "ESCROW_VIEW"
// 2: "ESCROW_UPDATE"
// 3: "PAYMENT_LINK_CREATE"
// 4: "PAYMENT_LINK_VIEW"
// 5: "BANK_CREATE"
// 6: "BANK_VIEW"
// 7: "KYB_CREATE"
// 8: "KYB_VIEW"
// 9: "KYB_UPDATE"
// 10: "CONFIGURATION_KEY_CREATE"
// 11: "CONFIGURATION_KEY_VIEW"
// 12: "CONFIGURATION_KEY_UPDATE"
// 13: "ROLE_CREATE"
// 14: "ROLE_VIEW"
// 15: "ROLE_UPDATE"
// 16: "ROLE_DELETE"
// 17: "USER_CREATE"
// 18: "USER_UPDATE"
// 19: "USER_VIEW"
// 20: "USER_DELETE"
// 21: "TEAM_CREATE"
// 22: "TEAM_UPDATE"
// 23: "TEAM_VIEW"
// 24: "TEAM_DELETE"



const permissionsSerialiser = () => {
  let userPermissions =  PERMISSIONS[0]?.is_configuration_keys_generated ? PERMISSIONS[0]?.dashboard_partner_user_role_info.user_data[0]?.permission  : allPermissionsBeforeSerialized
  userPermissions.map((p) => {
    let s = `${p.ResourceName}_${p.Action}`;
    CURRENT_USER_PERMISSIONS.push(s);
  });
};

export const hasCreatedKeys = () => {
  return PERMISSIONS[0]?.is_configuration_keys_generated;
};

// pemission paramenter should be from current permission values from the BE
export const hasPermission = (permission) => {
  return CURRENT_USER_PERMISSIONS.includes(permission);
};

// pass array of permissions with valid permission values will return true only if all permissions are valid
export const hasMultiplePermissions = (permissions) => {
  if (PERMISSIONS.length === 0) {
    loadPermissions();
  }
  permissions.forEach((p) => {
    if (!CURRENT_USER_PERMISSIONS.includes(p)) {
      return false;
    }
  });
  return true;
};

// see if current user is manager or owner or any custom role if value is not present consider as owner
export const getCurrentUserRole = () => {
  let userData = PERMISSIONS[0]?.dashboard_partner_user_role_info.user_data || [];
  let role = userData[0] && userData[0].role;
  return role || "owner";
};

export const getPrimaryOwnerID = () => {
  PERMISSIONS[0]?.dashboard_partner_user_role_info.user_data[0]?.team_data.forEach((member) => {
    if (member.is_primary_owner) {
      return member.user_id;
    }
  });
  return false;
};

export const isPermissionLoaded = () => {
  return PERMISSIONS.length > 0
}

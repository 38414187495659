<template>
  <div class="tw-mr-4">
    <PopperComponent :useTeleport="true" :showContent="showGetHelp" contentClasses="tp-popper-dropdown" placement="bottom-end" @onDocClick ="showGetHelp = false">
      <template v-slot:trigger>
        <!-- desktop -->
        <div class="tw-relative">
          <button class="tw-h-28P" @click="showGetHelp =!showGetHelp">
            <div class="tw-flex tw-items-center tw-gap-2">
              <div class=" tw-leading-none">
                <img
                  src="@/assets/images/supportEntry.svg"
                  alt="Get help logo"
                />
              </div>
              <div class="tw-mr-1.5 tw-text-xs tw-font-semibold tw-hidden tw-text-neutral-800 xl:tw-block">Get Help</div>
            </div>
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div
          class="tw-py-0.5 tw-min-w-180P"
        >
          <div class="tw-flex tw-flex-col">
            <a href="https://support.tazapay.com/" target="_blank"
              ><div
                class="tw-cursor-pointer tw-py-3 tw-px-4 tw-text-sm tw-font-semibold tw-text-neutral-800 hover:tw-bg-neutral-150"
              >
                FAQ
              </div></a
            >
            <div
              class="tw-cursor-pointer tw-py-3 tw-px-4 tw-text-sm tw-font-semibold tw-text-neutral-800 hover:tw-bg-neutral-150"
              @click="showDrawer = true; showGetHelp = false "
            >
              Contact support
            </div>
          </div>
        </div>
      </template>
    </PopperComponent>
    <div>
      <ContactSupport v-if="showDrawer"
        :showDrawer="showDrawer"
        @supportClose="supportClose"
      />
    </div>
  </div>
</template>
<script>
import ContactSupport from "@/views/contactSupport/contactSupport.vue";
import PopperComponent from "@/components/shared/PopperComponent.vue";
export default {
  name: "GetHelp",
  components: {
    ContactSupport,
    PopperComponent,
  },
  data: () => ({
    showGetHelp: false,
    showDrawer: false,
  }),
  methods: {
    supportClose(value) {
      this.showDrawer = value;
    },
  },
};
</script>

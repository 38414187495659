import { ERROR_TYPE } from  '@/constants/errorCodes.js'

export const handleResponse = (response) => {
  if(response){
    let res = response;    

    // handle errors
    if(res?.errors && Array.isArray(res.errors)){
      res.errors.some(err => {
        //if session expired reload the page
        if(err?.code == ERROR_TYPE.SESSION_EXPIRED.CODE) {          
          return window.location.reload();
        }
      })
    }    
  }  
  return response;
}
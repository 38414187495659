import { ROUTE_AUTHENTICATION } from "@/constants/route";

export function logOut(clearStorage = false,queryParams) {
  if (clearStorage) {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }
  removeChatbotJS();
  // Q: why we're reloading the page instead of vue-router
  // A: - We're setting some localstorage values based on vendor scripts[IP.js, Chatbot.js] located at Index.html, 
  //    - We'll be removing local & session storages when user logout.
  //    - To keep the required vendor details, we're reloading the sign-in page.
  //    - so that all the necessary scripts will re-load again.

  // window.location.href 
  let url = ROUTE_AUTHENTICATION.SIGNIN;
  let searchParams = ""
  if(queryParams){
    searchParams = "?"
    for (const [key, value] of Object.entries(queryParams)) {
      searchParams = searchParams + key + '='+ value + "&";
    }
  }
  if(searchParams){
    url = url + searchParams;
  }
  window.location.href = url;
}

function removeChatbotJS() {
  const chatFrameId = document.getElementById('engagely-frame')
  const chatEngagelyEngagement = document.getElementById('engagely-engagements')
  if(chatFrameId){
    chatFrameId.remove();
    }
    if(chatEngagelyEngagement){
    chatEngagelyEngagement.remove();
    }
}
<template>
  <div
    class="kyb-phone-input tw-flex tw-flex-col tw-pt-2 tw-pb-4 sm:tw-flex-row sm:tw-items-center sm:tw-pb-2"
    :class="{ 'tw-pt-4 sm:tw-pb-4': viewMode }"
  >
    <div
      class="tw-mb-2 tw-mr-8 tw-w-auto tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700 sm:tw-mb-0 sm:tw-w-48"
    >
      <label class="tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700"> {{ label }}</label>
      <label v-if="optional && !viewMode" class="tw-ml-1 tw-text-xs tw-text-neutral-400">(optional)</label>
    </div>

    <div class="tw-row tw-flex tw-w-full sm:tw-w-80" v-if="!viewMode">
      <input-text
        class="tw-mr-2 tw-w-14"
        autocomplete="off"
        :errors="[]"
        :disabled="disabled"
        v-model:modelValue="code"
        @textInputOnChange="dispatchChange"
      ></input-text>
      <input-text
        refs="contactInput"
        class="tw-w-full"
        type="tel"
        placeholder="Enter contact number"
        autocomplete="off"
        :disabled="disabled"
        :errors="contactError"
        :class="{ 'contains-error': this.contactError.length > 0 }"
        v-model:modelValue="contactNumber"
        @textInputOnChange="dispatchChange"
        @inputValueChange="handleValueChange"
      ></input-text>
    </div>
    <div class="tw-w-full sm:tw-w-80" v-if="viewMode">
      <label class="value-label tw-w-full tw-text-base tw-leading-sm tw-text-neutral-700 sm:tw-w-80">{{
        value ? contactCode + " " + value : "-"
      }}</label>
    </div>
  </div>
</template>

<script>
import { InputText } from "tazapay-ui";

//import InputText from "@/ui-components/formControls/InputText.vue";
import parsePhoneNumber from "libphonenumber-js";
export default {
  components: { InputText },

  props: {
    label: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    contactCode: {
      type: String,
    },
    value: {
      type: String,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contactError: [],
      contactNumber: this.value,
      code: this.contactCode,
    };
  },
  watch: {
    contactCode() {
      this.code = this.contactCode;
    },
    value() {
      this.contactNumber = this.value;
    },
    errors() {
      this.contactError = [...this.errors];
    },
  },
  methods: {
    // handleContactNumberChange(e) {
    //   this.contactNumber = e;
    //   this.dispatchChange();
    // },
    // handleContactCodeChange(e) {
    //   this.code = e;
    //   this.dispatchChange();
    // },
    handleValueChange() {
      let result = parsePhoneNumber(this.code + this.contactNumber);
      if ((result && result.isValid()) || this.contactNumber === "") {
        return;
      } else {
        this.contactNumber = "";
        this.dispatchChange();
      }
    },
    dispatchChange() {
      if (!this.code) {
        this.code = "";
      }
      if (this.code.length && !this.code.startsWith("+")) {
        this.code = "+" + this.code;
      }
      // country code goes more the 4 digits when auto-filling, that is wrong
      if (this.code.length > 4) {
        this.code = "";
      }

      let result = parsePhoneNumber(this.code + this.contactNumber);
      if (result && result.isValid()) {
        this.contactError = [];
        this.triggerEmit();
      } else if (this.contactNumber === "" && !this.optional) {
        this.contactError = ["Please fill this required field"];
        this.triggerEmit();
      } else {
        this.contactError = ["invalid number"];
        this.triggerEmit();

        // setTimeout(() => {
        //   this.contactError = this.optional ? [] : this.contactError;
        // }, 1000);
      }
    },
    triggerEmit() {
      let eventData = {
        fieldName: this.fieldName,
        value: this.contactNumber,
        contactCode: this.code,
      };
      this.$emit("onChange", eventData);
    },
  },
};
</script>

<style scoped>
.kyb-phone-input:deep .input-holder {
  margin-bottom: 0px;
}
.kyb-phone-input:deep .error-holder {
  margin-top: 0px;
}

.kyb-phone-input:deep .contains-error .error-holder {
  margin-top: 5px;
}

.kyb-phone-input:deep .input-holder .input-box:disabled {
  @apply tw-bg-neutral-200;
}
/* .value-label {
  line-height: 1;
}
@media screen and (min-width: 600px) {
  .value-label {
    line-height: 48px;
  }
} */
</style>

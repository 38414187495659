<template>
  <div
    class="kyb-text-input tw-flex tw-flex-col tw-pt-2 tw-pb-4 sm:tw-flex-row sm:tw-items-center sm:tw-pb-2"
    :class="{ 'tw-pt-4 sm:tw-pb-4': viewMode }"
  >
    <div v-if="label" class="tw-mb-2 tw-mr-8 tw-w-auto sm:tw-mb-0 sm:tw-w-48">
      <label class="tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700"
        >{{ label }}
        <span v-if="tag" class="tag tw-ml-1 tw-rounded tw-bg-success-100 tw-p-1 tw-text-2xs">{{ tag }}</span></label
      >
      <label v-if="optional && !viewMode" class="tw-ml-1 tw-text-xs tw-text-neutral-400">(optional)</label>
    </div>
    <input-text
      v-if="!viewMode"
      class="tw-w-full sm:tw-w-80"
      :title="valueToDisplay"
      :class="{ 'contains-error': errorMsg.length > 0, 'pan-text-input': this.validation === 'pan' }"
      :errors="errorMsg"
      :placeholder="placeholder"
      :leftIcon="leftIcon"
      @textInputOnChange="handleChange"
      @inputOnFocus="$emit('onFocus')"
      @inputOnBlur="$emit('onBlur')"
      :disabled="disabled"
      :modelValue="valueToDisplay"
    ></input-text>
    <label
      v-if="viewMode"
      class="value-label tw-w-full tw-break-all tw-text-base tw-leading-sm tw-text-neutral-700 sm:tw-w-80"
      >{{ valueToDisplay ? valueToDisplay : "-" }}
    </label>
  </div>
</template>

<script>
// import { InputText } from "tazapay-ui";

import InputText from "@/ui-components/formControls/InputText.vue";
import FormValidation from "@/ui-components/formBuilder/FormValidation";

export default {
  components: { InputText },
  props: {
    label: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array],
    },
    placeholder: {
      type: String,
    },
    optional: {
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    validation: {
      type: String,
    },
    tag: {
      type: String,
      default: "",
    },
    leftIcon: {
      type: String,
    },
  },
  data() {
    return {
      errorMsg: [],
      formValidation: new FormValidation(),
    };
  },
  watch: {
    errors: {
      handler() {
        this.$nextTick(() => (this.errorMsg = [...new Set([...this.errorMsg, ...this.errors])]));
      },
      deep: true,
    },
  },
  computed: {
    valueToDisplay() {
      return Array.isArray(this.value) ? this.value.join(", ") : this.value;
    },
  },
  methods: {
    handleChange(e) {
      this.validateInput(e);
      let eventData = {
        fieldName: this.fieldName,
        value: e,
      };
      this.$emit("onChange", eventData);
    },
    validateInput(e) {
      let validations = [];
      this.required ? validations.push({ key: "required" }) : "";
      this.type === "email" ? validations.push({ key: "email" }) : "";
      this.type === "alphanumericonly" ? validations.push({ key: "alphanumericonly" }) : "";
      this.validation === "url" ? validations.push({ key: "urlValidation" }) : "";
      this.validation === "pan" ? validations.push({ key: "panValidation" }) : "";
      this.errorMsg = [...this.formValidation.validateModel({ model: e }, { validations, required: true })];
      return this.errorMsg.length === 0;
    },
  },
};
</script>

<style scoped>
.kyb-text-input:deep .input-holder {
  margin-bottom: 0px;
}
.kyb-text-input:deep .error-holder {
  margin-top: 0px;
}

.kyb-text-input:deep .contains-error .error-holder {
  margin-top: 5px;
}
.kyb-text-input:deep .input-holder .input-box:disabled {
  @apply tw-bg-neutral-200;
}

.kyb-text-input:deep .pan-text-input input {
  @apply tw-uppercase;
}

.kyb-text-input:deep .pan-text-input input::placeholder {
  @apply tw-normal-case;
}

.tag {
  color: #0e9f6e;
  font-weight: 700;
}
/* .value-label {
  line-height: 1;
}
@media screen and (min-width: 600px) {
  .value-label {
    line-height: 48px;
  }
} */
</style>

<template>
  <div class="tw-px-4 tw-py-4 sm:tw-px-10 sm:tw-py-8">
    <div v-if="isGoogleAuthEnabled">
      <GoogleAuthentication data-cy="googleSignIn" buttonLabel="Sign in with Google" pageReference="signin" />
    </div>
    <div class="">
      <FormBuilder
        :formData="formData"
        :validationRules="APP_SIGNIN_VALIDATION_RULES"
        :serverErrors="signinServerErrors"
        @onSubmit="submit"
        submitLabel="Sign In"
        :formSubmissionInProgress="formSubmissionInProgress"
      />
    </div>
    <div class="tw-mt-4 tw-text-center">
      <span class="tw-cursor-pointer tw-text-sm tw-font-normal sm:tw-text-base">
        <router-link data-cy="forgotPwdlink" to="/forgotpassword">Forgot password?</router-link>
      </span>
    </div>
    <div class="tw-mt-6 tw-mb-12 tw-text-center sm:tw-mb-6 md:tw-mb-0 lg:tw-mb-0 xl:tw-mb-0">
      <div class="tw-text-base tw-font-normal" data-cy="signupHint">Don't have an account yet?</div>
      <div class="tw-mt-2">
        <span class="tw-cursor-pointer tw-text-base tw-font-normal tw-no-underline">
          <!-- <router-link data-cy="signupLink" :to="redirectToSignUp">Sign up now</router-link> -->
          <a data-cy="signupLink" :href="signupUrl" target="_self">Sign up now</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Signin } from "@/api/legacyapi/user";
import { SigninHubspot } from "@/api/legacyapi/hubspot";
import { SetFlag } from "@/api/legacyapi/flags";
import { emailRedirection } from "@/helpers/emailRedirection";
import { signupRedirection } from "@/helpers/signupRedirection";
import { setPlatformUser } from "@/helpers/platformUser";
import { identifyUser, toCamelCase, stopDataCaptureByEmailId, signedInUser } from "@/helpers/fullstoryHelper";
import GoogleAuthentication from "@/components/shared/GoogleAuthentication";

import { getFingerPrint } from "@/helpers/fpHelper.js";

import { FormBuilder } from "tazapay-ui";
import { APP_SIGNIN_VALIDATION_RULES } from "@/constants/modelValidation";
import { getPermissions, hasCreatedKeys } from "@/helpers/permissionHelper";
import { ROUTE_SETTINGS, ROUTE_PARAMS } from "@/constants/route.js";
import { sleep } from "@/helpers/sleep.js";
import { useGlobalStore } from "@/stores/GlobalStore";
import { useNotificationStore } from "@/stores/NotificationStore";
import { setLocalStorage } from "@/helpers/storageService";

export default {
  name: "Signin",
  title: "Signin",
  components: {
    FormBuilder,
    GoogleAuthentication,
  },
  data: () => ({
    formData: [
      {
        type: "text",
        modelKey: "email",
        label: "Email",
        model: "",
        validations: [],
        placeholder: "Enter email address",
        enableLowerCase: true,
        id: 1,
        cyID: "email",
      },
      {
        type: "password",
        modelKey: "password",
        label: "Password",
        model: "",
        validations: [],
        placeholder: "Enter password",
        id: 2,
        enablePwdToggle: true,
        cyID: "password",
      },
    ],
    APP_SIGNIN_VALIDATION_RULES,
    formSubmissionInProgress: false,
    signinServerErrors: [],
    isGoogleAuthEnabled: true,
  }),
  computed: {
    redirectToSignUp() {
      return signupRedirection();
    },
    signupUrl() {
      return import.meta.env.VITE_PO_SIGNUP_URL;
    },
  },
  methods: {
    // This is commented based on DHAMU suggestion.
    // setNavState() {
    //   window.history.pushState({ email: this.email }, "", "/forgotpassword");
    // },
    goTolanding() {
      emailRedirection("/home");
    },
    setUserType(platformRole = false) {
      if (platformRole) {
        setPlatformUser();
      }
    },
    setLoggedInUserId(userId = "") {
      if (userId) {
        localStorage.setItem("loggedInUserId", userId);
      }
    },
    setKybFlag(kybBanner = false) {
      if (kybBanner) {
        localStorage.setItem("displayKybBanner", kybBanner);
      }
    },

    submit(data) {
      this.signinServerErrors = [];
      this.formSubmissionInProgress = true;
      const params = new URLSearchParams(window.location.search);
      const referrer = params.get("referrer");
      Signin(data.email, data.password)
        .then(async (response) => {
          if (this.handleResponseError(response)) return;
          if (response) {
            const {
              username,
              email,
              emailVerified,
              kyb_approved,
              quality_flag,
              platform_role,
              id,
              kyb_banner,
              add_rbac_delay,
              redirect_uri,
              created_at,
              AuthenticationResult: { AccessToken },
            } = response?.output;

            stopDataCaptureByEmailId(email);

            const formattedUserName = toCamelCase(username);

            let userData = {
              id: id,
              email: email,
              username: formattedUserName,
            };
            identifyUser(userData);

            userData = { type: "Tazapay Signup", ...userData };
            signedInUser(userData);

            if (import.meta.env.VITE_ENV_NAME === "sandbox" && redirect_uri) {
              window.open(redirect_uri, "_self");
              return;
            }
            // TODO: to replace with localStorage with more secure option.
            setLocalStorage("userName", username);
            setLocalStorage("accessToken", AccessToken);
            setLocalStorage("userEmail", email);
            setLocalStorage("verifiedEmail", emailVerified);
            setLocalStorage("userCreatedDate", created_at);
            this.setUserType(platform_role);
            this.setLoggedInUserId(id);
            this.setKybFlag(kyb_banner);
            useGlobalStore().setKybApprovedStatus(kyb_approved);
            useGlobalStore().setQualityFlag(quality_flag);

            //based on <add_rbac_delay> response, we will put delay on RBAC api call
            if (add_rbac_delay) {
              useNotificationStore().add({
                message: "Login Successful! Checking for permissions...",
                type: "success",
              });
              await sleep(5000);
            }

            if (!emailVerified) {
              localStorage.setItem("isEmailNotVerified", true);
              if (AccessToken) {
                if (response?.status === 200) {
                  getPermissions()
                    .then((data) => {
                      if (data.length > 0) {
                        if (hasCreatedKeys()) {
                          setPlatformUser();
                        }
                        SigninHubspot(email);
                        this.goTolanding();
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }
            } else if (emailVerified) {
              const flagName = "emailVerified";
              const flagStatus = true;

              SetFlag(flagName, flagStatus)
                .then((response) => {
                  if (response?.output?.statusCode === 200) {
                    SigninHubspot(email);
                    if (AccessToken) {
                      if (referrer) {
                        window.location.href = referrer;
                      } else {
                        getPermissions()
                          .then((data) => {
                            if (data.length > 0) {
                              const hasCountry = Boolean(
                                data[0]?.dashboard_partner_user_role_info?.user_data[0]?.country
                              );
                              if (hasCreatedKeys()) {
                                setPlatformUser();
                              }
                              if (!hasCountry) {
                                this.$router.push(`${ROUTE_SETTINGS.ACCOUNT_TYPE}?${ROUTE_PARAMS.REF_SOCIAL}`);
                              } else {
                                this.goTolanding();
                              }
                            }
                          })
                          .catch((err) => console.error("Signin >> loading permissions error", err));
                      }
                    }
                  }
                })
                .catch((err) => {
                  console.error("Signin >> SetFlag error", err);
                });
              getFingerPrint();
            }
          }
        })
        .catch((err) => {
          console.error("Signin >> Submit Form Error", err);
          this.handleResponseError(err);
        });
    },

    handleResponseError(response) {
      const statusCode = response?.output?.statusCode ?? response?.statusCode;
      const message = response?.output?.message ?? response?.message;
      if ([400, 409].includes(statusCode)) {
        this.signinServerErrors.push(message);
        this.formSubmissionInProgress = false;
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.isGoogleAuthEnabled = import.meta.env.VITE_GOOGLE_AUTH_ENABLED !== "false";
  },
};
</script>

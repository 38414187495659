import ModuleGuard from "./guards/module-guard";
import { ROUTE_TRANSACTION } from "@/constants/route.js";

export const SettlementRoutes = [
  {
    path: `${ROUTE_TRANSACTION.SETTLEMENT_LISTING}`,
    name: "settlementsListing",
    beforeEnter: ModuleGuard,
    component: () => import("@/views/settlement/listing/SettlementListing.vue"),
    meta: {
      title: "Settlements",
      toolbar: {
        title: "Settlements",
        primaryLeft: "BankDetailsBanner",
      },
      layout: "AppLayout",
      classes: {
        app: "tl-app--bg-white tl-app--content-toolbar",
      },
    },
  },
];

import api from "@/api/Api";
import { agreementList, exportSettlement } from "@/constants/apiEndPoints";
let BASE_URL = import.meta.env.VITE_AGREEMENT_API_BASE_URL;
let APP_BASE_URL = import.meta.env.VITE_BASE_URL;
let EXPORT_SETTLEMENT = import.meta.env.VITE_EXPORT_URL;

function getAgreementList() {
  const agreementListing = `${BASE_URL}${agreementList.agreementList}`;
  return api.get(agreementListing);
}

function getAgreementSearch(queryparams) {
  const agreementListing = `${BASE_URL}${agreementList.agreementSearch}`;
  let url = new URL(agreementListing);
  for (const key in queryparams) {
    url.searchParams.append(key, queryparams[key]);
  }
  return api.get(url.href);
}
function getSettlementListing(queryparams) {
  const settlementListing = `${APP_BASE_URL}${agreementList.agreementSettlementList}`;
  let url = new URL(settlementListing);
  for (const key in queryparams) {
    url.searchParams.append(key, queryparams[key]);
  }
  return api.get(url.href);
}
function getSettlementCSV(params) {
  const settlementCSV = `${EXPORT_SETTLEMENT}${exportSettlement.exportSettlement}`;
  return api.post(settlementCSV, params);
}

export { getAgreementList, getAgreementSearch, getSettlementListing, getSettlementCSV };

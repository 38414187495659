<template>
  <div
    class="kyb-representative-files tw-border tw-border-solid tw-border-neutral-100 tw-shadow-2"
    :class="{ ' tw-mb-4': viewMode }"
  >
    <div
      class="tw-flex tw-items-center tw-justify-between tw-bg-neutral-150 tw-p-4 tw-text-sm tw-font-bold tw-leading-sm tw-text-primary-700"
    >
      <label class="tw-uppercase">{{ heading }}</label>
      <label
        v-if="showDelete && !viewMode"
        class="tw-borer-solid tw-cursor-pointer tw-rounded tw-border tw-border-neutral-300 tw-bg-white tw-p-2 tw-shadow-2"
        @click="handleDelete"
      >
        <tc-icon icon="trash-can"></tc-icon>
        <span class="tw-ml-2 tw-hidden sm:tw-inline">Delete</span>
      </label>
    </div>
    <div :class="{ 'show-border': viewMode }" class="tw-px-4 tw-py-4">
      <kyb-file-upload
        label="Business Registration proof"
        :viewMode="viewMode"
        :disabled="disabled"
        :fileInfo="registrationFileInfoData"
        fieldName="registrationProofDoc"
        :showTooltip="true"
        :required="true"
        :errors="docErrors.registrationProofDoc"
        @onChange="handleRegistrationProofDocChange"
        @removeFile="removeDocument('registrationProofDoc')"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Business Registration Proof</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            Any Government issued business registeration proof that has not expired and has the following：
          </div>
          <div class="tw-mt-4 tw-ml-5 tw-text-sm tw-font-normal tw-text-white">
            <ul>
              <li>Legal Name of the Business Business</li>
              <li>Business Registration Number</li>
              <li>Nature of Business</li>
              <li>Incorporation Date & Place</li>
              <li>Registered and Operating Business Address</li>
            </ul>
          </div>
        </template>
      </kyb-file-upload>
      <!-- <kyb-checkbox
        label="Operating address proof"
        checkboxDescription="Same as in business registration proof"
        :viewMode="viewMode"
        :value="sameAddress"
        fieldName="addressProof"
        :showTooltip="true"
        @onChange="handleAddressProofChange"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Operating Address Proof</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            Please provide any of the following documents showing current operating address :
          </div>
          <div class="tw-mt-4 tw-ml-5 tw-text-sm tw-font-normal tw-text-white">
            <ul>
              <li>A Recent Utility Bill</li>
              <li>Bank Statement or</li>
              <li>Lease Agreement</li>
            </ul>
          </div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            Document must be recent (not more than 6 months old) and should be in the name of the business.
          </div>
        </template>
      </kyb-checkbox> -->

      <kyb-file-upload
        label="Operating address proof"
        :viewMode="viewMode"
        :disabled="disabled"
        :showTooltip="true"
        :required="true"
        fieldName="AddressProofDoc"
        :fileInfo="addressProofFileInfoData"
        :errors="docErrors.AddressProofDoc"
        @onChange="handleIdentityAddressDocChange"
        @removeFile="removeDocument('AddressProofDoc')"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Operating Address Proof</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            Please provide any of the following documents showing current operating address :
          </div>
          <div class="tw-mt-4 tw-ml-5 tw-text-sm tw-font-normal tw-text-white">
            <ul>
              <li>A Recent Utility Bill</li>
              <li>Bank Statement or</li>
              <li>Lease Agreement</li>
            </ul>
          </div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            Document must be recent (not more than 6 months old) and should be in the name of the business.
          </div>
        </template>
      </kyb-file-upload>
      <kyb-file-upload
        v-if="showBeneficialOwner"
        label="Business Shareholding Structure"
        :viewMode="viewMode"
        :disabled="disabled"
        :showTooltip="true"
        :required="!optional"
        fieldName="beneficialOwnershipDoc"
        :fileInfo="beneficialOwnershipFileInfoData"
        :optional="optional"
        :errors="docErrors.beneficialOwnershipDoc"
        @onChange="handleBeneficialOwnershipDocChange"
        @removeFile="removeDocument('beneficialOwnershipDoc')"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Business Shareholding Structure</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">
            If your entity has any corporate shareholder(s), please provide either of the following:
          </div>
          <div class="tw-mt-4 tw-ml-5 tw-text-sm tw-font-normal tw-text-white">
            <ul>
              <li>upload an official document that shows shareholding structure as per your country.</li>
              <li>provide a signed declaration of company shareholding structure.</li>
            </ul>
          </div>
        </template>
      </kyb-file-upload>
    </div>
  </div>
</template>

<script>
import KybFileUpload from "./kybFileUpload.vue";
// import KybCheckbox from "./kybCheckbox.vue";
export default {
  components: {
    KybFileUpload,
    // KybCheckbox,
  },
  props: {
    heading: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    documents: {
      type: Array,
    },
    // sameAddressProof: {
    //   type: Boolean,
    //   default: true,
    // },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    entityName: {
      type: String,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sameAddress: false,
      registrationFileInfoData: null,
      addressProofFileInfoData: null,
      beneficialOwnershipFileInfoData: null,
      repDocs: [],
      docErrors: {},
    };
  },
  computed: {
    showBeneficialOwner() {
      return this.entityName !== "Individual" && this.entityName !== "Sole Proprietorship";
    },
  },
  watch: {
    sameAddressProof() {
      this.sameAddress = this.sameAddressProof;
    },
    documents() {
      this.updateDocuments();
    },
    errors: {
      handler(newValue) {
        this.docErrors = { ...newValue };
      },
      deep: true,
    },
    optional(newValue) {
      if(newValue) {
        this.docErrors.beneficialOwnerError = "";
      }
    }
  },
  methods: {
    updateDocuments() {
      this.registrationFileInfoData = null;
      this.addressProofFileInfoData = null;
      this.beneficialOwnershipFileInfoData = null;
      this.repDocs = [];
      this.documents.forEach((doc) => {
        if (doc.tag === "registrationProofDoc") {
          this.registrationFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        } else if (doc.tag === "AddressProofDoc") {
          this.addressProofFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        } else if (doc.tag === "beneficialOwnershipDoc") {
          this.beneficialOwnershipFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        }
      });
    },
    handleDelete() {
      this.$emit("onDelete", this.fieldName);
    },
    // handleAddressProofChange(e) {
    //   this.sameAddress = e.value;
    //   this.repDocs = this.repDocs.filter((item) => item.tag !== "AddressProofDoc");
    //   this.dispatchChange();
    // },

    handleRegistrationProofDocChange(e) {
      let docData = {
        proof_type: "Business",
        name: "Business Registration Proof",
        type: "Other",
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Business Registration Proof",
        tag: "registrationProofDoc",
      };

      this.repDocs.push(docData);
      this.dispatchChange("registrationProofDoc");
    },

    handleIdentityAddressDocChange(e) {
      let docData = {
        proof_type: "Address",
        name: "Business Address Proof",
        type: "Other",
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Business Address Proof",
        tag: "AddressProofDoc",
      };
      this.repDocs.push(docData);
      this.dispatchChange("AddressProofDoc");
    },
    handleBeneficialOwnershipDocChange(e) {
      let docData = {
        proof_type: "Business",
        name: "Beneficial Ownership Proof",
        type: "Other",
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Beneficial Ownership Proof",
        tag: "beneficialOwnershipDoc",
      };
      this.repDocs.push(docData);
      this.dispatchChange("beneficialOwnershipDoc");
    },
    removeDocument(fieldName) {
      this.repDocs = this.repDocs.filter((item) => item.tag !== fieldName);
      this.dispatchChange(fieldName);
    },
    dispatchChange(fieldName) {
      let result = {
        same_operating_address: this.sameAddress,
        documents: this.repDocs,
        fieldName,
      };
      this.$emit("onChange", result);
    },
  },
  mounted() {
    this.sameAddress = this.sameAddressProof;
    this.updateDocuments();
  },
};
</script>

<style scoped>
/* .show-border > div {
  border: 1px solid #f1f5f9;
} */
.kyb-representative-files:deep .tc-tips {
  z-index: unset;
}
</style>

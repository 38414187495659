import api from '@/api/Api';
let BASE_URL = import.meta.env.VITE_BANK_BASE_URL;
let VUE_APP_SELLER_FLAG_URL = import.meta.env.VITE_SELLER_FLAG_URL;

import { account } from '@/constants/apiEndPoints'

//GET : to get prepopulated bank details & saved bank details
export function getBankDetails (data) {
  const bankDetailsUrl = `${BASE_URL}${account.bankDetails}`;
  return api.get(bankDetailsUrl, data)
}

//POST : to save bank details
export function addBankDetails (data) {
  const addBankUrl = `${BASE_URL}${account.bankDetails}`;
  return api.post(addBankUrl, data)
}

//PUT : to change primary bank account
export function updateBankAccount (bankId) {
  const updateBankUrl = `${BASE_URL}${account.updateBank}${bankId}`;
  return api.put(updateBankUrl)
}

//PUT : to change bank account details
export function updateBankDetails (data) {
  const updateBankDetailsUrl = `${VUE_APP_SELLER_FLAG_URL}${account.updateBankDetails}`;
  return api.put(updateBankDetailsUrl,data)
}

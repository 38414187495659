// list of all permisiions available in the system needs to be updated to be in sync with BE
const allPermissionsBeforeSerialized = [
  { ResourceName: "ESCROW", Action: "CREATE", PermissionID: 1 },
  { ResourceName: "ESCROW", Action: "VIEW", PermissionID: 2 },
  { ResourceName: "ESCROW", Action: "UPDATE", PermissionID: 3 },
  { ResourceName: "PAYMENT_LINK", Action: "CREATE", PermissionID: 4 },
  { ResourceName: "PAYMENT_LINK", Action: "VIEW", PermissionID: 5 },
  { ResourceName: "BANK", Action: "CREATE", PermissionID: 6 },
  { ResourceName: "BANK", Action: "VIEW", PermissionID: 7 },
  { ResourceName: "KYB", Action: "CREATE", PermissionID: 8 },
  { ResourceName: "KYB", Action: "VIEW", PermissionID: 9 },
  { ResourceName: "KYB", Action: "UPDATE", PermissionID: 10 },
  { ResourceName: "CONFIGURATION_KEY", Action: "CREATE", PermissionID: 11 },
  { ResourceName: "CONFIGURATION_KEY", Action: "VIEW", PermissionID: 12 },
  { ResourceName: "CONFIGURATION_KEY", Action: "UPDATE", PermissionID: 13 },
  { ResourceName: "ROLE", Action: "CREATE", PermissionID: 14 },
  { ResourceName: "ROLE", Action: "VIEW", PermissionID: 15 },
  { ResourceName: "ROLE", Action: "UPDATE", PermissionID: 16 },
  { ResourceName: "ROLE", Action: "DELETE", PermissionID: 17 },
  { ResourceName: "USER", Action: "CREATE", PermissionID: 18 },
  { ResourceName: "USER", Action: "UPDATE", PermissionID: 19 },
  { ResourceName: "USER", Action: "VIEW", PermissionID: 20 },
  { ResourceName: "USER", Action: "DELETE", PermissionID: 21 },
  { ResourceName: "TEAM", Action: "CREATE", PermissionID: 22 },
  { ResourceName: "TEAM", Action: "UPDATE", PermissionID: 23 },
  { ResourceName: "TEAM", Action: "VIEW", PermissionID: 24 },
  { ResourceName: "TEAM", Action: "DELETE", PermissionID: 25 },
];

const allPermissions = {
  ESCROW_CREATE: "ESCROW_CREATE",
  ESCROW_VIEW: "ESCROW_VIEW",
  ESCROW_UPDATE: "ESCROW_UPDATE",
  PAYMENT_LINK_CREATE: "PAYMENT_LINK_CREATE",
  PAYMENT_LINK_VIEW: "PAYMENT_LINK_VIEW",
  BANK_CREATE: "BANK_CREATE",
  BANK_VIEW: "BANK_VIEW",
  KYB_CREATE: "KYB_CREATE",
  KYB_VIEW: "KYB_VIEW",
  KYB_UPDATE: "KYB_UPDATE",
  CONFIGURATION_KEY_CREATE: "CONFIGURATION_KEY_CREATE",
  CONFIGURATION_KEY_VIEW: "CONFIGURATION_KEY_VIEW",
  CONFIGURATION_KEY_UPDATE: "CONFIGURATION_KEY_UPDATE",
  ROLE_CREATE: "ROLE_CREATE",
  ROLE_VIEW: "ROLE_VIEW",
  ROLE_UPDATE: "ROLE_UPDATE",
  ROLE_DELETE: "ROLE_DELETE",
  USER_CREATE: "USER_CREATE",
  USER_UPDATE: "USER_UPDATE",
  USER_VIEW: "USER_VIEW",
  USER_DELETE: "USER_DELETE",
  TEAM_CREATE: "TEAM_CREATE",
  TEAM_UPDATE: "TEAM_UPDATE",
  TEAM_VIEW: "TEAM_VIEW",
  TEAM_DELETE: "TEAM_DELETE",
};

// owner permission profile
const ownerPermissions = allPermissions;

const roles = {
  OWNER: "owner",
  MANAGER: "manager",
};

// TO DO create permission profiles for all the roles available

export { allPermissionsBeforeSerialized, allPermissions, ownerPermissions, roles };

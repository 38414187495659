<template>
  <div>
    <Layout>
      <router-view />
    </Layout>
    <Teleport to="#teleportal">
      <ModalContainer  ref="modalManager" />
    </Teleport>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, provide, ref } from "vue";
import { ModalContainer } from "tazapay-ui"
import Layout from "@/components/layout/Layout.vue";
import { fetchIPDetails } from '@/helpers/ipHelper';
import { useGtm } from '@gtm-support/vue-gtm';

const modalManager = ref(null)

provide('createModal', (...a) => modalManager.value.createModal(...a))      
provide('closeModal', (...a) => modalManager.value.closeModal(...a))   

const gtm = useGtm(); 

onMounted(()=> {
  if (gtm.enabled()) {
    window.dataLayer?.push({event: "gtm.historyChange"});
  }
  
  if (import.meta.env.MODE === "production") {
    fetchIPDetails()
  }
});
</script>

<template>
  <div
    class="kyb-representative-detail tw-mb-5 tw-border tw-border-solid tw-border-neutral-100 tw-shadow-2 lg:tw-mx-10"
    :class="{ 'tw-mx-4 tw-mb-4': viewMode }"
  >
    <div
      class="tw-flex tw-items-center tw-justify-between tw-bg-neutral-150 tw-p-4 tw-text-sm tw-font-bold tw-leading-sm tw-text-primary-700"
    >
      <label class="tw-uppercase">{{ heading }}</label>
      <label
        v-if="showDelete && !viewMode"
        class="tw-borer-solid tw-cursor-pointer tw-rounded tw-border tw-border-neutral-300 tw-bg-white tw-p-2 tw-shadow-2"
        @click="handleDelete"
      >
        <tc-icon icon="trash-can"></tc-icon>
        <span class="tw-ml-2 tw-hidden sm:tw-inline">Delete</span>
      </label>
    </div>
    <div :class="{ 'show-border': viewMode }">
      <kyb-text-input
        class="tw-px-4"
        label="First Name"
        placeholder="First Name"
        fieldName="firstName"
        :value="firstName"
        :required="true"
        :errors="errors.firstName"
        :viewMode="viewMode"
        @onChange="handleChange"
      ></kyb-text-input>
      <kyb-text-input
        class="tw-px-4"
        label="Last Name"
        placeholder="Last Name"
        fieldName="lastName"
        :value="lastName"
        :required="true"
        :viewMode="viewMode"
        :errors="errors.lastName"
        @onChange="handleChange"
      ></kyb-text-input>
      <kyb-select-input
        class="tw-px-4"
        label="Nationality"
        fieldName="nationality"
        autocomplete="country"
        :value="nationality"
        :multiple="false"
        :viewMode="viewMode"
        :items="getcountryList()"
        :errors="errors.nationality"
        @onChange="onCountryChange"
        placeholder="Select Nationality"
      ></kyb-select-input>
      <kyb-select-input
        class="tw-px-4"
        label="Role"
        :value="roles"
        fieldName="roles"
        placeholder="Role"
        @onChange="handleChange"
        :viewMode="viewMode"
        :multiple="true"
        :errors="errors.roles"
        :beneficialError="beneficialError"
        description="Please select all roles relevant to you"
        :items="['Authorized Representative', 'Beneficial Owner', 'Director']"
      ></kyb-select-input>
      <kyb-text-input
        class="tw-px-4"
        label="Email"
        placeholder="Email"
        fieldName="email"
        autocomplete="email"
        :value="email"
        type="email"
        :required="true"
        :errors="errors.email"
        :viewMode="viewMode"
        @onChange="handleChange"
      ></kyb-text-input>
      <kyb-phone-input
        class="tw-px-4"
        label="Contact Number"
        :optional="true"
        fieldName="contactNumber"
        :contactCode="contactCodeData"
        :value="contactNumber"
        :viewMode="viewMode"
        @onChange="handleChange"
      ></kyb-phone-input>
    </div>
  </div>
</template>

<script>
import kybTextInput from "@/components/shared/kybDialog/kybTextInput.vue";
import kybPhoneInput from "@/components/shared/kybDialog/kybPhoneInput.vue";
import KybSelectInput from "./kybSelectInput.vue";
import allCountries from "@/helpers/allCountries";

export default {
  components: {
    kybTextInput,
    kybPhoneInput,
    KybSelectInput,
  },
  props: {
    heading: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    showDelete: {
      type: Boolean,
    },
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    email: {
      type: String,
    },
    contactNumber: {
      type: String,
    },
    contactCode: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    nationality: {
      type: String,
    },
    roles: {
      type: [String, Array],
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    beneficialError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contactCodeData: "",
    };
  },
  methods: {
    handleChange(e) {
      let eventData = {
        value: e.value,
        fieldName: this.fieldName,
        subFieldName: e.fieldName,
        contactCode: e.contactCode || "",
      };
      this.$emit("onChange", eventData);
    },
    handleDelete() {
      this.$emit("onDelete", this.fieldName);
    },
    getcountryList() {
      const countryList = [];
      allCountries.forEach((e) => {
        countryList.push(e.name);
      });
      return countryList;
    },
    onCountryChange(e) {
      for (var i = 0; i < allCountries.length; i++) {
        if (allCountries[i].name === e.value) {
          this.contactCodeData = "+" + allCountries[i].dialCode;
        }
      }
      this.handleChange(e);
    },
  },
  watch: {
    contactCode() {
      this.contactCodeData = this.contactCode;
    },
  },
  mounted() {
    this.contactCodeData = this.contactCode;
  },
};
</script>

<style scoped>
.show-border > div {
  border: 1px solid #f1f5f9;
}
</style>

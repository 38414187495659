<template>
  <div id="dialog-comp" class="tw-fixed tw-top-0 tw-left-0 tw-flex tw-hidden tw-h-full tw-w-full tw-border-0">
    <div
      v-show="!showThankyou"
      v-if="!showContactOps"
      class="kyb-dialog tw-flex tw-flex-col tw-justify-between tw-rounded"
    >
      <div class="tw-flex tw-h-full tw-overflow-auto tw-rounded">
        <div class="dialog-sidemenu tw-hidden tw-max-w-xs tw-bg-neutral-100 tw-p-6 lg:tw-block">
          <div class="tw-px-2 tw-text-2xl tw-font-bold tw-leading-sm tw-text-neutral-800">
            {{ kybCompleted ? "Form Details" : "Complete Form Details" }}
          </div>
          <div class="tw-px-2 tw-pt-4 tw-pb-2 tw-text-sm tw-leading-lg tw-text-neutral-600">
            Complete your form successfully for activating your account for payouts
          </div>
          <kyb-sidemenu-item
            v-for="menu in sideMenus"
            v-bind="menu"
            :active="activeItemId === menu.id"
            :key="menu.label"
            :disabled="isCurrentFormValid"
            @selectedItem="handleSidemenuSelect"
          ></kyb-sidemenu-item>
        </div>

        <div class="tw-flex tw-w-full tw-flex-col">
          <div
            ref="dialogHeader"
            class="dialog-header tw-flex tw-items-center tw-justify-between tw-bg-white tw-px-6 tw-py-4"
          >
            <div class="tw-block lg:tw-hidden"></div>
            <label class="tw-hidden tw-text-xl tw-font-bold tw-leading-lg tw-text-primary-700 lg:tw-block">{{
              activeSectionLabel
            }}</label>
            <div>
              <div
                class="tw-inline-flex tw-items-center lg:tw-hidden"
                v-for="(menu, index) in sideMenus"
                :key="menu.id"
              >
                <Stepper
                  :id="menu.id"
                  :isActive="activeItemId === menu.id"
                  :isCompleted="menu.isCompleted"
                  :value="index + 1"
                  :label="menu.mobileLabel"
                  :isLast="menu.isLast"
                  :disabled="isCurrentFormValid"
                  @selectedItem="handleSidemenuSelect"
                />
              </div>
            </div>
            <tc-icon class="tw-cursor-pointer" icon="close" size="xl" @click="hideKybModal"></tc-icon>
          </div>
          <!-- hide banner if kyb is submitted. -->
          <EmailVerificationBanner
            v-if="!basicUserDetails.email_verified && !kybCompleted"
            text="Verify your email to complete KYB/KYC Details"
          ></EmailVerificationBanner>
          <div class="stepper-component tw-mt-6 tw-flex tw-justify-start lg:tw-hidden">
            <label
              class="tw-block tw-pl-6 tw-text-xl tw-font-bold tw-leading-lg tw-text-primary-700 lg:tw-hidden lg:tw-pl-10"
              >{{ activeSectionLabel }}</label
            >
          </div>
          <div ref="dialogBody" class="dialog-body tw-h-full tw-overflow-auto tw-bg-white">
            <div
              class="contact-info-input tw-px-6 tw-pt-4 lg:tw-px-10 lg:tw-pt-10"
              :class="{ 'component-view-mode': kybCompleted }"
              v-show="activeItemId === 'contact-info'"
            >
              <div :class="{ 'show-border tw-rounded tw-border tw-border-solid tw-border-neutral-150': kybCompleted }">
                <div v-for="comp in getContactInfoCompData" :key="comp.id">
                  <component
                    :is="comp.compName"
                    v-bind="comp"
                    :key="comp.id"
                    :viewMode="kybCompleted"
                    @onChange="handleChange"
                  ></component>
                  <div
                    class="tw--mt-1 tw-flex tw-pb-2 sm:tw-mt-0"
                    v-if="basicUserDetails.email_verified && comp.id === 'registered-email'"
                  >
                    <div class="tw-mt--1 tw-mr-0 tw-w-auto sm:tw-mt-0 sm:tw-mr-8 sm:tw-w-48"></div>
                    <div class="tw-text-xs tw-leading-lg tw-text-success-800">Email verified !</div>
                  </div>
                  <div
                    v-if="comp.id === 'registered-email' && !basicUserDetails.email_verified && !kybCompleted"
                    class="tw--mt-1 tw-flex tw-pb-2 sm:tw-mt-0"
                  >
                    <div class="tw-mt--1 tw-mr-0 tw-w-auto sm:tw-mt-0 sm:tw-mr-8 sm:tw-w-48"></div>
                    <div class="error-holder tw-text-xs">
                      <div data-cy="errorMsg" class="error-msg">Please verify your email.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="address-details tw-px-6 tw-pt-4 lg:tw-px-10 lg:tw-pt-10"
              :class="{ 'component-view-mode': kybCompleted }"
              v-show="activeItemId === 'personal-details' || activeItemId === 'business-details'"
            >
              <div :class="{ 'show-border tw-rounded tw-border tw-border-solid tw-border-neutral-150': kybCompleted }">
                <div v-for="comp in getAddressDetailCompData" :key="comp.id">
                  <component
                    v-if="comp.id !== 'pan'"
                    :is="comp.compName"
                    v-bind="comp"
                    :viewMode="kybCompleted"
                    :errors="comp.errors"
                    @onChange="handleAddressDetailsChange"
                  ></component>
                  <component
                    v-if="comp.id === 'pan' && basicUserDetails.country === 'India'"
                    :is="comp.compName"
                    v-bind="comp"
                    :viewMode="kybCompleted"
                    :errors="comp.errors"
                    @onChange="handleAddressDetailsChange"
                  ></component>
                  <img
                    v-if="websiteValidation(addressDetails.website) && comp.id === 'website'"
                    class="tw-hidden lg:tw-float-right lg:tw--mt-11 lg:tw-mr-12 lg:tw-block"
                    :src="
                      'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://' +
                      addressDetails.website +
                      '/&size=32'
                    "
                    height="32"
                    width="32"
                  />
                </div>
              </div>
            </div>
            <div
              class="bank-details tw-px-6 tw-pt-4 lg:tw-px-10 lg:tw-pt-10"
              :class="{ 'component-view-mode': kybCompleted }"
              v-show="activeItemId === 'bank-details'"
            >
              <KybBankDetails
                ref="bankDetailsTab"
                :readOnlyMode="KYBStatus !== 'not_started'"
                @onChange="handleBankDetailsChange"
              />
            </div>
            <div
              class="documents-details tw-px-6 tw-pt-4 lg:tw-px-10 lg:tw-pt-10"
              :class="{ 'component-view-mode': kybCompleted }"
              v-show="activeItemId === 'document-details'"
            >
              <div
                v-if="!kybCompleted"
                class="tw-mb-4 tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-neutral-200 tw-pb-6 tw-text-base tw-font-bold tw-leading-lg tw-text-neutral-700"
              >
                Please upload supporting KYB/KYC documents to verify the provided details.
              </div>
              <kyb-business-files
                v-if="basicUserDetails.ind_bus_type !== 'Individual'"
                class="tw-mb-4"
                heading="Business Documents"
                :documents="getBusinessDocs"
                :viewMode="kybCompleted"
                :disabled="kybCompleted"
                :sameAddressProof="businessDocs.same_operating_address"
                :entityName="basicUserDetails['ind_bus_type']"
                :errors="documentDetailsErrors.businessDocErrors"
                :optional="!businessLegalRep.is_ownership_declared"
                @onChange="handleBusinessFileChange"
              ></kyb-business-files>
              <kyb-representative-files
                class="tw-mb-4"
                :heading="getRepresentativeName + '\'s' + ' Proof'"
                fieldName=""
                :viewMode="kybCompleted"
                :documents="representativeDocs.documents"
                :sameAddressProof="representativeDocs.same_operating_address"
                :errors="documentDetailsErrors.representativeDocsErrors"
                @onChange="handleOwnerFileChange"
              >
              </kyb-representative-files>
              <kyb-representative-files
                class="tw-mb-4"
                v-for="item in additionalReps"
                :key="item.id"
                :viewMode="kybCompleted"
                :heading="item.firstName + ' ' + item.lastName + '\'s' + ' Proof'"
                :fieldName="item.id || item.uniqueKey"
                :documents="item.documents"
                :sameAddressProof="item.same_operating_address"
                :id="item.uniqueKey"
                :errors="item['representativeDocsErrors']"
                @onChange="handleRepFileChange($event, item.id || item.uniqueKey)"
              >
              </kyb-representative-files>

              <kybBusinessDetailsDocs
                v-if="basicUserDetails.ind_bus_type !== 'Individual'"
                class="tw-mb-4"
                heading="Business History & Track Records (Optional)"
                subHeading="By providing these documents, your application review may be faster."
                :documents="getBusinessHistoryDocs"
                :viewMode="kybCompleted"
                :disabled="kybCompleted"
                :entityName="basicUserDetails['ind_bus_type']"
                @onChange="handleBusinessHistoryDocsChange"
              >
              </kybBusinessDetailsDocs>

              <div class="tw-mb-5 tw-mt-4 tw-border tw-border-solid tw-border-neutral-200"></div>
              <kyb-additional-docs
                label="Additional documents"
                placeholder="Enter type of document"
                inputHint="ex. GST, Tax Certificate, Bank statement, etc"
                fieldName="additionalDocs"
                :fileLists="additionalDocs"
                :viewMode="kybCompleted"
                @onChange="handleAdditionalDocsChange"
              ></kyb-additional-docs>
              <div class="tw-mb-4 tw-mt-4 tw-border tw-border-solid tw-border-neutral-200"></div>

              <div class="tw-mb-4">
                <div class="tw-mb-4 tw-text-sm tw-font-bold tw-text-neutral-700">Declaration</div>
                <input-checkbox
                  class="declaration-checkbox tw-text-neutral-600"
                  :model="declaration"
                  :disabled="kybCompleted"
                  :showMultipleErrors="false"
                  :errors="declarationError"
                  @inputOnChange="handleDeclarationChange"
                  >By submitting this form, you confirm that:
                </input-checkbox>
                <div>
                  <ul class="tw-ml-12 tw-text-base tw-leading-lg tw-text-neutral-600">
                    <li>You are authorised to act on behalf of the business.</li>
                    <li>
                      All beneficial owners, directors, and partners have been declared and their details have been
                      provided
                    </li>
                    <li>All information and documents provided are true, complete and accurate.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="sole-legal-representative"
              :class="{ 'component-view-mode': kybCompleted }"
              v-show="activeItemId === 'sole-legal-representative'"
            >
              <div
                :class="{ 'show-border tw-rounded tw-border tw-border-solid tw-border-neutral-150': kybCompleted }"
                class="tw-mx-4 tw-mt-4 lg:tw-mx-10 lg:tw-mt-10"
              >
                <component
                  v-for="comp in getSoleLegalReprentativeCompData"
                  :is="comp.compName"
                  :key="comp.id"
                  v-bind="comp"
                  :viewMode="kybCompleted"
                  :selectableOptions="selectableOptions"
                  @onChange="handleSoleRepChanges"
                ></component>
              </div>

              <kybRepresentativeDetail
                class="tw-mt-4"
                v-if="this.soleLegalRepDetails['ownership'] === false"
                heading="SOLE PROPRIETOR DETAILS"
                fieldName="addtionalRepDetails"
                v-bind="additionalReps[0]"
                :viewMode="kybCompleted"
                :errors="additionalReps[0].errors"
                :beneficialError="true"
                @onChange="handleSoleRepChanges"
              ></kybRepresentativeDetail>
            </div>
            <div
              class="legal-representative tw-scroll-smooth"
              :class="{ 'component-view-mode': kybCompleted }"
              v-show="activeItemId === 'legal-representative'"
            >
              <div
                :class="{
                  'show-border tw-mb-5 tw-rounded tw-border  tw-border-solid tw-border-neutral-150': kybCompleted,
                }"
                class="tw-mx-4 tw-mt-4 tw-mb-4 tw-border-0 tw-border-b tw-border-solid tw-border-neutral-200 tw-pb-2 lg:tw-mx-10 lg:tw-mt-10"
              >
                <component
                  v-for="comp in getBusinessLegalReprentativeCompData"
                  :is="comp.compName"
                  :key="comp.id"
                  v-bind="comp"
                  :viewMode="kybCompleted"
                  :selectableOptions="selectableOptions"
                  @onChange="handleBusinessRepChange"
                ></component>
              </div>
              <div
                class="tw-block"
                v-for="(representative, index) in additionalReps"
                :key="representative['uniqueKey']"
              >
                <kybRepresentativeDetail
                  fieldName="legalRepList"
                  :heading="'Individual ' + (index + 1) + ' Details'"
                  :showDelete="true"
                  v-bind="representative"
                  :viewMode="kybCompleted"
                  :errors="representative['errors']"
                  @onChange="handleBusinessRepChange($event, representative['uniqueKey'])"
                  @onDelete="handleBusinessRepDelete($event, representative['uniqueKey'])"
                ></kybRepresentativeDetail>
              </div>
              <div
                class="tw-mt-4 tw-ml-3 tw-mr-6 tw-mb-2 tw-block tw-px-6 tw-pt-1 tw-pb-2 tw-text-sm tw-leading-lg tw-text-neutral-700"
              >
                <div>Regulatory requirements require us to collect information on all individuals who are:</div>
                <ol class="tw-pl-4">
                  <li>Key officers (directors or partners),</li>
                  <li>Beneficial owners (individual shareholders who own or control 25% or more of the business),</li>
                  <li>Account users (i.e. individuals acting on behalf of the business).</li>
                </ol>
              </div>
              <button
                class="tw-ml-6 tw-rounded-md tw-border tw-border-solid tw-border-neutral-200 tw-p-4 lg:tw-ml-10"
                @click="addLegalRep"
                v-if="!kybCompleted"
              >
                <tc-icon icon="plus" color="primary-700" size="lg"></tc-icon>
                <label class="tw-ml-4 tw-cursor-pointer tw-text-sm tw-font-bold tw-leading-sm tw-text-primary-700"
                  >Add Additional Individual</label
                >
              </button>
              <div
                v-show="!businessLegalRep.ownership && !beneficialOwnerCheck"
                class="tw-ml-6 tw-mt-4 tw-mb-4 tw-border-0 tw-border-t tw-border-solid tw-border-neutral-200 tw-pt-4 tw-pb-4 lg:tw-mx-10 lg:tw-ml-10"
              >
                <div class="tw-mb-4 tw-text-sm tw-font-bold tw-text-neutral-700">Declaration</div>
                <input-checkbox
                  class="declaration-checkbox beneficial-owner-error tw-text-base tw-text-neutral-600"
                  :model="businessLegalRep.is_ownership_declared"
                  :disabled="kybCompleted"
                  :showMultipleErrors="false"
                  :errors="beneficialOwnerCheckError"
                  @inputOnChange="handleBeneficialOwnerChange"
                  >By confirming you agree that, No individual directly or indirectly owns more than 25% of the
                  business.
                </input-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="dialogFooter"
        class="dialog-footer tw-flex tw-items-center tw-justify-end tw-rounded tw-border-0 tw-border-t tw-border-solid tw-border-neutral-200 tw-bg-white tw-p-4"
      >
        <button
          class="tw-rounded tw-border tw-border-solid tw-border-neutral-300 tw-px-6 tw-py-3 tw-text-base tw-font-bold tw-leading-lg tw-text-neutral-600"
          :class="{
            'tw-border-primary-700 tw-bg-primary-700 tw-text-white ':
              kybCompleted && activeItemId === 'document-details',
          }"
          @click="handleBack"
          v-show="activeItemId !== 'contact-info'"
        >
          Back
        </button>
        <button
          class="tw-ml-4 tw-rounded tw-border tw-border-solid tw-border-primary-700 tw-bg-primary-700 tw-px-6 tw-py-3 tw-text-base tw-font-bold tw-leading-lg tw-text-white tw-shadow-1 tw-transition tw-duration-300 tw-ease-in-out"
          v-show="!(kybCompleted && activeItemId === 'document-details')"
          :disabled="fetching"
          @click="handleSaveAndNext"
        >
          {{ okLabel }}
        </button>
      </div>
    </div>
    <!-- TO DO Handle thankyou screen render -->
    <div
      v-show="showThankyou"
      v-if="!showContactOps"
      class="thankyou-banner tw-flex tw-flex-col tw-rounded sm:tw-w-full"
    >
      <div class="tw-flex tw-items-center tw-justify-between tw-rounded tw-bg-white tw-p-5">
        <tc-icon class="tw-ml-auto tw-cursor-pointer" icon="close" size="xl" @click="hideKybModal"></tc-icon>
      </div>
      <kybThankyou
        @showKybDialog="
          () => {
            this.showThankyou = false;
            this.handleSidemenuSelect({ value: 'contact-info' });
          }
        "
        @hideKybDialog="hideKybModal"
      />
    </div>
    <div v-if="showContactOps" class="contact-ops-banner">
      <div class="tw-flex tw-items-center tw-justify-between tw-rounded tw-bg-white tw-p-5">
        <tc-icon class="tw-ml-auto tw-cursor-pointer" icon="close" size="xl" @click="hideKybModal"></tc-icon>
      </div>
      <div class="tw-mt-6 tw-text-center tw-text-xl tw-font-semibold tw-text-neutral-800 lg:tw-text-2xl xl:tw-text-2xl">
        Contact operations
      </div>
      <div class="tw-py-5 tw-px-10">
        Your application was submitted offline, please reach out to our operations team at
        <a href="mailto:trade-ops@tazapay.com" target="_blank">trade-ops@tazapay.com</a> for any queries or
        clarifications required.
      </div>
    </div>
  </div>
</template>

<script>
import {
  sidemenuItems,
  contactInfoComponents,
  personalDetailsComponents,
  soleLegalReprentative,
  businessLegalReprentative,
  businessDetail,
} from "@/components/shared/kybDialog/kybCompData";
import { getAccountDetails } from "@/api/settings";
import { getKYBStatus, createKYB, getKYBData, updateKYB } from "@/api/kybAPI.js";
import kybSidemenuItem from "@/components/shared/kybDialog/kybSidemenuItem.vue";
import kybTextInput from "@/components/shared/kybDialog/kybTextInput.vue";
import kybTextAreaInput from "@/components/shared/kybDialog/kybTextAreaInput.vue";
import KybPhoneInput from "@/components/shared/kybDialog/kybPhoneInput.vue";
import KybSelectInput from "@/components/shared/kybDialog/kybSelectInput.vue";
import KybAddressInput from "@/components/shared/kybDialog/kybAddressInput.vue";
import kybCheckbox from "@/components/shared/kybDialog/kybCheckbox.vue";
import kybFileUpload from "@/components/shared/kybDialog/kybFileUpload.vue";
import kybRadioGroup from "@/components/shared/kybDialog/kybRadioGroup.vue";
import kybAdditionalDocs from "@/components/shared/kybDialog/kybAdditionalDocs.vue";
import kybThankyou from "@/components/shared/kybDialog/kybThankyou.vue";
import kybRepresentativeDetail from "@/components/shared/kybDialog/kybRepresentativeDetail.vue";
import kybRepresentativeFiles from "@/components/shared/kybDialog/kybRepresentativeFiles.vue";
import EmailVerificationBanner from "@/components/banners/EmailVerificationBanner.vue";

import kybBusinessFiles from "@/components/shared/kybDialog/kybBusinessFiles.vue";
import SocialMedia from "@/components/shared/kybDialog/components/SocialMedia.vue";
import kybBusinessDetailsDocs from "@/components/shared/kybDialog/components/kybBusinessDetailsDocs.vue";

import { updateKYBApprovedStatus } from "@/helpers/kybStatusUtils.js";
import { updateQualityFlag } from "@/helpers/qualityFlagUtils.js";

import { nextTick } from "@vue/runtime-core";
import FormValidation from "@/ui-components/formBuilder/FormValidation";
import { InputCheckbox, Stepper } from "tazapay-ui";
import { clearStorage } from "@/helpers/storageService.js";
import parsePhoneNumber from "libphonenumber-js";
import { ROUTE_SETTINGS } from "@/constants/route.js";
import { ERROR_TYPE } from "@/constants/errorCodes.js";
import { regexUrlValidation } from "@/helpers/regex.js";
import { ref } from "vue";
import KybBankDetails from "@/components/shared/kybDialog/kybBankDetails.vue";
import { useGlobalStore } from "@/stores/GlobalStore";
import { useNotificationStore } from "@/stores/NotificationStore";

const REQUIRED_ERROR = "Please fill this required field";
const DOCUMENT_REQUIRED_ERROR = "Please upload this required document";

export default {
  components: {
    kybSidemenuItem,
    kybTextInput,
    KybPhoneInput,
    KybSelectInput,
    KybAddressInput,
    kybFileUpload,
    kybCheckbox,
    kybAdditionalDocs,
    kybRadioGroup,
    kybRepresentativeDetail,
    kybRepresentativeFiles,
    kybBusinessFiles,
    kybThankyou,
    Stepper,
    InputCheckbox,
    EmailVerificationBanner,
    KybBankDetails,
    kybTextAreaInput,
    SocialMedia,
    kybBusinessDetailsDocs,
  },
  data() {
    return {
      basicUserDetails: {
        email: "",
        contact_code: "",
        contact_number: "",
        country: "",
        ind_bus_type: "",
        name: "",
        business_name: "",
        email_verified: false,
        isSaved: false,
        firstName: "",
        lastName: "",
      },
      addressDetails: {
        name: "",
        firstName: "",
        lastName: "",
        nationality: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          state: "",
          city: "",
          postal: "",
          country: "",
        },
        description: "",
        website: "",
        isSaved: false,
        pan: "",
        yearsInOperation: "",
        turnover: "",
        businessDescriptionDetail: "",
        socialMedia: {
          facebookId: "",
          twitterId: "",
          linkedinId: "",
          instagramId: "",
        },
      },
      bankDetails: {
        local: {},
        foreign: {},
        isSaved: false,
        isValid: false,
      },
      documentDetails: {
        isSaved: false,
      },
      soleLegalRepDetails: {
        firstName: "",
        lastName: "",
        roles: ["Beneficial Owner"],
        nationality: "",
        ownership: true,
        isSaved: false,
      },
      businessLegalRep: {
        firstName: "",
        lastName: "",
        roles: ["Beneficial Owner"],
        ownership: true,
        nationality: "",
        isSaved: false,
        is_ownership_declared: false,
      },
      additionalReps: [],
      representativeDocs: {
        documents: [],
        same_operating_address: true,
        identityProofType: "",
      },
      businessDocs: {
        documents: [],
        same_operating_address: true,
      },
      businessHistoryDocs: {
        documents: [],
      },
      additionalDocs: [],
      showThankyou: false,
      activeItemId: "contact-info",
      accountId: "",
      applicationId: "",
      applicationType: "",
      KYBStatus: "",
      businessId: "",
      representativeId: "",
      formValidation: new FormValidation(),
      fetching: false,
      declaration: false,
      errors: [],
      showContactOps: false,
      contactInfoCompData: ref(contactInfoComponents),
      personalDetailsCompData: ref(personalDetailsComponents),
      businessDetailCompData: ref(businessDetail),
      soleLegalReprentativeCompData: ref(soleLegalReprentative),
      businessLegalReprentativeCompData: ref(businessLegalReprentative),
      kybRepresentativeFields: {
        firstName: "",
        lastName: "",
        email: "",
        roles: [],
        nationality: "",
      },
      documentDetailsErrors: {
        businessDocErrors: {},
        representativeDocsErrors: {},
        beneficialOwnershipDoc: {},
      },
      declarationError: [],
      beneficialOwnerCheck: true,
      beneficialOwnerCheckError: [],
    };
  },
  watch: {
    KYBStatus() {
      this.declaration = this.kybCompleted;
      this.$emit("kybStatusChange", this.KYBStatus);
    },
    applicationType(newValue) {
      if (newValue === "Individual") {
        this.businessId = "";
      }
    },
  },
  computed: {
    kybCompleted() {
      return this.KYBStatus !== "not_started";
    },
    getRepresentativeName() {
      let entityType = this.basicUserDetails["ind_bus_type"];
      if (entityType === "Individual") {
        return this.addressDetails.firstName + " " + this.addressDetails.lastName;
      } else if (entityType === "Sole Proprietorship") {
        return this.soleLegalRepDetails.firstName + " " + this.soleLegalRepDetails.lastName;
      } else {
        return this.businessLegalRep.firstName + " " + this.businessLegalRep.lastName;
      }
    },
    okLabel() {
      if (this.fetching) {
        return "Saving...";
      } else if (this.activeItemId === "bank-details" && !this.isCurrentFormSaved) {
        return "Save & Next";
      } else if (this.kybCompleted) {
        return "Next";
      } else if (this.activeItemId === "document-details") {
        return "Submit";
      } else if (this.isCurrentFormValid && this.isCurrentFormSaved) {
        return "Next";
      }
      return "Save & Next";
    },
    sideMenus() {
      let result = [];
      let entityType = this.basicUserDetails["ind_bus_type"];
      if (entityType) {
        result = sidemenuItems.filter(function (item) {
          return item.supportedBy.includes(entityType);
        });

        result.forEach((item) => {
          if (entityType === "Business") {
            item.isCompleted = false;
          } else if (item.id === "contact-info") {
            item.isCompleted = this.basicUserDetails.isSaved;
          } else if (item.id === "personal-details") {
            item.isCompleted = this.addressDetails.isSaved;
          } else if (item.id === "sole-legal-representative") {
            item.isCompleted = this.soleLegalRepDetails.isSaved;
          } else if (item.id === "legal-representative") {
            item.isCompleted = this.businessLegalRep.isSaved;
          } else if (item.id === "business-details") {
            item.isCompleted = this.addressDetails.isSaved;
          } else if (item.id === "bank-details") {
            item.isCompleted = this.bankDetails.isSaved;
          } else if (item.id === "document-details") {
            item.isCompleted = this.documentDetails.isSaved;
          }
        });

        return result;
      }
      return sidemenuItems;
    },
    getContactInfoCompData() {
      let compData = this.contactInfoCompData;
      compData["email"]["value"] = this.basicUserDetails["email"];
      compData["country"]["value"] = this.basicUserDetails["country"];
      compData["contact_number"]["value"] = this.basicUserDetails["contact_number"];
      compData["contact_number"]["contactCode"] = this.basicUserDetails["contact_code"];
      let entityType = this.basicUserDetails["ind_bus_type"];
      if (!this.kybCompleted && ["Business", "Individual"].includes(this.basicUserDetails["ind_bus_type"])) {
        entityType = null;
      }
      compData["ind_bus_type"]["value"] = entityType;
      return compData;
    },
    getAddressDetailCompData() {
      let compData;
      if (this.basicUserDetails["ind_bus_type"] === "Individual") {
        compData = this.personalDetailsCompData;
        compData["firstName"]["value"] = this.addressDetails["firstName"];
        compData["lastName"]["value"] = this.addressDetails["lastName"];
        compData["nationality"]["value"] = this.addressDetails["nationality"];
      } else {
        compData = this.businessDetailCompData;
        compData["name"]["value"] = this.addressDetails["name"];
      }
      compData["pan"]["value"] = this.addressDetails["pan"];
      compData["yearsInOperation"]["value"] = this.addressDetails["yearsInOperation"];
      compData["turnover"]["value"] = this.addressDetails["turnover"];
      compData["businessDescriptionDetail"]["value"] = this.addressDetails["businessDescriptionDetail"];
      compData["description"]["value"] = this.addressDetails["description"];
      compData["address"]["country"] = this.basicUserDetails["country"];
      compData["address"]["addressLine1"] = this.addressDetails.address.addressLine1;
      compData["address"]["addressLine2"] = this.addressDetails.address.addressLine2;
      compData["address"]["state"] = this.addressDetails["address"]["state"];
      compData["address"]["city"] = this.addressDetails["address"]["city"];
      compData["address"]["postal"] = this.addressDetails["address"]["postal"];
      compData["address"]["postal"] = this.addressDetails["address"]["postal"];
      compData["website"]["value"] = this.addressDetails["website"];
      compData["socialMedia"]["facebookId"] = this.addressDetails.socialMedia.facebookId;
      compData["socialMedia"]["twitterId"] = this.addressDetails.socialMedia.twitterId;
      compData["socialMedia"]["linkedinId"] = this.addressDetails.socialMedia.linkedinId;
      compData["socialMedia"]["instagramId"] = this.addressDetails.socialMedia.instagramId;

      return compData;
    },

    getSoleLegalReprentativeCompData() {
      let soleLegalReprentative = this.soleLegalReprentativeCompData;
      soleLegalReprentative["sole_legal_reprentative_first_name"]["value"] = this.soleLegalRepDetails["firstName"];
      soleLegalReprentative["sole_legal_reprentative_last_name"]["value"] = this.soleLegalRepDetails["lastName"];

      soleLegalReprentative["sole_legal_reprentative_ownership"]["groupData"] = [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ];
      soleLegalReprentative["sole_legal_reprentative_ownership"]["value"] = this.soleLegalRepDetails["ownership"];
      soleLegalReprentative["sole_legal_reprentative_role"]["value"] = this.soleLegalRepDetails.roles;
      soleLegalReprentative["sole_legal_reprentative_nationality"]["value"] =
        this.soleLegalRepDetails.nationality || this.basicUserDetails.country;

      return soleLegalReprentative;
    },
    getBusinessLegalReprentativeCompData() {
      let businessLegalReprentative = this.businessLegalReprentativeCompData;
      businessLegalReprentative["business_legal_reprentative_ownership"]["groupData"] = [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ];

      businessLegalReprentative["business_legal_reprentative_ownership"]["value"] = this.businessLegalRep["ownership"];
      businessLegalReprentative["business_legal_reprentative_first_name"]["value"] = this.businessLegalRep["firstName"];
      businessLegalReprentative["business_legal_reprentative_last_name"]["value"] = this.businessLegalRep["lastName"];

      businessLegalReprentative["business_legal_reprentative_role"]["value"] = this.businessLegalRep.roles;

      businessLegalReprentative["business_legal_reprentative_nationality"]["value"] =
        this.businessLegalRep.nationality || this.basicUserDetails.country;

      return businessLegalReprentative;
    },
    isContactInfoValid() {
      if (this.kybCompleted) {
        return true;
      }
      // return false, if the Mandatory field are falsy.
      let result = parsePhoneNumber(this.basicUserDetails["contact_code"] + this.basicUserDetails["contact_number"]);
      if (
        !this.basicUserDetails.email ||
        !this.basicUserDetails.country ||
        !this.basicUserDetails["ind_bus_type"] ||
        this.basicUserDetails["ind_bus_type"] === "Business" ||
        (this.basicUserDetails["ind_bus_type"] === "Individual" && !this.kybCompleted) ||
        !(result && result.isValid()) ||
        !this.basicUserDetails.email_verified
      ) {
        return false;
      }
      return true;
    },
    isAddressDetailsValid() {
      if (this.kybCompleted) {
        return true;
      }
      // return false, if the Mandatory field are falsy.
      let address = this.addressDetails.address;
      if (!this.isValidString(address.addressLine1)) {
        return false;
      }
      if (!this.isValidString(address.postal)) {
        return false;
      }
      if (!this.addressDetails.description) {
        return false;
      }
      if (!this.isValidUrl(this.addressDetails.website)) {
        return false;
      }
      if (!this.isValidPan(this.addressDetails.pan) && this.basicUserDetails.country === "India") {
        return false;
      }
      if (!this.addressDetails.yearsInOperation) {
        return false;
      }
      if (!this.addressDetails.turnover) {
        return false;
      }
      if (
        !this.isValidMinLength(
          this.addressDetails.businessDescriptionDetail,
          this.businessDetailCompData["businessDescriptionDetail"].minlength
        )
      ) {
        return false;
      }
      if (this.basicUserDetails["ind_bus_type"] !== "Individual") {
        if (!this.isValidString(this.addressDetails.name)) {
          return false;
        }
      } else {
        if (!this.isValidString(this.addressDetails.firstName)) {
          return false;
        }
        if (!this.isValidString(this.addressDetails.lastName)) {
          return false;
        }
        if (!this.isValidString(this.addressDetails.nationality)) {
          return false;
        }
      }
      return true;
    },
    isDocumentDetailsValid() {
      let businessDocValid = true;
      if (this.basicUserDetails["ind_bus_type"] !== "Individual") {
        businessDocValid = this.isBusinessDocsValid;
      }
      let repDocValid = this.isRepresentativeDocsValid;
      let addRepValid = this.isAdditionalRepsValid;
      return businessDocValid && repDocValid && addRepValid && this.declaration;
    },
    isBusinessDocsValid() {
      let sholudContainAddressDoc = !this.businessDocs.same_operating_address;
      let businessProof = this.businessDocs.documents.some((item) => item.tag === "registrationProofDoc");
      let addressProof = sholudContainAddressDoc
        ? this.businessDocs.documents.some((item) => item.tag === "AddressProofDoc")
        : true;
      return businessProof && addressProof;
    },
    isRepresentativeDocsValid() {
      let sholudContainAddressDoc = !this.representativeDocs.same_operating_address;
      let identifyFront = this.representativeDocs.documents.some((doc) => doc.tag === "identityProofTypeFrontDoc");
      let identifyBack = this.representativeDocs.documents.some((doc) => doc.tag === "identityProofTypeBackDoc");
      let addressProof = sholudContainAddressDoc
        ? this.representativeDocs.documents.some((item) => item.tag === "AddressProofDoc")
        : true;
      return identifyFront && identifyBack && addressProof;
    },
    isAdditionalRepsValid() {
      let result = this.additionalReps.every((rep) => {
        let item = rep.documents || [];
        // Todo
        let sholudContainAddressDoc = !(rep.same_operating_address ?? true);
        // let sholudContainAddressDoc = false;

        // if (item.length === 0 && sholudContainAddressDoc) {
        //   return false;
        // } else if (item.length === 0 && !sholudContainAddressDoc) {
        //   return true;
        // }
        let identifyFront = item.some((doc) => doc.tag === "identityProofTypeFrontDoc");
        let identifyBack = item.some((doc) => doc.tag === "identityProofTypeBackDoc");
        let addressProof = sholudContainAddressDoc ? item.some((item) => item.tag === "AddressProofDoc") : true;
        return identifyFront && identifyBack && addressProof;
      });
      return result;
    },
    isSoleLegalRepValid() {
      // return false, if the Mandatory field are falsy.
      if (!this.soleLegalRepDetails.firstName || !this.soleLegalRepDetails.lastName) {
        return false;
      } else if (!this.soleLegalRepDetails.roles.length) {
        return false;
      } else if (!this.soleLegalRepDetails.nationality) {
        return false;
      }

      if (this.soleLegalRepDetails.ownership === false) {
        let solePropDetails = this.additionalReps[0];
        if (!solePropDetails.firstName || !solePropDetails.lastName) {
          return false;
        } else if (!this.isValidEmail(solePropDetails.email)) {
          return false;
        } else if (!solePropDetails.roles.length || !solePropDetails.nationality) {
          return false;
        }
      }
      return true;
    },
    isBusinessLegalRepValid() {
      // return false, if the Mandatory field are falsy.
      if (!this.businessLegalRep.firstName || !this.businessLegalRep.lastName || !this.businessLegalRep.nationality) {
        return false;
      } else if (!this.businessLegalRep.roles.length) {
        return false;
      }

      let result;
      if (!this.businessLegalRep.ownership) {
        const isBeneficialOwnerExists = this.additionalReps.filter((item) => {
          return item.roles.includes("Beneficial Owner");
        });
        result = !this.businessLegalRep.is_ownership_declared ? isBeneficialOwnerExists.length !== 0 : true;
      }

      // if anyone of rep is falsy. then result is falsy.
      this.additionalReps.forEach((item) => {
        if (!item.firstName || !item.lastName || !this.isValidEmail(item.email)) {
          result = false;
        } else if (!(item.roles && item.roles.length) || !item.nationality) {
          result = false;
        }
      });
      return result ?? true;
    },
    activeSectionLabel() {
      let activeItem = this.sideMenus.filter((menu) => menu.id === this.activeItemId);
      return activeItem[0] ? activeItem[0].label : "";
    },
    isCurrentFormValid() {
      if (this.activeItemId === "contact-info") {
        return this.isContactInfoValid;
      } else if (this.activeItemId === "personal-details" || this.activeItemId === "business-details") {
        return this.isAddressDetailsValid;
      } else if (this.activeItemId === "bank-details") {
        return this.bankDetails.isValid;
      } else if (this.activeItemId === "document-details") {
        return this.isDocumentDetailsValid;
      } else if (this.activeItemId === "sole-legal-representative") {
        return this.isSoleLegalRepValid;
      } else if (this.activeItemId === "legal-representative") {
        return this.isBusinessLegalRepValid;
      }
      return true;
    },
    isCurrentFormSaved() {
      if (this.activeItemId === "contact-info") {
        return this.basicUserDetails.isSaved;
      } else if (this.activeItemId === "personal-details" || this.activeItemId === "business-details") {
        return this.addressDetails.isSaved;
      } else if (this.activeItemId === "bank-details") {
        return this.bankDetails.isSaved;
      } else if (this.activeItemId === "document-details") {
        return this.documentDetails.isSaved;
      } else if (this.activeItemId === "sole-legal-representative") {
        return this.soleLegalRepDetails.isSaved;
      } else if (this.activeItemId === "legal-representative") {
        return this.businessLegalRep.isSaved;
      }
      return true;
    },
    getBusinessDocs() {
      return this.businessDocs.documents.filter(
        (item) => item.proof_type === "Business" || item.proof_type === "Address"
      );
    },
    getBusinessHistoryDocs() {
      return this.businessDocs.documents.filter((item) => item.proof_type === "Business History Doc");
    },
  },
  methods: {
    selectableOptions(options) {
      if (!this.soleLegalRepDetails["ownership"] || !this.businessLegalRep["ownership"]) {
        return !options.includes("Beneficial Owner");
      } else {
        return true;
      }
    },
    websiteValidation(model) {
      return regexUrlValidation(model);
    },
    hideKybModal() {
      if (document.getElementById("dialog-comp") != null) {
        document.getElementById("dialog-comp").classList.add("tw-hidden");
      }
    },
    changeSideMenuItem() {
      let completedItems = this.sideMenus.filter((item) => {
        return item.isCompleted;
      });
      let lastItem = completedItems[completedItems.length - 1];
      lastItem ? this.handleSidemenuSelect({ value: lastItem.id }) : "";
    },
    handleChange(e) {
      if (e.fieldName === "typeOfEntity") {
        this.basicUserDetails["ind_bus_type"] = e.value;
        this.contactInfoCompData["ind_bus_type"].errors = [];
        this.applicationType = e.value === "Individual" ? "Individual" : "Business";
        this.resetFields();
      } else if (e.fieldName === "registeredContact") {
        this.basicUserDetails["contact_number"] = e.value;
        this.basicUserDetails["contact_code"] = e.contactCode;
      }
      this.basicUserDetails.isSaved = false;
    },
    handleAddressDetailsChange(e) {
      // TODO handle name of individual
      if (e.fieldName === "name") {
        this.addressDetails["name"] = e.value;
      } else if (e.fieldName === "address") {
        this.addressDetails[e.fieldName][e.subFieldName] = e.value;
      } else if (e.fieldName === "website") {
        this.addressDetails["website"] = e.value;
      } else if (e.fieldName === "description") {
        this.addressDetails["description"] = e.value;
      } else if (e.fieldName === "firstName") {
        this.addressDetails["firstName"] = e.value;
      } else if (e.fieldName === "lastName") {
        this.addressDetails["lastName"] = e.value;
      } else if (e.fieldName === "nationality") {
        this.addressDetails["nationality"] = e.value;
      } else if (e.fieldName === "pan") {
        this.addressDetails["pan"] = e.value;
      } else if (e.fieldName === "yearsInOperation") {
        this.addressDetails["yearsInOperation"] = e.value;
        this.businessDetailCompData["yearsInOperation"].errors = [];
      } else if (e.fieldName === "turnover") {
        this.addressDetails["turnover"] = e.value;
        this.businessDetailCompData["turnover"].errors = [];
      } else if (e.fieldName === "businessDescriptionDetail") {
        this.addressDetails["businessDescriptionDetail"] = e.value;
      } else if (e.fieldName === "socialMedia") {
        this.addressDetails[e.fieldName][e.subFieldName] = e.value;
      }

      this.addressDetails.isSaved = false;
    },
    handleBankDetailsChange(record) {
      this.bankDetails.local = record.local;
      this.bankDetails.foreign = record.foreign;
      if (record.markDirty) this.bankDetails.isSaved = false;
      else this.bankDetails.isSaved = record?.local?.id?.length > 0 || record?.foreign?.id?.length > 0;
    },
    handleAdditionalDocsChange(e) {
      this.additionalDocs = e.value;

      this.documentDetails.isSaved = false;
    },
    handleDeclarationChange() {
      this.declaration = !this.declaration;
      this.declarationError = [];
    },
    handleBeneficialOwnerChange() {
      this.businessLegalRep.is_ownership_declared = !this.businessLegalRep.is_ownership_declared;
      this.beneficialOwnerCheckError = [];
    },
    handleRepFileChange(e, uniqueKey) {
      let rep = this.additionalReps.filter((item) => item.id === uniqueKey)[0];
      rep["same_operating_address"] = e.same_operating_address;
      rep["documents"] = e.documents;
      this.documentDetails.isSaved = false;
      // initialize representativeDocsErrors if not there;
      rep.representativeDocsErrors = rep.representativeDocsErrors || {};
      if (e.fieldName !== "AddressProofDoc" && e.fieldName !== "identityProofType") {
        rep.representativeDocsErrors[e.fieldName] = e.documents.some((item) => item.tag === e.fieldName)
          ? ""
          : DOCUMENT_REQUIRED_ERROR;
      }
      if (e.fieldName === "identityProofType") {
        rep["identityProofType"] = e.value;
        rep.representativeDocsErrors[e.fieldName] = e.value !== "" ? "" : DOCUMENT_REQUIRED_ERROR;
      }
      if (!e.same_operating_address && e.fieldName === "AddressProofDoc") {
        rep.representativeDocsErrors.respsAddressProofDoc = e.documents.some((item) => item.tag === "AddressProofDoc")
          ? ""
          : DOCUMENT_REQUIRED_ERROR;
      }
      if (e.same_operating_address) {
        rep.representativeDocsErrors.respsAddressProofDoc = "";
      }
      this.additionalReps = [...this.additionalReps];
    },
    handleOwnerFileChange(e) {
      this.representativeDocs["documents"] = e.documents;
      this.representativeDocs["same_operating_address"] = e.same_operating_address;
      this.documentDetails.isSaved = false;
      if (e.fieldName !== "AddressProofDoc" && e.fieldName !== "identityProofType") {
        this.documentDetailsErrors.representativeDocsErrors[e.fieldName] = e.documents.some(
          (item) => item.tag === e.fieldName
        )
          ? ""
          : DOCUMENT_REQUIRED_ERROR;
      }

      if (e.fieldName === "identityProofType") {
        this.representativeDocs.identityProofType = e.value;
        this.documentDetailsErrors.representativeDocsErrors[e.fieldName] =
          e.value !== "" ? [] : [DOCUMENT_REQUIRED_ERROR];
      }

      if (!e.same_operating_address && e.fieldName === "AddressProofDoc") {
        this.documentDetailsErrors.representativeDocsErrors.respsAddressProofDoc = e.documents.some(
          (item) => item.tag === "AddressProofDoc"
        )
          ? ""
          : DOCUMENT_REQUIRED_ERROR;
      }
      if (e.same_operating_address) {
        this.documentDetailsErrors.representativeDocsErrors.respsAddressProofDoc = "";
      }
    },
    handleBusinessFileChange(e) {
      this.businessDocs["documents"] = e.documents;
      this.businessDocs["same_operating_address"] = e.same_operating_address;
      this.documentDetails.isSaved = false;
      this.documentDetailsErrors.businessDocErrors[e.fieldName] = e.documents.some((item) => item.tag === e.fieldName)
        ? ""
        : DOCUMENT_REQUIRED_ERROR;
    },
    handleBusinessHistoryDocsChange(e) {
      this.businessHistoryDocs["documents"] = e.documents;
      this.documentDetails.isSaved = false;
    },
    fetchBasicDetails() {
      getAccountDetails().then(
        (res) => {
          if (res) {
            let data = res.data;
            useGlobalStore().setAccountInfo(data);
            // need to clean up
            updateQualityFlag();
            if (!data.country) {
              this.$router.push(`${ROUTE_SETTINGS.ACCOUNT_TYPE}?reference=social`);
            }

            this.basicUserDetails["email"] = data.has_team_associated ? data.owner_email : data.email;
            this.basicUserDetails["contact_code"] = data.has_team_associated
              ? data.owner_contact_code || ""
              : data.contact_code || "";
            this.basicUserDetails["contact_number"] = data.has_team_associated
              ? data.owner_contact_number || ""
              : data.contact_number || "";
            this.basicUserDetails["country"] = data.has_team_associated ? data.owner_country : data.country;
            this.basicUserDetails["ind_bus_type"] = data.has_team_associated
              ? data.owner_entity_type || data.owner_ind_bus_type
              : data.entity_type || data.ind_bus_type;
            this.basicUserDetails["name"] = data.has_team_associated ? data.owner_name : data.name;
            this.basicUserDetails["business_name"] = data.has_team_associated
              ? data.owner_business_name
              : data.business_name;
            this.basicUserDetails["email_verified"] = data.has_team_associated
              ? data.owner_email_verified
              : data.email_verified;
            this.basicUserDetails.firstName = data.has_team_associated ? data.owner_first_name : data.first_name;
            this.basicUserDetails.lastName = data.has_team_associated ? data.owner_last_name : data.last_name;
            this.addressDetails.firstName = data.has_team_associated ? data.owner_first_name : data.first_name;
            this.addressDetails.lastName = data.has_team_associated ? data.owner_last_name : data.last_name;
            this.accountId = data.has_team_associated ? data.owner_id : data.id;
            this.applicationType = data.has_team_associated ? data.owner_ind_bus_type : data.ind_bus_type;
            let isIndividual = this.basicUserDetails["ind_bus_type"] === "Individual";
            if (isIndividual) {
              this.addressDetails.firstName = data.has_team_associated ? data.owner_first_name : data.first_name;
              this.addressDetails.lastName = data.has_team_associated ? data.owner_last_name : data.last_name;
              this.addressDetails.nationality = this.basicUserDetails.country;
            } else {
              this.soleLegalRepDetails.firstName = data.has_team_associated ? data.owner_first_name : data.first_name;
              this.soleLegalRepDetails.lastName = data.has_team_associated ? data.owner_last_name : data.last_name;
              this.soleLegalRepDetails.nationality = data.has_team_associated ? data.owner_country : data.country;
              this.businessLegalRep.firstName = data.has_team_associated ? data.owner_first_name : data.first_name;
              this.businessLegalRep.lastName = data.has_team_associated ? data.owner_last_name : data.last_name;
              this.businessLegalRep.nationality = data.has_team_associated ? data.owner_country : data.country;
              this.addressDetails.name = this.basicUserDetails["business_name"];
            }

            this.fetchKYBStatus();
          }
        },
        (rej) => {
          if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
            clearStorage();
            this.$router.push("/signin");
          }
        }
      );
    },

    fetchBeneficialOwnerStatus() {
      let additionalRepsBeneficialOwner = [];
      additionalRepsBeneficialOwner = this.additionalReps.filter((item) => {
        return item.roles.includes("Beneficial Owner");
      });
      this.beneficialOwnerCheck = additionalRepsBeneficialOwner.length !== 0;
    },

    fetchKYBStatus(onlyFetch) {
      getKYBStatus({ account_id: this.accountId }).then(
        (res) => {
          this.applicationId = res.data.data.application_id;
          this.KYBStatus = res.data.data.state || "not_started";
          useGlobalStore().setKybStatus(this.KYBStatus,true);
          updateKYBApprovedStatus();
          if (this.applicationId && !onlyFetch) {
            getKYBData(this.applicationId).then(
              (res) => {
                this.showThankyou = this.activeItemId === "document-details";
                this.bindBusinessDetails(res.data.data);
                this.bindRepresentativeDetails(res.data.data);
                this.updateCompletedItems();
                this.updateSavedStatus();
                this.moveToNextPage();
                this.fetching = false;
              },
              (rej) => {
                if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
                  clearStorage();
                  this.$router.push("/signin");
                }
              }
            );
          }
        },
        (rej) => {
          if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
            clearStorage();
            this.$router.push("/signin");
          }
        }
      );
    },
    updateCompletedItems() {
      this.basicUserDetails.isSaved = this.isContactInfoValid;
      this.addressDetails.isSaved = this.isAddressDetailsValid;
      this.soleLegalRepDetails.isSaved = this.isSoleLegalRepValid;
      this.businessLegalRep.isSaved = this.isBusinessLegalRepValid;
      //this.bankDetails.isSaved = this.isBankDetailsValid;
      this.documentDetails.isSaved = this.isDocumentDetailsValid;
    },
    updateSavedStatus() {
      switch (this.activeItemId) {
        case "contact-info":
          this.basicUserDetails.isSaved = true;
          break;
        case "personal-details":
          this.addressDetails.isSaved = true;
          break;
        case "sole-legal-representative":
          this.soleLegalRepDetails.isSaved = true;
          break;
        case "legal-representative":
          this.businessLegalRep.isSaved = true;
          break;
        case "business-details":
          this.addressDetails.isSaved = true;
          break;
        case "bank-details":
          this.bankDetails.isSaved = true;
          break;
        case "document-details":
          this.documentDetails.isSaved = true;
          break;
        default:
          break;
      }
    },
    handleSidemenuSelect(e) {
      this.activeItemId = e.value;
    },
    handleSoleRepChanges(e) {
      if (e.fieldName === "soleLegalReprentativeFirstName") {
        this.soleLegalRepDetails.firstName = e.value;
      } else if (e.fieldName === "soleLegalReprentativeLastName") {
        this.soleLegalRepDetails.lastName = e.value;
      } else if (e.fieldName === "soleLegalReprentativeRole") {
        if (this.soleLegalRepDetails["ownership"]) {
          if (!e.value.includes("Beneficial Owner")) {
            e.value.push("Beneficial Owner");
          }
        }
        this.soleLegalRepDetails["roles"] = [...e.value];
        this.validateCurrentFromField(this.soleLegalReprentativeCompData, this.soleLegalRepDetails);
      } else if (e.fieldName === "soleLegalReprentativeOwnership") {
        this.soleLegalRepDetails["ownership"] = e.value;
        if (e.value) {
          this.additionalReps = [];
        } else {
          this.soleLegalRepDetails["roles"] = [];
          this.additionalReps = [
            {
              firstName: "",
              lastName: "",
              email: "",
              contactNumber: "",
              contactCode: this.basicUserDetails.contact_code,
              roles: ["Beneficial Owner"],
              nationality: "",
            },
          ];
        }
        let roles = this.soleLegalRepDetails["roles"];
        this.soleLegalRepDetails["roles"] = e.value && roles.length === 0 ? ["Beneficial Owner"] : roles;
        // const roles = this.soleLegalRepDetails["roles"];
        // if (e.value) {
        //   this.soleLegalRepDetails["roles"] = roles.length ? ["Beneficial Owner"] : roles;
        // } else {
        //   this.soleLegalRepDetails["roles"] = roles.length ? ["Authorized Representative"] : roles;
        // }
        this.validateCurrentFromField(this.soleLegalReprentativeCompData, this.soleLegalRepDetails);
      } else if (e.fieldName === "soleLegalReprentativeNationality") {
        this.soleLegalRepDetails.nationality = e.value;
      } else if (e.fieldName === "addtionalRepDetails") {
        this.additionalReps[0] ? "" : this.additionalReps.push({});
        this.additionalReps[0][e.subFieldName] = e.value;
        if (e.subFieldName === "contactNumber") {
          this.additionalReps[0]["contactCode"] = e.contactCode;
        } else if (e.subFieldName === "roles") {
          if (!this.soleLegalRepDetails["ownership"]) {
            if (!e.value.includes("Beneficial Owner")) {
              e.value.push("Beneficial Owner");
            }
          }
          this.additionalReps[0]["roles"] = [...e.value];
        }
        this.validateAdditionalRepsFields(this.additionalReps[0]);
      }
      this.soleLegalRepDetails.isSaved = false;
    },

    handleBusinessRepChange(e, uniqueKey) {
      if (e.fieldName === "legalReprentativeFirstName") {
        this.businessLegalRep.firstName = e.value;
      } else if (e.fieldName === "legalReprentativeLastName") {
        this.businessLegalRep.lastName = e.value;
      } else if (e.fieldName === "legalReprentativeRole") {
        if (this.businessLegalRep["ownership"]) {
          if (!e.value.includes("Beneficial Owner")) {
            e.value.push("Beneficial Owner");
          }
        }
        this.businessLegalRep["roles"] = [...e.value];
        this.validateCurrentFromField(this.businessLegalReprentativeCompData, this.businessLegalRep);
      } else if (e.fieldName === "legalReprentativeNationality") {
        this.businessLegalRep["nationality"] = e.value;
      } else if (e.fieldName === "legalReprentativeOwnership") {
        this.businessLegalRep["is_ownership_declared"] = false;
        this.businessLegalRep["ownership"] = e.value;
        let roles = this.businessLegalRep["roles"];
        if (e.value) {
          if (!roles.includes("Beneficial Owner")) {
            roles.push("Beneficial Owner");
          }
          this.businessLegalRep["roles"] = [...roles];
        } else {
          this.businessLegalRep["roles"] = [];
        }
        this.additionalReps = [
          {
            firstName: "",
            lastName: "",
            email: "",
            roles: ["Beneficial Owner"],
            nationality: "",
            contactNumber: "",
            contactCode: this.basicUserDetails.contact_code,
            showDelete: true,
          },
        ];
        this.fetchBeneficialOwnerStatus();
        this.validateCurrentFromField(this.businessLegalReprentativeCompData, this.businessLegalRep);
      } else if (e.fieldName === "legalRepList") {
        let rep = this.additionalReps.filter((item) => {
          return item.uniqueKey === uniqueKey;
        });
        rep[0][e.subFieldName] = e.value;
        if (e.subFieldName === "contactNumber") {
          rep[0]["contactCode"] = e.contactCode;
        } else if (e.subFieldName === "roles") {
          rep[0]["roles"] = e.value;
          if (rep[0]["roles"].includes("Beneficial Owner")) {
            this.beneficialOwnerCheck = true;
          } else {
            this.beneficialOwnerCheck = false;
          }
        }
        this.validateAdditionalRepsFields(rep[0]);
        this.additionalReps = [...this.additionalReps];
      }
      this.businessLegalRep.isSaved = false;
    },
    handleBusinessRepDelete(e, uniqueKey) {
      this.additionalReps = this.additionalReps.filter((item) => {
        return item.uniqueKey !== uniqueKey;
      });
      this.businessLegalRep.isSaved = false;
      if (!this.businessLegalRep.ownership) {
        this.fetchBeneficialOwnerStatus();
      }
    },
    validateCurrentFromField(compData, modelData) {
      let compDataValues = Object.values(compData);
      compDataValues.forEach((item) => {
        if (item.required && item.modelKey === "contact_number") {
          let result = parsePhoneNumber(item.contactCode + item.value);
          if (result && result.isValid()) {
            item.errors = [];
          } else if (item.contactCode === "" || item.value === "") {
            item.errors = [REQUIRED_ERROR];
          } else {
            item.errors = ["invalid number"];
          }
        } else {
          let modelValue = modelData[item.modelKey];
          if (modelValue === "" || (Array.isArray(modelValue) && modelValue.length === 0)) {
            item.errors = [REQUIRED_ERROR];
          } else {
            item.errors = [];
          }
        }
      });
    },
    validateAdditionalRepsFields(modelData) {
      modelData.errors = {};
      let compDataKeys = Object.keys(modelData);
      compDataKeys.forEach((item) => {
        let modelValue = modelData[item];
        if (modelValue === "" || (Array.isArray(modelValue) && modelValue.length === 0)) {
          modelData.errors[item] = [REQUIRED_ERROR];
        } else {
          modelData.errors[item] = [];
        }
      });
    },
    validateBusinessDetailFromField(compData, modelData) {
      let compDataValues = Object.values(compData);
      compDataValues.forEach((item) => {
        if (item.required && item.modelKey !== "address") {
          if (item.required && item.modelKey === "website") {
            let modelValue = modelData[item.modelKey];
            let validations = [];
            validations.push({ key: "required" });
            validations.push({ key: "urlValidation" });
            item.errors = this.formValidation.validateModel({ model: modelValue }, { validations, required: true });
          } else if (item.required && item.modelKey === "pan") {
            let modelValue = modelData[item.modelKey];
            let validations = [];
            validations.push({ key: "panValidation" });
            item.errors = this.formValidation.validateModel({ model: modelValue }, { validations, required: true });
          } else {
            let modelValue = modelData[item.modelKey];
            if (modelValue === "" || (Array.isArray(modelValue) && modelValue.length === 0)) {
              item.errors = [REQUIRED_ERROR];
            } else {
              item.errors = [];
            }
          }
        } else {
          item.errors = {};
          let compDataKeys = Object.keys(modelData.address);
          compDataKeys.forEach((keyName) => {
            let addressKeyValue = item[keyName];
            if (addressKeyValue === "") {
              item.errors[keyName] = [REQUIRED_ERROR];
            } else {
              item.errors[keyName] = [];
            }
          });
        }
      });
    },
    validateDocumentDetailsFromField() {
      const registrationProofDoc = this.businessDocs.documents.some((item) => item.tag === "registrationProofDoc")
        ? ""
        : DOCUMENT_REQUIRED_ERROR;
      const AddressProofDoc = this.businessDocs.documents.some((item) => item.tag === "AddressProofDoc")
        ? ""
        : DOCUMENT_REQUIRED_ERROR;

      let beneficialOwnershipDoc = "";
      if (this.businessLegalRep.is_ownership_declared) {
        beneficialOwnershipDoc = this.businessDocs.documents.some((item) => item.tag === "beneficialOwnershipDoc")
          ? ""
          : DOCUMENT_REQUIRED_ERROR;
      }

      this.documentDetailsErrors.businessDocErrors = {
        registrationProofDoc,
        AddressProofDoc,
        beneficialOwnershipDoc,
      };

      const identityProofType = this.representativeDocs.identityProofType !== "" ? [] : [DOCUMENT_REQUIRED_ERROR];

      const identityProofTypeFrontDoc = this.representativeDocs.documents.some(
        (item) => item.tag === "identityProofTypeFrontDoc"
      )
        ? ""
        : DOCUMENT_REQUIRED_ERROR;
      const identityProofTypeBackDoc = this.representativeDocs.documents.some(
        (item) => item.tag === "identityProofTypeBackDoc"
      )
        ? ""
        : DOCUMENT_REQUIRED_ERROR;
      let respsAddressProofDoc = "";
      if (!this.representativeDocs.same_operating_address) {
        respsAddressProofDoc = this.representativeDocs.documents.some((item) => item.tag === "AddressProofDoc")
          ? ""
          : DOCUMENT_REQUIRED_ERROR;
      }
      this.documentDetailsErrors.representativeDocsErrors = {
        identityProofType,
        identityProofTypeFrontDoc,
        identityProofTypeBackDoc,
        respsAddressProofDoc,
      };

      if (this.additionalReps.length) {
        let additionalRespsAddressProofDoc = "";
        this.additionalReps.forEach((item) => {
          if (item.documents === null) {
            if (!item.same_operating_address) {
              additionalRespsAddressProofDoc = DOCUMENT_REQUIRED_ERROR;
            }
            item.representativeDocsErrors = {
              identityProofType: [DOCUMENT_REQUIRED_ERROR],
              identityProofTypeFrontDoc: DOCUMENT_REQUIRED_ERROR,
              identityProofTypeBackDoc: DOCUMENT_REQUIRED_ERROR,
              respsAddressProofDoc: additionalRespsAddressProofDoc,
            };
          } else {
            const additionalRepsFrontDoc = item.documents.some((item) => item.tag === "identityProofTypeFrontDoc")
              ? ""
              : DOCUMENT_REQUIRED_ERROR;
            const additionalRepsBackDoc = item.documents.some((item) => item.tag === "identityProofTypeBackDoc")
              ? ""
              : DOCUMENT_REQUIRED_ERROR;

            const additionalIdentityProofType = item.identityProofType !== "" ? [] : [DOCUMENT_REQUIRED_ERROR];

            if (!item.same_operating_address) {
              additionalRespsAddressProofDoc = item.documents.some((item) => item.tag === "AddressProofDoc")
                ? ""
                : DOCUMENT_REQUIRED_ERROR;
            }
            item.representativeDocsErrors = {
              identityProofType: additionalIdentityProofType,
              identityProofTypeFrontDoc: additionalRepsFrontDoc,
              identityProofTypeBackDoc: additionalRepsBackDoc,
              respsAddressProofDoc: additionalRespsAddressProofDoc,
            };
          }
        });
      }
      this.declarationError = this.declaration ? "" : ["Please acknowledge the declaration and click submit"];
      if (this.declarationError){
        const errorElement = document.getElementsByClassName("declaration-checkbox");
        errorElement[0]?.scrollIntoView({ behavior: "smooth" });
      }
    },
    validateBankDetails(staySilent = false) {
      let { isValid, errorMsg } = this.$refs.bankDetailsTab?.validateData();
      if (isValid) this.bankDetails.isValid = true;
      else {
        this.bankDetails.isValid = false;
        if (!staySilent)
          useNotificationStore().add({
            message: errorMsg,
            type: "error",
          });
      }
    },
    validateMandatoryFormFields() {
      switch (this.activeItemId) {
        case "contact-info":
          this.validateCurrentFromField(this.contactInfoCompData, this.basicUserDetails);
          break;
        case "personal-details":
          this.validateCurrentFromField(this.businessDetailCompData, this.businessDetail);
          break;
        case "sole-legal-representative":
          this.validateCurrentFromField(this.soleLegalReprentativeCompData, this.soleLegalRepDetails);
          if (this.additionalReps.length) {
            this.additionalReps.forEach((item) => this.validateAdditionalRepsFields(item));
          }
          break;
        case "legal-representative":
          this.validateCurrentFromField(this.businessLegalReprentativeCompData, this.businessLegalRep);
          if (this.additionalReps.length) {
            this.additionalReps.forEach((item) => this.validateAdditionalRepsFields(item));
          }
          this.checkIfBeneficialOwnerExists();
          break;
        case "business-details":
          this.validateBusinessDetailFromField(this.businessDetailCompData, this.addressDetails);
          break;
        case "bank-details":
          this.validateBankDetails();
          break;
        case "document-details":
          this.validateDocumentDetailsFromField();
          break;
        default:
          break;
      }
    },
    checkIfBeneficialOwnerExists() {
      if (!this.businessLegalRep.ownership) {
        this.fetchBeneficialOwnerStatus();
        this.beneficialOwnerCheckError = this.businessLegalRep.is_ownership_declared
          ? []
          : ["Please acknowledge the declaration and click submit"];
        nextTick(() => {
          const errorElement = document.getElementsByClassName("beneficial-owner-error");
          errorElement[0]?.scrollIntoView({ behavior: "smooth" });
        });
      }
    },
    handleSaveAndNext() {
      if (this.activeItemId === "bank-details") this.validateBankDetails(true);

      if (!this.isCurrentFormValid) {
        this.validateMandatoryFormFields();
        nextTick(() => {
          const errorElement = document.getElementsByClassName("input-error-border");
          errorElement[0]?.scrollIntoView({ behavior: "smooth" });
        });
        return;
      } else if (this.isCurrentFormValid && this.isCurrentFormSaved) {
        this.moveToNextPage();
      } else if (this.isCurrentFormValid && !this.isCurrentFormSaved) {
        if (this.activeItemId === "bank-details") {
          this.fetching = true;
          this.$refs.bankDetailsTab
            ?.saveToServer()
            .then(() => {
              this.bankDetails.isSaved = true;
              this.moveToNextPage();
            })
            .catch((error) => {
              console.log("Failed to save", error);
            })
            .finally(() => (this.fetching = false));
        } else {
          this.prepareSave();
        }
      }
    },
    moveToNextPage() {
      this.sideMenus.every((item, index) => {
        if (this.activeItemId === item.id && this.sideMenus[index + 1]) {
          this.activeItemId = this.sideMenus[index + 1].id;
          return false;
        }
        return true;
      });
    },
    handleBack() {
      if (this.activeItemId === "document-details" && !this.kybCompleted) {
        this.declaration = false;
        this.documentDetails.isSaved = false;
      }
      if (this.activeItemId === "legal-representative" && !this.businessLegalRep.ownership) {
        this.fetchBeneficialOwnerStatus();
      }
      this.sideMenus.every((item, index) => {
        if (this.activeItemId === item.id) {
          this.activeItemId = this.sideMenus[index - 1].id;
          return false;
        }
        return true;
      });
    },
    addLegalRep(e) {
      this.additionalReps.push({ ...this.kybRepresentativeFields, uniqueKey: Date.now(), roles: ["Beneficial Owner"] });
      nextTick(() => {
        e.target.scrollIntoView({ behavior: "smooth" });
      });
      this.fetchBeneficialOwnerStatus();
    },
    isValidString(e) {
      let validations = [];
      validations.push({ key: "required" });
      let errorMsg = this.formValidation.validateModel({ model: e }, { validations, required: true });
      return errorMsg.length === 0;
    },
    isValidUrl(e) {
      let validations = [];
      validations.push({ key: "required" });
      validations.push({ key: "urlValidation" });
      let errorMsg = this.formValidation.validateModel({ model: e }, { validations, required: true });
      return errorMsg.length === 0;
    },
    isValidPan(e) {
      let validations = [];
      validations.push({ key: "required" });
      validations.push({ key: "panValidation" });
      let errorMsg = this.formValidation.validateModel({ model: e }, { validations, required: true });
      return errorMsg.length === 0;
    },
    isValidMinLength(e, minlength) {
      let validations = [];
      validations.push({ key: "required" });
      validations.push({ key: "minlength", value: minlength });
      let errorMsg = this.formValidation.validateModel({ model: e }, { validations, required: true });
      return errorMsg.length === 0;
    },
    isValidEmail(e) {
      let validations = [];
      validations.push({ key: "required" });
      validations.push({ key: "email" });
      let errorMsg = this.formValidation.validateModel({ model: e }, { validations, required: true });
      return errorMsg.length === 0;
    },
    isValidNumber(e) {
      let validations = [];
      validations.push({ key: "required" });
      validations.push({ key: "numberOnly" });
      let errorMsg = this.formValidation.validateModel({ model: e }, { validations, required: true });
      return errorMsg.length === 0;
    },
    resetFields() {
      let isIndividual = this.basicUserDetails["ind_bus_type"] === "Individual";
      this.addressDetails = {
        name: isIndividual ? this.basicUserDetails["name"] : this.basicUserDetails["business_name"],
        firstName: this.basicUserDetails.firstName,
        lastName: this.basicUserDetails.lastName,
        nationality: this.basicUserDetails.country,
        address: {
          addressLine1: "",
          addressLine2: "",
          state: "",
          city: "",
          postal: "",
        },
        description: "",
        website: "",
        isSaved: false,
        pan: "",
        yearsInOperation: "",
        turnover: "",
        businessDescriptionDetail: "",
        socialMedia: {
          facebookId: "",
          twitterId: "",
          linkedinId: "",
          instagramId: "",
        },
      };
      this.documentDetails = {
        isSaved: false,
      };
      this.soleLegalRepDetails = {
        firstName: this.basicUserDetails.firstName,
        lastName: this.basicUserDetails.lastName,
        roles: ["Beneficial Owner"],
        nationality: this.basicUserDetails.country,
        ownership: true,
        isSaved: false,
      };
      this.businessLegalRep = {
        name: isIndividual ? "" : this.basicUserDetails["name"],
        firstName: this.basicUserDetails.firstName,
        lastName: this.basicUserDetails.lastName,
        nationality: this.basicUserDetails.country,
        roles: ["Beneficial Owner"],
        ownership: true,
        isSaved: false,
      };
      this.errors = [];
      let isSoleProp = this.basicUserDetails["ind_bus_type"] === "Sole Proprietorship";
      if (isSoleProp) {
        this.additionalReps = [];
      } else {
        this.additionalReps = [
          {
            firstName: "",
            lastName: "",
            email: "",
            contactNumber: "",
            contactCode: this.basicUserDetails.contact_code,
            roles: ["Beneficial Owner"],
            nationality: "",
          },
        ];
      }

      this.representativeDocs = {
        documents: [],
        same_operating_address: true,
      };
      this.businessDocs = {
        documents: [],
        same_operating_address: false,
      };
      this.businessHistoryDocs = {
        documents: [],
      };
      this.additionalDocs = [];
    },

    addBusinessDetails(requestData) {
      if (this.basicUserDetails["ind_bus_type"] !== "Individual") {
        // let isSoleProp = this.basicUserDetails["ind_bus_type"] === "Sole Proprietorship";
        let business = {
          name: this.addressDetails.name,
          same_operating_address: this.businessDocs.same_operating_address,
          address_line_1: this.addressDetails.address.addressLine1,
          address_line_2: this.addressDetails.address.addressLine2, // missing
          city: this.addressDetails.address.city,
          state: this.addressDetails.address.state,
          country: this.basicUserDetails.country,
          website: this.addressDetails.website,
          description: this.addressDetails.description,
          zip_code: this.addressDetails.address.postal,
          documents: [...this.businessDocs.documents, ...this.businessHistoryDocs.documents],
          social_media_links: {
            facebook: this.addressDetails.socialMedia.facebookId,
            instagram: this.addressDetails.socialMedia.instagramId,
            linkedin: this.addressDetails.socialMedia.linkedinId,
            twitter: this.addressDetails.socialMedia.twitterId,
          },
          pan_detail: this.addressDetails.pan,
          years_in_business: this.addressDetails.yearsInOperation,
          annual_turnover: this.addressDetails.turnover,
          business_description_detail: this.addressDetails.businessDescriptionDetail,
        };
        if (this.businessId) {
          business.id = this.businessId;
        }
        requestData["business"] = business;
      }
    },
    getRepresentativeDocs() {
      let additionDocs = [];
      this.additionalDocs.forEach((item) => {
        let docData = {
          proof_type: "Additional Doc",
          type: "Other",
          url: item.document_url,
          file_name: item.file_name,
          description: item.docName,
          tag: "additionalDocs",
        };
        if (item.id) {
          docData.id = item.id;
        }
        additionDocs.push(docData);
      });
      return [...this.representativeDocs.documents, ...additionDocs];
    },
    addRepresentativeDetails(requestData) {
      let repDetails = {};
      let isIndividual = this.basicUserDetails["ind_bus_type"] === "Individual";
      let isSoleProp = this.basicUserDetails["ind_bus_type"] === "Sole Proprietorship";
      // let isBusiness = !isSoleProp && !isIndividual;
      if (isIndividual) {
        repDetails = this.addressDetails;
      } else if (isSoleProp) {
        repDetails = this.soleLegalRepDetails;
      } else {
        repDetails = this.businessLegalRep;
      }
      let representative = {
        first_name: repDetails.firstName || this.basicUserDetails.firstName,
        last_name: repDetails.lastName || this.basicUserDetails.lastName,
        type: "Representative",
        role: "Other",
        roles: repDetails?.roles || [],
        nationality: repDetails?.nationality || "",
        address_line_1: isIndividual ? this.addressDetails.address.addressLine1 : "",
        address_line_2: isIndividual ? this.addressDetails.address.addressLine2 : "",
        city: isIndividual ? this.addressDetails.address.city : "",
        state: isIndividual ? this.addressDetails.address.state : "",
        country: this.basicUserDetails["country"],
        contact_code: this.basicUserDetails["contact_code"] || "",
        zip_code: isIndividual ? this.addressDetails.address.postal : "",
        mobile_number: this.basicUserDetails["contact_number"] || "",
        email: this.basicUserDetails["email"] || "",
        same_operating_address: this.representativeDocs.same_operating_address,
        website: this.addressDetails.website || "",
        ownership_status: repDetails?.ownership ?? false,
        documents: this.getRepresentativeDocs(),
        description: this.addressDetails.description,
        is_ownership_declared:
          !isSoleProp && !this.beneficialOwnerCheck ? this.businessLegalRep.is_ownership_declared : false,
      };
      if (this.representativeId) {
        representative.id = this.representativeId;
      }
      requestData["representative"] = representative;
    },
    addAdditionalReps(requestData) {
      let additionalReps = [];
      this.additionalReps.forEach((item) => {
        additionalReps.push({
          first_name: item.firstName,
          last_name: item.lastName,
          type: "Owner",
          roles: item.roles,
          email: item.email,
          mobile_number: item.contactNumber,
          id: item.id,
          contact_code: item.contactCode,
          nationality: item.nationality,
          documents: item.documents,
          same_operating_address: item.same_operating_address,
        });
      });

      requestData["owner"] = additionalReps;
    },
    bindBusinessDocs(businessDocs) {
      this.businessDocs.documents = businessDocs;
    },
    bindBusinessDetails(responce) {
      let business = responce.business;
      if (!business) {
        return;
      }
      // if (responce["entity_name"] === "Sole Pr@oprietorship") {
      //   this.soleLegalRepDetails.name == business.name;
      // } else {
      //   this.businessLegalRep.name = business.name;
      // }
      this.businessId = business.id;
      this.addressDetails.name = business.name;
      this.basicUserDetails["ind_bus_type"] = responce["entity_name"];
      this.applicationType = responce["application_type"];
      this.addressDetails.address.addressLine1 = business.address_line_1;
      this.addressDetails.address.addressLine2 = business.address_line_2;
      this.addressDetails.address.city = business.city;
      this.addressDetails.address.state = business.state;
      this.addressDetails.address.postal = business.zip_code;
      this.addressDetails.pan = business.pan_detail;
      this.addressDetails.socialMedia.facebookId = business?.social_media_links?.facebook;
      this.addressDetails.socialMedia.instagramId = business?.social_media_links?.instagram;
      this.addressDetails.socialMedia.linkedinId = business?.social_media_links?.linkedin;
      this.addressDetails.socialMedia.twitterId = business?.social_media_links?.twitter;
      this.addressDetails.yearsInOperation = business.years_in_business;
      this.addressDetails.turnover = business.annual_turnover;
      this.addressDetails.businessDescriptionDetail = business.business_description_detail;

      this.businessDocs.same_operating_address = business.same_operating_address;

      this.basicUserDetails.country = business.country;
      this.addressDetails.website = business.website;
      this.addressDetails.description = business.description;
      this.bindBusinessDocs(business.documents || []);
    },
    bindRepresentativeDocs(repDocs = []) {
      let representativeDocs = [];
      let additionalDocs = [];
      repDocs.forEach((item) => {
        if (
          item.tag === "identityProofTypeFrontDoc" ||
          item.tag === "identityProofTypeBackDoc" ||
          item.tag === "AddressProofDoc"
        ) {
          representativeDocs.push(item);
          this.representativeDocs.identityProofType = item.type || "";
        } else if (item.tag === "additionalDocs") {
          let fileInfo = {
            file_name: item.file_name,
            document_url: item.url,
            id: item.id,
            docName: item.description,
          };
          additionalDocs.push(fileInfo);
        }
      });
      this.representativeDocs.documents = representativeDocs;
      this.additionalDocs = additionalDocs;
    },
    bindAdditionalReps(responce) {
      let additionalReps = responce.owner || [];
      let newReps = [];

      additionalReps.forEach((item, index) => {
        let result = {
          firstName: item.first_name,
          lastName: item.last_name,
          email: item.email,
          contactNumber: item.mobile_number,
          id: item.id,
          contactCode: item.contact_code,
          roles: item.roles,
          nationality: item.nationality,
          uniqueKey: index,
          documents: item.documents,
          same_operating_address: item.same_operating_address,
          identityProofType: item.documents?.[0]?.type || "",
        };
        newReps.push(result);
      });

      this.additionalReps = newReps;
    },

    bindRepresentativeDetails(responce) {
      let representative = responce.representative;
      if (!representative) {
        this.showContactOps = true;
        return;
      }
      let isSoleRep = responce["entity_name"] === "Sole Proprietorship";
      let isIndividual = responce["entity_name"] === "Individual";

      if (isIndividual) {
        this.addressDetails.firstName = representative.first_name;
        this.addressDetails.lastName = representative.last_name;

        this.addressDetails.address.addressLine1 = representative.address_line_1;
        this.addressDetails.address.addressLine2 = representative.address_line_2;
        this.addressDetails.address.city = representative.city;
        this.addressDetails.address.state = representative.state;
        this.addressDetails.address.country = representative.country;
        this.addressDetails.address.postal = representative.zip_code;
        this.addressDetails.website = representative.website;
        this.addressDetails.description = representative.description;
        this.addressDetails.nationality = representative.nationality;

        this.representativeDocs.same_operating_address = representative.same_operating_address;
      } else if (isSoleRep) {
        this.soleLegalRepDetails.firstName = representative.first_name;
        this.soleLegalRepDetails.lastName = representative.last_name;
        if (representative.ownership_status && !representative.roles.length) {
          this.soleLegalRepDetails.roles = ["Beneficial Owner"];
          this.soleLegalRepDetails.isSaved = false;
        } else {
          this.soleLegalRepDetails.roles = representative.roles;
        }
        this.soleLegalRepDetails.nationality = representative.nationality;
        this.soleLegalRepDetails.ownership = representative.ownership_status;
      } else {
        this.businessLegalRep.firstName = representative.first_name;
        this.businessLegalRep.lastName = representative.last_name;
        if (representative.ownership_status && !representative.roles.length) {
          this.businessLegalRep.roles = ["Beneficial Owner"];
          this.businessLegalRep.isSaved = false;
        } else {
          this.businessLegalRep.roles = representative.roles;
        }
        this.businessLegalRep.nationality = representative.nationality;

        this.businessLegalRep.ownership = representative.ownership_status;
        this.businessLegalRep.is_ownership_declared = representative.is_ownership_declared;
        if (!this.businessLegalRep.ownership) {
          this.fetchBeneficialOwnerStatus();
        }
      }
      this.representativeId = representative.id;

      this.basicUserDetails["contact_number"] = representative.mobile_number || "";
      this.basicUserDetails["contact_code"] = representative.contact_code || "";

      this.basicUserDetails["email"] = representative.email;
      this.basicUserDetails["ind_bus_type"] = responce["entity_name"];
      this.applicationType = responce["application_type"];
      this.representativeDocs.same_operating_address = representative.same_operating_address;
      this.bindRepresentativeDocs(representative.documents || []);
      this.bindAdditionalReps(responce);
    },
    prepareSave() {
      let requestData = {};
      requestData["account_id"] = this.accountId;
      requestData["application_type"] = this.applicationType;
      requestData["entity_name"] = this.basicUserDetails["ind_bus_type"];
      requestData["submit"] = this.activeItemId === "document-details";

      this.addBusinessDetails(requestData);
      this.addRepresentativeDetails(requestData);
      this.addAdditionalReps(requestData);

      if (this.applicationId && !this.fetching) {
        this.fetching = true;
        updateKYB(this.applicationId, requestData).then(
          () => {
            // this.showThankyou = this.activeItemId === "document-details";
            // if (this.showThankyou) {
            //   // update KYB data from server. for uploaded document urls
            //   this.fetchKYBStatus(false);
            // } else if (
            //   this.activeItemId === "sole-legal-representative" ||
            //   this.activeItemId === "legal-representative"
            // ) {
            //   // update KYB data from server. to update rep unique ids.
            //   this.fetchKYBStatus(false);
            // }
            this.fetchKYBStatus(false);
            // this.updateSavedStatus();
            // this.moveToNextPage();
          },
          (rej) => {
            if (
              rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT) ||
              rej?.message?.includes(ERROR_TYPE.INVALID_AUTH_TOKEN.MESSAGE_SHORT)
            ) {
              clearStorage();
              this.$router.push("/signin");
            }
            // error message will appear only when business name already exists
            else if (this.activeItemId === "business-details" && rej?.errors) {
              this.businessDetailCompData.name.errors.push(rej?.errors[0]?.message);
              this.fetching = false;
            }
          }
        );
      } else {
        createKYB(requestData).then(
          (res) => {
            if (res?.status === 200 && res?.data?.status === "success") {
              this.applicationId = res.data.data.application_id;
              getKYBData(this.applicationId)
                .then((res) => {
                  this.bindBusinessDetails(res.data.data);
                  this.bindRepresentativeDetails(res.data.data);
                })
                .catch((err) => {
                  console.error(err);
                });
              this.updateSavedStatus();
              this.moveToNextPage();
            }
          },
          (rej) => {
            if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
              clearStorage();
              this.$router.push("/signin");
            }
          }
        );
      }
    },
    observerDialog() {
      const observer = new MutationObserver((mutationsList) => {
        if (mutationsList[0].attributeName === "class" && !this.kybCompleted) {
          this.changeSideMenuItem();
        }
      });
      const targetNode = this.$el;
      const config = { attributes: true };
      observer.observe(targetNode, config);
    },
  },
  mounted() {
    // document.getElementById("dialog-comp").showModal();
    this.fetchBasicDetails();
    this.observerDialog();
  },
};
</script>

<style lang="scss" scoped>
#dialog-comp {
  @apply tw-z-79000;
  background: rgba(39, 48, 63, 0.5);
}
.kyb-dialog {
  margin: auto;
  max-width: min-content;
  background-color: white;
  width: min-content;
  min-width: 340px;
  height: calc(100% - 40px);
  width: 100%;
  max-width: calc(100% - 40px);
}
.thankyou-banner,
.contact-ops-banner {
  margin: auto;
  max-width: min-content;
  background-color: white;

  min-width: 340px;
  height: calc(100% - 40px);
}
.legal-representative.component-view-mode
// .address-details.component-view-mode
{
  border: 1px solid #f1f5f9;
  border-radius: 4px;
}

.contact-info-input.component-view-mode {
  border: 1px solid #f1f5f9;
  border-radius: 4px;
}

.show-border > div {
  border-bottom: 1px solid #f1f5f9;
  margin-left: 16px;
  margin-right: 16px;
}
.input-holder.declaration-checkbox {
  margin-bottom: 8px;
}
:deep.input-holder.declaration-checkbox .check-box-container {
  display: inline-flex;
}
:deep.input-holder.declaration-checkbox .input-checkbox {
  min-width: 25px;
  max-width: 25px;
}

// .contact-info-input.component-view-mode:deep .value-label,
// .address-details.component-view-mode:deep .value-label {
//   height: auto;
//   line-height: 1;
// }

// @media screen and (max-width: 600px) {
//   .legal-representative.component-view-mode:deep .kyb-representative-detail .value-label,
//   .sole-legal-representative.component-view-mode:deep .kyb-representative-detail .value-label {
//     height: auto;
//     line-height: 1;
//     padding-bottom: 32px;
//   }

//   .sole-legal-representative.component-view-mode:deep .value-label,
//   .legal-representative.component-view-mode:deep .value-label {
//     height: auto;
//     line-height: 1;
//     padding-bottom: 16px;
//   }
// }

@media screen and (min-width: 600px) {
  .thankyou-banner,
  .contact-ops-banner {
    width: 608px;
  }
}
@media screen and (min-width: 1000px) {
  .kyb-dialog {
    height: calc(100% - 80px);
    max-width: 1024px;
    width: 1024px;
  }
  .thankyou-banner,
  .contact-ops-banner {
    height: calc(100% - 80px);
    max-width: 1024px;
    width: 1024px;
  }
}

dialog::backdrop {
  background: rgba(39, 48, 63, 0.5);
}
.dialog-header {
  grid-area: header;
  box-shadow: inset 0px -1px 1px #cfd8e3;
}
.dialog-sidemenu {
  grid-area: sidemenu;
  max-width: 300px;
  min-width: 300px;
}
.dialog-body {
  grid-area: body;
}
.dialog-footer {
  grid-area: footer;
  max-height: 80px;
}
</style>

<template>
  <div class="google-signup-wrapper">
    <div class="google-signup-btn tw-text-center tw-cursor-pointer" @click="singInViaGoogle">
      <div class="google-icon">
        <img src="@/assets/images/googleicon.svg" />
      </div>
      {{buttonLabel}}
    </div>
    <div class="or-bar text-center"><span>or</span></div>
    <ConfirmDialog 
      v-if="showConfirmationDialog"
      :showCancelButton="false"
      @modalOnSave="initiateGoogleAuth">
      Please sign up with the email id used in the escrow request.
    </ConfirmDialog>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import {ConfirmDialog} from "tazapay-ui";

export default {
  name: 'GoogleAuthentication',
  components: { ConfirmDialog },
  props: {
    buttonLabel: { default: 'Sign up with Google'},
    pageReference: { default: 'signup' },
    enableConfirmation: { default: false }
  },
  data: () => ({
    showConfirmationDialog: false
  }),
  methods: {
    singInViaGoogle() {
      if (this.enableConfirmation) {
        this.showConfirmationDialog = true;
      } else {
        this.initiateGoogleAuth();
      }
    },
    initiateGoogleAuth() {
      Auth.federatedSignIn({provider: 'Google'});
    }
  },
  mounted() {
    window.localStorage.setItem("auth-previous-page", `/${this.pageReference}`);
  }
}
</script>
<template>
  <div class="tp-tab">
    <template v-for="item in tabItems" :key="item.label">
      <div
        @click="setTabActive(item)"
        class="tp-tab__item"
        :class="{ 'tp-tab__item--active': item.activate === tabActivate }"
      >
        {{ item.label }}
      </div>
    </template>
  </div>
</template>

<script setup>
/* eslint-disable */
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  tabItems: Array,
  tabActivate: String,
});
const emit = defineEmits(["sendTabActive"]);

function setTabActive(val) {
  emit("sendTabActive", val);
}
</script>

<style lang="scss" scoped>
.tp-tab {
  // box-shadow: 0px 1px 2px rgba(226, 232, 240, 0.24), 0px 1px 3px rgba(226, 232, 240, 0.5);
  @apply tw-flex tw-items-center;

  &__ {
    &item {
      @apply tw-relative tw-flex tw-cursor-pointer tw-items-center tw-px-6 tw-text-sm tw-text-neutral-500 hover:tw-text-primary-700; // tw-border-0 tw-border-r tw-border-neutral-200 tw-border-solid;

      &:first-child {
        padding-left: 0;
      }

      &:after {
        content: "";
        height: 14px;
        max-height: 14px;
        width: 1px;
        background-color: $neutral-200;
        display: block;
        position: absolute;
        right: 0;
      }

      &--active {
        @apply tw-pointer-events-none tw-font-semibold tw-text-primary-800;
      }
    }
  }
}
</style>

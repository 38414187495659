<template>
  <div class="tp-setup">
    <div class="tp-setup__title">Set up Guide</div>

    <!-- <div class="tp-setup__status">
      <div class="tp-status__progress">{{ cardNo - 1 }} of 3 tasks completed</div>
      <div class="tp-status__bar">
        <div class="tp-status__pending">
          <div
            class="tp-status__completed"
            :class="
              step == 'kyb'
                ? 'tw-w-2 tw-rounded-full tw-bg-primary-900'
                : step == 'configuration'
                ? 'tw-w-1/3 tw-rounded tw-bg-success-700'
                : step == 'transaction'
                ? 'tw-w-2/3 tw-rounded tw-bg-success-700'
                : ''
            "
          ></div>
        </div>
      </div>
    </div> -->

    <div class="tp-steps">
      <!-- :class="step == 'kyb' ? 'tw-pt-4' : step == 'configuration' ? 'tw-py-0' : step == 'transaction' ? 'tw-pb-4' : ''" -->
      <!-- step 1 -->
      <div v-if="step != 'emailVerify'" class="tp-steps__item">
        <div class="tp-steps__title">Verify your email</div>
        <img v-if="['kyb', 'transaction'].includes(step)" src="@/assets/images/completed-green-tick.svg" />
      </div>
      <div v-if="step == 'emailVerify'" class="tp-steps__item tp-steps__item--open">
        <div class="tp-steps__content">
          <div class="tp-steps__heading">Verify your email</div>
          <div class="tp-steps__desc">Verify your email to get started with KYB process</div>
        </div>
        <button class="tp-steps__action" @click="sendVerficationEmail">{{ emailCTA }}</button>
      </div>
      <!-- step 2 -->
        <div v-if="step != 'kyb' && !sandboxEnv" class="tp-steps__item">
          <!-- :style="step == 'transaction' ? 'border-bottom: 1px solid #E2E8F0' : ''" -->
          <div class="tp-steps__title">KYB Verification</div>
          <img v-if="step == 'transaction'" src="@/assets/images/completed-green-tick.svg" />
        </div>

        <div v-if="step == 'kyb' && !sandboxEnv" class="tp-steps__item tp-steps__item--open">
          <div class="tp-steps__content">
            <div class="tw-flex tw-items-center tw-gap-2">
              <div class="tp-steps__heading">KYB Verification</div>
              <div v-if="underReviewStatus" class="tp-steps__status">Under review</div>
            </div>
            <div class="tp-steps__desc">{{ kybDescription }}</div>
          </div>
          <button v-if="kybStatus == 'not_started'" class="tp-steps__action" @click="loadKybModal">Complete Now</button>
        </div>

      <!-- step 2 -->
      <!-- <div
        v-if="step != 'configuration'"
        class="tp-steps__item"
        >
        <div class="tp-steps__title">Set up platform configuration</div>
        <img v-if="step == 'transaction'" src="@/assets/images/completed-green-tick.svg" />
      </div>

      <div v-if="step == 'configuration'" class="tp-steps__item tp-steps__item--open">
        <div class="tp-steps__content">
          <div class="tp-steps__heading">Set up platform configuration</div>
          <div class="tp-steps__desc">
            Manage how your payment links look during checkout. Add your logo, platform name, and other display
            settings.
          </div>
        </div>
        <div class="tp-steps__buttons">
          <button class="tp-steps__action" @click="openConfigurationPage()">Configure Now</button>
          <button class="tp-steps__skip" @click="skipConfigSetup()">Skip</button>
        </div>
      </div> -->

      <!-- step 3 -->
      <div v-if="step != 'transaction'" class="tp-steps__item">
        <div class="tp-steps__title">Create your first transaction</div>
      </div>
      <div v-if="step == 'transaction'" class="tp-steps__item tp-steps__item--open">
        <div class="tp-steps__content">
          <div class="tp-steps__heading">Create your first transaction</div>
          <div class="tp-steps__desc">
            Use our no-code payment solution or integrate with our solution and create your first transaction.
          </div>
        </div>
        <button class="tp-steps__action" @click="createTransaction()">Create Transaction</button>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useGlobalStore } from "@/stores/GlobalStore";
import { VerifyEmail } from "@/api/legacyapi/user";
import { getLocalStorage } from "@/helpers/storageService";

// import { setCookie, getCookie } from "@/helpers/storageService";

const router = useRouter();

let sandboxEnv = ref(import.meta.env.VITE_ENV_NAME === "sandbox");
let emailCTA = ref("Send Verification");

// let closePlatformConfigStep = ref(false);

let underReviewStatus = computed(() => {
  return (
    ["pending", "initiated", "rejected"].includes(kybStatus.value) ||
    (kybStatus.value == "completed" && qualityFlag.value == "FDD Not Completed")
  );
});

let kybDescription = computed(() => {
  if (kybStatus.value == "not_started") {
    return "Completing your KYB is required before you can accept payments, we need to verify your identity.";
  } else {
    return "Your KYB verification is underway. You will be notified via email once it is approved.";
  }
});

let kybStatus = computed(() => {
  return useGlobalStore().getKybStatus || "not_started";
});

let qualityFlag = computed(() => {
  return useGlobalStore().qualityFlag;
});

let step = computed(() => {
  let showKybStep = ["not_started", "initiated", "pending", "rejected"];
  // if (
  //   showKybStep.includes(kybStatus.value) ||
  //   (kybStatus.value == "completed" && qualityFlag.value == "FDD Not Completed")
  // ) {
  //   return "kyb";
  // } else if (kybStatus.value == "completed") {
  //   if (closePlatformConfigStep.value) {
  //     return "transaction";
  //   } else {
  //     return "configuration";
  //   }
  // }

  if (useGlobalStore().accountInfo.email_verified) {
    
    if (sandboxEnv.value) {
      return "transaction";
    } 
    
    if (
      showKybStep.includes(kybStatus.value) ||
      (kybStatus.value == "completed" && qualityFlag.value == "FDD Not Completed")
    ) {
      return "kyb";
    } else {
      return "transaction";
    }
    
  } else {
    return "emailVerify";
  }
});

function loadKybModal() {
  if (document.getElementById("dialog-comp") != null) {
    document.getElementById("dialog-comp").classList.remove("tw-hidden");
  }
}

function sendVerficationEmail() {
  emailCTA.value = "Processing ...";
  let mailId = getLocalStorage("userEmail");
  VerifyEmail(mailId).then(
    () => {
      emailCTA.value = "Re-send Verfication";
    },
    (err) => {
      console.log(err);
    }
  );
}

// function openConfigurationPage() {
//   skipConfigSetup();
//   router.push(`/marketplace/platform-config?from=setup`);
// }

function createTransaction() {
  router.push(`/marketplace/create-payment-link`);
}

// function skipConfigSetup() {
//   closePlatformConfigStep.value = true;
//   setCookie("platformConfig", "yes", 5);
// }

// function checkPlatformConfigStatus() {
//   let isCookieAvailable = getCookie("platformConfig");
//   if (isCookieAvailable) {
//     closePlatformConfigStep.value = true;
//   }
// }

onMounted(() => {
  // checkPlatformConfigStatus();
});
</script>

<style lang="scss" scoped>
.tp-setup {
  @apply tw-rounded-lg tw-bg-white tw-px-6 tw-pt-6 tw-pb-8 tw-shadow-1;
  &__ {
    &title {
      @apply tw-mb-6 tw-text-xl tw-font-semibold tw-leading-sm tw-text-primary-800;
    }
    // &status {
    //   @apply tw-mb-6 tw-flex tw-items-center tw-gap-2;
    // }
  }
}

// .tp-status {
//   &__ {
//     &progress {
//       @apply tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-sm tw-text-neutral-800;
//     }
//     &bar {
//       @apply tw-flex tw-w-full tw-items-center;
//     }
//     &pending {
//       @apply tw-relative tw-h-2 tw-w-full tw-rounded tw-bg-neutral-200;
//     }
//     &completed {
//       @apply tw-absolute tw-h-2;
//     }
//   }
// }

.tp-steps {
  @apply tw-rounded-lg tw-border tw-border-solid tw-border-neutral-200 tw-py-4;
  &__ {
    &item {
      @apply tw-relative tw-flex tw-justify-between tw-gap-3 tw-border tw-border-solid tw-border-transparent tw-bg-white tw-px-6;

      &:not(:first-child) {
        @apply tw-pt-4;
      }
      &:not(:last-child) {
        @apply tw-pb-4;
        border-bottom-color: $neutral-200;
      }
      &--open {
        @apply tw--mx-3 tw-rounded-lg tw-border-neutral-200 tw-pl-9 tw-pr-6 sm:tw-flex-row sm:tw-items-center tw-flex-col;
        margin-top: -1px;
        padding-block: 16px !important;
        &:before {
          content: "";
          width: 12px;
          position: absolute;
          inset: 0 auto 0 0;
          background: linear-gradient(179.83deg, #0b3747 9.97%, #bbf6e2 120.8%);
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
        }

        @include bp(lg) {
          min-height: 148px;
        }
      }
    }
    &title {
      @apply tw-text-base tw-font-semibold tw-leading-lg tw-text-neutral-400;
    }
    &content {
      @apply tw-flex tw-max-w-md tw-flex-col tw-gap-3;
    }
    &heading {
      @apply tw-text-xl tw-font-semibold tw-leading-sm tw-text-primary-800;
    }
    &status {
      @apply tw-rounded tw-bg-warn-200 tw-px-2 tw-py-1 tw-text-xs tw-font-normal tw-leading-lg tw-text-warn-500;
    }
    &desc {
      @apply tw-text-sm tw-font-normal tw-leading-lg tw-text-neutral-600;
    }
    &buttons {
      @apply tw-flex tw-flex-col tw-items-end tw-gap-3;
    }
    &action {
      @apply tw-whitespace-nowrap tw-rounded tw-bg-primary-800 tw-py-4 tw-px-6 tw-text-sm tw-font-semibold tw-leading-lg tw-text-white;
    }
    &skip {
      @apply tw-py-2 tw-px-4 tw-text-sm tw-font-semibold tw-leading-lg tw-text-primary-700;
    }
  }
}
</style>

<template>
  <div class="tw-flex tw-flex-col tw-gap-3 tw-text-center">
    <img class="tw-px-28 tw-pt-24" src="@/assets/images/done-tick.svg" alt="submitted" />
    <div class="tw-px-9 tw-text-2xl tw-font-semibold tw-text-primary-700">Thanks for reaching out</div>
    <div class="tw-px-14 tw-text-base tw-font-normal tw-text-black">
      Someone from our team will connect with you soon
    </div>
    <div class="tw-px-28 tw-py-16 tw-text-base tw-font-normal tw-text-black">The Tazapay team</div>
  </div>
</template>
<script>
export default {
  name: "ThanksSection",
};
</script>

import { SOCIAL_SIGNUP } from "@/constants/route.js";

export const OAuthRoutes = [
  {
    path: "/oauth",
    name: "oauth",
    meta: { title: "OAUTH", layout: "BlankLayout" },
    component: () => import("@/views/oauth/Oauth.vue"),
  },
  {
    path: "/oauthsignout",
    name: "oauthsignout",
    meta: { title: "Signout", layout: "BlankLayout" },
    component: () => import("@/views/oauth/OauthSignout.vue"),
  },
  {
    path: `/${SOCIAL_SIGNUP.SOCIAL_SIGNUP_ERROR}`,
    name: "ssoerror",
    meta: { title: "SSO Error", layout: "BlankLayout" },
    component: () => import("@/views/oauth/SSOError.vue"),
  },
];

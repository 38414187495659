<template>
  <div
    class="kyb-representative-files tw-border tw-border-solid tw-border-neutral-100 tw-shadow-2"
    :class="{ ' tw-mb-4': viewMode }"
  >
    <div
      class="tw-flex tw-items-center tw-justify-between tw-bg-neutral-150 tw-p-4 tw-text-sm tw-font-bold tw-leading-sm tw-text-primary-700"
    >
      <label class="tw-uppercase">{{ heading }}</label>
      <label
        v-if="showDelete && !viewMode"
        class="tw-borer-solid tw-cursor-pointer tw-rounded tw-border tw-border-neutral-300 tw-bg-white tw-p-2 tw-shadow-2"
        @click="handleDelete"
      >
        <tc-icon icon="trash-can"></tc-icon>
        <span class="tw-ml-2 tw-hidden sm:tw-inline">Delete</span>
      </label>
    </div>
    <div class="tw-pl-4 tw-pt-6 tw-text-neutral-800">{{ subHeading }}</div>
    <div :class="{ 'show-border': viewMode }" class="tw-px-4 tw-py-4">
      <kyb-file-upload
        label="Bank statement proof (latest 3 months)"
        :viewMode="viewMode"
        :disabled="disabled"
        :fileInfo="bankStatementFileInfoData"
        fieldName="bankStatementProofDoc"
        :showTooltip="true"
        :required="false"
        :errors="docErrors.bankStatementProofDoc"
        @onChange="handleBankStatementProofDocChange"
        @removeFile="removeDocument('bankStatementProofDoc')"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Bank statement proof</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">Please provide business account bank statement of last 3 months and should be in the name of the business.</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">Submitting additional documents on business track record is optional at this stage, but may help us to accelerate the onboarding & underwriting process.</div>
        </template>
      </kyb-file-upload>

      <kyb-file-upload
        label="Previous card processing statements"
        :viewMode="viewMode"
        :disabled="disabled"
        :showTooltip="true"
        :required="false"
        fieldName="previousCardStatementProofDoc"
        :fileInfo="previousCardStatementFileInfoData"
        :errors="docErrors.previousCardStatementProofDoc"
        @onChange="handlePreviousCardStatementDocChange"
        @removeFile="removeDocument('previousCardStatementProofDoc')"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Previous card processing statements</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">Please provide previous card processing statement of last 3 months and should be in the name of the business.</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">Submitting additional documents on business track record is optional at this stage, but may help us to accelerate the onboarding & underwriting process.</div>
        </template>
      </kyb-file-upload>
      <kyb-file-upload
        label="Sample invoices for sales of goods & services"
        :viewMode="viewMode"
        :disabled="disabled"
        :showTooltip="true"
        :required="false"
        fieldName="sampleInvoicesDoc"
        :fileInfo="sampleInvoicesFileInfoData"
        :errors="docErrors.sampleInvoicesDoc"
        @onChange="handleSampleInvoicesDocChange"
        @removeFile="removeDocument('sampleInvoicesDoc')"
      >
        <template v-slot:tooltipContent>
          <div class="tw-text-sm tw-font-semibold tw-text-white">Sample invoices for sales of goods & services</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">Please provide previous customer sample invoices for sales of goods & services that should verify the name of the business and operating address.</div>
          <div class="tw-mt-4 tw-text-sm tw-font-normal tw-text-white">Submitting additional documents on business track record is optional at this stage, but may help us to accelerate the onboarding & underwriting process.</div>
        </template>
      </kyb-file-upload>
    </div>
  </div>
</template>

<script>
import KybFileUpload from "@/components/shared/kybDialog/kybFileUpload.vue";

export default {
  components: {
    KybFileUpload,
  },
  props: {
    heading: {
      type: String,
    },
    subHeading: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    documents: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    entityName: {
      type: String,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bankStatementFileInfoData: null,
      previousCardStatementFileInfoData: null,
      sampleInvoicesFileInfoData: null,
      repDocs: [],
      docErrors: {},
    };
  },
  watch: {
    documents() {
      this.updateDocuments();
    },
  },
  methods: {
    updateDocuments() {
      this.bankStatementFileInfoData = null;
      this.previousCardStatementFileInfoData = null;
      this.sampleInvoicesFileInfoData = null;
      this.repDocs = [];
      this.documents.forEach((doc) => {
        if (doc.tag === "bankStatementProofDoc") {
          this.bankStatementFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        } else if (doc.tag === "previousCardStatementProofDoc") {
          this.previousCardStatementFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        } else if (doc.tag === "sampleInvoicesDoc") {
          this.sampleInvoicesFileInfoData = {
            file_name: doc.file_name,
            document_url: doc.url,
          };
          this.repDocs.push(doc);
        }
      });
    },
    handleDelete() {
      this.$emit("onDelete", this.fieldName);
    },

    handleBankStatementProofDocChange(e) {
      let docData = {
        proof_type: "Business History Doc",
        name: "Bank Statement Proof",
        type: "Other",
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Bank Statement Proof",
        tag: "bankStatementProofDoc",
      };

      this.repDocs.push(docData);
      this.dispatchChange("bankStatementProofDoc");
    },

    handlePreviousCardStatementDocChange(e) {
      let docData = {
        proof_type: "Business History Doc",
        name: "Previous card processing statements",
        type: "Other",
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Previous card processing statements",
        tag: "previousCardStatementProofDoc",
      };
      this.repDocs.push(docData);
      this.dispatchChange("previousCardStatementProofDoc");
    },
    handleSampleInvoicesDocChange(e) {
      let docData = {
        proof_type: "Business History Doc",
        name: "Sample invoices for sales of goods & services",
        type: "Other",
        url: e.value.document_url,
        file_name: e.value.file_name,
        description: "Sample invoices for sales of goods & services",
        tag: "sampleInvoicesDoc",
      };
      this.repDocs.push(docData);
      this.dispatchChange("sampleInvoicesDoc");
    },
    removeDocument(fieldName) {
      this.repDocs = this.repDocs.filter((item) => item.tag !== fieldName);
      this.dispatchChange(fieldName);
    },
    dispatchChange(fieldName) {
      let result = {
        documents: this.repDocs,
        fieldName,
      };
      this.$emit("onChange", result);
    },
  },
  mounted() {
    this.updateDocuments();
  },
};
</script>

<style scoped>
/* .show-border > div {
  border: 1px solid #f1f5f9;
} */
.kyb-representative-files:deep .tc-tips {
  z-index: unset;
}
</style>

<template>
  <div class="kyb-checkbox tw-flex tw-flex-col tw-pt-2 tw-pb-1 sm:tw-flex-row sm:tw-items-center sm:tw-pb-1">
    <label
      class="tw-mb-2 tw-mr-8 tw-flex tw-w-auto tw-items-center tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700 sm:tw-mb-0 sm:tw-w-48"
      >{{ label }}
      <tc-tips class="tw-ml-2" v-if="showTooltip" :position="tooltipPosition" trigger="click" size="lg">
        <template v-slot:trigger>
          <tc-icon icon="information-outline" color="primary-700" size="xl"></tc-icon>
        </template>
        <slot name="tooltipContent"></slot></tc-tips
    ></label>
    <div class="tw-flex tw-w-full tw-items-center sm:tw-w-80">
      <input-checkbox
        @inputOnChange="handleChange"
        :errors="[]"
        :model="isChecked"
        :disabled="disabled"
      ></input-checkbox>
      <label class="tw-text-sm tw-font-normal tw-leading-lg tw-text-neutral-700">{{ checkboxDescription }}</label>
    </div>
  </div>
</template>

<script>
import { InputCheckbox, Tips as TcTips } from "tazapay-ui";
//import InputCheckbox from "@/ui-components/formControls/InputCheckbox.vue";
//import TcTips from "@/ui-components/tips.vue";

export default {
  components: { InputCheckbox, TcTips },
  props: {
    label: {
      type: String,
    },
    checkboxDescription: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: this.value,
    };
  },
  watch: {
    value() {
      this.isChecked = this.value;
    },
  },
  computed: {
    tooltipPosition() {
      return this.label === "Address proof" ? "bottom" : "top-right";
    },
  },
  methods: {
    handleChange(e) {
      if (this.viewMode) {
        return;
      }
      this.isChecked = e;
      let eventData = {
        fieldName: this.fieldName,
        value: e,
      };
      this.$emit("onChange", eventData);
    },
  },
};
</script>

<style scoped>
.kyb-checkbox .input-holder {
  margin-bottom: 0px;
  display: flex;
}
</style>

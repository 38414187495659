<template>
  <div>
    <div
      class="tw-rounded tw-border tw-border-solid"
      :class="errors?.length ? 'error-border' : 'tw-border-neutral-300'"
    >
      <div class="tw-flex tw-flex-col tw-gap-2 tw-rounded tw-bg-info-50 tw-py-2 tw-px-3 tw-text-sm tw-leading-lg">
        <label class="tw-font-bold tw-text-neutral-700"> {{ label }} </label>
        <label class="tw-text-neutral-500" :class="showSubLabel ? 'tw-block' : 'tw-hidden'"> {{ subLabel }}</label>
      </div>
      <div
        class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-border-0 tw-border-t tw-border-solid tw-border-neutral-300 tw-py-2 tw-px-3"
        v-for="item in items"
        @click="selectItems(item)"
      >
        <label
          class="tw-cursor-pointer tw-text-sm tw-font-normal tw-text-neutral-700"
          :class="selectedItems.includes(item) ? 'tw-font-bold' : ''"
          >{{ item }}</label
        >
        <tc-icon
          v-show="selectedItems.includes(item)"
          size="lg"
          color="primary-800"
          icon="checkbox-marked-circle"
        ></tc-icon>
        <tc-icon
          v-show="!selectedItems.includes(item)"
          size="lg"
          color="neutral-400"
          icon="checkbox-blank-circle-outline"
        ></tc-icon>
      </div>
    </div>
    <div v-show="errors">
      <div class="error-msg tw-pt-1">
        {{ errors[0] }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, defineEmits } from "vue";
defineProps({
  label: {
    type: String,
  },
  subLabel: {
    type: String,
  },
  items: {
    type: Array,
  },
  errors: {
    type: Array,
    default: [],
  },
  showSubLabel: {
    type: Boolean,
  },
});

const emit = defineEmits(["change"]);
// eslint-disable-next-line
let selectedItems = ref([]);

// eslint-disable-next-line
function selectItems(item) {
  if (selectedItems.value.includes(item)) {
    selectedItems.value = selectedItems.value.filter((val) => {
      return val !== item;
    });
  } else {
    selectedItems.value.push(item);
  }
  dispatchEvent();
}

// eslint-disable-next-line
function dispatchEvent() {
  emit("change", selectedItems.value);
}
</script>
<style scoped>
.error-msg {
  color: var(--color-danger-600);
  font-size: 12px;
  text-align: left;
  font-weight: 500;
}
.error-border {
  border: 1px solid transparent;
  box-shadow: 0 0 0 2px var(--color-danger-600);
  outline: 0;
}
</style>

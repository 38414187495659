import ModuleGuard from './guards/module-guard';
import { ROUTE_BANK } from '@/constants/route';
export const BankRoutes = [
  {
    path: ROUTE_BANK.BANK_DETAILS,
    name: "addBankDetails", 
    beforeEnter: ModuleGuard,
    component: () => import("@/views/account/bankDetails/containers/bankDetailsContainer.vue"),
    meta: {
      title: "Bank Details",
      breadCrumb(route) {
        if(!route.params.action){
          route.meta.toolbar.title = 'Bank Details'
          return []
        }

        let paths = [
          {
            text: 'Bank Details',
            to: { name: 'addBankDetails' }
          }
        ]

        if(route.params.action === 'view'){
          route.meta.toolbar.title = 'View Bank Details'
          paths.push({
            text: route.meta.toolbar.title
          })
          return paths
        }

        if(route.params.action === 'add'){
          route.meta.toolbar.title = 'Add Bank Details'
          paths.push({
            text: route.meta.toolbar.title
          })
          return paths
        }
        return []
      },
      toolbar: {
        title: "Bank Details"
      },
      layout: "AppLayout"
    }
  },
  {
    path: ROUTE_BANK.DRAGON_CHECKOUT,
    name: "DragonpayCheckout",
    component: () => import("@/views/dragonpay/DragonpayCheckout.vue"),
    meta: { 
      title: "Dragonpay Checkout",
      layout: "BlankLayout"   
    },
  }, 
  
];
import { defineStore } from 'pinia';

let nextId = 1;

export const useNotificationStore = defineStore('Notification', {
  state: () => { 
    return{
      notifications: [],
    }
  },

  actions: {
    add(notification){
      this.notifications.unshift({
        ...notification,
        id: nextId++
      })
    },
    remove(notificationToRemove){
      this.notifications = this.notifications.filter(
        notification => notification.id !== notificationToRemove.id
      )
    },
    clear(){
      this.notifications = [];
    }
  }
})
import api from './Api'

const BASE_URL = import.meta.env.VITE_RBAC_BASE_URL
const OPEN_URL = import.meta.env.VITE_RBAC_OPEN_API_BASE_URL

export const getCurrentUserData = () => {
  return api.get(`${BASE_URL}/rbac/user`)
}

export const InviteUser = (req) => {
   return api.post(`${BASE_URL}/rbac/user/invite`, req)
}

export const getInvitationDetails = () => {
  // TO DO get BASE_URL fixed at BE
  return api.get(`${OPEN_URL}/invitation`)
}

export const acceptUserInvite = (req) => {
  return api.post(`${OPEN_URL}/accept`, req)
}

export const removeUser = (req) => {
  return api.post(`${BASE_URL}/rbac/user/remove`, req)
}

export const updateUser = (req) => {
  return api.put(`${BASE_URL}/role`, req)
}


const MarketPlaceDashBoardGuard = (to, from, next) => {
  const isAllowedUser = (localStorage.getItem('accessToken')
    && localStorage.getItem('accessToken').length);
  const isMarketPlaceUser = localStorage.getItem('platformUser')
  if (isAllowedUser && isMarketPlaceUser == "true") {
    next();
  } else {
    next('/signin');
  }
}

export default MarketPlaceDashBoardGuard;
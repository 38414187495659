import router from '../router/index'

const emailRedirection = (fallbackURL) => {
  const navigationFrom = JSON.parse(localStorage.getItem('navigationFrom'));
  if (navigationFrom && navigationFrom != 'null') {
    router.push(navigationFrom.href); // to get the query params changed path to href
    localStorage.setItem('navigationFrom', null);
  } else {
    router.push(fallbackURL);
  }
}

const setRedirectionReferrer = () => {
  const params = new URLSearchParams(window.location.search);
  const referrer = params.get("referrer");
  if (referrer) {
    const navigation = { path: referrer };
    localStorage.setItem("navigationFrom", JSON.stringify(navigation));
  }
}

export { emailRedirection, setRedirectionReferrer };

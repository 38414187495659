import ModuleGuard from './guards/module-guard';

export const SettingsRoutes = [
  {
    path: "/account-creation", name: "accountcreation", meta: { title: "Account Creation",  layout: "AccountLayout" },
    beforeEnter: ModuleGuard,
    component: () => import("@/views/settings/AccountCreation.vue")
  },
  {
    path: "/account-type", name: "accounttype", meta: { title: "Account Type", layout: "AccountLayout" },
    beforeEnter: ModuleGuard,
    component: () => import("@/views/settings/AccountType.vue")
  }
];
<template>
  <div
    class="kyb-social-media tw-border-0 tw-border-t tw-border-solid tw-border-neutral-200 tw-pt-2 tw-pb-4 sm:tw-flex-row sm:tw-items-baseline sm:tw-pb-2"
    :class="viewMode ? 'tw-block' : 'tw-flex tw-flex-col'"
    >
    <!-- <label
      class="tw-text-sm tw-leading-sm tw-font-bold tw-text-neutral-700 tw-w-auto tw-mb-2 tw-mr-8 sm:tw-w-48 sm:tw-mb-0"
      >{{ label }}</label
    > -->
    <div v-if="label" class="tw-mb-2 tw-mr-8 tw-w-auto sm:tw-mb-0 sm:tw-w-48">
      <label v-if="showSocialMedia || !viewMode" class="tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700">{{ label }}</label>
      <label v-if="optional && !viewMode" class="tw-ml-1 tw-text-xs tw-text-neutral-400">(optional)</label>
    </div>
    <div class="tw-w-full sm:tw-w-80" v-if="!viewMode">
      <input-text
        class="tw-my-4 tw-w-full"
        :title="facebookId"
        :modelValue="facebookId"
        placeholder="Enter your business’s facebook ID"
        leftIcon="facebook"
        :disabled="disabled"
        @textInputOnChange="handleChange($event, 'facebookId')"
        :errors="[]"
      ></input-text>
      <input-text
        class="tw-my-4 tw-w-full"
        :title="twitterId"
        :modelValue="twitterId"
        placeholder="Enter your business’s twitter ID"
        leftIcon="twitter"
        :disabled="disabled"
        @textInputOnChange="handleChange($event, 'twitterId')"
        :errors="[]"
      ></input-text>
      <input-text
        class="tw-my-4 tw-w-full"
        :title="linkedinId"
        :modelValue="linkedinId"
        placeholder="Enter your business’s linkedin ID"
        leftIcon="linkedin"
        :disabled="disabled"
        @textInputOnChange="handleChange($event, 'linkedinId')"
        :errors="[]"
      ></input-text>
      <input-text
        class="tw-my-4 tw-w-full"
        :title="instagramId"
        :modelValue="instagramId"
        placeholder="Enter your business’s instagram ID"
        leftIcon="instagram"
        :disabled="disabled"
        @textInputOnChange="handleChange($event, 'instagramId')"
        :errors="[]"
      ></input-text>
    </div>

    <div class="tw-w-full tw-ml-2 tw-mt-4" v-if="viewMode">
      <div v-if="facebookId" class="tw-flex tw-my-1"> 
        <img :src="getIconUrl('facebook')" />
        <a :href="facebookId" target="_blank" class="tw-my-2 tw-ml-4 tw-leading-none">{{ facebookId }}</a>
      </div>
      <div v-if="twitterId" class="tw-flex tw-my-1"> 
        <img :src="getIconUrl('twitter')" />
        <a :href="twitterId" target="_blank" class="tw-my-2 tw-ml-4 tw-leading-none">{{ twitterId }}</a>
      </div>
      <div v-if="linkedinId" class="tw-flex tw-my-1">
        <img :src="getIconUrl('linkedin')" />
        <a :href="linkedinId" target="_blank" class="tw-my-2 tw-ml-4 tw-leading-none">{{ linkedinId }}</a>
      </div>
      <div v-if="instagramId" class="tw-flex tw-my-1">
        <img :src="getIconUrl('instagram')" />
        <a :href="instagramId" target="_blank" class="tw-my-2 tw-ml-4 tw-leading-none">{{ instagramId }}</a>
      </div>
    </div>
  </div>
</template>

<script>
// import {InputText} from "tazapay-ui"

import InputText from "@/ui-components/formControls/InputText.vue";
import { useAbsoluteUrl } from "@/composables/viteSupport.js";

export default {
  components: { InputText },
  props: {
    label: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    optional: {
      type: Boolean,
    },
    facebookId: {
      type: String,
    },
    twitterId: {
      type: String,
    },
    linkedinId: {
      type: String,
    },
    instagramId: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getImageUrl(img){
       let result =  useAbsoluteUrl(img);
       return result;
    },
    handleChange(e, key) {
      let eventData = {
        fieldName: this.fieldName,
        subFieldName: key,
        value: e,
      };
      this.$emit("onChange", eventData);
    },
    getIconUrl(iconName) {
      return iconName ? this.getImageUrl(`${iconName}.svg`) : "";
    },
  },
  computed:{
    showSocialMedia(){
      return (this.facebookId || this.twitterId || this.linkedinId || this.instagramId)
    }
  }
};
</script>

<style scoped>
.kyb-social-media:deep .contains-error .error-holder {
  margin-top: 5px;
}
.kyb-social-media:deep .input-holder .input-box:disabled {
  @apply tw-bg-neutral-200;
}

.kyb-social-media:deep .input-holder .input-box:disabled {
  @apply tw-bg-neutral-200;
}

.kyb-social-media .clear-margin .input-holder {
  margin-bottom: 3px;
}
</style>

import api from "@/api/Api";
let CORE_BASE_URL = import.meta.env.VITE_BASE_URL;

/**
 * function to get KPI details based on param dates.
 * @param {Object} params
 * @returns
 */
function getKPIdetails(params) {
  const url = `${CORE_BASE_URL}/dashboard/kpi`;
  return api.get(url, { params });
}

export { getKPIdetails };

import { defineStore } from 'pinia';

let nextId = 1;

export const useMessageStore = defineStore('Message', {
  state: () => { 
    return{
      messages: [],
    }
  },

  actions: {
    add(message){
      this.messages.unshift({
        ...message,
        id: nextId++
      })
    },
    remove(messageToRemove){
      this.messages = this.messages.filter(
        message => message.id !== messageToRemove.id
      )
    }
  }
})
<script setup>
/* eslint-disable */
import { ref, reactive, computed, defineProps, watch, onMounted } from "vue";
import { InputButton, TipBanner, Chip, InputSelect } from "tazapay-ui";
import kybTextInput from "@/components/shared/kybDialog/kybTextInput.vue";
import FormValidation from "@/ui-components/formBuilder/FormValidation";
import { getAccountLabel } from "@/constants/bankCurrencies.js";

const emitter = defineEmits(["onChange", "onDelete"]);
const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
  bankCodes: {
    type: Object,
    default: () => ({}),
  },
  isDeletable: {
    type: Boolean,
    default: true,
  },
  isEditable: {
    type: Boolean,
    default: false,
  },
  hasDefaultEditMode: {
    type: Boolean,
    default: false,
  },
  isAddressRequired: {
    type: Boolean,
    default: false,
  },
  isContactNumRequired: {
    type: Boolean,
    default: false,
  },
  contactCode: {
    type: String,
    default: "",
  },
  bankDetails: {
    type: Object
  }
});

const currency = computed(() => {
  if (Array.isArray(props.modelValue.currency)) {
    return props.modelValue.currency.join(", ");
  }
  return props.modelValue.currency;
});

const formFields = [
  {
    fieldName: "currency",
    label: "Currency",
    value: "",
    viewMode: true,
    errors: [],
  },
  {
    fieldName: "legal_name",
    label: "Account Holder's Name",
    placeholder: "Enter name of bank account",
    value: "",
    tip: "Your legal business name should be same as the bank account name in order for us to release the money to your account.",
    required: true,
    validations: [{ key: "required" }],
    errors: [],
  },
  {
    fieldName: "bank_name",
    label: "Name of the Bank",
    placeholder: "Enter name of bank",
    value: "",
    required: true,
    validations: [{ key: "required" }, { key: "nospecialchar" }],
    errors: [],
  },

  {
    fieldName: "account_number",
    label: "Account Number",
    placeholder: "Enter account number",
    value: "",
    required: true,
    type: "alphanumericonly",
    class: "fs-exclude",
    validations: [{ key: "required" }, { key: "alphanumericonly" }],
    errors: [],
  },
  {
    fieldName: "address",
    label: "Address",
    placeholder: "Enter address",
    value: "",
    required: true,
    validations: [{ key: "required" }, { key: "maxlength", value: 50 }],
    errors: [],
  },
  {
    fieldName: "city",
    label: "City",
    placeholder: "Enter city",
    value: "",
    required: true,
    validations: [{ key: "required" }, { key: "maxlength", value: 50 }],
    errors: [],
  },
  {
    fieldName: "state",
    label: "State",
    placeholder: "Enter state",
    value: "",
    required: true,
    validations: [{ key: "required" }, { key: "maxlength", value: 50 }],
    errors: [],
  },
  {
    fieldName: "zip_code",
    label: "Postcode",
    placeholder: "Enter postcode",
    value: "",
    required: true,
    type: "numberOnly",
    validations: [{ key: "required" }, { key: "numberOnly" }, { key: "maxlength", value: 50 }],
    errors: [],
  },
  {
    fieldName: "country_code",
    label: "Contact Code",
    value: "",
    viewMode: true,
    errors: [],
  },
  {
    fieldName: "contact_number",
    label: "Contact Number",
    placeholder: "Enter contact number",
    value: "",
    required: true,
    type: "numberOnly",
    validations: [{ key: "required" }, { key: "numberOnly" }],
    errors: [],
  },
];

let countrySelected = ref(props.modelValue?.country)
let countryList = ref(["United States of America", props.bankDetails?.country_name || countrySelected.value])

const editMode = ref(false);
const isFormDataDirty = ref(false);
const formvalidator = new FormValidation();
const formData = reactive([]);
const showAccountNameTip = ref(false);
const getFieldIndex = (fieldName) => formData.findIndex((field) => field.fieldName === fieldName);
const getFormData = (fieldName) => formData[getFieldIndex(fieldName)].value;
const updateFormInput = ({ fieldName, value }) => {
  formData[getFieldIndex(fieldName)].value = value;
  isFormDataDirty.value = true;
  dispatchChange();
};

const changeCountry = (val) => {
  countrySelected.value = val
  formData[getFieldIndex("country")] = val;
  isFormDataDirty.value = true;
  dispatchChange();
}

const bankCode = computed(() =>
  props.bankCodes[getFormData("currency")]?.length ? props.bankCodes[getFormData("currency")] : ["Bank Code"]
);

const reloadFormData = () => {
  formData.length = 0;
  formFields.forEach((field) => {
    let isNotCommonField = !["address", "city", "state", "zip_code", "country_code", "contact_number"].includes(
      field.fieldName
    );
    let isAddressField = ["address", "city", "state", "zip_code"].includes(field.fieldName);
    let isContactNumField = ["country_code", "contact_number"].includes(field.fieldName);
    if (isNotCommonField) {
      formData.push(field);
    } else {
      if (isAddressField && props.isAddressRequired) {
        formData.push(field);
      }
      if (isContactNumField && props.isContactNumRequired) {
        formData.push(field);
      }
    }
  });

  let currency

  formData.forEach((field, index) => {
    if (field.fieldName === "account_number") {
      let AccountNumber = getAccountLabel(getFormData("currency"));
      formData[index].label = AccountNumber;
      formData[index].placeholder = "Enter " + AccountNumber;
      formData[index].value = props.modelValue[field.fieldName];
    } else if (field.fieldName === "country_code") {
      formData[index].value = props.contactCode;
    } else if (field.fieldName === "currency") {
      // some times multiple foreign bank detail will come.
      currency = props.modelValue[field.fieldName];
      formData[index].value = Array.isArray(currency) ? currency[0] : currency;
    } else {
      formData[index].value = props.modelValue[field.fieldName];
    }
  });
  // create input component for every bank code received from server.

  let availableBankCodes = bankCode.value
  if (props.bankDetails?.currency_bank_codes.USD === bankCode.value) {
    if (!(countrySelected.value === "United States of America" && (Array.isArray(currency) ? currency[0] === "USD" : currency === "USD"))) {
      availableBankCodes = availableBankCodes.filter(swift => swift === "SWIFT Code")
    }
  }
  availableBankCodes.forEach((code) => {
    let bankData = {
      fieldName: `bank_codes_${code}`,
      subFieldName: code,
      label: code,
      placeholder: "Enter the " + `${code}`,
      value: props.modelValue.bank_codes?.[code],
      required: true,
      validations: [{ key: "required" }],
      errors: [],
    };

    formData.splice(3, 0, bankData);
  });
  if (props.hasDefaultEditMode) {
    editMode.value = true;
  }
  if (props.isEditable && !props.modelValue.id) {
    editMode.value = true;
  }
  // isFormDataDirty.value = false;
};

onMounted(reloadFormData);
watch(() => [props.modelValue, countrySelected.value], reloadFormData);

const deleteAccount = () => {
  emitter("onDelete");
};

const composeData = () =>
  JSON.parse(
    JSON.stringify(
      formData.reduce(
        (record, field) => {
          if (field.fieldName.startsWith("bank_codes_")) {
            record["bank_codes"] = record["bank_codes"] || {};
            record["bank_codes"][field.subFieldName] = field.value;
          } else {
            record[field.fieldName] = field.value;
          }
          return record;
        },
        {
          id: props.modelValue?.id || "",
          is_primary_account: props.modelValue.is_primary_account,
          country_name: countrySelected.value
        }
      )
    )
  );

const dispatchChange = () => {
  emitter("onChange", composeData());
};

const validateData = () => {
  let result = {};
  formData.forEach((field, index) => {
    formData[index].errors.length = 0;
    if (field.validations) {
      formData[index].errors.push(
        ...formvalidator.validateModel(
          {
            model: field.value,
          },
          {
            validations: field.validations,
            required: true,
          }
        )
      );
      if (formData[index].errors.length) {
        result[field.fieldName] = formData[index].errors;
      }
    }
  });
  return JSON.parse(
    JSON.stringify({
      isValid: Object.keys(result).length === 0,
      error: result,
    })
  );
};

const cancel = () => {
  // if (!props.modelValue.id) 
  emitter("back");

  editMode.value = false;
  reloadFormData();
};

const save = () => {
  let { isValid } = validateData();
  if (isValid) {
    emitter("save", composeData());
    editMode.value = false;
  }
};

defineExpose({
  validateData,
});
</script>
<template>
  <div v-if="$attrs.onBack" class="tw-mb-4 tw-cursor-pointer tw-text-primary-800" @click="$emit('back')">
    <tc-icon icon="arrow-left" class="tw-mr-2" />View all Bank Accounts
  </div>
  <div class="tp-form">
    <div class="tp-form__actions">
      <span class="tw-mr-2 tw-uppercase"> {{ currency }} Account </span>
      <Chip v-if="modelValue.is_primary_account" label="Primary Account" type="success" class="tw-text-xs" />
      <div class="tw-flex-grow"></div>
      <div class="tp-form__action">
        <InputButton
          v-if="isEditable && !editMode"
          styleClass="tp-basic-btn btn-small tw-text-xs"
          buttonClass="tw-p-2"
          buttonLabel=""
          @inputOnCLick="editMode = true"
        >
          <tc-icon icon="pencil" />
          <span class="tw-hidden sm:tw-inline">Edit</span>
        </InputButton>
        <InputButton
          v-if="isDeletable"
          styleClass="tp-basic-btn btn-small tw-text-xs"
          buttonClass="tw-p-2"
          buttonLabel=""
          @inputOnCLick="deleteAccount"
        >
          <tc-icon icon="trash-can" />
          <span class="tw-hidden sm:tw-inline">Delete</span>
        </InputButton>
      </div>
    </div>
    <div class="tp-form__field">
      <div class="tp-form__country">
        <label class="tp-form__label">Country</label>
        <div v-if="currency === 'USD'">
          <label v-if="!(isEditable && editMode) || (bankDetails?.country_name || modelValue.country) === 'United States of America'" class="tp-form__label--input">{{ countrySelected }}</label>
          <InputSelect
            v-else
            :options="countryList"
            :errors="[]"
            v-model:model="countrySelected"
            class="tw-w-full sm:tw-w-80"
            @inputOnChange="changeCountry($event)"
            :disabled="!(isEditable && editMode)"
          />
        </div>
        <div v-else class="tp-form__label--input">
          {{ bankDetails?.country_name || countrySelected }}
        </div>
      </div>
      <template v-for="field in formData" :key="field.fieldName">
        <kybTextInput
          v-bind="field"
          :viewMode="field.viewMode || !(isEditable && editMode)"
          v-on="{
            ...(field.fieldName === 'legal_name' && { onFocus: () => (showAccountNameTip = true) }),
            ...(field.fieldName === 'legal_name' && { onBlur: () => (showAccountNameTip = false) }),
          }"
          @onChange="updateFormInput"
        />
        <div v-if="field.tip" v-show="showAccountNameTip" class="tp-form__tip">
          <div class="tp-form__tip-label"></div>
          <div class="tp-form__tip-banner">
            <TipBanner icon="none" type="warn" :message="field.tip" />
          </div>
        </div>
      </template>
    </div>
  </div>
  <div v-if="$attrs.onSave && isEditable && editMode" class="tp-form__action tw-justify-end tw-p-4">
    <InputButton styleClass="tp-basic-btn tw-text-xs" buttonLabel="Cancel" @inputOnCLick="cancel"></InputButton>
    <InputButton
      styleClass="tp-basic-btn btn-primary tw-text-xs"
      buttonLabel="Save"
      @inputOnCLick="save"
      :disableBtn="!isFormDataDirty"
    >
    </InputButton>
  </div>
</template>
<style lang="scss" scoped>
.tp-form {
  box-shadow: 0px 1px 3px rgba(226, 232, 240, 0.5), 0px 1px 2px rgba(226, 232, 240, 0.24);
  @apply tw-flex tw-flex-col tw-border tw-border-solid tw-border-neutral-100 tw-text-primary-700;
  
  &__ {
    &action {
      @apply tw-flex tw-gap-2;
      &s {
        @apply tw-flex tw-items-center tw-justify-start tw-bg-neutral-150 tw-p-4 tw-font-semibold;
      }
    }
    &field {
      @apply tw-flex tw-flex-col tw-p-4;
    }
    &country {
      @apply tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center sm:tw-pb-2 sm:tw-pt-2 tw-pb-4 tw-pt-4;
    }
    &label {
      @apply tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700 tw-mb-2 tw-mr-8 tw-w-auto sm:tw-mb-0 sm:tw-w-48;
      
      &-- {
        &input {
          @apply tw-w-full sm:tw-w-80 sm:tw-pb-2 sm:tw-pt-2 tw-text-neutral-700;
        }
      }
    }
    &tip {
      @apply tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center;

      &- {
        &label {
          @apply tw-mr-8 tw-w-auto sm:tw-w-48;
        }
        &banner {
          @apply tw-w-full sm:tw-w-80;
        }
      }
    }
  }
}

.tp-basic-btn.no-border:deep(button) {
  border: none;
}

.tp-basic-btn.btn-primary:deep(button) {
  @apply tw-bg-primary-700 tw-text-white;
}
</style>

// export default name = 'CPV'
import clearStorageAndRedirect from "@/helpers/clearStorageAndRedirect";

// sets BASE URL based on env
let REST_URL = import.meta.env.VITE_BASE_URL

export async function GetFlag (flagName) {
  try {
    const response = await fetch(
      `${REST_URL}/user/v1/flags?flagNames=${flagName}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.accessToken
        }
      }
    )
    clearStorageAndRedirect(response)
    const out = await response.json()

    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}

export async function SetFlag (flagName, value) {
  let data = {
    flagName,
    value
  }
  try {
    const response = await fetch(`${REST_URL}/user/v1/flags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.accessToken
      },
      body: JSON.stringify(data)
    })
    clearStorageAndRedirect(response)
    const out = await response.json()

    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    return {
      error: error,
      status: '404'
    }
  }
}

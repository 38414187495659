<template>
  <component :is="layout">
    <slot />
  </component>
</template>
<script>
import BlankLayout from "@/components/layout/BlankLayout.vue";
import AccountLayout from "@/components/layout/AccountLayout.vue";
import AppLayout from "@/components/layout/AppLayout.vue";

export default {
  name: "Layout",
  components:{
    BlankLayout,
    AccountLayout,
    AppLayout
  },
  computed: {
    layout() {      
      return this.$route.meta.layout || 'BlankLayout';
    }
  }
}
</script>

<!-- <script setup>
import { defineAsyncComponent, computed } from 'vue'
import { useRoute } from 'vue-router';
/* eslint-disable */
const route = useRoute()
let layout = computed( () => { 
        const meta = route.meta;
        let current;
        console.log("meta.layout", meta.layout);
        switch(meta.layout){
          case 'Account':
            current = 'AccountLayoutNew';
            break;
          case 'App':
            current = 'LayoutNew';
            break;
        }
        return defineAsyncComponent( () => import(`@/components/shared/${current ?? 'BlankLayout'}.vue`) )
    }
)
</script> -->


<!-- working but page rerender -->
<!-- <script setup>
import AppLayoutDefault from './BlankLayout'
import { markRaw, watch, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
/* eslint-disable */
const route = useRoute()
const layout = shallowRef({})
layout.value = markRaw(AppLayoutDefault)

watch( () => 
  route.meta, 
  async (meta) => {
    try {
      const component = await import(`@/components/shared/${ meta.layout }.vue`)
      layout.value = component?.default || AppLayoutDefault
    } catch (e) {
      layout.value = AppLayoutDefault
    }
  }, { immediate: true })
	
</script> -->


<!-- <script setup>
import AppLayoutDefault from './BlankLayout'
import { markRaw, watch, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
/* eslint-disable */
const route = useRoute()
const layout = shallowRef({})
layout.value = markRaw(AppLayoutDefault)

watch( () => 
  route.meta, 
  (meta) => {
    try {
      let current;
      console.log("meta.layout", meta.layout);
      switch(meta.layout){
        case 'Account':
          current = 'AccountLayoutNew';
          break;
        case 'App':
          current = 'LayoutNew';
          break;
        case 'Blank':
          current = 'Blank';
          break;
      }
      console.log("current", current);
      const component = defineAsyncComponent( () => import(`@/components/shared/${current ?? 'BlankLayout'}.vue`) )
      layout.value = component?.default || AppLayoutDefault
    } catch (e) {
      layout.value = AppLayoutDefault
    }
  }, { immediate: true })
	
</script> -->
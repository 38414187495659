import axios from "axios";
import router from '../router'
// import store from '@/store'
import { handleResponse } from '@/helpers/handleResponse';
import { ERROR_TYPE } from "@/constants/errorCodes"

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL
});

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('accessToken');
    const userIP = localStorage.getItem('user-ip') || '';
    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'TZ-User-IP': userIP
      }
      if (!config.url.includes('internal')) { 
        config.headers['Cache-Control'] = 'no-cache';
        config.headers['Pragma'] = 'no-cache';
        config.headers['Expires'] = '0';
      } 
    } if(config.url.includes('invitation')) {
      config.headers['id'] = localStorage.getItem('invitation_id')
    }
    return config;
  },


  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return handleResponse(response);
  },
  function (error) {
    console.error('before setting error', error);
    let errorData = error?.response?.data;
    // store.dispatch('notification/add', {
    //   message: errorData?.errors?.[0]?.message || errorData?.error?.message || errorData?.message || 'Something went wrong!!',
    //   type: 'error',
    // })      
    if (error?.response) {
      let responseCode = errorData?.statusCode;
      let responseMessage = errorData?.message;
      // let statusCode = error.response. ;
      if (responseCode === ERROR_TYPE.TOKEN_EXPIRED.CODE || responseMessage == ERROR_TYPE.TOKEN_EXPIRED.MESSAGE) {
        localStorage.clear()
        sessionStorage.clear()
        router.push("/signin");
      }
    }
    return Promise.reject(errorData);
  }
);

export default api;

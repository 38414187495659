const mpEndpoints = {
  createUserEndpoint: '/dashboard/user',
  createEscrowEndpoint: '/dashboard/escrow',
  createPaymentLinkEndpoint: '/dashboard/session/payment',
  countryConfigEndpoint: '/dashboard/metadata/countryconfig?country=',
  invoiceCurrencyEndPoint: '/dashboard/metadata/invoicecurrency',
  dashboardMetaDataEndPoint: '/dashboard/metadata',
  getUserDetailsEndPoint: '/dashboard/user/:email_id',
  getUploadUrlEndPoint: '/dashboard/doc/upload',
  getSupportTopicEndPoint: '/dashboard/support/topic',
  submitSupportEndpoint: '/dashboard/support/ticket',
  acuantRefundFeedbackEndpoint: '/dashboard/monitoring/feedback'
}

export default mpEndpoints
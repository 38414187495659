import { ROUTE_AUTHENTICATION } from "@/constants/route";
import { getSessionStorage } from "@/helpers/storageService";

const signupRedirection = () => {
  const url = JSON.parse(getSessionStorage("signupUrl"))
  if (url) {
    return url;
  } else {
    return `${ROUTE_AUTHENTICATION.SIGNUP}`;
  }
}

export { signupRedirection }
<template>
  <div class="kyb-additional-docs tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-baseline">
    <div class="tw-mb-2 tw-mr-8 tw-flex tw-w-auto sm:tw-mb-0 sm:tw-w-48" v-if="!viewMode || uploadedLists.length > 0">
      <tc-icon icon="file-document" color="neutral-300" size="lg" class="tw-mr-2"></tc-icon>
      <div class="tw-flex tw-flex-row md:tw-flex-col">
        <span class="tw-mr-1 tw-text-xs tw-font-bold tw-leading-sm tw-text-neutral-700">{{ label }}</span>
        <span class="tw-text-2xs tw-font-normal tw-leading-lg tw-text-neutral-400"> (optional)</span>
      </div>
    </div>
    <div class="tw-w-full sm:tw-w-80">
      <div class="uploaded-file-lists">
        <div v-for="file in uploadedLists" :key="file.file_name" class="">
          <div class="tw-text-sm tw-font-normal tw-leading-lg tw-text-neutral-400">
            {{ file.docName }}
          </div>
          <kyb-file-upload
            class="tw-pt-0 sm:tw-pt-0"
            :fileInfo="file"
            @removeFile="removeAdditonalDoc(file)"
            :viewMode="viewMode"
          ></kyb-file-upload>
        </div>
      </div>
      <div class="tw-flex tw-flex-col sm:tw-mt-2 sm:tw-flex-row" v-if="!viewMode">
        <input-text
          :errors="documentNameErr"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model:modelValue="docName"
          @textInputOnChange="handleDocNameChange"
        ></input-text>
        <kyb-file-upload
          class="sm:tw-ml-2 sm:tw-pt-0"
          @onChange="handleUploaded"
          :onlyUpload="true"
          :disabled="!docName"
          :uploadUrl="uploadUrl"
        ></kyb-file-upload>
      </div>
      <div class="tw-whitespace-nowrap tw-text-xs tw-font-normal tw-leading-lg tw-text-neutral-600" v-if="!viewMode">
        {{ inputHint }}
      </div>
    </div>
  </div>
</template>

<script>
import KybFileUpload from "@/components/shared/kybDialog/kybFileUpload.vue";
//import InputText from "@/ui-components/formControls/InputText.vue";
import { InputText } from "tazapay-ui";
import FormValidation from "@/ui-components/formBuilder/FormValidation";
import { nextTick } from "@vue/runtime-core";

export default {
  components: {
    KybFileUpload,
    InputText,
  },
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    fileLists: {
      type: Array,
    },
    fieldName: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    uploadUrl: {
      type: String,
      default: "",
    },
    inputHint: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uploadedLists: [],
      docName: "",
      documentNameErr: []
    };
  },
  watch: {
    fileLists() {
      this.uploadedLists = this.fileLists ? [...this.fileLists] : [];
    },
  },
  methods: {
    handleUploaded(e) {
      this.uploadedLists.push({
        docName: this.docName,
        file_name: e.value.file_name,
        document_url: e.value.document_url,
        responce: e.value.responce,
      });
      nextTick(() => {
        this.docName = "";
      });
      this.dispatchChange();
    },
    handleDocNameChange(value) {
      let formValidation = new FormValidation();
      let validations = [{ key: "alphaNumericSentence" }];
      let err = formValidation.validateModel({ model: value }, { validations, required: true });
      if (err[0]){
        this.documentNameErr.push(err[0]);
        err = [];
        this.docName = "";
      }
      else{
        this.docName = value
        this.documentNameErr = [];
      }
    },
    removeAdditonalDoc(file) {
      this.uploadedLists = this.uploadedLists.filter((list) => {
        return list !== file;
      });
      this.dispatchChange();
    },
    dispatchChange() {
      let eventData = {
        fieldName: this.fieldName,
        value: this.uploadedLists,
      };
      this.$emit("onChange", eventData);
    },
  },
  mounted() {
    this.uploadedLists = this.fileLists ? [...this.fileLists] : [];
  },
};
</script>

<style scoped>
.kyb-additional-docs:deep .input-holder,
.kyb-additional-docs:deep .error-holder {
  margin-bottom: 0px;
  margin-top: 0px;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
//components
import Signin from '@/views/authentication/Signin.vue'
import Reports from "@/views/Reports/Reports.vue";
import Home from "@/views/home/HomePage.vue";
//routes
import { AuthenticationRoutes } from "./authentication";
import { EscrowRoutes } from "./escrow"
import { AccountRoutes } from "./account"
import { BankRoutes } from "./bank-details"
import { SettingsRoutes } from "./settings"
import { OAuthRoutes } from "./oauth-routes";
import { MarketPlaceRoutes } from './market-place'
import { MarketPlaceDashBoardRoutes } from './m-dashboard';
import { TransactionRoutes } from "./transaction"
import { SettlementRoutes } from "./settlement"
import { ActionsRequiredRoutes } from "./actionsRequired"
//guards
import AuthGuard from './guards/auth-guard';
import ModuleGuard from '@/router/guards/module-guard';
import { ShopifyRoutes } from './shopify';

const routes = [
  { path: "/", name: "LogIn", meta: { title: "Sign In", layout: "AccountLayout" }, component: Signin, beforeEnter: AuthGuard },
  { path: "/home", name: "Home", meta: { title: "Home", layout: "AppLayout" }, component: Home, beforeEnter: ModuleGuard },
  ...AuthenticationRoutes,
  ...EscrowRoutes,
  ...BankRoutes,
  ...SettingsRoutes,
  ...MarketPlaceRoutes,
  ...AccountRoutes,
  ...OAuthRoutes,
  ...MarketPlaceDashBoardRoutes,
  ...TransactionRoutes,
  ...ActionsRequiredRoutes,
  ...SettlementRoutes,
  ...ShopifyRoutes,
  { path: "/reports", name: "Reports", meta: { title: "Reports",toolbar:{title:"Reports"}, layout: "AppLayout"}, component: Reports, beforeEnter: ModuleGuard },
  { path: "/:catchAll(.*)", name: "NotFound", meta: { title: "Not Found", layout: "BlankLayout" },component: () => import("@/views/NotFound.vue") }  
];

const router = createRouter({
  history: createWebHistory(),
  routes,

  //scroll to top when navigating to a new route
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { top: 0 }
    }
  }
})

router.afterEach((to) => {
  document.title = to?.meta?.title ? `${to.meta.title} | Tazapay` : 'Tazapay';
});

export default router

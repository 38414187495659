import api from "@/api/Api";
let BASE_URL = import.meta.env.VITE_REPORTS_URL;

export function getReports(params) {
  const url = `${BASE_URL}/reports`;
  return api.get(url, { params });
}

export function fetchStatus(params) {
  const url = `${BASE_URL}/reports/status`;
  return api.get(url, { params });
}

export function downloadReport(id) {
  const url = `${BASE_URL}/reports/download/${id}`;
  return api.get(url);
}

export function triggerReport(data) {
  const url = `${BASE_URL}/reports/trigger_sqs`;
  return api.post(url,data);
}

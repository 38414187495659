<template>
  <Tile bg="tw-bg-neutral-50" style="background: linear-gradient(285.82deg, #F5FAFF 0%, #FFFFFF 51.04%, #F5FAFF 100%);">
    <template #header>
      Having Trouble?
    </template>
    Whether it’s integration, account settings, or anything else, feel free to reach out at support@tazapay.com

    <template #footer>
      <div class="tw-text-right">
        <span class="tw-cursor-pointer" @click="showDrawer = true">
          <img src="@/assets/images/arrow.svg" alt="arrow"/>
        </span>
      </div>
    </template>
    <ContactSupport v-if="showDrawer"
      :showDrawer="showDrawer"
      @supportClose="supportClose"
    />
  </Tile>
</template>

<script setup>
/* eslint-disable */
import Tile from "@/views/home/components/Tile.vue";
import ContactSupport from "@/views/contactSupport/contactSupport.vue";
import { ref } from "vue";

const showDrawer = ref(false)

function supportClose(value) {
  showDrawer.value = value;
}
</script>

<style lang="scss" scoped>

</style>
import allCountries from "@/helpers/allCountries";

function getcountryList() {
  const countryList = [];
  allCountries.forEach((e) => {
    countryList.push(e.name);
  });
  return countryList;
}

export const sidemenuItems = [
  {
    id: "contact-info",
    label: "Basic Info",
    mobileLabel: "Basics",
    isCompleted: false,
    supportedBy: [
      "Company",
      "Limited Liability Partnership",
      "Partnership",
      "Sole Proprietorship",
      "Individual",
      "Trust",
      "Nonprofit Organization",
      "Corporation",
      "Others",
      "Business",
    ],
  },
  {
    id: "personal-details",
    label: "Personal Details",
    mobileLabel: "Personal",
    isCompleted: false,
    supportedBy: ["Individual"],
  },
  {
    id: "sole-legal-representative",
    label: "Representative & Owners",
    mobileLabel: "Representative",
    isCompleted: false,
    supportedBy: ["Sole Proprietorship"],
  },
  {
    id: "legal-representative",
    label: "Representative & Owners",
    mobileLabel: "Representative",
    isCompleted: false,
    supportedBy: [
      "Company",
      "Limited Liability Partnership",
      "Partnership",
      "Trust",
      "Nonprofit Organization",
      "Corporation",
      "Others",
      "Business",
    ],
  },
  {
    id: "business-details",
    label: "Business Details",
    mobileLabel: "Business",
    isCompleted: false,
    supportedBy: [
      "Company",
      "Limited Liability Partnership",
      "Partnership",
      "Sole Proprietorship",
      "Trust",
      "Nonprofit Organization",
      "Corporation",
      "Others",
      "Business",
    ],
  },
  {
    id: "bank-details",
    label: "Bank Details",
    mobileLabel: "Bank",
    isCompleted: false,
    supportedBy: [
      "Company",
      "Limited Liability Partnership",
      "Partnership",
      "Sole Proprietorship",
      "Individual",
      "Trust",
      "Nonprofit Organization",
      "Corporation",
      "Others",
      "Business",
    ],
  },
  {
    id: "document-details",
    label: "Documents",
    mobileLabel: "Documents",
    isCompleted: false,
    supportedBy: [
      "Company",
      "Limited Liability Partnership",
      "Partnership",
      "Sole Proprietorship",
      "Individual",
      "Trust",
      "Nonprofit Organization",
      "Corporation",
      "Others",
      "Business",
    ],
    isLast: true,
  },
];

export const contactInfoComponents = {
  email: {
    id: "registered-email",
    label: "Registered Email",
    fieldName: "registeredEmail",
    compName: "kybTextInput",
    disabled: true,
    required: true,
    type: "email",
    errors: [],
    modelKey: "email",
  },
  country: {
    id: "registered-country",
    label: "Country",
    fieldName: "registeredCountry",
    compName: "kybTextInput",
    disabled: true,
    required: true,
    type: "text",
    errors: [],
    modelKey: "country",
  },
  contact_number: {
    id: "registered-contact",
    label: "Contact Number",
    optional: false,
    fieldName: "registeredContact",
    compName: "kybPhoneInput",
    required: true,
    errors: [],
    modelKey: "contact_number",
  },
  ind_bus_type: {
    id: "type-of-entity",
    label: "Type of entity",
    fieldName: "typeOfEntity",
    compName: "kybSelectInput",
    placeholder: "Select entity type",
    items: [
      "Company",
      "Limited Liability Partnership",
      "Partnership",
      "Sole Proprietorship",
      // "Individual",
      "Trust",
      "Nonprofit Organization",
      "Corporation",
      "Others",
    ],
    required: true,
    errors: [],
    modelKey: "ind_bus_type",
  },
};
export const personalDetailsComponents = {
  firstName: {
    id: "first-name",
    label: "First Name of Individual",
    placeholder: "",
    fieldName: "firstName",
    compName: "kybTextInput",
    required: true,
    type: "text",
  },
  lastName: {
    id: "last-name",
    label: "Last Name of Individual",
    placeholder: "",
    fieldName: "lastName",
    compName: "kybTextInput",
    required: true,
    type: "text",
  },
  nationality: {
    id: "nationality",
    label: "Nationality",
    placeholder: "Select a country",
    fieldName: "nationality",
    compName: "kybSelectInput",
    items: getcountryList(),
    required: true,
  },
  address: {
    id: "address",
    label: "Residence Address",
    fieldName: "address",
    compName: "kybAddressInput",
    responseKey: "residenceAddress",
    required: true,
  },
  description: {
    id: "business-description",
    label: "Nature of Business",
    placeholder: "Enter nature of business",
    fieldName: "description",
    compName: "kybTextInput",
    required: true,
    type: "text",
  },
  website: {
    id: "website",
    label: "Website",
    placeholder: "Business website or social media page",
    fieldName: "website",
    compName: "kybTextInput",
    required: true,
    type: "text",
    validation: "url",
  },
};

export const individualDocuments = {
  identityProofType: {
    id: "identity-proof",
    label: "Individual’s Identity proof",
    fieldName: "identityProofType",
    compName: "kybSelectInput",
    placeholder: "Select ID proof",
    items: ["Passport", "Driving License", "National ID", "Other"],
    required: true,
  },
  identityProofTypeFrontDoc: {
    id: "individual-proof-front",
    label: "ID proof's First page/ Frontside",
    fieldName: "identityProofTypeFrontDoc",
    compName: "kybFileUpload",
    required: true,
  },
  identityProofTypeBackDoc: {
    id: "individual-proof-back",
    label: "ID proof's last page/ Backside",
    fieldName: "identityProofTypeBackDoc",
    compName: "kybFileUpload",
    required: true,
  },
  addressProof: {
    id: "address-proof",
    label: "Address proof",
    fieldName: "addressProof",
    compName: "kybCheckbox",
    checkboxDescription: "Same as in Identity proof",
    required: true,
  },
};

export const soleLegalReprentative = {
  sole_legal_reprentative_first_name: {
    id: "sole-legal-reprentative-name",
    label: "Authorized Representative’s First Name",
    fieldName: "soleLegalReprentativeFirstName",
    compName: "kybTextInput",
    required: true,
    type: "text",
    errors: [],
    modelKey: "firstName",
  },
  sole_legal_reprentative_last_name: {
    id: "sole-legal-reprentative-name",
    label: "Authorized Representative’s Last Name",
    fieldName: "soleLegalReprentativeLastName",
    compName: "kybTextInput",
    required: true,
    type: "text",
    errors: [],
    modelKey: "lastName",
  },
  sole_legal_reprentative_nationality: {
    id: "sole-legal-reprentative-nationality",
    label: "Nationality",
    fieldName: "soleLegalReprentativeNationality",
    compName: "kybSelectInput",
    placeholder: "Select Nationality",
    items: getcountryList(),
    required: true,
    errors: [],
    modelKey: "nationality",
  },

  sole_legal_reprentative_ownership: {
    id: "sole-legal-reprentative-ownership",
    label: "Ownership",
    fieldName: "soleLegalReprentativeOwnership",
    compName: "kybRadioGroup",
    radioDescription: "Do you own or control full 100% of the business",
    required: true,
    errors: [],
    modelKey: "ownership",
  },
  sole_legal_reprentative_role: {
    id: "sole-legal-reprentative-role",
    label: "Role",
    fieldName: "soleLegalReprentativeRole",
    compName: "kybSelectInput",
    placeholder: "Select Role",
    description: "Please select all roles relevant to you",
    items: ["Authorized Representative", "Beneficial Owner", "Director"],
    required: true,
    multiple: true,
    errors: [],
    modelKey: "roles",
    beneficialError: true,
  },
};

export const businessLegalReprentative = {
  business_legal_reprentative_first_name: {
    id: "business-legal-reprentative-first-name",
    label: "Authorized Representative’s First Name",
    fieldName: "legalReprentativeFirstName",
    compName: "kybTextInput",
    required: true,
    type: "text",
    errors: [],
    modelKey: "firstName",
  },
  business_legal_reprentative_last_name: {
    id: "business-legal-reprentative-last-name",
    label: "Authorized Representative’s Last Name",
    fieldName: "legalReprentativeLastName",
    compName: "kybTextInput",
    required: true,
    type: "text",
    errors: [],
    modelKey: "lastName",
  },

  business_legal_reprentative_nationality: {
    id: "business-legal-reprentative-nationality",
    label: "Nationality",
    fieldName: "legalReprentativeNationality",
    compName: "kybSelectInput",
    placeholder: "Select Nationality",
    items: getcountryList(),
    required: true,
    errors: [],
    modelKey: "nationality",
  },
  business_legal_reprentative_ownership: {
    id: "business-legal-reprentative-ownership",
    label: "Ownership",
    fieldName: "legalReprentativeOwnership",
    compName: "kybRadioGroup",
    radioDescription: "Do you own or control 25% or more of the business?",
    required: true,
    errors: [],
    modelKey: "ownership",
  },
  business_legal_reprentative_role: {
    id: "business-legal-reprentative-role",
    label: "Role",
    fieldName: "legalReprentativeRole",
    compName: "kybSelectInput",
    placeholder: "Select Role",
    description: "Please select all roles relevant to you",
    items: ["Authorized Representative", "Beneficial Owner", "Director"],
    required: true,
    multiple: true,
    errors: [],
    modelKey: "roles",
    beneficialError: true,
  },
};

export const businessDetail = {
  name: {
    id: "name",
    label: "Registered Business Name",
    placeholder: "Enter business name",
    fieldName: "name",
    compName: "kybTextInput",
    required: true,
    type: "text",
    errors: [],
    modelKey: "name",
  },
  pan: {
    id: "pan",
    label: "Business PAN",
    placeholder: "Enter business PAN",
    fieldName: "pan",
    compName: "kybTextInput",
    required: true,
    type: "text",
    errors: [],
    validation: "pan",
    modelKey: "pan",
  },
  yearsInOperation: {
    id: "yearsInOperation",
    label: "Years in Operation",
    placeholder: "Select number of years",
    fieldName: "yearsInOperation",
    compName: "kybSelectInput",
    items: ["0 - 1 Year", "1 - 5 Years", "5 - 10 Years", "10 Years & above"],
    required: true,
    type: "text",
    errors: [],
    modelKey: "yearsInOperation",
  },
  turnover: {
    id: "turnover",
    label: "Business Turnover (in USD)",
    placeholder: "Select turnover",
    fieldName: "turnover",
    compName: "kybSelectInput",
    items: ["Less than 100k", "100k - 500k", "500k - 1M", "1M - 5M", "5M and above"],
    required: true,
    type: "text",
    errors: [],
    modelKey: "turnover",
  },
  address: {
    id: "address",
    label: "Operating address",
    fieldName: "address",
    compName: "kybAddressInput",
    responseKey: "residenceAddress",
    validationErrors: {},
    modelKey: "address",
    required: true,
  },
  description: {
    id: "business-description",
    label: "Nature of Business",
    placeholder: "Enter nature of business",
    fieldName: "description",
    compName: "kybTextInput",
    required: true,
    errors: [],
    type: "text",
    modelKey: "description",
  },
  businessDescriptionDetail: {
    id: "business-description-detail",
    label: "Business Description",
    placeholder: "Please tell us more about your business",
    fieldName: "businessDescriptionDetail",
    compName: "kybTextAreaInput",
    minlength: 30,
    required: true,
    type: "textarea",
    errors: [],
    modelKey: "businessDescriptionDetail",
  },
  website: {
    id: "website",
    label: "Website Link",
    placeholder: "Enter the business website",
    fieldName: "website",
    compName: "kybTextInput",
    leftIcon: "link",
    required: true,
    errors: [],
    type: "text",
    validation: "url",
    modelKey: "website",
  },
  socialMedia: {
    id: "socialMedia",
    label: "Social Media",
    fieldName: "socialMedia",
    compName: "SocialMedia",
    responseKey: "socialMedia",
    optional: true,
    validationErrors: {},
    modelKey: "socialMedia",
    required: false,
  },
};

export const businessDocuments = {
  identityProofType: {
    id: "identity-proof",
    label: "Legal Representative’s Identity proof",
    fieldName: "identityProofType",
    compName: "kybSelectInput",
    placeholder: "Select ID proof",
    items: ["Passport", "Driving License", "National ID", "Other"],
    required: true,
  },
  identityProofTypeFrontDoc: {
    id: "individual-proof-front",
    label: "ID proof's First page/ Frontside",
    fieldName: "identityProofTypeFrontDoc",
    compName: "kybFileUpload",
    required: true,
  },
  identityProofTypeBackDoc: {
    id: "individual-proof-back",
    label: " ID proof's last page/ Backside",
    fieldName: "identityProofTypeBackDoc",
    compName: "kybFileUpload",
    required: true,
  },
  registrationProofDoc: {
    id: "business_registration_proof",
    label: "Business Registration proof",
    fieldName: "registrationProofDoc",
    compName: "kybFileUpload",
    required: true,
  },
  addressProof: {
    id: "address-proof",
    label: "Operating address proof",
    fieldName: "addressProof",
    compName: "kybCheckbox",
    checkboxDescription: "Same as in business registration proof",
    required: true,
  },
};

export const identifyUser = (userData) => {
  window?.FS_FullStory?.identify(userData.id, {
    displayName: userData.username,
    email: userData.email,
  });
};

export const toCamelCase = (strValue) => {
  const arr = strValue.split(" ");

  let formattedStrValue = [];
  arr.forEach((item) => {
    formattedStrValue.push(item.charAt(0).toUpperCase() + item.slice(1));
  });
  formattedStrValue = formattedStrValue.join(" ");
  return formattedStrValue;
};

export const stopDataCaptureByEmailId = (email) => {
  if (/@tazapay.com\s*$/.test(email)) {
    window?.FS_FullStory?.shutdown();
  }
};

export const signedInUser = (userData) => {
  window?.FS_FullStory?.event('SignedIn User', {
    id: userData.id,
    name: userData.username,
    email: userData.email,
    type: userData.type
  });
};
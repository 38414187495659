import { defineStore } from "pinia";

export const useGlobalStore = defineStore("Global", {
  state: () => ({
    kybApproved: null,
    qualityFlag: "",
    kybStatus: "not_started",
    isKybFetched: false,
    accountInfo: {},
    tradeRole: "",
    partner_code: "",
    platformInfo: {}
  }),
  getters: {
    getKybApprovedStatus: (state) => state.kybApproved,
    getKybStatus: (state) => state.kybStatus,
    getQualityFlag: (state) => state.qualityFlag,
    getAccountInfo: (state) => state.accountInfo,
    getTradeRole: (state) => state.tradeRole,
    getPartnerCode: (state) => state.partner_code,
  },
  actions: {
    // reset the flag after succcessfull redirect
    setKybApprovedStatus(payload) {
      this.kybApproved = payload;
    },
    setQualityFlag(payload) {
      this.qualityFlag = payload;
    },
    setKybStatus(payload,kybFetch) {
      this.isKybFetched = kybFetch;
      this.kybStatus = payload;
    },
    setAccountInfo(payload) {
      this.accountInfo = payload;
    },
    setTradeRole(payload) {
      this.tradeRole = payload;
    },
    setPartnerCode(payload) {
      this.partner_code = payload;
    },
  },
});

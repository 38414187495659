<template>
  <div>
    <div class="tw-bg-white">
      <div
        class="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-px-6 tw-pt-4 md:tw-h-14 md:tw-flex-row md:tw-items-center md:tw-justify-between md:tw-pt-0"
      >
        <div class="tw-text-xl tw-font-bold md:tw-text-2xl">Generated Reports</div>
        <div class="tw-flex tw-items-center tw-gap-2">
          <tc-icon icon="help-circle" color="primary-700" />
          <a
            class="tw-text-xs tw-font-semibold tw-leading-xs tw-text-primary-700"
            href="https://support.tazapay.com/what-do-the-columns-in-the-exported-csv-file-mean"
            target="_blank"
          >
            What do the columns in the exported CSV file mean?
          </a>
        </div>
      </div>
      <div class="tw-hidden tw-w-full tw-items-center tw-bg-info-50 tw-text-xs tw-font-bold md:tw-flex">
        <div class="tw-w-full tw-py-3 tw-pl-4">Report</div>
        <div class="tw-flex tw-w-full tw-items-center">
          <span>Requested Date</span>
          <span class="tw-ml-1 tw-flex tw-flex-col tw-justify-between">
            <img class="tw-cursor-pointer" src="@/assets/images/taza-icon/menu-up.svg" @click="sortReport('desc')" />
            <img
              class="tw-mt-1 tw-cursor-pointer"
              src="@/assets/images/taza-icon/menu-down.svg"
              @click="sortReport('asc')"
            />
          </span>
        </div>
        <div class="tw-w-full tw-pr-4">Action</div>
      </div>
      <div
        v-for="report in reportList"
        :key="report.file_name"
        class="tw-mt-4 tw-block tw-w-full tw-items-center tw-rounded-lg tw-border-0 tw-border-b-2 tw-border-solid tw-border-neutral-100 tw-bg-white tw-text-xs tw-shadow-2 md:tw-mt-0 md:tw-flex md:tw-rounded-none"
      >
        <div class="tw-w-full tw-py-4 tw-pl-4">
          <div class="tw-text-sm tw-font-bold tw-leading-lg tw-text-primary-700">{{ reportTypes[report.type] }}</div>
          <div class="tw-text-xs tw-leading-sm tw-text-neutral-600">{{ getFilterDate(report.applied_filters) }}</div>
        </div>
        <div class="tw-flex tw-w-full tw-py-4 tw-pl-4 md:tw-block md:tw-pl-0">
          <div
            class="tw-mr-1 tw-text-xs tw-leading-sm tw-text-neutral-500 md:tw-hidden md:tw-text-sm md:tw-text-neutral-700"
          >
            Requested
          </div>
          <div class="tw-text-xs tw-leading-sm tw-text-neutral-500 md:tw-text-sm md:tw-text-neutral-700">
            {{ getDate(report.created_at) }}
          </div>
          <div class="tw-ml-2 tw-text-xs tw-leading-sm tw-text-neutral-500 md:tw-ml-0">
            {{ getTime(report.created_at) }}
          </div>
        </div>
        <div
          class="tw-w-full tw-border-0 tw-border-t tw-border-solid tw-border-neutral-150 tw-bg-neutral-50 tw-py-2 tw-pr-4 md:tw-border-t-0 md:tw-bg-white md:tw-py-4"
        >
          <div
            v-if="report.status === 'preparing'"
            class="tw-px-4 tw-py-2 tw-text-xs tw-font-bold tw-leading-sm tw-text-neutral-500 md:tw-px-0"
          >
            Preparing download...
          </div>
          <button
            v-else-if="report.status === 'ready'"
            class="report-download-btn tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded tw-border-0 tw-border-solid tw-border-neutral-200 tw-px-4 tw-py-2 tw-text-primary-700 tw-transition-all md:tw-w-auto md:tw-justify-start md:tw-border md:tw-shadow-2"
            @click="initiateDownload(report.file_name)"
          >
            <tc-icon class="tw-hidden md:tw-block" icon="tray-arrow-down"></tc-icon>
            <span class="tw-ml-1 tw-font-bold">Download</span>
            <tc-icon class="tw-block md:tw-hidden" icon="chevron-right"></tc-icon>
          </button>
          <div
            v-else-if="report.status === 'failed'"
            class="tw-flex tw-w-full tw-justify-between tw-px-4 tw-py-2 tw-font-bold md:tw-w-auto md:tw-justify-start md:tw-px-0"
          >
            <span class="tw-text-danger-600">Request Failed</span>
            <button @click="retryDownload(report)" class="tw-ml-3 tw-text-xs tw-leading-sm tw-text-info-600">
              <tc-icon icon="reload"></tc-icon>
              <span class="tw-ml-1 tw-underline">Try again</span>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="tw-pt-4 tw-text-center tw-font-bold" v-if="!reportList.length">{{ description }}</div> -->
      <div class="tw-pt-4 tw-text-center tw-font-bold" v-if="!reportList.length">
        <Noreports />
      </div>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import Noreports from "./NoReports.vue";
import { getLocalStorage } from "@/helpers/storageService";
import { getAccountDetails } from "@/api/settings";
import { getReports, fetchStatus, downloadReport, triggerReport } from "@/api/reportsAPI";
import { onMounted, ref, onUnmounted } from "vue";
import { clearStorage } from "@/helpers/storageService.js";
import { useRouter } from "vue-router";
import { h } from "vue";
import { ERROR_TYPE } from "@/constants/errorCodes.js";
import { useNotificationStore } from "@/stores/NotificationStore";

const notificationStore = useNotificationStore();
const router = useRouter();

let sortOrder = "desc";
let timerId = "";
let description = ref("Fetching..Please Wait");
let reportList = ref([]);

function sortReport(e) {
  sortOrder = e;
  fetchReportDetails();
}

const reportTypes = {
  all_transactions: "All Transactions",
  succeeded_transactions: "Succeeded Transactions",
  pending_transactions: "Pending Transactions",
  refunded_transactions: "Refunded Transactions",
  failed_transactions: "Failed Transactions",
  mp_all_payout_transaction: "Settlement Transactions",
  escrow: "Escrow",
  payment_link: "Payment Link",
};

async function getAccountId() {
  let accountId = getLocalStorage("loggedInUserId");
  if (accountId) {
    return accountId;
  }
  accountId = await getAccountDetails()
    .then((res) => {
      return res.data.id;
    })
    .catch((err) => {
      console.error(err);
    });
  return accountId;
}

function getTime(dateStr) {
  let dateIns = new Date(dateStr);
  return dateIns.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
}

function getDate(dateStr) {
  let dateIns = new Date(dateStr);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return `${dateIns.getDate()} ${monthNames[dateIns.getMonth()]} ${dateIns.getFullYear()}`;
}

function getFilterDate(filterData) {
  let fromDate = filterData["from_date"];
  // hack to show all Data in report listing. when no date is selected.
  if (fromDate === "0001-01-01T00:00:00Z") {
    fromDate = null;
  }
  let toDate = filterData["to_date"];
  if (fromDate && toDate) {
    return `${getDate(fromDate)} -  ${getDate(toDate)}`;
  } else {
    return "All data";
  }
}

function initiateFetchStatus() {
  let fileNames = reportList.value.map((report) => report.file_name);
  if (fileNames.length) {
    timerId = setInterval(() => {
      let params = {
        file_name: fileNames.join(),
      };
      fetchReportStatus(params);
    }, 5000);
  }
}

function fetchReportStatus(params) {
  fetchStatus(params).then(
    (res) => {
      let result = res.data.data;
      reportList.value.forEach((report) => {
        result.forEach((resItem) => {
          if (resItem.id === report.id) {
            report.status = resItem.status;
          }
        });
      });
    },
    (rej) => {
      if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
        clearStorage();
        router.push("/signin");
      } else {
        showErrorToaster();
      }
    }
  );
}

function fetchReportDetails() {
  getAccountId()
    .then((accountId) => {
      let queryParams = {
        account_id: accountId,
        source: "mp",
        sort_order: sortOrder,
      };
      getReports(queryParams).then(
        (res) => {
          reportList.value = res.data.data ?? [];
          description.value = reportList.value.length ? "Fetching..Please Wait" : "No Reports Found";
          initiateFetchStatus();
        },
        (rej) => {
          if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
            clearStorage();
            router.push("/signin");
          } else {
            showErrorToaster();
          }
        }
      );
    })
    .catch((err) => {
      console.error(err);
    });
}

function initiateDownload(fileName) {
  downloadReport(fileName).then(
    (res) => {
      let fileURL = res.data.data.file_url;
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "export.csv");
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    (rej) => {
      if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
        clearStorage();
        router.push("/signin");
      } else {
        showErrorToaster();
      }
    }
  );
}

function retryDownload(report) {
  let reqBody = {
    applied_filters: report.applied_filters,
    report_type: report.type,
    generated_by: getLocalStorage("userEmail"),
  };
  triggerReport(reqBody).then(
    () => {
      showPreparingToaster();
    },
    (rej) => {
      if (rej?.message?.includes(ERROR_TYPE.UNAUTHORISED.MESSAGE_SHORT)) {
        clearStorage();
        router.push("/signin");
      } else {
        showErrorToaster();
      }
    }
  );
}

function showErrorToaster() {
  const notification = {
    type: "error",
    icon: "refresh",
    message: h(
      "span",
      {
        class: "tw-font-bold tw-text-base tw-leading-sm",
      },
      "Something went wrong. Please try again"
    ),
  };
  notificationStore.add(notification);
}

function showPreparingToaster() {
  const notification = {
    type: "info",
    icon: "refresh",
    message: h(
      "span",
      {
        class: "tw-font-bold tw-text-base tw-text-primary-700 tw-leading-sm",
      },
      "Preparing download. Please wait"
    ),
  };
  notificationStore.add(notification);
}

onMounted(() => {
  fetchReportDetails();
});

onUnmounted(() => {
  clearInterval(timerId);
});
</script>

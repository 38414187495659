<template>
  <span :class="classList()">
    <slot></slot>
  </span>
</template>
<script>
export default {
  name: 'tc-text',
  data(){
    return{
      fontWeight: 'normal',
      lineHeight: 'base'
    }
  },
  props: {
    size: {
      type: String,
      default: 'base',
      validator: function (value) {
        // The value must match one of these strings
        return ['xs', 'sm','base','lg','xl','2xl','3xl','4xl','5xl'].indexOf(value) !== -1
      }
    },
    weight: {
      type: String,
      default: 'normal',
      validator: function (value) {
        // The value must match one of these strings
        return ['normal', 'semibold'].indexOf(value) !== -1
      }
    },
    //line-height
    lh: {
      type: String,      
      validator: function (value) {
        // The value must match one of these strings
        return ['sm', 'base', 'lg'].indexOf(value) !== -1
      }
    }
  },
  methods: {
    classList(){
      // font-weight semibold - applicable for all font-size
      // font-weight normal - not applicable above 20px
      switch(this.size){
        case 'xs':
          this.fontWeight = this.weight || this.fontWeight
          this.lineHeight = this.lh || 'sm'
          break;
        case 'sm':
        case 'base':
          this.fontWeight = this.weight || this.fontWeight
          this.lineHeight = this.lh || 'base'
          break;
        case 'lg':
        case 'xl':
        case '2xl':
          this.fontWeight = 'semibold'
          this.lineHeight = this.lh || 'sm'
          break;
        case '3xl':
        case '4xl':
        case '5xl':
          this.fontWeight = 'semibold'
          this.lineHeight = this.lh || 'xs'
      }
      return [
        `tw-text-${this.size}`,
        `tw-font-${this.fontWeight}`,
        `tw-leading-${this.lineHeight}`
      ]
    }
  },
  created(){
    this.classList();
  }
}
</script>
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobileConfig = () => {
  const environment = import.meta.env.VITE_ENV_NAME;
  const redirectSignIn = import.meta.env.VITE_SIGNIN_URL;
  const redirectSignOut = import.meta.env.VITE_SIGNOUT_URL;
  let awsAuthConfig = {};
  if (environment === 'production')  {
    awsAuthConfig = {
      "aws_project_region": "ap-southeast-1",
      "aws_cognito_region": "ap-southeast-1",
      "aws_user_pools_id": "ap-southeast-1_utkx4cYKf",
      "aws_user_pools_web_client_id": "32s023bhjjjoimrb24gokhk6oo",
      "oauth": {
          "domain": "auth.tazapay.com",
          "scope": [ "phone", "email", "openid", "profile","aws.cognito.signin.user.admin"],
          "redirectSignIn": redirectSignIn,
          "redirectSignOut": redirectSignOut,
          "responseType": "token"
      },
      "federationTarget": "COGNITO_USER_POOLS"
    }
  } else if (environment === 'dev')  {
    awsAuthConfig = {
      "aws_project_region": "ap-southeast-1",
      "aws_cognito_region": "ap-southeast-1",
      "aws_user_pools_id": "ap-southeast-1_vKPx4S1N1",
      "aws_user_pools_web_client_id": "7t8r0gt64e1vlqitt43bqmgii",
      "oauth": {
          "domain": "auth-dev.tazapay.com",
          "scope": [ "phone", "email", "openid", "profile","aws.cognito.signin.user.admin"],
          "redirectSignIn": redirectSignIn,
          "redirectSignOut": redirectSignOut,
          "responseType": "token"
      },
      "federationTarget": "COGNITO_USER_POOLS"
    }
   } else {
    awsAuthConfig = {
      "aws_project_region": "ap-southeast-1",
      "aws_cognito_region": "ap-southeast-1",
      "aws_user_pools_id": "ap-southeast-1_vKPx4S1N1",
      "aws_user_pools_web_client_id": "42tuen1u1ahuo344bqcf4htifp",
      "oauth": {
          "domain": "auth-dev.tazapay.com",
          "scope": [ "phone", "email", "openid", "profile","aws.cognito.signin.user.admin"],
          "redirectSignIn": redirectSignIn,
          "redirectSignOut": redirectSignOut,
          "responseType": "token"
      },
      "federationTarget": "COGNITO_USER_POOLS"
    }
  }
  return awsAuthConfig;
};

const awsmobile = awsmobileConfig()

export default awsmobile;

<template>
  <div
    class="kyb-text-input tw-flex tw-flex-col tw-pt-2 tw-pb-4 sm:tw-flex-row sm:tw-pb-2"
    :class="{ 'tw-pt-4 sm:tw-pb-4': viewMode }"
  >
    <div v-if="label" class="tw-mb-2 tw-mr-8 tw-w-auto sm:tw-mb-0 sm:tw-w-48">
      <label class="tw-text-sm tw-font-bold tw-leading-sm tw-text-neutral-700">{{ label }}</label>
      <label v-if="optional && !viewMode" class="tw-ml-1 tw-text-xs tw-text-neutral-400">(optional)</label>
    </div>
    <input-text-area
      v-if="!viewMode"
      class="tw-w-full sm:tw-w-80"
      :title="value"
      :class="{ 'contains-error': errorMsg.length > 0 }"
      :errors="errorMsg"
      :placeholder="placeholder"
      :minlength="minlength" 
      :maxlength="maxlength"
      @textInputOnChange="handleChange"
      @inputOnFocus="$emit('onFocus')"
      @inputOnBlur="$emit('onBlur')"
      :disabled="disabled"
      :modelValue="value"
    ></input-text-area>
    <label
      v-if="viewMode"
      class="value-label tw-w-full tw-break-all tw-text-base tw-leading-sm tw-text-neutral-700 sm:tw-w-80"
      >{{ value ? value : "-" }}</label
    >
  </div>
</template>

<script>
import { InputTextArea } from "tazapay-ui";

// import InputTextArea from "@/ui-components/formControls/InputTextArea.vue";
import FormValidation from "@/ui-components/formBuilder/FormValidation";

export default {
  components: { InputTextArea },
  props: {
    label: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    optional: {
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    validation: {
      type: String,
    },
    minlength: {
      type: Number
    },
    maxlength: {
      type: Number
    },
  },
  data() {
    return {
      errorMsg: [],
      formValidation: new FormValidation(),
    };
  },
  watch: {
    errors: {
      handler(){
        this.$nextTick(()=>
          this.errorMsg =  [...new Set([...this.errorMsg, ...this.errors])]
        )
        },
        deep:true
    }
  },
  methods: {
    handleChange(e) {
      this.validateInput(e);
      let eventData = {
        fieldName: this.fieldName,
        value: e,
      };
      this.$emit("onChange", eventData);
    },
    validateInput(e) {
      let validations = [];
      this.required ? validations.push({ key: "required" }) : "";
      this.minlength ? validations.push({ key: "minlength", value: this.minlength }) : "";
      this.errorMsg = [...this.formValidation.validateModel({ model: e }, { validations, required: true })];
      return this.errorMsg.length === 0;
    },
  },
};
</script>

<style scoped>
.kyb-text-input:deep .input-holder {
  margin-bottom: 0px;
}
.kyb-text-input:deep .error-holder {
  margin-top: 0px;
}
.kyb-text-input:deep .input-box {
  height: 120px;
}

.kyb-text-input:deep .contains-error .error-holder {
  margin-top: 5px;
}
.kyb-text-input:deep .input-holder .input-box:disabled {
  @apply tw-bg-neutral-200;
}

/* .value-label {
  line-height: 1;
}
@media screen and (min-width: 600px) {
  .value-label {
    line-height: 48px;
  }
} */
</style>

let REST_URL = import.meta.env.VITE_BASE_URL

export async function Signup (userModel) {
  let data = {
    first_name: userModel.first_name,
    last_name: userModel.last_name,
    email: userModel.email,
    password: userModel.password,
    country: userModel.country,
    contact_code: userModel.contact_code,
    contact_number: userModel.contact_number,
    escrow_id: userModel.escrow_id,
    description: userModel.description,
    business_name: userModel.business_name,
  }
  //TODO - add sanity checks for name, email, password not empty
  try {
    const response = await fetch(`${REST_URL}/api/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const out = await response.json()

    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}

export async function Signin (email, password) {
  let data = {
    email,
    password
  }
  let searchParams = new URLSearchParams(window.location.search)
  let referrer = searchParams.get('ref');
  try {
    const response = await fetch(`${REST_URL}/api/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "x-referer": referrer,
      },
      body: JSON.stringify(data)
    })
    const out = await response.json()
    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}

export async function Signout () {
  try {
    const response = await fetch(`${REST_URL}/api/signout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.accessToken
      }
    })
    const out = await response.json()

    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}

export async function ForgotPassword (email) {
  let data = {
    email
  }
  try {
    const response = await fetch(`${REST_URL}/api/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const out = await response.json()
    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}

export async function ConfirmPassword (email, confirmation_code, password) {
  let data = {
    email,
    confirmation_code,
    password
  }
  try {
    const response = await fetch(`${REST_URL}/api/confirm-forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const out = await response.json()
    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}

export async function VerifyEmail (email) {
  let data = {
    email
  }
  try {
    const response = await fetch(`${REST_URL}/api/verify-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.accessToken
      },
      body: JSON.stringify(data)
    })
    const out = await response.json()
    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}

export async function SignupPartnerUser (userModel) {
  let data = {
    account_id: userModel.account_id,
    code: userModel.code,
    customer_id: userModel.customer_id,
    signature: userModel.signature,
    tp_url: userModel.tp_url
  }

  try {
    const response = await fetch(`${REST_URL}/api/signup-partner-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.accessToken
      },
      body: JSON.stringify(data)
    })
    const out = await response.json()

    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}

export async function PartnerSignOut () {

  try {
    const response = await fetch(`${REST_URL}/api/partnersignout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.accessToken
      }
    })
    const out = await response.json()

    return {
      output: out,
      status: response.status
    }
  } catch (error) {
    //TODO - standardize error status codes.
    return {
      error: error,
      status: '404'
    }
  }
}
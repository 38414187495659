<template>
  <div
    class="tw-mt-4 tw-flex tw-items-center tw-rounded tw-transition tw-duration-150 tw-ease-in-out"
    :class="computedClass"
    @click="handleClick"
  >
    <button class="tw-py-4 tw-pl-3 tw-pr-2 tw-text-sm tw-font-bold" :class="{ 'tw-cursor-default': !isCompleted }">
      {{ label }}
    </button>
    <tc-icon v-if="isCompleted" icon="check-circle"></tc-icon>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    isCompleted: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedClass() {
      if (this.active) {
        return ["tw-bg-primary-800", "tw-text-white"];
      } else if (this.isCompleted) {
        return ["tw-bg-transparent", "tw-text-success-800"];
      }
      return ["tw-bg-transparent", "tw-text-neutral-400"];
    },
  },
  methods: {
    handleClick(e) {
      if (this.isCompleted && this.disabled) {
        let eventData = {
          event: e,
          value: this.id,
        };
        this.$emit("selectedItem", eventData);
      }
    },
  },
};
</script>

<style></style>

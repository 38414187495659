<template>
  <!-- 
  SIDEBAR >> NAVIGATION
 -->
  <div class="tl-nav" id="tl-nav">
    <!-- 
    NAV CLOSE ON MOBILE      
  -->
    <div v-show="isNavBarOpen" class="tl-nav__close" @click="hideNavOptions">
      <i class="mdi mdi-close tw-text-2xl tw-text-primary-800"></i>
    </div>
    <!-- 
    NAV OPEN ON MOBILE
  -->
    <div v-show="!isNavBarOpen" class="tl-nav__open" @click="showNavOptions">      
      <img src="@/assets/images/sidebar/hamburger.svg" class="tl-nav__hamburger" />      
    </div>

    <div class="tl-nav__wrapper tw-flex-col xl:tw-flex" :class="[isNavBarOpen ? 'tw-top-12' : 'tw-hidden']" id="tl-nav-wrapper">
      <!-- 
      NAV HEADER
    -->
      <div class="tl-nav__header">
        <img class="tl-nav__logo" data-gtm-key="logo_click" src="@/assets/images/sidebar/tazapay.svg" height="32" />
        <div class="tl-nav__collapsable" @click="toggleNavCollapse">
          <div class="tl-nav__collapse" data-tippy-content="Collapse Navbar">
            <img src="@/assets/images/sidebar/navCollapse.svg" />
          </div>
          <div class="tl-nav__collapsed" data-tippy-content="Expand Navbar">
            <img src="@/assets/images/sidebar/menu.svg" class="tl-nav__collapsed-default" />
            <img src="@/assets/images/sidebar/navCollapsed.svg" class="tl-nav__collapsed-hover"/>
          </div>
        </div>
      </div>

      <div class="tl-nav__content">
        <!-- 
        NAV LIST
      -->
        <div v-for="navItem in navList" :key="navItem" class="tl-nav__list">
          <div
            v-for="nav in navItem"
            :key="nav"
            class="tl-nav__item"
            :class="[{ 'tl-nav__item-sub': nav?.sub, 'tl-nav__item-sub--active': nav?.inSubNav && nav?.sub }, nav.classesItem]"
          >
            <div class="tl-nav__title" v-if="nav.title && nav.isVisible" :data-cy="nav.cypress">
              <span>{{ nav.title }}</span>
            </div>
            <router-link
              :to="nav?.route"
              v-if="nav?.route && nav?.isVisible"
              @click="hideNavOptions"
              class="tl-nav__link"
              active-class="tl-nav__link--active"
              :data-tippy-content="nav?.text"
              :data-cy="nav.cypress"
            >
              <span class="tl-nav__icon" v-if="nav?.icon">
                <img :src="getImageUrl(nav.icon)" />
              </span>
              <span class="tl-nav__icon-selected" v-if="nav?.iconSelected">
                <img :src="getImageUrl(nav.iconSelected)" />
              </span>
              <span class="tl-nav__label">
                <span class="tl-nav__text">{{ nav?.text }}</span>
                <span class="tl-nav__tag tw-ml-1" v-if="nav?.tag">{{ nav.tag }}</span>
              </span>
              <span class="tl-nav__badge tw-ml-1" v-if="nav.badge">{{ nav.badge }}</span>
            </router-link>
            <div v-if="nav.sub" class="tl-nav__sub tw-mt-2">
              <div v-for="navSub in nav?.sub" :key="navSub.route">
                <router-link
                  :to="navSub?.route"
                  v-if="navSub?.route && navSub?.isVisible"
                  @click="hideNavOptions"
                  class="tl-nav__link"
                  active-class="tl-nav__link--active"
                  :data-tippy-content="navSub?.text"
                  :data-cy="navSub.cypress"
                >
                  <span class="tl-nav__icon" v-if="navSub?.icon">
                    <img :src="getImageUrl(navSub.icon)" />
                  </span>
                  <span class="tl-nav__icon-selected" v-if="navSub?.iconSelected">
                    <img :src="getImageUrl(navSub.iconSelected)" />
                  </span>
                  <span class="tl-nav__label">
                    <span class="tl-nav__text">{{ navSub?.text }}</span>
                    <span class="tl-nav__tag tw-ml-1" v-if="navSub.tag">{{ navSub.tag }}</span>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="tl-nav__footer tw-hidden tw-items-center tw-justify-center xl:tw-flex">
        <div @click="toggleNavCollapse">
          <div class="tl-nav__collapse">
            <img src="@/assets/images/sidebar/navCollapse.svg" />
            <div>Collapse Navbar</div>
          </div>
          <div class="tl-nav__collapsed" data-tippy-content="Expand Navbar">
            <img src="@/assets/images/sidebar/navCollapsed.svg" />
          </div>
        </div>
      </div> -->
    </div>

    <!-- 
    NAV OVERLAY ON MOBILE WHILE NAV OPENED
  -->
    <div
      class="tl-nav__overlay"
      :class="{ 'tw-hidden': !isNavBarOpen }"
      @click="hideNavOptions"
    ></div>
  </div>
</template>

<script>
//ROUTES
import { ROUTE_TRANSACTION, ROUTE_SIMPLIFIED, ROUTE_MARKETPLACE, ROUTE_ACTIONS } from "@/constants/route";
import { GetFlag } from "@/api/legacyapi/flags";
import { getPlatformUser } from "@/helpers/platformUser";
import { useAbsoluteUrl } from "@/composables/viteSupport.js";
import { getCurrentUserRole, hasPermission, 
  // hasCreatedKeys 
} from "@/helpers/permissionHelper";
import { allPermissions, roles } from "@/constants/permissions";
import { useActionRequiredStore } from "@/stores/ActionRequiredStore";
import { delegate } from "tippy.js";
import "tippy.js/dist/tippy.css";
import { mapState } from "pinia";

let tippyInstance = null;

export default {
  name: "Sidebar",
  data: function () {
    return {
      isEmailVerified: true,
      navList: [],
      userRole: "",
      ROLES: roles,
      isNavBarOpen: false,
    };
  },
  created() {
    useActionRequiredStore().getActionRequiredCount();
  },
  mounted() {    
    //on click on body, hide the nav if it is shown    
    document.documentElement.addEventListener('click', this.removeNavOptions, true);       
    this.getEmailVerifiedFlag();
    this.setNavDefaults();
    this.userRole = getCurrentUserRole();
    this.tippyToggle();
  },
  unmounted() {
    //remove the event listeners related to nav
    document.documentElement.removeEventListener("click", this.removeNavOptions, true);    
    this.tippyDestroy();
  },  
  computed: {
    isPlatformUser() {
      let platformUser = getPlatformUser() || "";
      return platformUser === "true";
    },
    inTransactionNav() {
      let subNavRoute = this.$route.path;
      if (
        subNavRoute === ROUTE_TRANSACTION.TRANSACTION_LISTING ||
        subNavRoute === ROUTE_SIMPLIFIED.CREATE_ESCROW ||
        subNavRoute === ROUTE_MARKETPLACE.CREATE_PAYMENT_LINK
      )
        return true;
      return false;
    },
    ...mapState(useActionRequiredStore, ["actionRequiredCount"]),
  },

  watch: {
    actionRequiredCount() {
      this.setNavDefaults();
    },
  },

  methods: {
    getImageUrl(img){
      return useAbsoluteUrl(`sidebar/${img}.svg`);
    },
    setNavDefaults() {
      this.navList = [
        [
          {
            text: "Home",
            route: "/home",
            icon: "home",
            iconSelected: "homeSelected",
            cypress: "homeLink",
            isVisible: hasPermission(allPermissions.ESCROW_CREATE),
          },
          {
            text: "Transactions",
            route: `${ROUTE_TRANSACTION.TRANSACTION_LISTING}`,
            icon: "list",
            iconSelected: "listSelected",
            cypress: "txnLink",
            isVisible: true,
            // tag: "NEW",
          },
          {
            text: "Action Required",
            route: `${ROUTE_ACTIONS.DELIVERY_PROOF}`,
            icon: "warning",
            iconSelected: "warningSelected",
            cypress: "actionsLink",
            isVisible: true,
            badge: this.actionRequiredCount,
          },
          {
            text: "Settlements",
            route: `${ROUTE_TRANSACTION.SETTLEMENT_LISTING}`,
            icon: "payout",
            iconSelected: "payoutSelected",
            cypress: "payoutLink",
            isVisible: true,
            classesItem: "tw-hidden md:tw-block",
          },
          {
            text: "Reports",
            route: "/reports",
            icon: "reports",
            iconSelected: "reportsSelected",
            cypress: "ReportLink",
            isVisible: hasPermission(allPermissions.PAYMENT_LINK_VIEW),
            classesItem: "tw-hidden md:tw-block",
          },
          // {
          //   text: "Transactions",
          //   route: "/agreement-listing",
          //   icon: "list",
          //   iconSelected: "listSelected",
          //   cypress: "escrowLink",
          //   isVisible: true,
          //   inSubNav: this.inTransactionNav,
          //   sub: [
          //     {
          //       text: "Create Escrow",
          //       route: "/simplified-creation",
          //       icon: "new",
          //       iconSelected: "newSelected",
          //       cypress: "createEscrowLink",
          //       isVisible: hasPermission(allPermissions.ESCROW_CREATE),
          //     },
          //     {
          //       text: "Create Payment Link",
          //       route: "/marketplace/create-payment-link",
          //       icon: "new",
          //       iconSelected: "newSelected",
          //       cypress: "paymentLink",
          //       isVisible: hasPermission(allPermissions.PAYMENT_LINK_CREATE),
          //       // tag: 'NEW'
          //     },
          //   ],
          // },          
        ],
        [
          {
            title: "Platforms & APIs",
            cypress: "platformText",
            isVisible: hasPermission(allPermissions.CONFIGURATION_KEY_VIEW),
          },
          {
            text: "Platform Config",
            route: "/marketplace/platform-config",
            icon: "configuration",
            iconSelected: "configurationSelected",
            cypress: "noConfigurationLink",
            isVisible: hasPermission(allPermissions.CONFIGURATION_KEY_VIEW),
          },
          {
            text: "API keys",
            route: "/marketplace/api-config",
            icon: "key",
            iconSelected: "keySelected",
            cypress: "apiConfig",
            isVisible:
              (this.isPlatformUser && hasPermission(allPermissions.CONFIGURATION_KEY_VIEW)) ||
              (!this.isPlatformUser && hasPermission(allPermissions.CONFIGURATION_KEY_CREATE)),
          },
        ],
        [
          {
            title: "Settings",
            cypress: "settingsLink",
            isVisible: true,
          },
          {
            text: "Account",
            route: "/account-details",
            icon: "account",
            iconSelected: "accountSelected",
            cypress: "accountLink",
            isVisible: true,
            // tag: hasCreatedKeys() ? "NEW" : "",
          },
          {
            text: "Bank Details",
            route: "/bank-details",
            icon: "bank",
            iconSelected: "bankSelected",
            cypress: "bankDetailsLink",
            isVisible: hasPermission(allPermissions.BANK_VIEW),
          },
        ],
      ];
    },
    showNavOptions() {
      this.isNavBarOpen = true;
      if (document.documentElement !== null) {
        document.documentElement.classList.add("tw-overflow-hidden");
      }
    },
    hideNavOptions() {           
      this.isNavBarOpen = false;
      document.documentElement.classList.remove("tw-overflow-hidden");
    },
    //on click on ROOT element, remove a class
    removeNavOptions(event) {
      if(document.getElementById("tl-nav-wrapper").isSameNode(event.target) ||
        document.getElementById("tl-nav-wrapper").contains(event.target)){
        // Event triggered from within the #tl-nav-wrapper div element
        // Do nothing and return
        return; 
      } 

      if (document.documentElement !== null) {
        this.hideNavOptions();        
      }
    },
    toggleNavCollapse() {
      document.getElementById("app").classList.toggle("tz-app--nav-collapsed");
      this.tippyToggle();
    },
    getEmailVerifiedFlag() {
      const flagName = "emailVerified";
      GetFlag(flagName)
        .then((response) => {
          let flagDetails = response?.output?.userFlagOutput;
          if (flagDetails === null) {
            this.isEmailVerified = false;
          }          
        })
        .catch((err) => {
          console.error(err);
        });
    },
    tippyInit() {
      this.tippyDestroy();
      tippyInstance = delegate("#tl-nav-wrapper", {
        target: "[data-tippy-content]", //get the element and grab the content from its attribute
        zIndex: "99000",
        placement: "right",
        theme: "nav", //css customization
      });
    },
    tippyDestroy() {
      if (tippyInstance) tippyInstance?.[0].destroy();
    },
    tippyToggle() {
      if (document.getElementById("app").classList.contains("tz-app--nav-collapsed")) {
        this.tippyInit();
      } else {
        this.tippyDestroy();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./layout.scss";
</style>
<style lang="scss">
[data-theme="nav"] {
  background-color: $neutral-100;
  color:$primary-800;
  box-shadow: 0px 2px 4px rgba(226, 232, 240, 0.24), 0px 4px 6px rgba(226, 232, 240, 0.5);
  border:1px solid $neutral-150;

  .tippy-content {
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
  }
  .tippy-arrow:before {
    border-right-color: $white !important;
  }
}
</style>

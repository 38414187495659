import AuthGuard from './guards/auth-guard';

export const AuthenticationRoutes = [
  {
    path: "/signin", name: "Signin", meta: { title: "Sign In", layout: "AccountLayout" },
    beforeEnter: AuthGuard,
    component: () => import("@/views/authentication/Signin.vue"),
  },
  {
    path: "/signup", name: "signup", meta: { title: "Sign Up" , layout: "BlankLayout" },
    beforeEnter: AuthGuard,
    component: () => import("@/views/authentication/Signup.vue")
  },
  {
    path: "/forgotpassword", name: "forgotpassword", meta: { title: "Forgot Password" , layout: "AccountLayout" },
    beforeEnter: AuthGuard,
    component: () => import("@/views/authentication/ForgotPassword.vue")
  },
  {
    path: "/escrowtermsandconditions", name: "escrowtermsandconditions", meta: { title: "Escrow Terms and Condition" , layout: "BlankLayout" },
    component: () => import("@/views/authentication/EscrowTermsConditions.vue")
  },
  {
    path: "/confirm-forgot-password", name: "resetpassword", meta: { title: "Reset Password" , layout: "AccountLayout" },
    beforeEnter: AuthGuard,
    component: () => import("@/views/authentication/ResetPassword.vue")
  },
  {
    path: "/changePasswordSuccesful", name: "resetsuccesful", meta: { title: "Reset Successful" , layout: "AccountLayout" },
    beforeEnter: AuthGuard,
    component: () => import("@/views/authentication/ResetSuccessful.vue")
  },
  {
    path: "/resetpasswordprogress", name: "resetpasswordprogress", meta: { title: "Reset Successful" , layout: "AccountLayout" },
    beforeEnter: AuthGuard,
    component: () => import("@/views/authentication/ResetPasswordProgress.vue")
  },
  {
    path: "/verificationlinkexpired", name: "verificationlinkexpired", meta: { title: "Verification link expired" , layout: "AccountLayout" },
    component: () => import("@/views/authentication/VerificationLinkExpired.vue")
  },
  {
    path: "/verificationcompleted", name: "verificationcompleted", meta: { title: "Verification Completed" , layout: "AccountLayout" },
    beforeEnter: AuthGuard,
    component: () => import("@/views/authentication/VerificationCompleted.vue")
  },
  {
    path: "/verify", name: "verify", meta: { title: "Verify Email" , layout: "AccountLayout" },
    component: () => import("@/views/authentication/Verify.vue")
  },
  {
    path: "/termsAndConditions/v1", name: "TermsConditions", meta: { title: "Terms and Conditions" , layout: "BlankLayout" },
    component: () => import("@/views/authentication/termsAndConditions/containers/termsConditionsContainer.vue")
  }


];